import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Form, DatePicker, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { IoTrashOutline } from 'react-icons/io5';
import FormLegend from '../../modUtils/components/FormLegend';
import AddPeriodConstraintModal from './AddPeriodConstraintModal';
import { ReactComponent as DeleteIcon } from '../../modEntity/assets/images/trash-can.svg';
import {
    Label,
    Modal,
    Spinner,
    Table,
} from '../../modUtils/components/componentsLibrary/componentsLibrary';
import {
    constraints_period_create,
    constraints_period_range_detail_delete,
    get_constraint_period_detail,
} from '../slices/constraintPeriodSlice';
import 'ck_commun/src/app/modProfil/style/AddPeriodRangeConstraintModal.scss';

export default function AddPeriodRangeConstraintModal({
    isEdit,
    periodRangesId,
    isModalVisible,
    selectedConfigId,
    setIsModalVisible,
}) {
    const formatDate = 'DD/MM';
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [periodId, setPeriodId] = useState(null);
    const [timePickers, setTimePickers] = useState([]);
    const [isEditPeriod, setIsEditPeriod] = useState(false);
    const { groupId } = useSelector((state) => state.manager);
    const { constraintsPeriodDetail } = useSelector(
        (state) => state.profil.constraintPeriod,
    );
    const [isModalVisibleAddPeriodRanges, setIsModalVisibleAddPeriodRanges] =
        useState(false);

    const getFormValue = () => {
        const MONTHS_MAP = {
            Janvier: 0,
            Février: 1,
            Mars: 2,
            Avril: 3,
            Mai: 4,
            Juin: 5,
            Juillet: 6,
            Août: 7,
            Septembre: 8,
            Octobre: 9,
            Novembre: 10,
            Décembre: 11,
        };

        const periodRanges = constraintsPeriodDetail?.period_ranges || [];
        const formattedRanges = periodRanges.map((range) => {
            const startMonthIndex = MONTHS_MAP[range.start_month] ?? null;
            const endMonthIndex = MONTHS_MAP[range.end_month] ?? null;

            const startDate =
                range.start_day && startMonthIndex !== null
                    ? dayjs().month(startMonthIndex).date(range.start_day)
                    : null;

            const endDate =
                range.end_day && endMonthIndex !== null
                    ? dayjs().month(endMonthIndex).date(range.end_day)
                    : null;

            return {
                id: range.id,
                startDate: startDate?.isValid() ? startDate : null,
                endDate: endDate?.isValid() ? endDate : null,
            };
        });

        // Initialisation des champs dans le formulaire
        const initialFormValues = {};
        formattedRanges.forEach(({ id, startDate, endDate }) => {
            initialFormValues[`startDate_${id}`] = startDate;
            initialFormValues[`endDate_${id}`] = endDate;
        });

        form.setFieldsValue(initialFormValues);
        setTimePickers(formattedRanges);
    };

    // Charger les détails de la contrainte
    const getConstraintPeriodDetail = async () => {
        setLoading(true);
        await dispatch(
            get_constraint_period_detail({
                groupId,
                configurationId: selectedConfigId,
                constraintId: periodRangesId,
            }),
        ).unwrap();
        setLoading(false);
    };

    // Creer une période
    const addNewPeriodConstraint = async (periodRanges) => {
        setLoading(true);
        await dispatch(
            constraints_period_create({
                groupId,
                configurationId: selectedConfigId,
                periodRanges,
            }),
        ).unwrap();
        setLoading(false);
    };

    // supprimer une période
    const deletePeriod = async (periodId) => {
        await dispatch(
            constraints_period_range_detail_delete({
                groupId,
                constraintPeriodId: periodRangesId,
                periodRangeId: periodId,
            }),
        ).unwrap();
    };

    // Effectuer une récupération de détails lors du mode édition
    useEffect(() => {
        if (isEdit && periodRangesId) {
            getConstraintPeriodDetail();
            getFormValue();
        } else {
            setTimePickers([
                { id: Date.now(), startDate: null, endDate: null },
            ]);
        }
    }, [isEdit, periodRangesId]);

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {
        form.submit();
    };

    const onFinish = (values) => {
        const periodRanges = timePickers.map(({ id, startDate, endDate }) => ({
            start_day: startDate ? startDate.date() : null,
            start_month: startDate ? startDate.month() + 1 : null,
            end_day: endDate ? endDate.date() : null,
            end_month: endDate ? endDate.month() + 1 : null,
        }));

        addNewPeriodConstraint(periodRanges);
        setIsModalVisible(false);
    };

    const handleClickAddTimeSlot = () => {
        const newPicker = { id: Date.now(), startDate: null, endDate: null };
        setTimePickers((prev) => [...prev, newPicker]);
        form.setFieldsValue({
            [`startDate_${newPicker.id}`]: null,
            [`endDate_${newPicker.id}`]: null,
        });
    };

    const handleRemoveTimeSlot = (id) => {
        setTimePickers((prev) => prev.filter((picker) => picker.id !== id));
        form.setFieldsValue((prevValues) => {
            const newValues = { ...prevValues };
            delete newValues[`startDate_${id}`];
            delete newValues[`endDate_${id}`];
            return newValues;
        });
    };

    const handleChangeStartDate = (date, id) => {
        setTimePickers((prev) =>
            prev.map((picker) =>
                picker.id === id ? { ...picker, startDate: date } : picker,
            ),
        );
        form.setFieldsValue({ [`startDate_${id}`]: date });
    };

    const handleChangeEndDate = (date, id) => {
        setTimePickers((prev) =>
            prev.map((picker) =>
                picker.id === id ? { ...picker, endDate: date } : picker,
            ),
        );
        form.setFieldsValue({ [`endDate_${id}`]: date });
    };

    const handleClickCreate = () => {
        setIsEditPeriod(false);
        setIsModalVisibleAddPeriodRanges(true);
    };

    const handleClickUpdate = (id) => {
        setIsEditPeriod(true);
        setPeriodId(id);
        setIsModalVisibleAddPeriodRanges(true);
    };

    const columns = [
        {
            title: 'scheduleAndPeriodConstraints.startPeriod',
            dataIndex: 'start_day',
            key: 'start_day',
            width: '45%',
            render: (text, record) => (
                <div
                    className='w-full h-full flex flex-col items-center justify-center'
                    onClick={() => handleClickUpdate(record?.id)}
                >
                    {`${text} ${record?.start_month}`}
                </div>
            ),
        },
        {
            title: 'scheduleAndPeriodConstraints.endPeriod',
            dataIndex: 'end_day',
            key: 'end_day',
            width: '45%',
            render: (text, record) => (
                <div
                    className='w-full h-full flex flex-col items-center justify-center'
                    onClick={() => handleClickUpdate(record?.id)}
                >
                    {`${text} ${record?.end_month}`}
                </div>
            ),
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (text, record) => (
                <div
                    className='w-full h-full flex items-center justify-center'
                    onClick={() => handleClickUpdate(text)}
                >
                    <div
                        className='m-auto w-[15px]'
                        onClick={(e) => {
                            e.stopPropagation();
                            deletePeriod(text);
                        }}
                    >
                        <DeleteIcon />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <Modal
            title={t('addPeriodRangeConstraintModal.addPeriod')}
            isOpen={isModalVisible}
            onClose={handleCancel}
            onCancel={handleCancel}
            onOk={isEdit ? null : handleOk}
        >
            {loading ? (
                <Spinner />
            ) : isEdit ? (
                <>
                    <Table
                        columns={columns}
                        dataSource={constraintsPeriodDetail?.period_ranges}
                    />

                    <div className='w-[50px] h-[50px] cursor-pointer my-4 text-primary-color mx-auto'>
                        {constraintsPeriodDetail?.period_ranges?.length <
                            15 && (
                            <IoIosAddCircleOutline
                                onClick={handleClickCreate}
                                className='w-full h-full'
                            />
                        )}
                    </div>

                    <AddPeriodConstraintModal
                        isModalVisible={isModalVisibleAddPeriodRanges}
                        setIsModalVisible={setIsModalVisibleAddPeriodRanges}
                        periodRangesId={periodRangesId}
                        periodId={periodId}
                        isEdit={isEditPeriod}
                    />
                </>
            ) : (
                <Form form={form} onFinish={onFinish} layout='vertical'>
                    <div className='flex flex-row justify-between items-center mb-4'>
                        <Label
                            className='w-[45%]'
                            label={t(
                                'addPeriodConstraintModal.periodStartDate',
                            )}
                        />

                        <Label
                            className='w-[45%]'
                            label={t('addPeriodConstraintModal.periodEndDate')}
                        />

                        <div className='text-red-500 ml-2 w-[24px] h-[24px] relative top-[6px]'></div>
                    </div>

                    {timePickers.map(({ id, startDate, endDate }, index) => (
                        <div
                            key={id}
                            className='flex flex-row justify-between items-baseline mb-4'
                        >
                            <div className='w-[45%]'>
                                <Form.Item
                                    name={`startDate_${id}`}
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                'addPeriodConstraintModal.pleaseSelectStartDate',
                                            ),
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        value={startDate}
                                        onChange={(date) =>
                                            handleChangeStartDate(date, id)
                                        }
                                        placeholder={t(
                                            'addPeriodConstraintModal.startOfPeriod',
                                        )}
                                        format='DD/MM/YYYY'
                                        popupClassName='AddPeriodRangeConstraintModal'
                                        disabledDate={(current) =>
                                            current &&
                                            current.year() !==
                                                new Date().getFullYear()
                                        } // Désactiver les dates hors de l'année en cours
                                    />
                                </Form.Item>
                            </div>
                            <div className='w-[45%]'>
                                <Form.Item
                                    name={`endDate_${id}`}
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                'addPeriodConstraintModal.pleaseSelectEndDate',
                                            ),
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        value={endDate}
                                        onChange={(date) =>
                                            handleChangeEndDate(date, id)
                                        }
                                        placeholder={t(
                                            'addPeriodConstraintModal.endOfPeriod',
                                        )}
                                        format='DD/MM/YYYY'
                                        popupClassName='AddPeriodRangeConstraintModal'
                                        disabledDate={(current) => {
                                            // Condition 1: La date ne peut pas être avant startDate
                                            const isBeforeStartDate =
                                                startDate &&
                                                current &&
                                                current <
                                                    startDate.endOf('day');

                                            // Condition 2: La date ne peut pas être dans une année autre que l'année en cours
                                            const isNotCurrentYear =
                                                current &&
                                                current.year() !==
                                                    new Date().getFullYear();

                                            // Retourner true si l'une des deux conditions est vraie (désactiver la date)
                                            return (
                                                isBeforeStartDate ||
                                                isNotCurrentYear
                                            );
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <div className='text-red-500 ml-2 w-[24px] h-[24px] relative top-[6px]'>
                                {index > 0 && (
                                    <IoTrashOutline
                                        onClick={() => handleRemoveTimeSlot(id)}
                                        className='cursor-pointer'
                                        size={24}
                                    />
                                )}
                            </div>
                        </div>
                    ))}

                    <div className='w-[50px] h-[50px] cursor-pointer my-4 text-primary-color mx-auto'>
                        {timePickers.length < 15 && (
                            <IoIosAddCircleOutline
                                onClick={handleClickAddTimeSlot}
                                className='w-full h-full'
                            />
                        )}
                    </div>

                    <FormLegend requiredFormItemLegend={true} />
                </Form>
            )}
        </Modal>
    );
}
