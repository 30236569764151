import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'ck_commun/src/app/modUtils/components/select/Select';
import { dateFormat } from 'ck_commun/src/app/modUtils/functions/values/dateFormat';
import FilterByDate from 'ck_commun/src/app/modUtils/components/filters/FilterByDate';
import ButtonSubmit from 'ck_commun/src/app/modUtils/components/buttons/ButtonSubmit';
import { getCurrencySymbol } from 'ck_commun/src/app/modUtils/functions/values/numberFormat';
import SelectSupportStatus from 'ck_commun/src/app/modSupport/components/SelectSupportStatus';
import { Label } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import { Table } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import { Spinner } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import { Pagination } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import { support_pre_payment_paginated_get } from 'ck_commun/src/app/modSupportPrepayment/slices/supportPrepaymentSlices';

export default function VoucherPage() {
    const format = 'DD/MM/YYYY';
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const startOfMonth = moment().startOf('month').format(format);
    const endOfMonth = moment().endOf('month').format(format);
    const { groupId } = useSelector((state) => state.manager);
    const { supportPrepayment, supportPrepaymentCount } = useSelector(
        (state) => state.supportPrepayment,
    );

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);

    // Fonction de gestion de changement de pagination
    const handlePaginationChange = (page, pageSize) => {
        // Mettez à jour les valeurs de limit et offset en fonction de la page et de la taille de page
        const newOffset = (page - 1) * pageSize;
        setOffset(newOffset);
        if (newOffset < 0) {
            setCurrentPage(1);
        } else {
            setCurrentPage(page);
        }
        setLimit(pageSize);
        // Effectuez une requête à l'API avec les nouvelles valeurs de limit et offset pour obtenir les données correspondantes
        getSupportPrePaymentPaginated(pageSize, newOffset);
    };

    // State pour gérer les filtres
    const [filters, setFilters] = useState({
        status: null,
        ordering: '-date',
        startDate: startOfMonth,
        endDate: endOfMonth,
    });

    // Fonction appelée lors du changement de valeur du status
    const onChangeSupportStatus = (value) => {
        setFilters((prevFilters) => ({
            ...prevFilters, // Garde les autres filtres inchangés
            status: value, // Met à jour status
        }));
    };

    // Fonction appelée lors du changement de valeur de ordering
    const onChangeOrdering = (value) => {
        setFilters((prevFilters) => ({
            ...prevFilters, // Garde les autres filtres inchangés
            ordering: value, // Met à jour ordering
        }));
    };

    // Fonction appelée lors du changement de valeur de startDate et endDate
    const handleDate = (selectedDate) => {
        // Valider et mettre à jour les dates du filtre
        if (
            selectedDate?.length > 0 &&
            selectedDate[0] &&
            moment(selectedDate[0], format, true).isValid()
        ) {
            setFilters((prevFilters) => ({
                ...prevFilters,
                startDate: selectedDate[0],
            }));
        }
        if (
            selectedDate?.length > 0 &&
            selectedDate[1] &&
            moment(selectedDate[1], format, true).isValid()
        ) {
            setFilters((prevFilters) => ({
                ...prevFilters,
                endDate: selectedDate[1],
            }));
        }
    };

    const getSupportPrePaymentPaginated = async (limit, offset) => {
        setLoading(true);
        await dispatch(
            support_pre_payment_paginated_get({
                groupId: groupId,
                dateStart: moment(filters?.startDate, format).unix(),
                dateEnd: moment(filters?.endDate, format).unix(),
                status: filters?.status,
                ordering: filters?.ordering,
                limit,
                offset,
            }),
        ).unwrap();
        setLoading(false);
    };
    useEffect(() => {
        if (groupId) {
            getSupportPrePaymentPaginated(limit, 0);
        }
    }, [groupId]);

    // Fonction appelée lors de la soumition
    const handleSubmit = async () => {
        getSupportPrePaymentPaginated(limit, 0);
    };

    const columns = [
        {
            title: t('table.tabColId'),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t('table.tabColEmail'),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: t('table.tabColAmount'),
            dataIndex: 'amount',
            key: 'amount',
            render: (text) => `${text} ${getCurrencySymbol()}`,
        },
        {
            title: t('table.tabColIncidentDate'),
            dataIndex: 'incident_date',
            key: 'incident_date',
            render: (text) => dateFormat(text),
        },
        {
            title: t('table.tabColIncidentEquipment'),
            dataIndex: 'incident_equipment',
            key: 'incident_equipment',
        },
        {
            title: t('table.tabColIncidentReason'),
            dataIndex: 'incident_reason',
            key: 'incident_reason',
        },
    ];

    return (
        <div>
            {/* Titre */}
            <div className='flex justify-center my-8'>
                <Label
                    className='text-[24px] leading-[32px] break-words uppercase'
                    label={t('voucherPage.title')}
                />
            </div>

            <div className='flex items-end flex-row my-4 flex-wrap gap-2'>
                {/* status */}
                <div>
                    <Label
                        className='h-[24px] !block'
                        label={t('voucherPage.labelStatus')}
                    />
                    <SelectSupportStatus onChange={onChangeSupportStatus} />
                </div>

                {/* ordering */}
                <div>
                    <Label
                        className='h-[24px] !block'
                        label={t('voucherPage.labelOrdering')}
                    />
                    <Select
                        className='!mb-0 !h-[40px]'
                        value={t('voucherPage.recentDate')}
                        onChange={onChangeOrdering}
                        options={[
                            { id: '-date', name: t('voucherPage.recentDate') },
                            { id: 'date', name: t('voucherPage.oldDate') },
                        ]}
                        optionAll={false}
                    />
                </div>

                {/* dateStart, dateEnd */}
                <FilterByDate onValueChange={handleDate} format={format} />

                <ButtonSubmit
                    onClick={() => handleSubmit()}
                    className='h-[40px] mt-2.5 ml-2.5'
                />
            </div>

            {loading ? (
                <Spinner />
            ) : (
                <>
                    <Pagination
                        defaultCurrent={currentPage}
                        defaultPageSize={limit}
                        showQuickJumper={true}
                        showTotal={false}
                        total={supportPrepaymentCount}
                        onChange={handlePaginationChange}
                        onShowSizeChange={handlePaginationChange}
                        hideOnSinglePage={true}
                    />

                    <Table columns={columns} dataSource={supportPrepayment} />
                </>
            )}
        </div>
    );
}
