import React from 'react';
import PropTypes from 'prop-types';
import './BrandHeader.scss';

const BrandHeader = ({
    logoSrc,
    appName,
    collapsed,
    showAppName = true,
    style = {},
}) => {
    return (
        <div
            className={`${showAppName ? 'app-name-container flex-display align-items-center' : 'flex flex-col items-center gap-2'}`}
            style={{ ...style.appNameContainer }}
        >
            <img
                className={`${showAppName ? 'app-logo' : ''}`}
                src={logoSrc}
                alt='App Logo'
                style={{ ...style.appLogo }}
            />
            {!collapsed && showAppName && (
                <h2 className='app-name' style={{ ...style.appName }}>
                    {appName}
                </h2>
            )}
        </div>
    );
};

BrandHeader.defaultProps = {
    collapsed: false,
};

BrandHeader.propTypes = {
    appName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    logoSrc: PropTypes.string,
    collapsed: PropTypes.bool,
};
export default BrandHeader;
