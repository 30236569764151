import { useState } from 'react';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../../modUtils/functions/permissions/handlePermissions';
import PermissionFadeContainer from '../../../modUtils/components/permissionFadeContainer/PermissionFadeContainer';
import { useTranslation } from 'react-i18next';
import ExportModalForm from '../../../modUtils/components/exportModal/ExportModalForm';
import RangePicker from '../../../modUtils/components/componentsLibrary/datePicker/RangePicker';
import moment from 'moment';
import { Formik } from 'formik';
import {
    Input,
    Modal,
    Spinner,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';

const ExportModalTickets = ({ modalExportTicket, toggleModalExportTicket }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup?.permissions;
    const verifyPermission =
        hasPermission('mod_sale:show_sale', permissions) &&
        hasPermission('mod_data:data_export', permissions);

    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission =
        hasPermission('mod_sale:show_sale', groupPermissions) &&
        hasPermission('mod_data:data_export', groupPermissions);

    const getExport = async (values) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            toggleModalExportTicket();
        }, 1500);
    };

    // Fonction pour gérer le changement de plage de dates
    const handleRangeChange = (start, end, setValues, values) => {
        setValues({ ...values, startDate: start, endDate: end });
    };

    return (
        verifyPermission && (
            <PermissionFadeContainer permission={verifyGroupPermission}>
                <Formik
                    initialValues={{
                        startDate: null,
                        endDate: null,
                        quantity: null,
                        formatFile: 'pdf',
                    }}
                >
                    {({ values, setValues, handleChange }) => (
                        <Modal
                            title={t('exportData.transExportModalTitle')}
                            isOpen={modalExportTicket}
                            onClose={toggleModalExportTicket}
                            onOk={() => getExport(values)}
                            onCancel={toggleModalExportTicket}
                            okText={t('common.transButtonExport')}
                        >
                            {loading ? (
                                <Spinner />
                            ) : (
                                <div className='mt-8 w-full sm:w-max mx-auto'>
                                    <p className='font-semibold text-sm'>
                                        {t(
                                            'modalViewTickets.modalCloseTicketsGroup.transDate',
                                        )}
                                    </p>
                                    <RangePicker
                                        className={
                                            'flex items-center gap-2 mb-4'
                                        }
                                        format={'DD/MM/YYYY'}
                                        color={'neutral'}
                                        onRangeChange={(start, end) =>
                                            handleRangeChange(
                                                start,
                                                end,
                                                setValues,
                                                values,
                                            )
                                        }
                                        startDate={
                                            moment(
                                                values.startDate,
                                                'DD/MM/YYYY',
                                            ).isValid()
                                                ? moment(
                                                      values.startDate,
                                                      'DD/MM/YYYY',
                                                  ).toDate()
                                                : null
                                        }
                                        endDate={
                                            moment(
                                                values.endDate,
                                                'DD/MM/YYYY',
                                            ).isValid()
                                                ? moment(
                                                      values.endDate,
                                                      'DD/MM/YYYY',
                                                  ).toDate()
                                                : null
                                        }
                                    />
                                    <ExportModalForm
                                        onChangeFileType={handleChange}
                                        name={'formatFile'}
                                        pdf={false}
                                    />
                                    <Input
                                        className={'mt-2 w-48'}
                                        placeholder={'100'}
                                        value={values.quantity}
                                        name={'quantity'}
                                        type={'number'}
                                        min={0}
                                        label={t(
                                            'modalViewTickets.transExportQuantity',
                                        )}
                                        onChange={handleChange}
                                    />
                                </div>
                            )}
                        </Modal>
                    )}
                </Formik>
            </PermissionFadeContainer>
        )
    );
};

export default ExportModalTickets;
