import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SupportMenuButton from '../../modWalletLocal/components/SupportMenuButton';
import { amountFormatWithCurrencySymbol } from '../../modUtils/functions/values/numberFormat';
import { Input, Pagination } from 'antd';
import { IoIosClose } from 'react-icons/io';
import ButtonSubmit from '../../modUtils/components/buttons/ButtonSubmit';
import AddNewProduct from '../components/productManagement/addNewProduct';
import { useTranslation } from 'react-i18next';
import { Table } from '../../modUtils/components/componentsLibrary/componentsLibrary';
import { get_products_by_group_paginated } from '../slices/boxSlice';

export default function ProductsPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { products, productsCount } = useSelector((store) => store.box);
    const { groupId } = useSelector((store) => store.manager);
    const [searchValue, setSearchValue] = useState();
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);

    const getProducts = async (offset, limit) => {
        const response = await dispatch(
            get_products_by_group_paginated({
                groupId,
                limit,
                offset,
            }),
        ).unwrap();
        if (response.results) setFilteredProducts(response.results);
    };

    const columns = [
        {
            title: t('table.tabColPhoto'),
            dataIndex: 'photo',
            key: 'photo',
            width: '5%',
            bold: true,
        },
        {
            title: t('table.tabColName'),
            dataIndex: 'name',
            key: 'name',
            width: '10%',
            bold: true,
        },
        {
            title: t('table.tabColTrade'),
            dataIndex: 'trade',
            key: 'trade',
            width: '10%',
            bold: true,
        },
        {
            title: t('table.tabColPrice'),
            dataIndex: 'price',
            key: 'price',
            width: '5%',
            bold: true,
        },
        {
            title: t('table.tabColAction'),
            dataIndex: 'action',
            key: 'actions',
            width: '5%',
            bold: true,
            render: (text, record) => <SupportMenuButton support={record} />,
        },
    ];

    const filteredSearch = filteredProducts?.map((product) => ({
        id: product.id,
        name: product.item.name,
        trade: product.item.label,
        price: amountFormatWithCurrencySymbol(product.item.price_by_unit),
    }));

    useEffect(() => {
        if (groupId) getProducts(0, limit);
    }, [groupId, searchValue]);

    const onExecuteSubmitBtn = () => {
        const filterProducts = products?.filter((product) =>
            product?.item?.name
                .toLowerCase()
                .includes(searchValue?.toLowerCase()),
        );
        setFilteredProducts(filterProducts);
    };

    const handlePaginationChange = (page, pageSize) => {
        const newOffset = (page - 1) * pageSize;
        setOffset(newOffset);
        if (newOffset < 0) {
            setCurrentPage(1);
        } else {
            setCurrentPage(page);
        }
        getProducts({ offset: newOffset, limit: pageSize });
    };

    return (
        <>
            <div class='filters gap-6 flex flex-row flex-wrap md:max-w-[calc(100vw-500px)]'>
                <div className='relative flex gap-6 mt-10 ml-4'>
                    <Input
                        type='text'
                        name='search'
                        placeholder={t('product.searchPlaceholder')}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        style={{
                            width: 290,
                            height: 50,
                        }}
                    />
                    {searchValue && (
                        <IoIosClose
                            className='absolute left-64 top-3 text-2xl cursor-pointer'
                            onClick={() => setSearchValue('')}
                        />
                    )}
                    <ButtonSubmit
                        className='h-[50px]'
                        onClick={onExecuteSubmitBtn}
                    />
                </div>
            </div>{' '}
            <div className=' mt-9'>
                <AddNewProduct />
            </div>
            {filteredSearch.length > 0 && (
                <div>
                    <div>
                        <Pagination
                            defaultCurrent={currentPage}
                            defaultPageSize={limit}
                            showQuickJumper={true}
                            showTotal={false}
                            total={productsCount}
                            onChange={handlePaginationChange}
                            onShowSizeChange={handlePaginationChange}
                            hideOnSinglePage={true}
                        />
                    </div>
                    <div className='text-left font-semibold uppercase text-[#354052] text-[14px] leading-[18px] m-2'>
                        {filteredSearch.length} {''}
                        {t('product.prodCount')}
                    </div>
                </div>
            )}
            <Table
                tableClassName={'min-w-[970px] mt-10'}
                dataSource={filteredSearch}
                columns={columns}
            />
        </>
    );
}
