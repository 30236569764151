import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { eEquipmentType } from '../../../commun_global/enums';
import { transactions_centralized_by_group_paginated_get } from '../../modSupportCentralized/slices/supportCentralizedSlices';
import { dateFormat } from '../../modUtils/functions/values/dateFormat';
import TablePaginatedScroll from '../../modUtils/components/componentsLibrary/table/TablePaginatedScroll';
import CKToolTip from '../../modUtils/components/CKToolTip/CKToolTip';

export default function EntityHistory({ entity, isLabel = true }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);

    const columns = [
        {
            title: t('entityHistory.date'),
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => dateFormat(text),
            width: '25%',
        },
        {
            title: t('entityHistory.salesNumber'),
            dataIndex: 'sale',
            key: 'sale',
            width: '25%',
            render: (text, record) => (
                <CKToolTip
                    placement='topLeft'
                    content={t('entityHistory.salesNumberIsAssociated')}
                    trigger='click'
                >
                    {text}
                </CKToolTip>
            ),
        },
        {
            title: t('entityHistory.site'),
            dataIndex: 'site_name',
            key: 'site_name',
            width: '25%',
        },
        {
            title: t('entityHistory.equipmentType'),
            dataIndex: 'equipment_type',
            key: 'equipment_type',
            render: (text, record) => t(eEquipmentType[text]),
            width: '25%',
        },
        {
            title: t('entityHistory.amount'),
            dataIndex: 'amount_applied',
            key: 'amount_applied',
            render: (text, record) => text + ' €',
            width: '25%',
        },
    ];

    const getSupportTransactions = async (limit, offset) => {
        const response = await dispatch(
            transactions_centralized_by_group_paginated_get({
                groupId: groupId,
                entityId: entity?.id,
                limit,
                offset,
            }),
        ).unwrap();
        return response;
    };

    return (
        <>
            {entity && (
                <TablePaginatedScroll
                    label={isLabel && t('entityHistory.supportHistory')}
                    fetch={getSupportTransactions}
                    effect={entity}
                    columns={columns}
                />
            )}
        </>
    );
}
