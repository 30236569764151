import React from 'react';
import Loading from './LoadingMessageSelect';

export default function OptionLoading({}) {
    return (
        <div
            className={`border border-solid border-input-color rounded-md bg-background-color
                text-primary-color w-full z-[100000] absolute max-w-52 px-4 py-2
                flex flex-row items-center text-base
            `}
        >
            <Loading />
        </div>
    );
}
