import React from 'react';
import { InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { getCurrencySymbol } from 'ck_commun/src/app/modUtils/functions/values/numberFormat';
import InputBaseForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/InputBaseForm';

export default function InputAmountForm({
    name = 'amount',
    messages = {},
    className = '',
    minAmount = 0.01,
    markerRequired = true,
}) {
    const { t } = useTranslation();

    const mergedMessages = {
        label: 'inputAmountForm.label',
        help: 'inputAmountForm.help', // todo a faire
        required: 'inputAmountForm.required',
        invalid: 'inputAmountForm.invalid',
        ...messages,
    };

    return (
        <InputBaseForm
            name={name}
            messages={mergedMessages}
            className={className}
            markerRequired={markerRequired}
            rules={[
                {
                    required: markerRequired,
                    message: t(mergedMessages.required),
                },
                {
                    validator: (_, value) =>
                        value >= minAmount && value <= 1000
                            ? Promise.resolve()
                            : Promise.reject(t(mergedMessages.invalid)),
                },
            ]}
        >
            <InputNumber
                min={minAmount}
                max={1000}
                step={0.01}
                precision={2}
                className='w-full'
                addonAfter={getCurrencySymbol()}
                stringMode
                formatter={(value) => value?.toString().replace('.', ',')}
                parser={(value) => value?.replace(',', '.')}
            />
        </InputBaseForm>
    );
}
