import React from 'react';
import PropTypes from 'prop-types';

const Badge = ({
    color,
    count,
    offset,
    verticalPosition,
    overflowCount,
    showZero,
    size,
    text,
    title,
    children,
}) => {
    const renderBadge = () => {
        if (count === 0 && !showZero && !text) {
            return null;
        }

        const formattedCount =
            overflowCount && count > overflowCount
                ? `${overflowCount}+`
                : count;

        return (
            <span
                className={`absolute left-[100%] translate-x-[-50%] translate-y-[-50%] inline-flex items-center justify-center min-w-[20px] w-auto h-auto px-[6px] text-[10px] font-bold text-destructive-foreground-color bg-destructive-color rounded-full border-[2px] border-solid border-destructive-foreground-color
                    ${size === 'small' ? 'min-w-[16px] h-[16px] text-[8px]' : 'min-w-[20px] h-[20px] text-[10px]'}`}
                style={{ backgroundColor: color, top: verticalPosition }}
                title={title}
            >
                {text || formattedCount}
            </span>
        );
    };

    return (
        <div className='relative inline-block mx-[15px]'>
            <div>{children}</div>
            {renderBadge()}
        </div>
    );
};

Badge.propTypes = {
    color: PropTypes.string,
    count: PropTypes.number,
    offset: PropTypes.string,
    verticalPosition: PropTypes.string,
    overflowCount: PropTypes.number,
    showZero: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'default', 'large']),
    text: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.node.isRequired,
};

Badge.defaultProps = {
    color: 'red',
    count: 0,
    offset: '0px',
    verticalPosition: '0px',
    overflowCount: null,
    showZero: false,
    size: 'default',
    text: '',
    title: '',
};

export default Badge;
