import React from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import InputBaseForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/InputBaseForm';

export default function InputEmailForm({
    name = 'email',
    messages = {},
    className = '',
    markerRequired = true,
}) {
    const { t } = useTranslation();

    const mergedMessages = {
        label: 'inputEmailForm.label',
        help: 'inputEmailForm.help',
        required: 'inputEmailForm.required',
        invalid: 'inputEmailForm.invalid',
        ...messages,
    };

    return (
        <InputBaseForm
            name={name}
            messages={mergedMessages}
            className={className}
            markerRequired={markerRequired}
            rules={[
                {
                    required: markerRequired,
                    message: t(mergedMessages.required),
                },
                {
                    type: 'email',
                    message: t(mergedMessages.invalid),
                },
            ]}
        >
            <Input
                placeholder={
                    mergedMessages?.placeholder &&
                    t(mergedMessages?.placeholder)
                }
            />
        </InputBaseForm>
    );
}
