import React from 'react';
import { Button } from '../../componentsLibrary/componentsLibrary';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExportButtonSvg } from '../../../assets/images/exportButtons/exportButton.svg';

export default function ExportButton({ disabled, onClick }) {
    const { t } = useTranslation();

    return (
        <Button type='image' onClick={onClick}>
            <ExportButtonSvg className='text-accent-color' />

            <span>{t('common.transButtonExport')}</span>
        </Button>
    );
}
