import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    httpGet,
    httpPost,
    httpDelete,
    httpPut,
} from '../../modApi/apis/httpConfig';

// retourner la liste des contraintes périodes d'une configuration
export const get_constraint_period_paginated = createAsyncThunk(
    'mod_profil/get_constraint_period_paginated',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_period/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// Créer une contrainte de période
export const constraints_period_create = createAsyncThunk(
    'mod_profil/constraints_period_create',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPost(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_period/`,
                {
                    name: values.name,
                    equipment_type: values.equipmentType,
                    is_global: values.isGlobal,
                    period_ranges: values.periodRanges,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// Création d'une periode de contrainte de période
export const constraints_period_range_create = createAsyncThunk(
    'mod_profil/constraints_period_range_create',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPost(
                `mod_profil/groups/${values.groupId}/constraints_period/${values.constraintPeriodId}/period_ranges/`,
                {
                    start_day: values.startDay,
                    start_month: values.startMonth,
                    end_day: values.endDay,
                    end_month: values.endMonth,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// retourner le détail d'une contrainte période
export const get_constraint_period_detail = createAsyncThunk(
    'mod_profil/get_constraint_period_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_period/${values.constraintId}/`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// Modifier une period
export const constraints_period_range_detail_update = createAsyncThunk(
    'mod_profil/constraints_period_range_detail_update',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPut(
                `mod_profil/groups/${values.groupId}/constraints_period/${values.constraintPeriodId}/period_ranges/${values.periodRangeId}/`,
                {
                    start_day: values.startDay,
                    start_month: values.startMonth,
                    end_day: values.endDay,
                    end_month: values.endMonth,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// Supprimer une contrainte période
export const delete_constraint_period_detail = createAsyncThunk(
    'mod_profil/delete_constraint_period_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpDelete(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_period/${values.constraintId}/`,
            );
            return { ...response.data, id: values.constraintId };
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// Supprimer une période
export const constraints_period_range_detail_delete = createAsyncThunk(
    'mod_profil/constraints_period_range_detail_delete',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpDelete(
                `mod_profil/groups/${values.groupId}/constraints_period/${values.constraintPeriodId}/period_ranges/${values.periodRangeId}/`,
            );
            return { ...response.data, id: values.periodRangeId };
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

const constraintPeriodSlice = createSlice({
    name: 'constraintPeriod',
    initialState: {
        loading: true,
        constraintsPeriod: [],
        constraintsPeriodDetail: {},
    },
    extraReducers: (builder) => {
        builder
            /* get_constraint_period_paginated */
            .addCase(
                get_constraint_period_paginated.pending,
                (state, action) => {
                    state.constraintsPeriod = [];
                },
            )
            .addCase(
                get_constraint_period_paginated.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.constraintsPeriod = action.payload.results;
                },
            )
            .addCase(
                get_constraint_period_paginated.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* constraints_period_create */
            .addCase(constraints_period_create.fulfilled, (state, action) => {
                state.loading = false;
                action.payload.index = action.payload.id;
                state.constraintsPeriod = [
                    ...state.constraintsPeriod,
                    action.payload,
                ];
            })
            .addCase(constraints_period_create.rejected, (state, action) => {
                state.loading = false;
            })

            /* constraints_period_range_create */
            .addCase(
                constraints_period_range_create.fulfilled,
                (state, action) => {
                    state.loading = false;

                    const newPeriod = action.payload; // Nouvelle période ajoutée
                    newPeriod.index = newPeriod.id; // Attribuer un index basé sur l'ID de la période

                    // ID de la contrainte principale
                    const constraintId = state.constraintsPeriodDetail.id;

                    // Mise à jour de constraintsPeriod
                    state.constraintsPeriod = state.constraintsPeriod.map(
                        (constraint) => {
                            if (constraint.id === constraintId) {
                                return {
                                    ...constraint,
                                    period_ranges: [
                                        ...constraint.period_ranges,
                                        newPeriod,
                                    ],
                                };
                            }
                            return constraint;
                        },
                    );

                    // Mise à jour de constraintsPeriodDetail
                    state.constraintsPeriodDetail.period_ranges = [
                        ...state.constraintsPeriodDetail.period_ranges,
                        newPeriod,
                    ];
                },
            )
            .addCase(
                constraints_period_range_create.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* get_constraint_period_detail */
            .addCase(get_constraint_period_detail.pending, (state, action) => {
                state.constraintsPeriodDetail = {};
            })
            .addCase(
                get_constraint_period_detail.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.constraintsPeriodDetail = action.payload;
                },
            )
            .addCase(get_constraint_period_detail.rejected, (state, action) => {
                state.loading = false;
            })

            /* constraints_period_range_detail_update*/
            .addCase(
                constraints_period_range_detail_update.fulfilled,
                (state, action) => {
                    // ID de la contrainte principale
                    const constraintId = state.constraintsPeriodDetail.id;

                    // Détails de la période mise à jour
                    const updatedPeriod = action.payload;

                    // Mise à jour de constraintsPeriod
                    state.constraintsPeriod = state.constraintsPeriod.map(
                        (constraint) => {
                            if (constraint.id === constraintId) {
                                return {
                                    ...constraint,
                                    period_ranges: constraint.period_ranges.map(
                                        (period) =>
                                            period.id === updatedPeriod.id
                                                ? updatedPeriod
                                                : period,
                                    ),
                                };
                            }
                            return constraint;
                        },
                    );

                    // Mise à jour de constraintsPeriodDetail
                    state.constraintsPeriodDetail.period_ranges =
                        state.constraintsPeriodDetail.period_ranges.map(
                            (period) =>
                                period.id === updatedPeriod.id
                                    ? updatedPeriod
                                    : period,
                        );

                    state.loading = false;
                },
            )
            .addCase(
                constraints_period_range_detail_update.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* delete_constraint_period_detail */
            .addCase(
                delete_constraint_period_detail.fulfilled,
                (state, action) => {
                    const constraintPeriodToDeleteId = action.payload.id;
                    state.constraintsPeriod = state.constraintsPeriod.filter(
                        (constraint) =>
                            constraint.id !== constraintPeriodToDeleteId,
                    );
                    state.loading = false;
                },
            )
            .addCase(
                delete_constraint_period_detail.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* constraints_period_range_detail_delete */
            .addCase(
                constraints_period_range_detail_delete.fulfilled,
                (state, action) => {
                    const periodToDeleteId = action.payload.id; // ID de la période à supprimer

                    // ID de la contrainte principale
                    const constraintId = state.constraintsPeriodDetail.id;

                    // Mise à jour de constraintsPeriod
                    state.constraintsPeriod = state.constraintsPeriod.map(
                        (constraint) => {
                            if (constraint.id === constraintId) {
                                return {
                                    ...constraint,
                                    period_ranges:
                                        constraint.period_ranges.filter(
                                            (period) =>
                                                period.id !== periodToDeleteId,
                                        ),
                                };
                            }
                            return constraint;
                        },
                    );

                    // Mise à jour de constraintsPeriodDetail
                    state.constraintsPeriodDetail.period_ranges =
                        state.constraintsPeriodDetail.period_ranges.filter(
                            (period) => period.id !== periodToDeleteId,
                        );

                    state.loading = false;
                },
            )
            .addCase(
                constraints_period_range_detail_delete.rejected,
                (state, action) => {
                    state.loading = false;
                },
            );
    },
});

export default constraintPeriodSlice.reducer;
