import moment from 'moment';
import _ from 'lodash'; // Lodash permet une comparaison profonde

/**
 * Vérifie si des changements ont été effectués dans un formulaire
 * @param {Object} initialValues - Les valeurs initiales du formulaire
 * @param {Object} currentValues - Les valeurs actuelles du formulaire
 * @returns {boolean} - Retourne `true` si des modifications ont été détectées, sinon `false`
 */
export const checkForChanges = (initialValues, currentValues) => {
    return !_.isEqual(initialValues, currentValues);
};

/**
 * Prépare les valeurs d'un formulaire en convertissant les dates en timestamp UNIX
 * @param {Object} values - Les valeurs du formulaire
 * @returns {Object} - Valeurs transformées avec les dates converties en timestamp UNIX
 */
export const prepareFormValues = (values) => {
    return Object.entries(values).reduce((acc, [key, value]) => {
        acc[key] =
            moment(value, moment.ISO_8601, true).isValid() ||
            moment.isMoment(value)
                ? moment(value).unix() // Convertir en timestamp UNIX si c'est une date valide
                : value;
        return acc;
    }, {});
};

/**
 * Récupère uniquement les valeurs modifiées entre deux objets
 * @param {Object} initialValues - Les valeurs initiales
 * @param {Object} newValues - Les nouvelles valeurs
 * @returns {Object} - Un objet contenant uniquement les valeurs modifiées
 */
export const getChangedValues = (initialValues, newValues) => {
    return Object.keys(newValues).reduce((acc, key) => {
        if (!_.isEqual(initialValues[key], newValues[key])) {
            acc[key] = newValues[key];
        }
        return acc;
    }, {});
};
