import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import AdminLink from '../../adminLink/AdminLink';
import { useDispatch, useSelector } from 'react-redux';
import LanguageSwitcher from '../../languageSwitcher/LanguageSwitcher';
import SelectGroup from '../../../../modManager/components/SelectGroup/SelectGroup';
import { hasPermission } from '../../../functions/permissions/handlePermissions';
import WeatherCard from 'ck_commun/src/app/modWeather/components/weatherCard/WeatherCard';
import { get_user_details } from 'ck_commun/src/app/modAuthentication/slices/authenticationSlice';
import PermissionFadeContainer from 'ck_commun/src/app/modUtils/components/permissionFadeContainer/PermissionFadeContainer';

export default function RightSideBar({ collapsed }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const { user } = useSelector((state) => state.authentication);
    const { selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission(
        'mod_weather:show_weather',
        permissions,
    );
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission(
        'mod_weather:show_weather',
        groupPermissions,
    );

    const getUserDetails = async () => {
        setLoading(true);
        await dispatch(get_user_details(localStorage.getItem('user')));
        setLoading(false);
    };

    useEffect(() => {
        getUserDetails();
    }, [i18next.language]);

    return (
        !collapsed && (
            <>
                <div className='flex flex-col p-2.5'>
                    <div className='h-10'>
                        <LanguageSwitcher color='light' />
                    </div>

                    <div className='text-base font-bold text-white text-center align-middle my-2'>
                        {!loading && user.first_name + ' ' + user.last_name}
                    </div>

                    <SelectGroup />

                    {verifyPermission && (
                        <PermissionFadeContainer
                            permission={verifyGroupPermission}
                        >
                            <div className='mt-10'>
                                <WeatherCard />
                            </div>
                        </PermissionFadeContainer>
                    )}
                </div>

                <div className='absolute bottom-10 right-0 px-7 w-[inherit] flex flex-col justify-content-center '>
                    <AdminLink label={t('Admin.label')} type='button' />
                </div>
            </>
        )
    );
}
