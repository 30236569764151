import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_group_detail } from '../slices/managerSlice';
import { Form, Input, Button, message } from 'antd';
import { Spinner } from '../../modUtils/components/componentsLibrary/componentsLibrary';
import AlertMessage from '../../modUtils/components/AlertMessage';
import GroupInfoCard from './GroupInfoCard';
import { ReactComponent as AdditionalAddress } from 'ck_commun/src/app/modManager/assets/images/additional-address.svg';
import { ReactComponent as Address } from 'ck_commun/src/app/modManager/assets/images/address.svg';
import { ReactComponent as Company } from 'ck_commun/src/app/modManager/assets/images/company.svg';
import { ReactComponent as Country } from 'ck_commun/src/app/modManager/assets/images/country.svg';
import { ReactComponent as ZipCode } from 'ck_commun/src/app/modManager/assets/images/zip-code.svg';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function GroupInformationsForm() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const { selectedGroup, groupDetail, groupPlans } = useSelector(
        (state) => state.manager,
    );
    const group = localStorage.getItem('group');
    const groupId = selectedGroup?.id || group;
    const [selectedCity, setSelectedCity] = useState();

    const getGroupDetail = async () => {
        try {
            setLoading(true);
            await dispatch(get_group_detail({ groupId })).unwrap();
            setLoading(false);
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error.status}
                        alertMessage='Une erreur est surevnue lors de la récupération des informations de votre société'
                        errorDetail={error.data.detail}
                    />
                ),
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        groupId && getGroupDetail();
        setLoading(false);
    }, [groupId, i18next.language]);

    useEffect(() => {
        form.resetFields();
        if (groupDetail) {
            let address = groupDetail?.site_default?.location_address || '';

            setSelectedCity({
                id: address?.city_id,
                postalCode: address?.zipcode,
                name: address?.city,
            });
            form.setFieldsValue({
                name: groupDetail?.name || '',
                locationStreet:
                    groupDetail?.site_default?.location_address?.street || '',
                locationNumber:
                    groupDetail?.site_default?.location_address
                        ?.street_number || '',
                country:
                    groupDetail?.site_default?.location_address?.country || '',
                locationAdditional:
                    groupDetail?.site_default?.location_address?.additional ||
                    '',
            });
        }
    }, [groupDetail, groupId, form]);

    // Récupérer les couleurs CSS du :root
    const primaryColor = getComputedStyle(document.documentElement)
        .getPropertyValue('--primary-color')
        .trim();

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <div>
                        <section>
                            <div className='max-w-screen-xl sm:px-6 sm:py-12 lg:px-8 lg:py-16'>
                                <div className='grid grid-rows-1 gap-4 md:grid-cols-2'>
                                    {groupDetail?.name && (
                                        <GroupInfoCard
                                            icon={
                                                <Company color={primaryColor} />
                                            }
                                            title={t(
                                                'groupInformation.transInformationCompanyName',
                                            )}
                                            subtitle={groupDetail?.name}
                                        />
                                    )}
                                    {(groupDetail?.site_default
                                        ?.location_address?.street ||
                                        groupDetail?.site_default
                                            ?.location_address
                                            ?.street_number) && (
                                        <GroupInfoCard
                                            icon={
                                                <Address color={primaryColor} />
                                            }
                                            title={t(
                                                'groupInformation.transInformationAddress',
                                            )}
                                            subtitle={`${
                                                groupDetail?.site_default
                                                    ?.location_address
                                                    ?.street ||
                                                'non renseigné, '
                                            } ${
                                                groupDetail?.site_default
                                                    ?.location_address
                                                    ?.street &&
                                                groupDetail?.site_default
                                                    ?.location_address
                                                    ?.street_number
                                                    ? ', '
                                                    : ''
                                            }
                        ${
                            groupDetail?.site_default?.location_address
                                ?.street_number || ''
                        }
                     `}
                                        />
                                    )}
                                    {groupDetail?.site_default?.location_address
                                        ?.additional && (
                                        <GroupInfoCard
                                            icon={
                                                <AdditionalAddress
                                                    color={primaryColor}
                                                />
                                            }
                                            title={t(
                                                'groupInformation.transInformationAdditionnalAddress',
                                            )}
                                            subtitle={
                                                groupDetail?.site_default
                                                    ?.location_address
                                                    ?.additional
                                            }
                                        />
                                    )}
                                    {(groupDetail?.site_default
                                        ?.location_address?.city ||
                                        groupDetail?.site_default
                                            ?.location_address?.zipcode) && (
                                        <GroupInfoCard
                                            icon={
                                                <ZipCode color={primaryColor} />
                                            }
                                            title={t(
                                                'groupInformation.transInformationZipCode',
                                            )}
                                            subtitle={`${
                                                groupDetail?.site_default
                                                    ?.location_address?.city ||
                                                'non renseigné, '
                                            } ${
                                                groupDetail?.site_default
                                                    ?.location_address?.city &&
                                                groupDetail?.site_default
                                                    ?.location_address?.zipcode
                                                    ? ', '
                                                    : ''
                                            }
                        ${
                            groupDetail?.site_default?.location_address
                                ?.zipcode || ''
                        }
                     `}
                                        />
                                    )}
                                    {groupDetail?.site_default?.location_address
                                        ?.country && (
                                        <GroupInfoCard
                                            icon={
                                                <Country color={primaryColor} />
                                            }
                                            title={t(
                                                'groupInformation.transInformationCountry',
                                            )}
                                            subtitle={
                                                groupDetail?.site_default
                                                    ?.location_address?.country
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </section>
                    </div>

                    {/* <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item
              label="Raison sociale"
              name="name"
              // rules={[
              //   {
              //     required: true,
              //     message: "Veuillez entrer la raison sociale / Nom de votre société",
              //   },
              // ]}
            >
              <Input placeholder="" readOnly />
            </Form.Item>
            <div className="grid grid-cols-4 gap-0 lg:gap-2">
              <div className="col-span-4 xl:col-span-1">
                <Form.Item label="N° adresse" name="locationNumber">
                  <Input type="number" placeholder="" readOnly />
                </Form.Item>
              </div>
              <div className="col-span-4 xl:col-span-3">
                <Form.Item label="Rue" name="locationStreet">
                  <Input placeholder="" readOnly />
                </Form.Item>
              </div>
            </div>

            <Form.Item label="Complément d'adresse" name="locationAdditional">
              <Input placeholder="" readOnly />
            </Form.Item>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-2">
              <Form.Item label="Code Postal - Ville" name="locationCity">
                <CityInput
                  defaultValue={selectedCity}
                  setValue={setSelectedCity}
                  readOnly
                />
              </Form.Item>
              <Form.Item label="Pays" name="country">
                <Input placeholder="" readOnly />
              </Form.Item>
            </div>

           
            //TODO fonctionnalité à rajouter quand l'api update user detail est prête
            <Form.Item>
              <ButtonSubmit onClick={() => handleSubmit()} label="ENREGISRTER" />
            </Form.Item> 
          </Form> */}
                </>
            )}
        </>
    );
}

export default GroupInformationsForm;
