import React from 'react';
import { Route } from 'react-router-dom';
import CkRoutes from 'ck_commun/src/app/modUtils/routes/CkRoutes';
import ProfilsPage from 'ck_commun/src/app/modProfil/pages/ProfilsPage';
import CustomersPage from 'ck_commun/src/app/modEntity/pages/CustomersPage';
import BuildingPage from 'ck_commun/src/app/modUtils/components/errorPage/BuildingPage';

export default function CrmRoutes() {
    return (
        <CkRoutes>
            <Route path='dashboard' element={<BuildingPage />} />
            <Route path='clients' element={<CustomersPage />} />
            <Route path='profiles' element={<ProfilsPage />} />
            <Route path='marketing' element={<BuildingPage />} />
            <Route path='facturation' element={<BuildingPage />} />
            <Route path='factures' element={<BuildingPage />} />
        </CkRoutes>
    );
}
