import { useTranslation } from 'react-i18next';
import {
    Button,
    Textarea,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { useState } from 'react';
import LastStepperScreen from './LastStepperScreen';
import {
    defaultValues,
    style_array,
    variable_array,
} from '../../assets/js/utils';
import { FaCheck } from 'react-icons/fa';

const FourthStepperScreen = ({ values, last, setFieldValue, mode }) => {
    const { t } = useTranslation();
    const [cardFormat, setCardFormat] = useState('paper');

    const [template, setTemplate] = useState(
        mode === 'update' || mode === 'duplicate'
            ? values.ticket_template_paper
            : defaultValues.ticket_template_paper,
    );

    const updateTemplateAndField = (updatedTemplate) => {
        setTemplate(updatedTemplate);
        setFieldValue(`ticket_template_${cardFormat}`, updatedTemplate);
    };

    const handleDoubleClickVariables = (variable) => {
        const updatedVariables = template.props.variables_available
            ? [...template.props.variables_available, variable]
            : [variable];

        const updatedTemplate = {
            ...template,
            props: {
                ...template.props,
                variables_available: updatedVariables,
            },
        };

        updateTemplateAndField(updatedTemplate);
    };

    const handleDoubleClickStyle = (variable) => {
        const updatedCommands = template.props.interpretor.commands_available
            ? [...template.props.interpretor.commands_available, variable]
            : [variable];

        const updatedTemplate = {
            ...template,
            props: {
                ...template.props,
                interpretor: {
                    ...template.props.interpretor,
                    commands_available: updatedCommands,
                },
            },
        };

        updateTemplateAndField(updatedTemplate);
    };

    const handleCardFormatChange = (e, newFormat) => {
        e.preventDefault();
        e.stopPropagation();
        setCardFormat(newFormat);
        setTemplate({
            ...template,
            props: {
                ...template.props,
                template_name: `ticket_template_${newFormat}`, // Setting template_name dynamically
            },
        });
        updateTemplateAndField({
            ...template,
            props: {
                ...template.props,
                template_name: `ticket_template_${newFormat}`,
            },
        });
    };

    const handleTemplateChange = (e) => {
        const updatedTemplate = {
            ...template,
            props: {
                ...template.props,
                module_name: e.target.value,
            },
        };
        updateTemplateAndField(updatedTemplate);
    };

    return (
        <div className='FourthStepperScreen'>
            {last ? (
                <LastStepperScreen values={values} />
            ) : (
                <>
                    <div className='flex flex-col mb-8 items-center'>
                        <p className='text-sm font-semibold'>
                            {t(
                                'createPromotionModal.stepperCreatePromotion.transCardFormat',
                            )}
                        </p>
                        <div className='flex gap-4 align-items-center'>
                            {/* <Button
                                type={
                                    cardFormat === 'email'
                                        ? 'primary'
                                        : 'default'
                                }
                                color='primary'
                                className='h-10 w-auto whitespace-nowrap text-sm font-normal'
                                size='sm'
                                onClick={(e) =>
                                    handleCardFormatChange(e, 'email')
                                }
                            >
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transEmail',
                                )}
                            </Button>
                         <Button
                                type={
                                    cardFormat === 'sms' ? 'primary' : 'default'
                                }
                                color='primary'
                                className='h-10 w-auto whitespace-nowrap text-sm font-normal'
                                size='sm'
                                onClick={(e) =>
                                    handleCardFormatChange(e, 'sms')
                                }
                            >
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transSMS',
                                )}
                            </Button> */}
                            {/* <Button
                                type={
                                    cardFormat === 'paper'
                                        ? 'primary'
                                        : 'default'
                                }
                                color='primary'
                                className='h-10 w-auto whitespace-nowrap text-sm font-normal'
                                size='sm'
                                onClick={(e) =>
                                    handleCardFormatChange(e, 'paper')
                                }
                            >
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transTicket',
                                )}
                            </Button> */}
                        </div>
                    </div>
                    <div className='flex justify-center gap-4 h-max flex-col-reverse sm:flex-row'>
                        {/*   <div className='sm:w-1/4 w-full h-full'>    
                            <p className='text-sm font-semibold mb-0'>
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transModels',
                                )}
                            </p>
                            <Textarea
                                className='border border-solid border-[#4D4D4D42] rounded-b-[5px] w-full h-[290px] p-2'
                                onChange={handleTemplateChange}
                            />
                        </div>*/}

                        <div
                            className={`w-full${cardFormat === 'email' ? ' sm:w-1/2' : ' sm:w-3/4'}`}
                        >
                            <p className='text-center text-sm font-semibold mb-0'>
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transDisplayedText',
                                )}
                            </p>
                            <Textarea
                                className='border border-solid border-[#4D4D4D42] rounded-b-[5px] w-full h-[290px] p-2'
                                value={template.data}
                                onChange={(e) => {
                                    setTemplate({
                                        ...template,
                                        data: e.target.value,
                                    });
                                    updateTemplateAndField({
                                        ...template,
                                        data: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className='sm:w-1/4 w-full h-full'>
                            <p className='text-sm font-semibold mb-0'>
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transVariables',
                                )}
                            </p>
                            <div className='border border-solid border-[#4D4D4D42] rounded-b-[5px] w-full h-[134px] p-2 overflow-auto cursor-pointer'>
                                {variable_array(t).map(
                                    ({ key, variable }, index) => (
                                        <p
                                            key={index}
                                            className='mb-[10px]'
                                            onDoubleClick={() =>
                                                handleDoubleClickVariables(
                                                    variable,
                                                )
                                            }
                                        >
                                            <span className='font-semibold text-sm'>
                                                {key}
                                            </span>{' '}
                                            {'${' + variable + '}'}
                                            {template.props.variables_available?.includes(
                                                variable,
                                            ) && (
                                                <FaCheck
                                                    color='#66A0E2'
                                                    className='ml-2'
                                                    fontSize={14}
                                                />
                                            )}
                                        </p>
                                    ),
                                )}
                            </div>
                            <p className='text-sm font-semibold mb-0'>
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transFormattingAvailable',
                                )}
                            </p>
                            <div className='border border-solid border-[#4D4D4D42] rounded-b-[5px] w-full h-[134px] p-2 overflow-auto cursor-pointer'>
                                {style_array(t).map(
                                    ({ key, variable, ennum }, index) => (
                                        <p
                                            key={index}
                                            className='mb-[10px]'
                                            onDoubleClick={() =>
                                                handleDoubleClickStyle(ennum)
                                            }
                                        >
                                            <span className='font-semibold text-sm'>
                                                {key}
                                            </span>{' '}
                                            {'<' + variable + '>'}
                                            {template.props.interpretor.commands_available?.includes(
                                                variable,
                                            ) && (
                                                <FaCheck
                                                    color='#66A0E2'
                                                    className='ml-2'
                                                    fontSize={14}
                                                />
                                            )}
                                        </p>
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default FourthStepperScreen;
