// Import des dépendances nécessaires
import React, { useEffect, useState, useCallback } from 'react';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import useFormResponse from 'ck_commun/src/app/modUtils/hooks/useFormResponse';
import { getChangedValues } from 'ck_commun/src/app/modUtils/functions/values/formUtils';
import CkFormModal from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/CkFormModal';
// Import des composants de formulaire
import RadioForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/RadioForm';
import SelectForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/SelectForm';
import CheckboxForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/CheckboxForm';
import InputNameForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/InputNameForm';
// Import des actions Redux
import {
    create_profil,
    get_profil_detail,
    update_profil_detail,
} from '../slices/profilSlice';

/**
 * 🛠️ Formulaire de gestion des profils
 * Utilisé dans la page CRM → Profils
 *
 * 📌 Accès rapide :
 * 🔗 URL : https://dev.v3.ck-wash.fr/manager/CRM/profils
 *
 * 🔍 Comment y accéder ?
 * 1. Créer un profil :
 *    - Étape 1 : Ouvrir la modal "Créer un profil" en cliquant sur le bouton correspondant.
 * 2. Modifier un profil :
 *    - Étape 1 : Sélectionner un profil.
 *    - Étape 2 : Ouvrir la modal "Modifier un profil" en cliquant sur l'icône stylo (🖊️) située sur la ProfilsCard.
 *
 * 📝 Rôle :
 * Ce formulaire permet de créer/modifier des profils dans l'interface CRM.
 */

export default function FormProfilModal({
    selectedProfilId,
    isModalVisible,
    setIsModalVisible,
}) {
    // Déclaration des constantes de base
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState();
    const { formResponse, loading, status } = useFormResponse();
    // Déclaration des constantes relatives au formulaire
    const edit = !!selectedProfilId;
    const { groupId } = useSelector((state) => state.manager);
    const [showExtraCheckboxes, setShowExtraCheckboxes] = useState(false);
    const { profilDetail, loadingDetail } = useSelector(
        (state) => state.profil.profil,
    );

    // Fonction pour initialiser les valeurs du formulaire
    const initializeFormValues = () => {
        const newInitialValues = {
            name: profilDetail.name || '',
            serviceType: profilDetail?.service_type || null,
            isPublic: profilDetail?.is_public,
            isActive: profilDetail?.is_active,
            isUsableByProfessional: profilDetail?.is_usable_by_professional,
            isUsableByParticular: profilDetail?.is_usable_by_particular,
            isConnectableToCompany: profilDetail?.is_connectable_to_company,
            isConnectableToGroup: profilDetail?.is_connectable_to_group,
            isConnectableToCustomer: profilDetail?.is_connectable_to_customer,
        };

        // Mettre à jour les valeurs initiales du formulaire
        form.setFieldsValue(newInitialValues);
        setInitialValues(newInitialValues);
        setShowExtraCheckboxes(profilDetail?.is_usable_by_professional);
    };

    // Remplir le formulaire en mode édition
    useEffect(() => {
        if (edit && profilDetail) {
            initializeFormValues();
        }
    }, [edit, profilDetail, selectedProfilId]);

    // create
    const create = useCallback(
        async (values) => {
            await formResponse(
                () => dispatch(create_profil({ groupId, ...values })).unwrap(),
                () => setIsModalVisible(false),
            );
        },
        [dispatch, formResponse, groupId],
    );

    // update
    const update = useCallback(
        async (values) => {
            await formResponse(
                () =>
                    dispatch(
                        update_profil_detail({
                            groupId,
                            profilId: selectedProfilId,
                            ...values,
                        }),
                    ).unwrap(),
                () => setIsModalVisible(false),
            );
        },
        [dispatch, formResponse, groupId, selectedProfilId],
    );

    // detail
    const detail = useCallback(async () => {
        await formResponse(() =>
            dispatch(
                get_profil_detail({ groupId, profilId: selectedProfilId }),
            ).unwrap(),
        );
    }, [dispatch, formResponse, groupId, selectedProfilId]);
    // pas utiliser actuelement
    useEffect(() => {
        if (edit && groupId && selectedProfilId !== profilDetail?.id) {
            detail();
        }
    }, [groupId, selectedProfilId]);

    // 🚀 Soumission du formulaire
    const onFinish = (values) => {
        // si le formulaire es en mode edit
        if (edit) {
            // on reccupere les nouvelles valeurs et celles qui on changé
            const changedValues = getChangedValues(initialValues, values);

            if (Object.keys(changedValues).length > 0) {
                update(changedValues); // Envoie uniquement les valeurs modifiées
            } else {
                // Si aucune modification n'a été apportée, la modal est fermée
                setIsModalVisible(false);
            }
        } else {
            create(values);
        }
    };

    const onFieldsChange = (_, allFields) => {
        // Récupérer la valeur du champ "isUsableByProfessional"
        const isUsableByProfessional = allFields.find(
            (field) => field.name.join('.') === 'isUsableByProfessional',
        )?.value;

        // Vérification pour afficher les checkboxes en fonction de la valeur
        if (isUsableByProfessional) {
            setShowExtraCheckboxes(true);
        } else {
            setShowExtraCheckboxes(false);
        }
    };

    return (
        <CkFormModal
            edit={edit}
            form={form}
            status={status}
            loading={loading}
            onFinish={onFinish}
            messages={{
                title: edit
                    ? 'formProfilModal.titleEdit'
                    : 'formProfilModal.titleCreate',
            }}
            loadingDetail={loadingDetail}
            initialValues={initialValues}
            isModalVisible={isModalVisible}
            onFieldsChange={onFieldsChange}
            setIsModalVisible={setIsModalVisible}
        >
            <InputNameForm
                messages={{
                    label: 'formProfilModal.labelProfil',
                    help: 'formProfilModal.helpProfil',
                    required: 'formProfilModal.requiredProfil',
                    placeholder: 'formProfilModal.labelProfil',
                }}
            />

            <div className='grid grid-cols-1 sm:grid-cols-2 gap-0 sm:gap-4'>
                {!edit && (
                    <SelectForm
                        name='serviceType'
                        options={[
                            // TODO utiliser une enum ?
                            { label: 'formProfilModal.subscription', value: 0 },
                            { label: 'formProfilModal.wallet', value: 1 },
                        ]}
                        messages={{
                            label: 'formProfilModal.labelServiceType',
                            help: 'formProfilModal.helpServiceType',
                            required: 'formProfilModal.requiredServiceType',
                            placeholder:
                                'formProfilModal.placeholderServiceType',
                        }}
                    />
                )}

                <RadioForm
                    name='isPublic'
                    defaultValue={
                        edit && profilDetail
                            ? profilDetail?.is_public
                            : undefined
                    }
                    options={[
                        // TODO utiliser une enum ?
                        { label: 'profilDetails.public', value: true },
                        { label: 'profilDetails.private', value: false },
                    ]}
                    messages={{
                        label: 'formProfilModal.labelIsPublic',
                        help: 'formProfilModal.helpIsPublic',
                        required: 'formProfilModal.requiredIsPublic',
                    }}
                />
            </div>

            <div className='flex'>
                <CheckboxForm
                    className='mr-8'
                    messages={{
                        label: 'formProfilModal.target',
                        help: 'formProfilModal.typeExplanation',
                    }}
                    options={[
                        {
                            name: 'isUsableByProfessional',
                            label: 'formProfilModal.professional',
                        },
                        {
                            name: 'isUsableByParticular',
                            label: 'formProfilModal.particular',
                        },
                    ]}
                />

                {showExtraCheckboxes && (
                    <CheckboxForm
                        messages={{
                            label: 'formProfilModal.usableBy',
                            help: 'formProfilModal.proOnlyForEmployees',
                        }}
                        options={[
                            {
                                name: 'isConnectableToCompany',
                                label: 'formProfilModal.company',
                            },
                            {
                                name: 'isConnectableToGroup',
                                label: 'formProfilModal.group',
                            },
                            {
                                name: 'isConnectableToCustomer',
                                label: 'formProfilModal.user',
                            },
                        ]}
                    />
                )}
            </div>
        </CkFormModal>
    );
}
