/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/payment/e_currency.py
Aucune modification manuelle acceptée
*/

// Code des devices
// ISO 4217
const eCurrency = Object.freeze({
  // CHF
  756: 'eCurrency.chf',

  // £
  826: 'eCurrency.gbp',

  // $
  840: 'eCurrency.usd',

  // EC$
  951: 'eCurrency.xcd',

  // ₣
  953: 'eCurrency.xpf',

  // €
  978: 'eCurrency.eur',

  // zł
  985: 'eCurrency.pln',
});

export default eCurrency;
