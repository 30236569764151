import React from 'react';
import { useTranslation } from 'react-i18next';
import CKToolTip from 'ck_commun/src/app/modUtils/components/CKToolTip/CKToolTip';
import ButtonDisabled from 'ck_commun/src/app/modUtils/components/buttons/ButtonDisabled';
import ButtonSubmit from 'ck_commun/src/app/modUtils/components/buttons/ButtonSubmit';

export default function SubmitForm({ loading, errorMessage }) {
    const { t } = useTranslation();

    const tooltipMessage = loading
        ? t('submitForm.toolTipLoading')
        : errorMessage
          ? `${t('submitForm.toolTipError')}\n${errorMessage}`
          : '';

    const buttonLabel = loading
        ? t('submitForm.buttonLoading')
        : errorMessage
          ? t('submitForm.buttonError')
          : null;

    return (
        <div className='flex justify-center mt-2'>
            {tooltipMessage ? (
                <CKToolTip
                    placement='top'
                    trigger='hover'
                    content={tooltipMessage}
                >
                    <div>
                        <ButtonDisabled>{buttonLabel}</ButtonDisabled>
                    </div>
                </CKToolTip>
            ) : (
                <ButtonSubmit />
            )}
        </div>
    );
}
