import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    dateFormat,
    setMomentLocale,
} from '../../../modUtils/functions/values/dateFormat.js';
import { getRandomColor } from '../../../modUtils/functions/theme/getRandomColor.js';
import moment from 'moment';
import { eCdbsPaymentType } from '../../../../commun_global/enums.js';
import './StatisticsByPaymentType.scss';
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

const barOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
        },
    },
};

//* debut StatisticByPaymentTypesDistribution
export const pieOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'left',
        },
        tooltip: {
            // callbacks: {
            //   label: (context) => {
            //     const label = context.label || '';
            //     const value = context.parsed || 0;
            //     const total = context.dataset.data.reduce((acc, current) => acc + current, 0);
            //     const percentage = context.dataset.data.percentage
            //     return `${label}: ${value} (${percentage})`;
            //   },
            // },
        },
    },
};
//* fin StatisticByPaymentTypesDistribution

export default function StatisticsByPaymentType({
    dataStatisticByPaymentTypesDistribution,
    dataStatisticByPaymentTypesDistributionByPeriod,
    dataStatisticByPaymentTypesGlobalByPeriod,
}) {
    const { t } = useTranslation();
    // setMomentLocale()
    const [
        statisticByPaymentTypesDistributionLabels,
        setStatisticByPaymentTypesDistributionLabels,
    ] = useState([]);
    const [
        statisticByPaymentTypesDistributionData,
        setStatisticByPaymentTypesDistributionData,
    ] = useState([]);
    const [
        statisticByPaymentTypesDistributionByPeriodLabels,
        setStatisticByPaymentTypesDistributionByPeriodLabels,
    ] = useState([]);
    const [
        statisticByPaymentTypesDistributionByPeriodData,
        setStatisticByPaymentTypesDistributionByPeriodData,
    ] = useState([]);
    const [paymentTypeColors, setPaymentTypeColors] = useState({});
    //REPARTITION PAR MOYEN DE PAIEMENT
    useEffect(() => {
        if (dataStatisticByPaymentTypesDistribution) {
            const newChartLabels = [];
            const newChartData = [];
            const newColors = {};

            dataStatisticByPaymentTypesDistribution.forEach((item) => {
                const paymentTypeLabel = t(eCdbsPaymentType[item.payment_type]);
                newChartLabels.push(paymentTypeLabel);
                newChartData.push(item.total);
                newColors[paymentTypeLabel] = getRandomColor();
            });

            setStatisticByPaymentTypesDistributionLabels(newChartLabels);
            setStatisticByPaymentTypesDistributionData(newChartData);
            setPaymentTypeColors(newColors);
        }
    }, [dataStatisticByPaymentTypesDistribution]);

    const piedata = {
        labels: statisticByPaymentTypesDistributionLabels,
        datasets: [
            {
                data: statisticByPaymentTypesDistributionData,
                backgroundColor: statisticByPaymentTypesDistributionLabels.map(
                    (label) => paymentTypeColors[label],
                ),
                borderColor: statisticByPaymentTypesDistributionLabels.map(
                    (label) => paymentTypeColors[label],
                ),
                borderWidth: 1,
            },
        ],
    };

    //PAR MOYEN DE PAIEMENT (CREDIT UTILISE )
    useEffect(() => {
        if (dataStatisticByPaymentTypesDistributionByPeriod) {
            const newChartLabels = [];
            const newDatasets = {};

            dataStatisticByPaymentTypesDistributionByPeriod.forEach((item) => {
                const label = dateFormat(item.date, 'MMM');
                newChartLabels.push(label);

                item.payment_types.forEach((paymentType) => {
                    if (!newDatasets[paymentType.payment_type]) {
                        newDatasets[paymentType.payment_type] = {
                            label: t(
                                eCdbsPaymentType[paymentType.payment_type],
                            ),
                            data: [],
                            backgroundColor:
                                paymentTypeColors[
                                    t(
                                        eCdbsPaymentType[
                                            paymentType.payment_type
                                        ],
                                    )
                                ],
                            borderColor:
                                paymentTypeColors[
                                    t(
                                        eCdbsPaymentType[
                                            paymentType.payment_type
                                        ],
                                    )
                                ],
                            borderWidth: 1,
                        };
                    }

                    newDatasets[paymentType.payment_type].data.push(
                        paymentType.total,
                    );
                });
            });

            setStatisticByPaymentTypesDistributionByPeriodLabels(
                newChartLabels,
            );
            setStatisticByPaymentTypesDistributionByPeriodData(
                Object.values(newDatasets),
            );
        }
    }, [dataStatisticByPaymentTypesDistributionByPeriod, paymentTypeColors]);

    const barData = {
        labels: statisticByPaymentTypesDistributionByPeriodLabels,
        datasets: statisticByPaymentTypesDistributionByPeriodData,
    };

    return (
        <div>
            <div className='uppercase text-[17px] leading-[24px] text-black font-bold m-[20px] text-center'>
                STATISTIQUE PAR MOYEN DE PAIEMENT
            </div>
            <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px] w-full'>
                <div className='flex-display flex-row justify-content-around '>
                    <div className='flex-display flex-column align-items-center'>
                        <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>
                            PAR MOYEN DE PAIEMENT (CREDIT UTILISE )
                        </div>
                        <div>
                            <Bar data={barData} options={barOptions} />
                        </div>
                    </div>
                    <div className='flex-display flex-column align-items-center'>
                        <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>
                            REPARTITION PAR MOYEN DE PAIEMENT
                        </div>
                        <div>
                            <Pie data={piedata} options={pieOptions} />
                        </div>
                    </div>
                </div>
                {/* <div className="legend-container">
          <ul>
            {combinedData.labels.map((label, index) => (
              <li key={label}>
                <span
                  className="legend-color"
                  style={{
                    backgroundColor:
                      combinedData.datasets[0].backgroundColor[index],
                  }}
                />
                <span className="legend-text">{label}</span>
              </li>
            ))}
          </ul>
        </div> */}
            </div>
            <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px] w-full'>
                <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>
                    CREDIT UTILISE PAR MOYEN DE PAIEMENT
                </div>
                <table
                    id='tablePaymentTypesGlobalByPeriod'
                    className='table table-stripped'
                >
                    <thead>
                        <tr>
                            <th></th>
                            {dataStatisticByPaymentTypesGlobalByPeriod?.map(
                                (entry) => (
                                    <th key={entry.date}>
                                        {dateFormat(
                                            entry.date,
                                            'MMMM',
                                        ).toUpperCase()}
                                    </th>
                                ),
                            )}
                        </tr>
                        {/* <tr>
              <th></th>
              {dataStatisticByPaymentTypesGlobalByPeriod?.map((entry) => (
                <th key={entry.date}>
                  <td>€</td>
                  <td>%</td>
                </th>
              ))}
            </tr> */}
                    </thead>
                    <tbody>
                        {dataStatisticByPaymentTypesGlobalByPeriod[0]?.result_real?.map(
                            (itemReal) => (
                                <tr key={itemReal.payment_type}>
                                    <td>
                                        {t(
                                            eCdbsPaymentType[
                                                itemReal.payment_type
                                            ],
                                        )}
                                    </td>
                                    {dataStatisticByPaymentTypesGlobalByPeriod?.map(
                                        (entry) => (
                                            <td key={entry.date}>
                                                {` ${entry?.result_real
                                                    ?.find(
                                                        (item) =>
                                                            item.payment_type ===
                                                            itemReal.payment_type,
                                                    )
                                                    ?.total.toLocaleString(
                                                        'fr-FR',
                                                    )} €`}
                                            </td>
                                            // <td key={entry.date}>
                                            //   {entry?.result_real
                                            //     ?.find((item) => item.payment_type === itemReal.payment_type)
                                            //     ?.percent}%
                                            // </td>
                                        ),
                                    )}
                                </tr>
                            ),
                        )}

                        {/* Ligne pour afficher turnover_real */}
                        <tr className='row-turnover-real'>
                            <td>CA Réel</td>
                            {dataStatisticByPaymentTypesGlobalByPeriod?.map(
                                (entry) => (
                                    <td
                                        key={entry.date}
                                    >{`${entry?.turnover_real.toLocaleString('fr-FR')} €`}</td>
                                ),
                            )}
                        </tr>

                        {/* Répétez le même processus pour result_others */}
                        {dataStatisticByPaymentTypesGlobalByPeriod[0]?.result_others?.map(
                            (itemOthers) => (
                                <tr key={itemOthers.payment_type}>
                                    <td>
                                        {t(
                                            eCdbsPaymentType[
                                                itemOthers.payment_type
                                            ],
                                        )}
                                    </td>
                                    {dataStatisticByPaymentTypesGlobalByPeriod?.map(
                                        (entry) => (
                                            <td key={entry.date}>
                                                {`${entry?.result_others
                                                    ?.find(
                                                        (item) =>
                                                            item.payment_type ===
                                                            itemOthers.payment_type,
                                                    )
                                                    ?.total.toLocaleString(
                                                        'fr-FR',
                                                    )} €`}
                                            </td>
                                            // <td key={entry.date}>
                                            //   {entry?.result_others
                                            //     ?.find((item) => item.payment_type === itemOthers.payment_type)
                                            //     ?.percent}%
                                            // </td>
                                        ),
                                    )}
                                </tr>
                            ),
                        )}

                        {/* Ligne pour afficher turnover_total */}
                        <tr className='row-turnover-total'>
                            <td>Montant total</td>
                            {dataStatisticByPaymentTypesGlobalByPeriod.map(
                                (entry) => (
                                    <td
                                        key={entry.date}
                                    >{`${entry.turnover_total.toLocaleString('fr-FR')} €`}</td>
                                ),
                            )}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}
