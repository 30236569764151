import { TimePicker, Space } from 'antd';
import { Label } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import Button from '../../../modUtils/components/buttons/Button';
import { CgAddR } from 'react-icons/cg';
import { FaRegMinusSquare } from 'react-icons/fa';
import moment from 'moment';

const RepeaterTime = ({
    startTimeLabel,
    endTimeLabel,
    timeRanges,
    setTimeRanges,
}) => {
    // Handle time change
    const handleTimeChange = (index, type, value) => {
        const updatedRanges = [...timeRanges];
        updatedRanges[index][type] = value;
        setTimeRanges(updatedRanges);
    };

    // Add a new time range
    const addTimeRange = () => {
        setTimeRanges([
            {
                id: timeRanges.length,
                start: moment('00:00', 'HH:mm'),
                end: moment('23:59', 'HH:mm'),
            },
            ...timeRanges,
        ]);
    };

    // Remove a time range
    const removeTimeRange = (index) => {
        setTimeRanges(timeRanges.filter((_, i) => i !== index));
    };

    return (
        <div>
            {timeRanges.map((range, index) => (
                <Space
                    key={range.id}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align='end'
                >
                    <div>
                        <Label label={startTimeLabel} />
                        <TimePicker
                            format='HH:mm'
                            allowClear={false}
                            defaultValue={range.start}
                            onChange={(value) =>
                                handleTimeChange(index, 'start', value)
                            }
                            placeholder='Start Time'
                            className='h-10'
                        />
                    </div>
                    <div>
                        <Label label={endTimeLabel} />
                        <TimePicker
                            format='HH:mm'
                            defaultValue={range.end}
                            onChange={(value) =>
                                handleTimeChange(index, 'end', value)
                            }
                            placeholder='End Time'
                            allowClear={false}
                            className='h-10'
                        />
                    </div>
                    {index > 0 ? (
                        <Button
                            color='outlined'
                            className={`flex items-center justify-center h-10`}
                            onClick={() => removeTimeRange(index)}
                        >
                            <FaRegMinusSquare fontSize={20} />
                        </Button>
                    ) : (
                        <Button
                            color='primary'
                            className={`flex items-center justify-center h-10`}
                            onClick={addTimeRange}
                        >
                            <CgAddR fontSize={20} />
                        </Button>
                    )}
                </Space>
            ))}
        </div>
    );
};

export default RepeaterTime;
