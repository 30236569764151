import React, { useState, useEffect, useRef } from 'react';
import { Table } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { dateFormat } from '../../../modUtils/functions/values/dateFormat';
import { useDispatch, useSelector } from 'react-redux';
import { get_device_histories_by_device_by_equipment_by_group_paginated } from '../../slices/equipmentSlice';
import { useTranslation } from 'react-i18next';
import {
    eDeviceStatus,
    eDeviceCategories,
    eDeviceCode,
    eFeatureId,
} from '../../../../commun_global/enums';
import './DeviceDetailedHistory.scss';
import AlertMessage from '../../../modUtils/components/AlertMessage';
import useScrollHandler from '../../../modUtils/hooks/useScrollHandler';
import { message } from 'antd';
import i18next from 'i18next';

export default function DeviceDetailedHistory({ equipment, device }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const { groupId, loadingGroup } = useSelector((state) => state.manager);
    const { deviceHistory } = useSelector((state) => state.equipment);
    const [devicesDetails, setDevicesDetails] = useState([]);
    const defaultOffset = 0;
    const defaultLimit = 10;
    const incrementalLimit = 2;
    const [limit, setLimit] = useState(defaultLimit); // Nombre d'éléments à charger à chaque fois
    const [offset, setOffset] = useState(defaultOffset); // Offset actuel pour la pagination
    const [hasMore, setHasMore] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [hasMoreDataToLoad, setHasMoreDataToLoad] = useState(false);
    const fetchData = async (offset, limit) => {
        setLoading(true);
        try {
            if (offset == defaultOffset) {
                setDevicesDetails([]);
            }

            setLoading(true);
            if (offset !== defaultOffset) {
                setIsLoadingMore(true);
            }

            const response = await dispatch(
                get_device_histories_by_device_by_equipment_by_group_paginated({
                    groupId: groupId,
                    equipmentId: equipment.id,
                    deviceId: device.id,
                    limit,
                    offset,
                }),
            ).unwrap();
            console.log(response);
            if (response.length === 0) {
                setHasMore(false);
            } else {
                setDevicesDetails((prevData) => [...prevData, ...response]);
                setOffset(offset + limit);
                let hasMore = response.next !== null ? true : false;
                setHasMoreDataToLoad(hasMore);
            }
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('devicesDetailedHistory.errorMessage')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (groupId || equipment.id || device.id) {
            fetchData(defaultOffset, defaultLimit);
        }
    }, [groupId, equipment, device, i18next.language]);

    const onScrollEnd = () => {
        if (!loading && hasMore) {
            getSupportTransactions(offset, incrementalLimit);
        }
    };

    const { tableRef, handleScroll } = useScrollHandler(onScrollEnd);

    const columns = [
        {
            title: t('table.tabColDate'),
            dataIndex: 'date',
            key: 'date',
            widtd: '30%',
            render: (text, record) => dateFormat(record.date),
        },
        {
            title: t('table.tabColStatus'), //statut
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            widtd: '20%',
            render: (text, record) => (
                <span
                    className={`codeEllips  ${text == 4 ? 'codeOk' : 'codeError'}`}
                ></span>
            ),
        },
        {
            title: t('table.tabColDescription'), //code erreur
            dataIndex: 'code_label',
            key: 'code_label',
            align: 'left',
            bold: true,
            width: '50%',
            ellipsis: true,
            render: (text, record) => (
                <span>
                    {record.code_label}
                    {record.code != 0 && `(${record.code})`}
                </span>
            ),
        },
    ];

    return (
        <>
            <div style={{ padding: '0 10px' }}>
                <div className='devicesDetailedHistoryDeviceNameContainer'>
                    <div className='devicesDetailedHistoryDeviceName'>
                        {/* <img
              src={process.env.PUBLIC_URL + '/img/design/iconRefresh.svg'}
              alt="refresh"
            /> */}
                        <div>
                            {t(eFeatureId[device?.feature_id])}{' '}
                            {device?.device_id > 1 && `(${device?.device_id})`}
                        </div>
                    </div>
                    <div className='cursor-pointer'>
                        <img
                            width={25}
                            src={
                                process.env.PUBLIC_URL +
                                '/img/design/IconRefresh.svg'
                            }
                            alt='refresh'
                            onClick={() => fetchData()}
                        />
                    </div>
                </div>
                <div>
                    <table className='devicesDetailedHistoryDeviceInfos'>
                        <tbody>
                            <tr>
                                <td>
                                    {t('devicesDetailedHistory.categoryId')}
                                </td>
                                <td>
                                    {' '}
                                    {t(
                                        eDeviceCategories[
                                            device?.device_category_id
                                        ],
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {t('devicesDetailedHistory.manuFacture')}
                                </td>
                                <td>{device?.manufacture}</td>
                            </tr>
                            <tr>
                                <td>{t('devicesDetailedHistory.product')}</td>
                                <td>{device?.product}</td>
                            </tr>
                            <tr>
                                <td>
                                    {t('devicesDetailedHistory.serialNumber')}
                                </td>
                                <td>{device?.serial_number}</td>
                            </tr>
                            {device?.is_software_pure ? (
                                <>
                                    <tr>
                                        <td>
                                            {t(
                                                'devicesDetailedHistory.softVersion',
                                            )}
                                        </td>
                                        <td>{device?.soft_version}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t(
                                                'devicesDetailedHistory.softConfig',
                                            )}
                                        </td>
                                        <td>{device?.soft_config}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t(
                                                'devicesDetailedHistory.lvVersion',
                                            )}
                                        </td>
                                        <td>{device?.lv_version}</td>
                                    </tr>
                                </>
                            ) : (
                                <tr>
                                    <td>
                                        {t(
                                            'devicesDetailedHistory.hardVersion',
                                        )}
                                    </td>
                                    <td>{device?.hard_version}</td>
                                </tr>
                            )}
                            <tr>
                                <td>{t('devicesDetailedHistory.status')}</td>
                                <td>
                                    <div className='flex-display flex-row align-items-center'>
                                        <span
                                            className={`codeEllips ${
                                                device?.status == 4
                                                    ? 'codeOk'
                                                    : device?.status == 3
                                                      ? 'codeError'
                                                      : device?.status == 0
                                                        ? 'codeNotInstalled'
                                                        : ''
                                            }`}
                                        ></span>
                                        <span style={{ marginLeft: 10 }}>
                                            {device?.code_label}
                                            {device?.code != 0 &&
                                                ` (${device?.code})`}
                                            {device?.updated_at &&
                                                ` ${dateFormat(device?.updated_at)}`}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='devicesDetailedHistoryTable' ref={tableRef}>
                <Table
                    columns={columns}
                    dataSource={devicesDetails}
                    onScroll={handleScroll}
                    scroll={{ y: 437 }}
                />
            </div>
        </>
    );
}
