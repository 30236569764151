import React from 'react';
import { Route } from 'react-router-dom';
import CkRoutes from 'ck_commun/src/app/modUtils/routes/CkRoutes';
import Account from 'ck_commun/src/app/modAuthentication/pages/Account';
import AccessListPage from 'ck_commun/src/app/modManager/pages/AccessListPage';
import BuildingPage from 'ck_commun/src/app/modUtils/components/errorPage/BuildingPage';

export default function SettingsRoutes() {
    return (
        <CkRoutes>
            <Route path='account' element={<Account />} />
            <Route path='access-list' element={<AccessListPage />} />
            <Route path='access-group' element={<BuildingPage />} />
            <Route path='alerts' element={<BuildingPage />} />
            <Route path='widgets' element={<BuildingPage />} />
        </CkRoutes>
    );
}
