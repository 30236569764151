import authenticationSlice from '../../modAuthentication/slices/authenticationSlice';
import equipmentSlice from '../../modEquipment/slices/equipmentSlice';
import managerSlice from '../../modManager/slices/managerSlice';
import locationSlice from '../../modLocation/slices/locationSlice';
import saleSlice from '../../modSale/slices/saleSlice';
import statementSlice from '../../modStatement/slices/statementSlice';
const commonReducer = {
    authentication: authenticationSlice,
    equipment: equipmentSlice,
    manager: managerSlice,
    location: locationSlice,
    sale: saleSlice,
    statement: statementSlice
    
};

export default commonReducer;
