import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Input, message } from 'antd';
import { eEquipmentType } from 'ck_commun/src/commun_global/enums.js';
import { periods } from '../functions/handleDaysOfWeek';
import { create_constraint_financial } from '../slices/constraintFinancialSlice';
import FormLegend from '../../modUtils/components/FormLegend';
import {
    Modal,
    Select,
} from '../../modUtils/components/componentsLibrary/componentsLibrary';
import AlertMessage from '../../modUtils/components/AlertMessage';

export default function AddFinancialConstraintModal({
    isModalVisible,
    setIsModalVisible,
    selectedConfigId,
    setLoading,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);
    const [form] = Form.useForm();

    // TODO CH UTILISÉ ENUM QUAND ELLE SERA DISPO
    const E_CONSTRAINT_FINANCIAL_LIMIT_UNIT_BASE = {
        1: t('addFinancialConstraintModal.amount'),
        2: t('addFinancialConstraintModal.usageCount'),
    };

    const E_CONSTRAINT_FINANCIAL_LIMIT_UNIT = Object.fromEntries(
        Object.entries(E_CONSTRAINT_FINANCIAL_LIMIT_UNIT_BASE).map(
            ([key, value]) => [value, parseInt(key)],
        ),
    );

    const optionsLimiteUnit = [
        {
            label: t('addFinancialConstraintModal.usageCount'),
            value: E_CONSTRAINT_FINANCIAL_LIMIT_UNIT[
                t('addFinancialConstraintModal.usageCount')
            ],
        },
        {
            label: t('addFinancialConstraintModal.amount'),
            value: E_CONSTRAINT_FINANCIAL_LIMIT_UNIT[
                t('addFinancialConstraintModal.amount')
            ],
        },
    ];
    const [limiteOptions, setLimiteOptions] = useState(
        optionsLimiteUnit[0].value,
    );

    // options Select
    const transformedData = Object.entries(eEquipmentType).map(
        ([key, value], index) => ({
            label: t(value),
            value: parseInt(key),
        }),
    );
    // Ajout de la première valeur { label: "Tous", value: null }
    transformedData.unshift({
        label: t('addFinancialConstraintModal.all'),
        value: null,
    });

    // handle change select equipment type
    const handleEquipmentTypeChange = (value) => {
        form.setFieldsValue({ equipmentType: value });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {
        form.submit();
    };

    // handle select period type
    const handleSelectPeriodType = (selectedValue) => {
        form.setFieldsValue({ periodType: selectedValue });
    };

    // handle select limite type
    const handleSelectLimiteType = (value) => {
        setLimiteOptions(value);
        form.setFieldsValue({ LimiteType: value });
    };

    // ajouter nouvelle contrainte finanicère
    const addNewFinancialConstraint = async (values) => {
        try {
            setLoading(true);
            await dispatch(
                create_constraint_financial({
                    groupId,
                    configurationId: selectedConfigId,
                    ...values,
                }),
            ).unwrap();
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={`Une erreur est survenue lors de l'ajout d'une nouvelle contrainte financière !`}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        } finally {
            setLoading(false);
            setIsModalVisible(false);
        }
    };

    useEffect(() => {
        form.resetFields();
        form.setFieldValue('LimiteType', limiteOptions);
    }, [groupId, form]);

    // fonction appelée quand on envoie le formulaire
    const onFinish = (values) => {
        addNewFinancialConstraint(values);
        setIsModalVisible(false);
    };

    return (
        <Modal
            title={t('addFinancialConstraintModal.addFinancialConstraint')}
            isOpen={isModalVisible}
            onClose={handleCancel}
            onCancel={handleCancel}
            onOk={handleOk}
        >
            <Form form={form} onFinish={onFinish} layout='vertical'>
                <div className='flex flex-col items-center'>
                    <div className='flex flex-row'>
                        <div className='mx-2 max-w-[160px] min-w-[160px]'>
                            <Form.Item
                                label={t(
                                    'addFinancialConstraintModal.equipmentType',
                                )}
                                name='equipmentType'
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'addFinancialConstraintModal.pleaseSelectDeviceType',
                                        ),
                                        // Validation personnalisée pour accepter null
                                        validator: (_, value) => {
                                            if (value !== null) {
                                                return Promise.resolve();
                                            }
                                            // Si "Tous" est sélectionné (null), le formulaire peut toujours passer
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Select
                                    options={transformedData}
                                    placeholder={t(
                                        'addFinancialConstraintModal.equipmentType',
                                    )}
                                    onSelect={handleEquipmentTypeChange}
                                    style={{ selectInput: { width: '130px' } }}
                                />
                            </Form.Item>
                        </div>

                        <div className='mx-2 max-w-[160px] min-w-[160px]'>
                            <Form.Item
                                label={t(
                                    'addFinancialConstraintModal.periodType',
                                )}
                                name='periodType'
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'addFinancialConstraintModal.pleaseSelectPeriodType',
                                        ),
                                    },
                                ]}
                            >
                                <Select
                                    options={periods}
                                    placeholder={t(
                                        'addFinancialConstraintModal.periodType',
                                    )}
                                    onSelect={handleSelectPeriodType}
                                    style={{ selectInput: { width: '130px' } }}
                                />
                            </Form.Item>
                        </div>

                        <div className='mx-2 max-w-[160px] min-w-[160px]'>
                            <Form.Item
                                label={t(
                                    'addFinancialConstraintModal.limitType',
                                )}
                                // name="LimiteType"
                                initialValue={optionsLimiteUnit}
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'addFinancialConstraintModal.pleaseSelectLimitType',
                                        ),
                                    },
                                ]}
                            >
                                <Select
                                    options={optionsLimiteUnit}
                                    onSelect={handleSelectLimiteType}
                                    defaultActiveFirstOption={true}
                                    placeholder={t(
                                        'addFinancialConstraintModal.limitType',
                                    )}
                                    style={{ selectInput: { width: '130px' } }}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    {limiteOptions === 1 && (
                        <Form.Item
                            label={t('addFinancialConstraintModal.limitIn')}
                            name='amountMax'
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'addFinancialConstraintModal.pleaseEnterTheLimit',
                                    ),
                                },
                                {
                                    validator: (_, value) => {
                                        const regex = /^\d{1,4}(\.\d{0,2})?$/; // Vérifie 4 chiffres avant la virgule et jusqu'à 2 après
                                        if (!value || !regex.test(value)) {
                                            return Promise.reject(
                                                new Error(
                                                    t(
                                                        'addFinancialConstraintModal.invalidAmount',
                                                    ),
                                                ),
                                            );
                                        }
                                        if (parseFloat(value) <= 0) {
                                            return Promise.reject(
                                                new Error(
                                                    t(
                                                        'addFinancialConstraintModal.mustBeGreaterThanZero',
                                                    ),
                                                ),
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input
                                placeholder=''
                                type='text' // Utiliser "text" pour permettre la validation avec regex
                                className='w-40'
                            />
                        </Form.Item>
                    )}

                    {limiteOptions === 2 && (
                        <Form.Item
                            label={t('addFinancialConstraintModal.limitInUse')}
                            name='usageMax'
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'addFinancialConstraintModal.pleaseEnterTheLimit',
                                    ),
                                },
                                {
                                    validator: (_, value) => {
                                        const regex = /^\d{1,4}$/; // Vérifie jusqu'à 4 chiffres entiers
                                        if (!value || !regex.test(value)) {
                                            return Promise.reject(
                                                new Error(
                                                    t(
                                                        'addFinancialConstraintModal.invalidAmount',
                                                    ),
                                                ),
                                            );
                                        }
                                        if (parseInt(value, 10) <= 0) {
                                            return Promise.reject(
                                                new Error(
                                                    t(
                                                        'addFinancialConstraintModal.mustBeGreaterThanZero',
                                                    ),
                                                ),
                                            );
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input
                                placeholder=''
                                type='text' // Utiliser "text" pour permettre la validation avec regex
                                className='w-40'
                            />
                        </Form.Item>
                    )}
                </div>

                <FormLegend requiredFormItemLegend={true} />
            </Form>
        </Modal>
    );
}
