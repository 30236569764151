/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/device/e_device_code_system.py
Aucune modification manuelle acceptée
*/

// Codes erreurs spécifiques au systeme général d'un equipement (Borne de paiement...)
const eDeviceCodeSystem = Object.freeze({
  // Systeme de paiements non disponible
  101: 'eDeviceCodeSystem.paymentsUnavailable',

  // Systeme de selections non disponible
  102: 'eDeviceCodeSystem.selectionsUnavailable',
});

export default eDeviceCodeSystem;
