import { Outlet, Navigate } from 'react-router-dom';
import { getAccessToken, isAccessTokenExpired } from '../../modAuthentication/functions/handleTokens';


const PrivateRoutes = () => {
    const token = getAccessToken();
    let isLogged = token !== null && !isAccessTokenExpired();
    return isLogged ? <Outlet /> : <Navigate to='/' />;
};
export default PrivateRoutes;
