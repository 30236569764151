import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    create_employees_by_group,
    get_permissions_by_group_paginated,
    update_employee_detail_by_group,
} from '../../../slices/managerSlice';
import {
    Input,
    Modal,
    Select,
} from '../../../../modUtils/components/componentsLibrary/componentsLibrary';
import ButtonSubmit from '../../../../modUtils/components/buttons/ButtonSubmit';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { FaExclamationCircle } from 'react-icons/fa';
import CKToolTip from '../../../../modUtils/components/CKToolTip/CKToolTip';

const EmployeeModal = ({ mode, isOpen, onClose, employeeData, refresh }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [selectedPermission, setSelectedPermission] = useState(
        employeeData?.permissions?.map(
            (permission) => `${permission.app_name}:${permission.code_name}`,
        ) || [],
    );
    const [isAdmin, setIsAdmin] = useState(false);
    const [error, setError] = useState(null);
    const [errorEmail, setErrorEmail] = useState(null);
    const { groupId, groupPermissions } = useSelector((state) => state.manager);
    const ValidationSchema = Yup.object({
        email: Yup.string()
            .email(t('employeeInfoModal.invalidEmail'))
            .required(t('employeeInfoModal.emailRequired')),
        permissions: Yup.array()
            .min(1, t('addNewCashbox.selectAtLeastOneEquipement'))
            .required(t('addNewCashbox.selectAtLeastOneEquipement')),
    });
    useEffect(() => {
        if (groupId) {
            dispatch(
                get_permissions_by_group_paginated({
                    groupId,
                    limit: 100,
                    offset: 0,
                }),
            );
        }
    }, []);

    useEffect(() => {
        if (mode === 'updatePermission' && employeeData) {
            setIsAdmin(employeeData.is_admin);
        } else {
            setSelectedPermission([]);
            setIsAdmin(false);
        }
    }, [employeeData, mode]);
    const handlePermissionChange = (selectedValues) => {
        if (!Array.isArray(selectedValues)) {
            selectedValues = [selectedValues];
        }
        // ajouter au tbleau de permission les nvlles permissions selectionnées
        const uniqueSelections = Array.from(
            new Set([...selectedPermission, ...selectedValues]),
        ); // Éviter les doublons
        setSelectedPermission(uniqueSelections); // Mettre à jour l'état local
    };

    const handleDeselectPermission = (deselectedValue) => {
        const updatedPermissions = selectedPermission.filter(
            (perm) => perm !== deselectedValue,
        );
        setSelectedPermission(updatedPermissions); // Met à jour l'état local
    };
    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true);
        try {
            const params = {
                groupId,
                isAdmin,
                permissions: selectedPermission,
            };
            if (mode === 'create') {
                //create
                await dispatch(
                    create_employees_by_group({
                        email: values.email,
                        ...params,
                    }),
                ).unwrap();
            } else if (mode === 'updatePermission' && employeeData) {
                //update
                await dispatch(
                    update_employee_detail_by_group({
                        employeeId: employeeData.id,
                        ...params,
                    }),
                ).unwrap();
            }
            refresh();
            onClose();
            resetForm();
        } catch (error) {
            if (error?.data?.detail?.email) {
                setErrorEmail(error?.data?.detail?.email);
            } else if (error?.data?.detail) {
                setError(error?.data?.detail);
            }
        } finally {
            setLoading(false);
        }
    };
    console.log('error email', errorEmail);

    return (
        <>
            <Modal
                title={
                    mode === 'create'
                        ? t('employeeInfoModal.addTitle')
                        : mode === 'updatePermission'
                          ? t('employeeInfoModal.updatePermissionTitle')
                          : "modifier les informations d'un employé"
                }
                isOpen={isOpen}
                onClose={onClose}
                height={550}
            >
                <Formik
                    initialValues={{
                        email: employeeData?.email || '',
                        permissions: selectedPermission,
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={(values, { resetForm }) => {
                        handleSubmit(values, resetForm);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                    }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                {mode === 'create' && (
                                    <Input
                                        value={values.email}
                                        onChange={handleChange}
                                        name='email'
                                        placeholder={t(
                                            'employeeInfoModal.emailPlaceholder',
                                        )}
                                    />
                                )}
                                {(errors.email || errorEmail) &&
                                    touched.email && (
                                        <p className='text-red-500'>
                                            {errors.email || errorEmail}
                                        </p>
                                    )}
                                <div className='flex flex-row py-4'>
                                    <label className='flex items-center'>
                                        <input
                                            type='radio'
                                            value={false}
                                            checked={isAdmin === true}
                                            onChange={() => setIsAdmin(true)}
                                        />
                                        <span className='ml-2'>
                                            {t('employeeInfoModal.admin')}
                                        </span>
                                    </label>
                                    <label className='flex items-center ml-4'>
                                        <input
                                            type='radio'
                                            value={false}
                                            checked={isAdmin === false}
                                            onChange={() => setIsAdmin(false)}
                                        />
                                        <span className='ml-2'>
                                            {t('employeeInfoModal.user')}
                                        </span>
                                    </label>
                                </div>
                                <div>
                                    <Select
                                        defaultValue={selectedPermission} // Liste des permissions sélectionnées
                                        options={groupPermissions.map(
                                            (perm) => ({
                                                value: `${perm.app_name}:${perm.code_name}`,
                                                label: (
                                                    <CKToolTip
                                                        content={perm.help_text}
                                                        placement='rightTop'
                                                        arrow={false}
                                                    >
                                                        {' '}
                                                        {perm.title}
                                                    </CKToolTip>
                                                ),
                                            }),
                                        )} // Liste des permissions disponibles
                                        onSelect={(value) => {
                                            handlePermissionChange(value);
                                            setFieldValue(
                                                'permissions',
                                                selectedPermission,
                                            );
                                        }} // Gestion de la sélection
                                        onDeselect={(value) => {
                                            handleDeselectPermission(value);
                                            setFieldValue(
                                                'permissions',
                                                selectedPermission,
                                            );
                                        }} // Gestion de la désélection
                                        multiple // Autoriser la sélection multiple
                                        placeholder={t(
                                            'employeeInfoModal.SelectPlaceholder',
                                        )}
                                        style={{
                                            select: {
                                                width: '300px',
                                            },
                                        }}
                                        dropdownMatchSelectWidth={false}
                                    />
                                </div>

                                <div className='mt-36 flex justify-center'>
                                    <ButtonSubmit>
                                        {mode === 'create'
                                            ? t(
                                                  'employeeInfoModal.createButton',
                                              )
                                            : t(
                                                  'employeeInfoModal.updateButton',
                                              )}
                                    </ButtonSubmit>
                                </div>
                                {error && (
                                    <div className='flex justify-center gap-1'>
                                        <FaExclamationCircle
                                            className='w-4'
                                            fill='red'
                                        />
                                        <span className='text-red-500 text-xs '>
                                            {error}
                                        </span>
                                    </div>
                                )}
                            </form>
                        );
                    }}
                </Formik>
            </Modal>
        </>
    );
};

export default EmployeeModal;
