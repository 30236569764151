import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { get_profil_detail } from '../slices/profilSlice';
import { dateFormat } from '../../modUtils/functions/values/dateFormat';
import TablePaginatedScroll from '../../modUtils/components/componentsLibrary/table/TablePaginatedScroll';

export default function ProfilEntities({ selectedProfilId }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);
    const { profilDetail } = useSelector((state) => state.profil.profil);

    const getProfilDetail = async () => {
        const response = await dispatch(
            get_profil_detail({
                groupId,
                profilId: selectedProfilId,
            }),
        ).unwrap();
        return response;
    };

    const columns = [
        {
            title: 'profilEntities.entityType',
            dataIndex: 'entity',
            key: 'entity_type',
            width: '20%',
            render: (text, record) => record?.entity?.entity_type,
        },
        {
            title: 'profilEntities.name',
            dataIndex: 'entity',
            key: 'name',
            width: '35%',
            render: (text, record) => record?.entity?.name,
        },
        {
            title: 'profilEntities.date',
            dataIndex: 'created_at',
            key: 'created_at',
            width: '35%',
            render: (text, record) => dateFormat(text),
        },
    ];

    return (
        <>
            {selectedProfilId && (
                <div className='flex flex-col justify-center items-center'>
                    <span className='font-black text-sm my-6'>
                        {t(
                            'profilEntities.listOfClientsAssociatedWithThisProfile',
                        )}
                    </span>

                    <TablePaginatedScroll
                        state={profilDetail}
                        fetch={getProfilDetail}
                        effect={selectedProfilId}
                        columns={columns}
                    />
                </div>
            )}
        </>
    );
}
