import React from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import InputBaseForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/InputBaseForm';

export default function InputNameForm({
    name = 'name',
    messages = {},
    className = '',
    disabled = false,
    markerRequired = true,
}) {
    const { t } = useTranslation();

    const mergedMessages = {
        label: 'inputNameForm.label',
        help: 'inputNameForm.help',
        required: 'inputNameForm.required',
        placeholder: 'inputNameForm.placeholder',
        ...messages,
    };

    return (
        <InputBaseForm
            name={name}
            messages={mergedMessages}
            className={className}
            markerRequired={markerRequired}
            rules={[
                {
                    required: markerRequired,
                    message: t(mergedMessages.required),
                },
            ]}
        >
            <Input
                placeholder={t(mergedMessages.placeholder)}
                disabled={disabled}
            />
        </InputBaseForm>
    );
}
