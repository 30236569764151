import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getScheduledDays } from '../functions/handleDaysOfWeek';
import ButtonAdd from '../../modUtils/components/buttons/ButtonAdd';
import AddScheduleConstraintModal from './AddScheduleConstraintModal';
import { ReactComponent as DeleteIcon } from '../../modEntity/assets/images/trash-can.svg';
import ConfirmationModal from '../../modUtils/components/confirmationModal/ConfirmationModal';
import {
    Spinner,
    Table,
} from '../../modUtils/components/componentsLibrary/componentsLibrary';
import {
    get_constraint_schedule_detail,
    get_constraint_schedule_paginated,
    delete_constraint_schedule_detail,
} from '../slices/constraintScheduleSlice';

export default function ScheduleConstraints({
    configuration,
    selectedConfigId,
    isConstraintActive,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [constraintId, setConstraintId] = useState(null);
    const { groupId } = useSelector((state) => state.manager);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isAddScheduleModalVisible, setIsAddScheduleModalVisible] =
        useState(false);
    const { constraintsSchedule } = useSelector(
        (state) => state.profil.constraintSchedule,
    );

    // Récupérer les contraintes de période
    const getConstraintSchedule = async (limit, offset) => {
        await dispatch(
            get_constraint_schedule_paginated({
                groupId: groupId,
                configurationId: selectedConfigId,
                limit,
                offset,
            }),
        ).unwrap();
    };

    // Récupérer le détail d'une contrainte horaires par son id
    const getConstraintScheduleDetail = async (constraintId) => {
        await dispatch(
            get_constraint_schedule_detail({
                groupId: groupId,
                configurationId: selectedConfigId,
                constraintId: constraintId,
            }),
        ).unwrap();
    };

    // Supprimer une contrainte de temps
    const deleteConstraintSchedule = async (constraintId) => {
        setLoading(true);
        await dispatch(
            delete_constraint_schedule_detail({
                groupId,
                configurationId: selectedConfigId,
                constraintId,
            }),
        ).unwrap();
        setLoading(false);
    };

    useEffect(() => {
        groupId && selectedConfigId && getConstraintSchedule();
    }, [groupId, selectedConfigId]);

    // quand on click sur confirmer
    const handleConfirm = () => {
        setIsConfirmModalVisible(false);
        deleteConstraintSchedule(constraintId);
    };

    // annule la confirmation
    const handleCancel = () => {
        setIsConfirmModalVisible(false);
    };

    // ouvre le modal de confirmation
    const handleClickDelete = async (constraintId) => {
        setConstraintId(constraintId);
        setIsConfirmModalVisible(true);
    };

    const openEditScheduleConstraintModal = (idSchedule) => {
        getConstraintScheduleDetail(idSchedule);
        setIsEdit(true);
        setIsAddScheduleModalVisible(true);
    };

    const openAddScheduleConstraintModal = () => {
        setIsEdit(false);
        setIsAddScheduleModalVisible(true);
    };

    const columns = [
        {
            title: 'scheduleAndPeriodConstraints.days',
            dataIndex: 'schedule_monday',
            key: 'schedule_monday',
            width: '40%',
            render: (text, record) => (
                <div
                    className='w-full h-full flex items-center justify-center'
                    onClick={() => openEditScheduleConstraintModal(record?.id)}
                >
                    {getScheduledDays({
                        schedule_monday: record.schedule_monday,
                        schedule_tuesday: record.schedule_tuesday,
                        schedule_wednesday: record.schedule_wednesday,
                        schedule_thursday: record.schedule_thursday,
                        schedule_friday: record.schedule_friday,
                        schedule_saturday: record.schedule_saturday,
                        schedule_sunday: record.schedule_sunday,
                    })}
                </div>
            ),
        },
        {
            title: 'scheduleAndPeriodConstraints.scheduleOf',
            dataIndex: 'time_slot',
            key: 'time_slot_hours_start',
            width: '15%',
            render: (text, record) => (
                <div
                    className='w-full h-full flex flex-col items-center justify-center'
                    onClick={() => openEditScheduleConstraintModal(record?.id)}
                >
                    {record?.time_slot?.map((hourStart, index) => (
                        <>
                            {index > 0 && (
                                <hr className='w-[calc(100%+10px)] border-gray-50 -ml-[5px]' />
                            )}

                            {hourStart.time_slot_hours_start}
                        </>
                    ))}
                </div>
            ),
        },
        {
            title: 'scheduleAndPeriodConstraints.scheduleAt',
            dataIndex: 'time_slot',
            key: 'time_slot_hours_end',
            width: '15%',
            render: (text, record) => (
                <div
                    className='w-full h-full flex flex-col items-center justify-center'
                    onClick={() => openEditScheduleConstraintModal(record?.id)}
                >
                    {record?.time_slot?.map((hourEnd, index) => (
                        <>
                            {index > 0 && (
                                <hr className='w-[calc(100%+10px)] border-gray-50 -ml-[5px]' />
                            )}

                            {hourEnd.time_slot_hours_end}
                        </>
                    ))}
                </div>
            ),
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (text, record) => (
                <div
                    className='w-full h-full flex items-center justify-center'
                    onClick={() => openEditScheduleConstraintModal(record?.id)}
                >
                    <div
                        className='m-auto w-[15px]'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClickDelete(text);
                        }}
                    >
                        <DeleteIcon />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            {isConstraintActive && (
                <div className='flex flex-col justify-center items-center'>
                    <span className='font-black text-sm my-6'>
                        {t('scheduleAndPeriodConstraints.timeConstraints')}
                    </span>

                    {configuration?.is_future && (
                        <>
                            <ButtonAdd
                                label={t(
                                    'scheduleAndPeriodConstraints.addTimeConstraint',
                                )}
                                className='mb-4'
                                onClick={() => openAddScheduleConstraintModal()}
                            />
                            {isAddScheduleModalVisible && (
                                <AddScheduleConstraintModal
                                    isModalVisible={isAddScheduleModalVisible}
                                    setIsModalVisible={
                                        setIsAddScheduleModalVisible
                                    }
                                    selectedConfigId={selectedConfigId}
                                    setLoading={setLoading}
                                    isEdit={isEdit}
                                />
                            )}
                        </>
                    )}

                    {loading ? (
                        <Spinner />
                    ) : (
                        <Table
                            columns={columns}
                            dataSource={constraintsSchedule}
                        />
                    )}

                    <ConfirmationModal
                        onOk={handleConfirm}
                        onCancel={handleCancel}
                        visible={isConfirmModalVisible}
                        content={t(
                            'scheduleAndPeriodConstraints.removeTimeConstraint',
                        )}
                    />
                </div>
            )}
        </>
    );
}
