import React from 'react';
import { useTranslation } from 'react-i18next';
import AlertMessage from 'ck_commun/src/app/modUtils/components/AlertMessage';
import SubmitForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/SubmitForm';
import LegendForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/LegendForm';

export default function FormFooter({ status, loading, errorMessage, filter }) {
    const { t } = useTranslation();

    return (
        <>
            {!filter && <LegendForm />}

            <div className={`${filter && 'inligne'}`}>
                <SubmitForm loading={loading} errorMessage={errorMessage} />
            </div>

            {!filter && (
                <div className='flex justify-center'>
                    {status && (
                        <AlertMessage
                            className='mt-4 flex justify-center'
                            status={status}
                            errorDetail={
                                status === 'success'
                                    ? t('loadingStatusForm.success')
                                    : t('loadingStatusForm.error')
                            }
                            // errorDetail={} // TODO utiliser le hook pour donner les detail des erreur
                        />
                    )}
                </div>
            )}
        </>
    );
}
