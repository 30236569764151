import { useTranslation } from 'react-i18next';
import Button from 'ck_commun/src/app/modUtils/components/buttons/Button';

const ErrorPage = ({
    icon,
    title,
    description,
    onClick = () => {},
    buttonText = 'Error404Page.homeReturn',
}) => {
    const { t } = useTranslation();
    return (
        <div className='grid bg-background-color'>
            <div className='text-center mt-20 flex items-center flex-col'>
                {icon}
                <h2 className='mt-10 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                    {title}
                </h2>
                <p className='mt-2 text-xl text-gray-500'>{description}</p>
                <Button onClick={() => onClick()} color='primary'>
                    {t(buttonText)}
                </Button>
            </div>
        </div>
    );
};

export default ErrorPage;
