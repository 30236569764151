import React, { useState, useEffect } from 'react';
import { eEquipmentType } from 'ck_commun/src/commun_global/enums.js';
import { useTranslation } from 'react-i18next';
import {
    Chart as ChartJS,
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);
//* debut répartition chiffre d'affaire par type d'equipement
export const pieoptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'left',
        },
        // tooltip: {
        //   callbacks: {
        //     label: (context) => {
        //       const label = context.label || '';
        //       const value = context.parsed || 0;
        //       const total = context.dataset.data.reduce((acc, current) => acc + current, 0);
        //       const percentage = ((value / total) * 100).toFixed(2) + '%';
        //       return `${label}: ${value} (${percentage})`;
        //     },
        //   },
        // },
    },
};
//* fin répartition chiffre d'affaire par type d'equipement
export default function StatisticsByEquipmentType({ data }) {
    const { t } = useTranslation();
    const [piedataLabels, setPiedataLabels] = useState([]);
    const [piedataData, setPiedataData] = useState([]);
    useEffect(() => {
        if (data) {
            const newChartLabels = [];
            const newChartData = [];
            [...data].forEach((item) => {
                newChartLabels.push(t(eEquipmentType[item.type]));
                newChartData.push(item.total_global);
            });
            setPiedataLabels(newChartLabels);
            setPiedataData(newChartData);
        }
    }, [data]);

    const piedata = {
        labels: piedataLabels,
        datasets: [
            {
                data: piedataData,
                backgroundColor: [
                    '#FF7F00',
                    '#005DFF',
                    '#01A73E',
                    '#FF7F00',
                    '#005DFF',
                    '#01A73E',
                    '#FF7F00',
                    '#005DFF',
                    '#01A73E',
                ],
                borderColor: [
                    '#FF7F00',
                    '#005DFF',
                    '#01A73E',
                    '#FF7F00',
                    '#005DFF',
                    '#01A73E',
                    '#FF7F00',
                    '#005DFF',
                    '#01A73E',
                ],
                borderWidth: 1,
            },
        ],
    };
    return (
        <div>
            <div className='uppercase text-[17px] leading-[24px] text-black font-bold m-[20px] text-center'>
                STATISTIQUE PAR TYPE D'EQUIPEMENT
            </div>
            <div className='flex-display flex-row justify-content-between'>
                <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px] w-[49%]'>
                    <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>
                        PAR TYPE D'EQUIPEMENT (CREDIT UTILISE )
                    </div>
                    {/* <Bar options={barOptions1} data={barData1} /> */}
                </div>
                <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px] w-[49%]'>
                    <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>
                        REPARTITION PAR TYPE D'EQUIPEMENT
                    </div>
                    <Pie options={pieoptions} data={piedata} />
                </div>
            </div>

            <div className='bg-[#D3E6FC2E] border border-[#E2E2E2] rounded-[4px] p-[10px] my-[10px] w-full'>
                <div className='text-primary-color text-center text-[14px] font-bold mb-[10px]'>
                    CREDIT UTILISE PAR TYPE D'EQUIPEMENT
                </div>
                <table></table>
            </div>
        </div>
    );
}
