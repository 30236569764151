import FirstStepperScreen from './FirstStepperScreen';
import FourthStepperScreen from './FourthStepperScreen';
import SecondStepperScreen from './SecondStepperScreen';
import ThirdStepperScreen from './ThirdStepperScreen';

export const StepperScreen = ({ step, ...props }) => {
    switch (step) {
        case 0:
            return <FirstStepperScreen {...props} />;
        case 1:
            return <SecondStepperScreen {...props} />;
        case 2:
            return <ThirdStepperScreen {...props} />;
        default:
            return <FourthStepperScreen {...props} />;
    }
};
