/**
 * Éclaircit une couleur HEX.
 * @param {string} color - La couleur HEX (#RRGGBB ou #RGB).
 * @param {number} percent - Pourcentage d'éclaircissement (0-100).
 * @returns {string} - Couleur HEX éclaircie.
 */
export function lightenHexColor(color, percent) {
    const hex = color.replace('#', '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Nouveau calcul pour éclaircir plus agressivement
    const lighten = (value, percent) => {
        // Si le pourcentage est très élevé, on se rapproche de 255
        return Math.min(
            255,
            Math.floor(value + (255 - value) * (percent / 100)),
        );
    };

    const newR = lighten(r, percent);
    const newG = lighten(g, percent);
    const newB = lighten(b, percent);

    return `#${((1 << 24) + (newR << 16) + (newG << 8) + newB)
        .toString(16)
        .slice(1)
        .toUpperCase()}`;
}
