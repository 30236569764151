// 📌 Importation des dépendances nécessaires
import React, { useState, useCallback } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useFormResponse from 'ck_commun/src/app/modUtils/hooks/useFormResponse';
import CkForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/CkForm';
// 📌 Importation des composants du filtre
import SelectSharedSitesForm from 'ck_commun/src/app/modSharedEquipment/components/SelectSharedSitesForm';
import SelectSharedGroupsForm from 'ck_commun/src/app/modSharedEquipment/components/SelectSharedGroupsForm';
import InputNameForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/InputNameForm';
// 📌 Importation des actions Redux
import { shared_equipments_paginated_get } from 'ck_commun/src/app/modSharedEquipment/slices/sharedEquipmentSlice';

/**
 * 🛠️ **FormFilterSharedEquipment** - Filtre des équipements partagés
 *
 * 📌 **Accès rapide :**
 * 🔗 URL : https://dev.v3.ck-wash.fr/manager/equipment/equipments-shared
 *
 * 🔍 **Comment y accéder ?**
 * 1. Ouvrir l'onglet "Équipements" dans la navigation.
 * 2. Aller dans "Équipements partagés".
 *
 * 📝 Rôle :
 * Ce filtre permet de gérer la liste affichée des équipements partagés.
 *
 */
export default function FormFilterSharedEquipment({ setFilterValues }) {
    // Déclaration des constantes de base
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { formResponse, status } = useFormResponse();
    // Déclaration des constantes relatives au formulaire
    const { groupId } = useSelector((state) => state.manager);
    const [group, setGroup] = useState(null);

    // Mise à jour du groupe sélectionné
    const onChangeSharedGroups = (value) => {
        setGroup(value);
    };

    // Filtrage
    const filter = useCallback(
        async (values) => {
            await formResponse(() =>
                dispatch(
                    shared_equipments_paginated_get({ groupId, ...values }),
                ).unwrap(),
            );
        },
        [dispatch, formResponse, groupId],
    );

    // Mise à jour des valeurs du filtre en temps réel
    const onFieldsChange = (_, allFields) => {
        const allValues = form.getFieldsValue(); // Récupère toutes les valeurs du formulaire
        setFilterValues && setFilterValues(allValues);
    };

    // 🚀 Soumission du formulaire
    const onFinish = (values) => {
        filter(values);
    };

    return (
        <CkForm
            form={form}
            status={status}
            onFinish={onFinish}
            filter={true}
            onFieldsChange={onFieldsChange}
        >
            {/* group */}
            <div className='w-52 mx-2'>
                <SelectSharedGroupsForm onChange={onChangeSharedGroups} />
            </div>

            {/* site */}
            <div className='w-52'>
                <SelectSharedSitesForm group={group} />
            </div>

            {/* serialNumber */}
            <div className='w-52 mx-2'>
                <InputNameForm
                    name='serialNumber'
                    markerRequired={false}
                    messages={{
                        label: t('formFilterSharedEquipment.label'),
                        help: '',
                        required: '',
                        placeholder: t('formFilterSharedEquipment.label'),
                    }}
                />
            </div>
        </CkForm>
    );
}
