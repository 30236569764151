import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    Input,
    Spinner,
    Badge,
} from '../../modUtils/components/componentsLibrary/componentsLibrary';
import { get_support_detail_by_group } from '../slices/walletLocalSlice';
import { dateFormat } from '../../modUtils/functions/values/dateFormat';
import { amountFormatWithCurrencySymbol } from '../../modUtils/functions/values/numberFormat';
import { message } from 'antd';
import AlertMessage from '../../modUtils/components/AlertMessage';
import i18next from 'i18next';

export default function SupportDetailsModal({
    modalVisible,
    setModalVisible,
    supportId,
}) {
    const { supportDetails } = useSelector((state) => state.walletLocal);
    const { groupId, loadingGroup } = useSelector((state) => state.manager);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const getSupportDetails = async () => {
        setLoading(true);
        try {
            await dispatch(
                get_support_detail_by_group({ groupId, supportId }),
            ).unwrap();
            setLoading(false);
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('supportDetailsModal.error')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
            setLoading(false);
            setModalVisible(false);
        }
    };

    useEffect(() => {
        if (supportId && groupId) {
            getSupportDetails();
        }
    }, [groupId, supportId, i18next.language]);
    var modalStyle = {
        modalContent: {
            minHeight: '60%',
            minWidth: '400px',
            width: '40%',
        },
    };
    return (
        <Modal
            // title={t("badge.badgeCreditModalTitle")}
            title={t('supportDetailsModal.title')}
            isOpen={modalVisible}
            onClose={() => setModalVisible(false)}
            style={modalStyle}
        >
            {loading ? (
                <Spinner />
            ) : (
                <>
                    {supportDetails?.suspected_fraud_count > 0 && (
                        <div className='flex-display justify-content-end'>
                            <Badge
                                color='#000'
                                overflowCount={99}
                                count={supportDetails?.suspected_fraud_count}
                                offset='8px'
                            >
                                <img
                                    width={40}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/badgesIcons/fraudeIcone.svg'
                                    }
                                    alt={
                                        supportDetails?.suspected_fraud_count +
                                        'fraudes'
                                    }
                                />
                            </Badge>
                        </div>
                    )}
                    <Input
                        label='Badge N°'
                        value={supportDetails?.badge_number}
                        disabled
                    />

                    <Input
                        label={t('supportDetailsModal.NSerial')}
                        value={supportDetails?.local_nse}
                        disabled
                    />

                    <Input
                        label='Type'
                        value={supportDetails?.amount_type_label}
                        disabled
                    />

                    <Input
                        label={t('supportDetailsModal.labelStatus')}
                        value={supportDetails?.status_label}
                        disabled
                    />

                    <Input
                        label={t('supportDetailsModal.labelAmount')}
                        value={
                            supportDetails?.amount &&
                            amountFormatWithCurrencySymbol(
                                supportDetails?.amount,
                            )
                        }
                        disabled
                    />
                    <Input
                        label={t('supportDetailsModal.labelLastVisit')}
                        value={dateFormat(supportDetails?.last_visit_date)}
                        disabled
                    />
                    {supportDetails?.entity && (
                        <Input
                            label={t('supportDetailsModal.labelEntity')}
                            value={supportDetails?.entity?.name}
                            disabled
                        />
                    )}
                </>
            )}
        </Modal>
    );
}
