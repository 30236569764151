import React, { useState, useRef, useEffect } from 'react';
import i18next from 'i18next';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NoData from 'ck_commun/src/app/modUtils/components/noData/NoData';
import AlertMessage from 'ck_commun/src/app/modUtils/components/AlertMessage';
import { Label } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import { ReactComponent as LaodingSvg } from 'ck_commun/src/app/modUtils/assets/images/laoding/laoding.svg';

export default function CkTablePaginatedScroll({
    label,
    fetch,
    effect,
    columns,
    maxHeight = 'max-h-[500px]',
}) {
    const limit = 30;
    const defaultOffset = 0;
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);

    const [offset, setOffset] = useState(defaultOffset);
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [hasMoreDataToLoad, setHasMoreDataToLoad] = useState(true);

    const timeoutId = useRef(null);
    const tableRef = useRef(null);

    const fetchData = async (offsetValue) => {
        if (!hasMoreDataToLoad || loading) return;
        setLoading(true);

        try {
            const response = await fetch(limit, offsetValue);

            if (response?.next === null) {
                setHasMoreDataToLoad(false);
            }

            if (
                Array.isArray(response?.results) &&
                response.results.length > 0
            ) {
                setDataSource((prevData) => [...prevData, ...response.results]);
            }
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={'Une erreur est survenue.'}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        } finally {
            setLoading(false);
        }
    };

    const onScrollEnd = () => {
        if (groupId && !loading && hasMoreDataToLoad) {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }

            timeoutId.current = setTimeout(() => {
                setOffset((prevOffset) => prevOffset + limit);
            }, 100);
        }
    };

    const handleScroll = () => {
        if (tableRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10) {
                onScrollEnd();
            }
        }
    };

    // Reset des données lors du changement de groupe ou de langue
    useEffect(() => {
        if (effect) {
            setDataSource([]);
            setOffset('reset'); // Réinitialisation de l'offset
            setHasMoreDataToLoad(true); // Réinitialisation de l'état pour savoir s'il y a plus de données
        }
    }, [effect, i18next.language, groupId]);

    // appel fetchData au demarage et a chaque changement de offset
    useEffect(() => {
        if (groupId) {
            if (offset !== 'reset') {
                fetchData(offset, limit);
            } else {
                setOffset(0);
            }
        }
    }, [offset, groupId]);

    return (
        <div className='flex flex-col items-center w-full mx-auto'>
            {label && (
                <Label
                    label={t(label)}
                    className='my-8 text-lg font-black text-black'
                />
            )}

            {/* div pour ne pas voir le text passer au dessu du header (1/2px) */}
            <div className='h-2 w-full sticky top-0 bg-white z-20 '></div>

            <div
                ref={tableRef}
                onScroll={handleScroll}
                className={`mt-[-2px] w-full overflow-y-auto border ${maxHeight}`}
            >
                <table className='text-black w-full border-collapse tabl'>
                    <thead className='sticky top-0 bg-white z-20'>
                        {columns?.map((column) => (
                            <th
                                key={column.id}
                                className={`min-h-[40px] px-[5px] whitespace-nowrap
                                text-center font-[11px]  leading-[32px] uppercase
                                bg-secondary-color text-primary-foreground-color
                                border border-[1px] border-solid border-border-color
                                ${column.headerClassName ? column.headerClassName : ''}`}
                            >
                                {t(column.title)}
                            </th>
                        ))}
                    </thead>
                    <tbody>
                        {dataSource?.length === 0 ? (
                            <tr className={`bg-background-color`}>
                                <td
                                    colSpan={columns.length} // pour prendre toute la ligne
                                    className={` even:bg-primary-light-color
                                    border border-[1px] border-solid border-border-color`}
                                >
                                    {loading ? (
                                        <div className='flex flex-col items-center mt-8 mb-4 text-black'>
                                            <LaodingSvg className='w-8 h-8 animate-spin' />
                                        </div>
                                    ) : (
                                        <NoData
                                            text={t('table.tabNoDataFilter')}
                                        />
                                    )}
                                </td>
                            </tr>
                        ) : (
                            <>
                                {dataSource?.map((row) => (
                                    <tr
                                        key={row.index}
                                        className={`bg-background-color w-full even:bg-primary-light-color`}
                                    >
                                        {columns?.map((column) => (
                                            <td
                                                key={column.id}
                                                className={` h-[32px] px-[5px] whitespace-nowrap
                                                border border-[1px] border-solid border-border-color
                                                ${column.className ? column.className : ''}`}
                                            >
                                                {column.render
                                                    ? column.render(
                                                          row[column.dataIndex],
                                                          row,
                                                      )
                                                    : row[column.dataIndex]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                {loading && (
                                    <tr
                                        className={`bg-background-color w-full even:bg-primary-light-color`}
                                    >
                                        <td
                                            colSpan={columns.length}
                                            className={`px-[5px] border border-[1px] border-solid border-border-color`}
                                        >
                                            <div className='flex flex-col items-center mt-8 mb-4'>
                                                <LaodingSvg className='w-8 h-8 animate-spin' />
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
