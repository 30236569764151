import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CKToolTip from 'ck_commun/src/app/modUtils/components/CKToolTip/CKToolTip';
import { ReactComponent as DeleteIcon } from 'ck_commun/src/app/modEntity/assets/images/trash-can.svg';
import ConfirmationModal from 'ck_commun/src/app/modUtils/components/confirmationModal/ConfirmationModal';
import Button from './Button';

export default function ButtonDelete({ onDelete, confirmationMessage }) {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const confirmMessage =
        confirmationMessage || t('buttonDelete.confirmDelete');

    // click sur l'icône de suppression
    const handleOnClick = () => {
        setIsModalVisible(true);
    };

    // confirmer la suppression
    const handleConfirm = () => {
        onDelete();
        setIsModalVisible(false);
    };

    return (
        <>
            <CKToolTip
                trigger='hover'
                content={t('buttonDelete.clickToDelete')}
                placement='top'
            >
                <Button
                    onClick={handleOnClick}
                    className='!p-0 !bg-transparent !border-none !outline-none cursor-pointer'
                >
                    <DeleteIcon className='w-auto h-[18px]' />
                </Button>
            </CKToolTip>

            <ConfirmationModal
                onOk={handleConfirm}
                onCancel={() => setIsModalVisible(false)}
                visible={isModalVisible}
                content={confirmMessage}
            />
        </>
    );
}
