// Import des dépendances nécessaires
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Form, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useFormResponse from 'ck_commun/src/app/modUtils/hooks/useFormResponse';
import CkForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/CkForm';
// Import des composants de formulaire
import moment from 'moment';
import { prepareFormValues } from 'ck_commun/src/app/modUtils/functions/values/formUtils';
import LabelForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/LabelForm';
import TextAreaForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/TextAreaForm';
import InputDateForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/InputDateForm';
import InputEmailForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/InputEmailForm';
import InputAmountForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/InputAmountForm';
// Import des actions Redux
import { support_pre_payment_create } from 'ck_commun/src/app/modSupportPrepayment/slices/supportPrepaymentSlices';

/**
 * 🛠️ Formulaire de remboursement pour l'équipement
 * Utilisé dans l'onglet "REMBOURSEMENT" de la modal "DÉPANNAGE"
 *
 * 📌 Accès rapide :
 * 🔗 URL : https://dev.v3.ck-wash.fr/manager/equipment/sites-equipments
 *
 * 🔍 Comment y accéder ?
 * 1. Ouvrir la modal "DÉPANNAGE".
 * 2. Aller dans l'onglet "REMBOURSEMENT".
 *
 * 📝 Rôle :
 * Ce formulaire permet de gérer les remboursements liés aux équipements.
 */

export default function FormRepay({ equipment }) {
    // Déclaration des constantes de base
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { formResponse, loading, status } = useFormResponse();
    // Déclaration des constantes relatives au formulaire
    const currentDate = useMemo(() => moment(), []);
    const { groupId } = useSelector((state) => state.manager);
    const oneMonthLaterDate = useMemo(() => moment().add(1, 'month'), []);

    const [email, setEmail] = useState();
    const [messageSuccess, setMessageSuccess] = useState();

    // create
    const create = useCallback(
        async (values) => {
            await formResponse(() =>
                dispatch(
                    support_pre_payment_create({
                        groupId,
                        incidentEquipment: equipment?.id,
                        ...values,
                    }),
                ).unwrap(),
            );
        },
        [dispatch, formResponse, groupId, equipment?.id],
    );

    // 🚀 Soumission du formulaire
    const onFinish = (values) => {
        // Préparer les valeurs avant de les envoyer à la fonction create
        const preparedValues = prepareFormValues(values);

        // on stock l'email pour le message de success
        setEmail(values.email);

        // Appel de la fonction create avec les valeurs préparées
        create(preparedValues);
    };

    useEffect(() => {
        if (status === 'success') {
            setMessageSuccess(`${t('formRepay.refundSentTo')}${email}`);
        }
    }, [status]);

    return (
        <CkForm
            form={form}
            status={status}
            loading={loading}
            onFinish={onFinish}
            messageSuccess={messageSuccess}
            className='px-[5%] overflow-auto h-full'
            initialValues={{
                incidentDate: currentDate,
                dateExpiration: oneMonthLaterDate,
            }}
        >
            <InputEmailForm
                messages={{
                    addHelp: t('formRepay.addHelp'),
                }}
            />

            {/* TODO Activer ces options lorsque le backend est prêt */}
            <div className='flex flex-col mt-2'>
                <LabelForm
                    messages={{
                        label: 'formRepay.typeOfReimbursement',
                        help: t('helpForm.disabled'),
                    }}
                />
                <Radio checked disabled>
                    {t('formRepay.QRcode')}
                </Radio>
            </div>

            {/* TODO Activer ces options lorsque le backend est prêt */}
            <div className='flex flex-col mt-2'>
                <LabelForm
                    messages={{
                        label: 'formRepay.typeOfShipment',
                        help: t('helpForm.disabled'),
                    }}
                />
                <Radio checked disabled>
                    {t('formRepay.email')}
                </Radio>
            </div>

            <TextAreaForm
                name='incidentReason'
                className='mt-2'
                messages={{ label: 'formRepay.reason' }}
            />

            <InputAmountForm
                className='mt-2'
                messages={{ label: 'formRepay.amountToCredit' }}
            />

            <InputDateForm
                form={form}
                name='incidentDate'
                className='mt-2'
                Datedefault={currentDate}
                messages={{
                    label: 'formRepay.incidentDate',
                    required: t('formRepay.incidentDateIsRequired'),
                }}
            />

            <InputDateForm
                form={form}
                name='dateExpiration'
                futureOnly={true}
                className='mt-2'
                Datedefault={oneMonthLaterDate}
                messages={{
                    label: 'formRepay.expirationDate',
                    required: t('formRepay.expirationDateIsRequired'),
                }}
            />
        </CkForm>
    );
}
