import React from 'react';
import { useTranslation } from 'react-i18next';
import { dateFormat } from '../../modUtils/functions/values/dateFormat';
import { Input } from '../../modUtils/components/componentsLibrary/componentsLibrary';
import { amountFormatWithCurrencySymbol } from '../../modUtils/functions/values/numberFormat';

export default function PaiementCBInfos({ payment }) {
    const { t } = useTranslation();

    const dataPayment = [
        {
            label: 'paiementCBInfos.date',
            value: dateFormat(payment?.date_creation),
        },
        {
            label: 'paiementCBInfos.amount',
            value: amountFormatWithCurrencySymbol(payment?.amount),
        },
        {
            label: 'paiementCBInfos.authorisation',
            value: payment?.autorisation,
        },
        {
            label: 'paiementCBInfos.discountNumber',
            value: payment?.remise_number,
        },
        {
            label: 'paiementCBInfos.transactionNumber',
            value: payment?.transaction_number,
        },
        { label: 'paiementCBInfos.siret', value: payment?.siret },
        {
            label: 'paiementCBInfos.creditCardNumber',
            value: payment?.credit_card_number,
        },
        { label: 'paiementCBInfos.taught', value: payment?.enseigne },
        {
            label: 'paiementCBInfos.logicalNumber',
            value: payment?.logical_number,
        },
        { label: 'paiementCBInfos.overrideCode', value: payment?.force_code },
        { label: 'paiementCBInfos.readingMode', value: payment?.reading_mod },
        {
            label: 'paiementCBInfos.applicationId',
            value: payment?.application_id,
        },
        {
            label: 'paiementCBInfos.applicationLabel',
            value: payment?.application_label,
        },
    ];

    return (
        <div className='grid grid-cols-1 lg:grid-cols-4 gap-4 px-9'>
            {dataPayment.map((item, index) => (
                <div key={index} className='col-span-1'>
                    <span>{t(item.label)}</span>
                    <Input
                        className='w-full'
                        size={30}
                        disabled
                        value={item.value}
                    />
                </div>
            ))}
        </div>
    );
}
