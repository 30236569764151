import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
    Card,
    Modal,
    Spinner,
} from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import { ReactComponent as CheckIconSvg } from 'ck_commun/src/app/modUtils/assets/images/checkIcon.svg';
import { entity_profil_through_by_group_paginated_get } from 'ck_commun/src/app/modSubscription/slices/subscriptionSlices';
import SubscriptionHistorysDetailModal from 'ck_commun/src/app/modSubscription/components/SubscriptionHistorysDetailModal';

export default function SubscriptionsHistorysListModal({ entity }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [profilThrough, setProfilThrough] = useState(false);
    const { groupId } = useSelector((state) => state.manager);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { entityProfilThrough } = useSelector((state) => state.subscription);
    const [
        isModalVisibleHistoricalProfile,
        setIsModalVisibleHistoricalProfile,
    ] = useState(false);

    const handleClick = (values) => {
        setProfilThrough(values);
        setIsModalVisibleHistoricalProfile(true);
    };

    // récupérer la liste des abonement de l'entitée
    const getEntityProfilThroughByGroupPaginated = async () => {
        setLoading(true);
        await dispatch(
            entity_profil_through_by_group_paginated_get({
                groupId,
                entityId: entity.id,
            }),
        ).unwrap();
        setLoading(false);
    };

    useEffect(() => {
        isModalVisible && entity && getEntityProfilThroughByGroupPaginated();
    }, [entity, groupId, isModalVisible]);

    return (
        <>
            <div onClick={() => setIsModalVisible(true)}>
                <Card
                    height='100%'
                    className='ml-2 min-h-[110px] flex flex-col items-center justify-center relative cursor-pointer'
                >
                    <div className='text-neutral-color text-sm font-semibold text-center w-64'>
                        {t('subscriptionsHistorysListModal.profileHistory')}
                    </div>
                </Card>
            </div>

            <Modal
                title={t('subscriptionsHistorysListModal.profileHistory')}
                isOpen={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                width={800}
                height={800}
                zIndex={999}
            >
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <p className='text-center'>
                            {t(
                                'subscriptionsHistorysListModal.pleaseSelectProfile',
                            )}
                        </p>

                        {entityProfilThrough.map(
                            (entityProfilThrough, index) => (
                                <div
                                    onClick={() =>
                                        handleClick(entityProfilThrough)
                                    }
                                >
                                    <Card
                                        key={entityProfilThrough.profil?.id}
                                        className={`
                                            rounded-md my-1.5 cursor-pointer
                                            border-blue-500 border-2
                                            w-1/2 mx-auto min-h-[52px] flex items-center
                                        `}
                                    >
                                        <div className='flex flex-row justify-start items-baseline font-semibold text-sm'>
                                            <div className='flex flex-row items-center'>
                                                <div className='w-8 mr-2'>
                                                    <img
                                                        src={
                                                            entityProfilThrough
                                                                .profil
                                                                ?.service_type_image
                                                        }
                                                        alt={
                                                            entityProfilThrough
                                                                .profil
                                                                ?.service_type_label
                                                        }
                                                    />
                                                </div>

                                                <div className='truncate max-w-[120px] min-w-[120px]'>
                                                    {
                                                        entityProfilThrough
                                                            .profil?.name
                                                    }
                                                </div>
                                            </div>

                                            <p className='truncate text-[10px] text-gray-400 m-0'>
                                                {t(
                                                    'subscriptionsHistorysListModal.startDate',
                                                )}{' '}
                                                {new Date(
                                                    entityProfilThrough.created_at *
                                                        1000,
                                                ).toLocaleDateString('fr-FR')}
                                            </p>

                                            {entityProfilThrough.is_active && (
                                                <div className='ml-4 text-right relative top-[7px]'>
                                                    <CheckIconSvg
                                                        width='25'
                                                        height='25'
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </Card>
                                </div>
                            ),
                        )}
                        {profilThrough && (
                            <SubscriptionHistorysDetailModal
                                entity={entity}
                                entityProfilThrough={profilThrough}
                                isModalVisible={isModalVisibleHistoricalProfile}
                                setIsModalVisible={
                                    setIsModalVisibleHistoricalProfile
                                }
                                setProfilThrough={setProfilThrough}
                            />
                        )}
                    </>
                )}
            </Modal>
        </>
    );
}
