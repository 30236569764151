import React, { useState } from 'react';
import CheckboxCRM from './CheckboxCRM';
import PeriodConstraints from './PeriodConstraints';
import { useDispatch, useSelector } from 'react-redux';
import ScheduleConstraints from './ScheduleConstraints';
import { update_configuration_detail_by_profil } from '../slices/configurationSlice';

export default function ScheduleAndPeriodConstraints({
    configuration,
    selectedProfilId,
    selectedConfigId,
}) {
    const dispatch = useDispatch();
    const { groupId } = useSelector((state) => state.manager);

    // États pour le chargement et les verrous pour éviter les appels multiples
    const [loadingPeriod, setLoadingPeriod] = useState(false);
    const [loadingSchedule, setLoadingSchedule] = useState(false);
    const [isSubmittingPeriod, setIsSubmittingPeriod] = useState(false);
    const [isSubmittingSchedule, setIsSubmittingSchedule] = useState(false);

    // Valeurs contrôlées directement à partir de `configuration`
    const isConstraintPeriodActive = configuration?.constraintperiod_is_active;
    const isConstraintScheduleActive =
        configuration?.constraintschedule_is_active;

    // Gestion des changements pour les contraintes périodiques
    const handleOnChangePeriod = async () => {
        if (isSubmittingPeriod) return; // Évite les appels multiples
        setIsSubmittingPeriod(true);
        setLoadingPeriod(true);

        try {
            await dispatch(
                update_configuration_detail_by_profil({
                    groupId,
                    profilId: selectedProfilId,
                    configurationId: selectedConfigId,
                    constraintsTimeIsActive: !isConstraintPeriodActive,
                }),
            ).unwrap();
        } catch (error) {
            console.error('Error updating period constraint:', error);
        } finally {
            setIsSubmittingPeriod(false);
            setLoadingPeriod(false);
        }
    };

    // Gestion des changements pour les contraintes horaires
    const handleOnChangeSchedule = async () => {
        if (isSubmittingSchedule) return; // Évite les appels multiples
        setIsSubmittingSchedule(true);
        setLoadingSchedule(true);

        try {
            await dispatch(
                update_configuration_detail_by_profil({
                    groupId,
                    profilId: selectedProfilId,
                    configurationId: selectedConfigId,
                    constraintscheduleIsActive: !isConstraintScheduleActive,
                }),
            ).unwrap();
        } catch (error) {
            console.error('Error updating schedule constraint:', error);
        } finally {
            setIsSubmittingSchedule(false);
            setLoadingSchedule(false);
        }
    };

    return (
        <div className='flex flex-row justify-between'>
            <div className='w-full mr-2'>
                {configuration?.is_future && (
                    <CheckboxCRM
                        checked={isConstraintScheduleActive}
                        onChange={handleOnChangeSchedule}
                        label='scheduleAndPeriodConstraints.enableTimeConstraints'
                        disabled={loadingSchedule} // Désactive pendant le chargement
                    />
                )}

                {isConstraintScheduleActive && (
                    <ScheduleConstraints
                        selectedConfigId={selectedConfigId}
                        configuration={configuration}
                        isConstraintActive={isConstraintScheduleActive}
                    />
                )}
            </div>

            <div className='w-full ml-2'>
                {configuration?.is_future && (
                    <CheckboxCRM
                        checked={isConstraintPeriodActive}
                        onChange={handleOnChangePeriod}
                        label='scheduleAndPeriodConstraints.activateDailyConstraints'
                        disabled={loadingPeriod} // Désactive pendant le chargement
                    />
                )}

                {isConstraintPeriodActive && (
                    <PeriodConstraints
                        selectedConfigId={selectedConfigId}
                        configuration={configuration}
                        isConstraintActive={isConstraintPeriodActive}
                    />
                )}
            </div>
        </div>
    );
}
