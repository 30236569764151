import React, { useState } from 'react';
import { ReactComponent as ToggleDropdown } from 'ck_commun/src/app/modManager/assets/images/toggleDropDown.svg';
import { useTranslation } from 'react-i18next';
import { FaUser } from 'react-icons/fa';

const EmployeeCard = ({
    firstName,
    lastName,
    email,
    image,
    isAdmin,
    onEditPermissionClick,
}) => {
    const { t } = useTranslation();
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropDownOpen(!isDropDownOpen);
    };

    return (
        <div className='w-full min-w-[300px] max-w-[300px] bg-white border border-gray-200 py-3 rounded-lg shadow h-full relative overflow-hidden '>
            <div className='flex justify-end px-4 pt-4'>
                {' '}
                <div className='relative'>
                    <button
                        id='dropdownButton'
                        onClick={toggleDropdown}
                        className='inline-block items-center p-2 bg-white border-none hover:bg-gray-200    rounded-lg text-sm p-1.5'
                        type='button'
                    >
                        <ToggleDropdown />
                    </button>
                    <div
                        id='dropdown'
                        className={`${
                            isDropDownOpen ? 'block' : 'hidden'
                        } z-50 absolute right-4 top-full mt-2 w-auto min-w-max text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow `}
                    >
                        <ul className='py-2'>
                            <li>
                                <a
                                    onClick={onEditPermissionClick}
                                    className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                                >
                                    {t('employeeCard.managePermission')}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className='flex flex-col items-center  '>
                {image && image ? (
                    <img
                        className='w-24 h-24 mb-3 rounded-full shadow-lg sm:w-16 sm:h-16 md:w-16 md:h-16 lg:w-16 lg:h-16'
                        src={image}
                        alt='Bonnie image'
                    />
                ) : (
                    <FaUser className='w-24 h-24 mb-3 rounded-full shadow-lg sm:w-20 sm:h-20 md:w-16 md:h-16 lg:w-16 lg:h-16' />
                )}

                <h5 className='mb-1 text-base break-all text-center font-medium text-gray-900 '>
                    {`${firstName} ${lastName}`}
                </h5>
                <span className='text-sm max-w-xs px-4 break-all text-center text-gray-500 '>
                    {email}
                </span>
                <div className='flex flex-row items-center mt-4 '>
                    {' '}
                    <span
                        className={`w-3 h-3  rounded-full ${
                            isAdmin ? 'bg-primary-color' : 'bg-yellow-500'
                        }`}
                    ></span>
                    <p className='ml-2 mt-4 text-sm text-gray-700 dark:text-gray-300'>
                        {isAdmin ? 'Admin' : 'User'}
                    </p>
                </div>
            </div>
        </div>
    );
};
export default EmployeeCard;
