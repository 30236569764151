/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/device/e_feature_id.py
Aucune modification manuelle acceptée
*/

// Type de device
// Permet de savoir de quel périphérique il s'agit
const eFeatureId = Object.freeze({
  // Inconnu
  0: 'eFeatureId.unknown',

  // Lecteur de billets
  1: 'eFeatureId.billValidator',

  // Accepteur de pièces
  2: 'eFeatureId.coinValidator',

  // Monnayeur rendeur
  8: 'eFeatureId.coinChanger',

  // Pré-caisse
  10: 'eFeatureId.preCashRegister',

  // Paiement sans-contact
  11: 'eFeatureId.cashless',

  // Terminal bancaire
  13: 'eFeatureId.bankingTerminal',

  // Télécollecte
  15: 'eFeatureId.remoteCollection',

  // Lecteur de badges
  21: 'eFeatureId.readerCashless',

  // Lecteur INSIDE
  23: 'eFeatureId.readerInside',

  // Lecteur mifare
  24: 'eFeatureId.readerMifare',

  // Badge rechargement
  25: 'eFeatureId.badgeReload',

  // Avaleur de badges
  26: 'eFeatureId.badgeSwallower',

  // Hopper
  31: 'eFeatureId.hopper',

  // Pusher
  32: 'eFeatureId.pusher',

  // Distributeur de produits
  37: 'eFeatureId.productDistributor',

  // Imprimante client
  41: 'eFeatureId.printerCustomer',

  // Vocal
  46: 'eFeatureId.vocal',

  // Lecteur de code-barres
  51: 'eFeatureId.barcodeReader',

  // Application mobile
  56: 'eFeatureId.mobileAppli',

  // Compteur déporté local
  61: 'eFeatureId.deportedCounterLocal',

  // Compteur déporté serveur
  62: 'eFeatureId.deportedCounterServer',

  // Historique serveur
  63: 'eFeatureId.serverHistoric',

  // Historique serveur
  64: 'eFeatureId.historicCkwash',

  // Bannière led
  66: 'eFeatureId.ledBanner',

  // Journal lumineux
  67: 'eFeatureId.ledBoard',

  // Dimmer
  68: 'eFeatureId.dimmer',

  // Système mail
  71: 'eFeatureId.mailSystem',

  // Système sms
  72: 'eFeatureId.smsSystem',

  // Serveur code
  73: 'eFeatureId.webServer',

  // Web serveur protégé
  74: 'eFeatureId.protectedWebServer',

  // Ethernet actif
  75: 'eFeatureId.ethernetActif',

  // Système d'alerte
  76: 'eFeatureId.alertSystem',

  // Ticket distant par mail
  77: 'eFeatureId.ticketMail',

  // Ticket distant par sms
  78: 'eFeatureId.ticketSms',

  // Configuration console
  81: 'eFeatureId.configurationConsole',

  // Protected menu
  82: 'eFeatureId.protectedMenu',

  // Discount
  86: 'eFeatureId.discount',

  // Alarme
  91: 'eFeatureId.alarm',

  // Button
  92: 'eFeatureId.button',

  // Clavier Matriciel
  93: 'eFeatureId.matrixKeyboard',

  // Interphone
  94: 'eFeatureId.intercom',

  // Selecteur
  95: 'eFeatureId.selector',

  // Programme led
  96: 'eFeatureId.ledProgram',

  // Mode esclave
  97: 'eFeatureId.slaveMode',

  // Animation led
  98: 'eFeatureId.ledAnimation',

  // Date heure
  99: 'eFeatureId.dateTime',

  // Machines
  100: 'eFeatureId.machines',

  // Retour control
  101: 'eFeatureId.inputControl',

  // Affichage
  121: 'eFeatureId.display',

  // Système général
  131: 'eFeatureId.systemGeneral',

  // Système Ckspros
  132: 'eFeatureId.systemCkspros',

  // Système code
  141: 'eFeatureId.codeSystem',

  // Serveur code
  142: 'eFeatureId.serverCode',

  // Détection de mouvement
  151: 'eFeatureId.movmentDetection',

  // Détecteur infrarouge
  152: 'eFeatureId.detectorIr',

  // Capteur
  153: 'eFeatureId.sensor',

  // Extension Ios
  161: 'eFeatureId.iosExtension',

  // Avaleur de badge
  171: 'eFeatureId.cardCapture',

  // Distributeur de cartes
  172: 'eFeatureId.cardDistributor',

  // Point de service
  181: 'eFeatureId.servicesPoint',

  // Lora
  191: 'eFeatureId.lora',

  // Modem
  192: 'eFeatureId.modem',

  // Wifi
  193: 'eFeatureId.wifi',

  // Périphérique domotique
  197: 'eFeatureId.domotics',

  // Sécheresse
  198: 'eFeatureId.categoryDrought',

  // Personnalisé
  199: 'eFeatureId.categoryCustom',

  // Gestionnaire de casier
  202: 'eFeatureId.categoryLockerManager',

  // Pac195x
  205: 'eFeatureId.categoryPac195X',

  // Personnalisé
  220: 'eFeatureId.custom',
});

export default eFeatureId;
