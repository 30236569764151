import React from 'react';
import { useTranslation } from 'react-i18next';
import MarkerRequiredForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/MarkerRequiredForm';

export default function LegendForm() {
    const { t } = useTranslation();

    return (
        <div className='flex items-center space-x-1'>
            <MarkerRequiredForm markerRequired={true} />
            <span className='text-xs'>{t('legendForm.requiredField')}</span>
        </div>
    );
}
