import React from 'react';
import { lightenHexColor } from '../functions/theme/lightenHexColor';

export default function AlertMessage({
    className,
    status,
    alertMessage,
    errorDetail,
}) {
    // Vérifie si errorDetail est un objet
    const errorMessages =
        typeof errorDetail === 'object' && errorDetail !== null
            ? Object.entries(errorDetail)
            : [];

    const accentColor = getComputedStyle(document.documentElement)
        .getPropertyValue('--accent-color')
        .trim();
    const destructiveColor = getComputedStyle(document.documentElement)
        .getPropertyValue('--destructive-color')
        .trim();
    const lighterAccentColor = lightenHexColor(accentColor, 90);
    const lighterDestructiveColor = lightenHexColor(destructiveColor, 90);
    let backgroundColor;
    if (status === 'success') {
        backgroundColor = lighterAccentColor;
    } else {
        backgroundColor = lighterDestructiveColor;
    }

    const colorVariants = {
        success: 'border-accent-color text-accent-color',
        error: 'border-destructive-color text-destructive-color',
    };

    return (
        <div className={`bg-background-color ${className}`}>
            <div
                className={`p-4 border rounded border-solid ${
                    status === 'success'
                        ? colorVariants['success']
                        : colorVariants['error']
                }`}
                style={{ backgroundColor }}
            >
                {status && status !== 'success' && status !== 'error' && (
                    <div className='text-lg font-semibold'>{`Erreur ${status}`}</div>
                )}

                <div className='font-bold'>{alertMessage}</div>

                {errorMessages.length > 0 ? (
                    errorMessages.map(([field, messages]) => (
                        <div key={field} className='text-sm'>
                            <strong>{field}:</strong>{' '}
                            {Array.isArray(messages)
                                ? messages.join(', ')
                                : messages}
                        </div>
                    ))
                ) : (
                    <div className='text-sm'>{errorDetail}</div>
                )}
            </div>
        </div>
    );
}
