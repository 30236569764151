import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import MarkerRequiredForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/MarkerRequiredForm';
import HelpForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/HelpForm';

export default function LabelForm({ messages = {}, markerRequired = false }) {
    const { t } = useTranslation();

    // Valeurs par défaut pour éviter les erreurs d'accès
    const { label = '' } = messages;

    return (
        <div className='flex items-center space-x-1'>
            <MarkerRequiredForm
                className='font-black'
                content={t('labelForm.markerRequired')}
                markerRequired={markerRequired}
            />

            {label && <Label label={label} className='text-black' />}

            <HelpForm messages={messages} markerRequired={markerRequired} />
        </div>
    );
}
