import React, { FC } from 'react';
import { ReactComponent as LogoGooglePlay } from '../assets/images/logoGooglePlay.svg';
import { ReactComponent as LogoAppleStore } from '../assets/images/logoAppleStore.svg';
import { useTranslation } from 'react-i18next';

interface MobilePageProps {
    appleStoreLink: string;
    googleStoreLink: string;
    logo: string;
}

const MobilePage: FC<MobilePageProps> = ({
    appleStoreLink,
    googleStoreLink,
    logo,
}) => {
    const { t } = useTranslation();

    return (
        <section className='bg-white p-4'>
            <div className='container flex flex-col items-center px-4 mx-auto xl:flex-row'>
                <img src={logo} className='w-64 h-64' alt='logo' />

                <div className='flex flex-col items-center mt-6'>
                    <h2 className='text-xl font-bold tracking-tight text-primary-color-800 text-center'>
                        {t<string>('mobilePage.downloadApplication')}
                    </h2>

                    <div className='mt-6 -mx-2'>
                        <div className='inline-flex w-full overflow-hidden rounded-lg shadow w-auto'>
                            <a
                                href={appleStoreLink}
                                className={`inline-flex items-center justify-center w-full py-3 text-xs font-bold text-white
                                    bg-gradient-to-r from-red-400 to-red-600 hover:text-neutral-300 
                                    transition-colors duration-150 transform
                                `}
                            >
                                <LogoAppleStore />

                                <span className='mx-2'>
                                    {t<string>('mobilePage.downloadAppleStore')}
                                </span>
                            </a>
                        </div>

                        <div className='inline-flex w-full mt-4 overflow-hidden rounded-lg shadow w-auto'>
                            <a
                                href={googleStoreLink}
                                className={`inline-flex items-center justify-center w-full py-3 text-xs font-bold text-primary-foreground-color
                                bg-gradient-to-r from-secondary-color to-primary-color hover:text-muted 
                                transition-colors duration-150 transform
                                `}
                            >
                                <LogoGooglePlay />

                                <span className='mx-2'>
                                    {t<string>('mobilePage.downloadGooglePlay')}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MobilePage;
