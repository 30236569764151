import React from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import InputBaseForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/InputBaseForm';

export default function TextAreaForm({
    name,
    messages = {},
    className = '',
    markerRequired = false,
}) {
    const { t } = useTranslation();

    const mergedMessages = {
        label: 'textAreaForm.label',
        required: 'textAreaForm.required',
        ...messages,
    };

    return (
        <InputBaseForm
            name={name}
            messages={mergedMessages}
            className={className}
            markerRequired={markerRequired}
            rules={[
                {
                    required: markerRequired,
                    message: t(mergedMessages.required),
                },
            ]}
        >
            <Input.TextArea />
        </InputBaseForm>
    );
}
