import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonDelete from 'ck_commun/src/app/modUtils/components/buttons/ButtonDelete';
import { Label } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import LoadingSpinner from 'ck_commun/src/app/modUtils/components/componentsLibrary/spinner/LoadingSpinner';
import RefreshButton from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/RefreshButton';

export default function FormHeader({
    label,
    filter,
    loading,
    isInModal,
    messages,
    onDelete,
    onRefresh,
}) {
    const { t } = useTranslation();

    return (
        <>
            {label && (
                <Label
                    label={label}
                    className='flex justify-center !text-[24px]'
                />
            )}

            {filter && (
                <p className='font-bold m-0 text-black'>
                    {t('sharedEquipmentFormFilter.filters')}
                </p>
            )}

            <div
                className={`mb-2 flex justify-end gap-2 ${isInModal ? 'absolute top-[0.8rem] right-[2.5rem]' : ''}
                ${filter ? 'absolute top-[0.8rem]' : ''}`}
            >
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        {onDelete && (
                            <ButtonDelete
                                onDelete={onDelete}
                                confirmationMessage={messages.confirmDelete}
                            />
                        )}

                        <RefreshButton onRefresh={onRefresh} />
                    </>
                )}
            </div>
        </>
    );
}
