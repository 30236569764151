import React from 'react';
import { logout } from '../../modAuthentication/functions/logout';
import { SRC_IMG } from '../../../../../../utils/constants';

export const menuData = [
    {
        icon: <img src={SRC_IMG + 'Accueil.svg'} alt='' />,
        itemKey: 'home',
        label: 'menu.transHome',
        prodVisible: true,
    },
    {
        icon: <img src={SRC_IMG + 'Equipement.svg'} alt='' />,
        itemKey: 'equipment',
        label: 'menu.transEquipment',
        submenus: [
            {
                itemKey: 'equipment/sites-equipments',
                label: 'menu.transSitesAndEquipment',
                prodVisible: true,
            },
            {
                itemKey: 'equipment/sites-map',
                label: 'menu.transSitesMap',
                prodVisible: true,
            },
            {
                itemKey: 'equipment/equipments-shared',
                label: 'menu.transEquipmentsShared',
                permissionName: 'mod_equipment_shared:can_access',
                prodVisible: true,
            },
        ],
        prodVisible: true,
    },
    {
        icon: <img src={SRC_IMG + 'Casiers.svg'} alt='' />,
        itemKey: 'locker',
        label: 'menu.transCasier',
        prodVisible: false,
        permissionName: 'mod_box:manage_box',
        hiddenWithoutPermissions: true, // Ajout de la propriété
        submenus: [
            {
                itemKey: 'locker/orders',
                label: 'menu.transOrders',
                permissionName: 'mod_box:manage_box',
                prodVisible: false,
            },
            {
                itemKey: 'locker/products',
                label: 'menu.transProduct',
                permissionName: 'mod_box:manage_box',
                prodVisible: false,
            },
            {
                itemKey: 'locker/suppliers',
                label: 'menu.transSuppliers',
                permissionName: 'mod_box:manage_box',
                prodVisible: false,
            },
        ],
    },
    {
        icon: <img src={SRC_IMG + 'Activite.svg'} alt='' />,
        itemKey: 'activity',
        label: 'menu.transActivities',
        permissionName: 'mod_cumulative:show_statistic',
        prodVisible: true,
        submenus: [
            {
                itemKey: 'activity/dashboard',
                label: 'menu.transActDashboard',
                permissionName: 'mod_cumulative:show_statistic',
                prodVisible: true,
            },
            {
                itemKey: 'activity/ca',
                label: 'menu.transActCA',
                permissionName: 'mod_cumulative:show_statistic',
                prodVisible: true,
            },
            {
                itemKey: 'activity/statistics',
                label: 'menu.transActStatistics',
                permissionName: 'mod_cumulative:show_statistic',
                prodVisible: false,
            },
            {
                itemKey: 'activity/marketing',
                label: 'menu.transActMarketing',
                permissionName: 'mod_cumulative:show_statistic',
                prodVisible: false,
            },
            {
                itemKey: 'activity/reporting',
                label: 'menu.transActReportings',
                permissionName: 'mod_cumulative:show_statistic',
                prodVisible: false,
            },
        ],
    },
    {
        icon: <img src={SRC_IMG + 'Historique.svg'} alt='' />,
        itemKey: 'history',
        label: 'menu.transHistory',
        permissionName: 'mod_sale:show_sale',
        submenus: [
            {
                itemKey: 'history/sales',
                label: 'menu.transHistSales',
                permissionName: 'mod_sale:show_sale',
                prodVisible: true,
            },
            {
                itemKey: 'history/telecollects',
                label: 'menu.transHistTelecollects',
                permissionName: 'mod_sale:show_sale',
                prodVisible: true,
            },
            {
                itemKey: 'history/pickups',
                label: 'menu.transHistStatements',
                permissionName: 'mod_statement:show_statement',
                prodVisible: false,
            },
            {
                itemKey: 'history/logs',
                label: 'menu.transHistLogs',
                permissionName: 'mod_sale:show_sale',
                prodVisible: false,
            },
        ],
        prodVisible: true,
    },
    {
        icon: <img src={SRC_IMG + 'BadgeCle.svg'} alt='' />,
        itemKey: 'badges',
        label: 'menu.transBadgesAndKeys',
        permissionName: 'mod_wallet_local:manage_support',
        submenus: [
            {
                itemKey: 'badges/dashboard',
                label: 'menu.transBadgesHome',
                permissionName: 'mod_wallet_local:manage_support',
                prodVisible: false,
            },
            {
                itemKey: 'badges/subscription',
                label: 'menu.transBadgesSubscription',
                permissionName: 'mod_wallet_local:manage_support',
                prodVisible: true,
            },
            {
                itemKey: 'badges/discounts',
                label: 'menu.transDiscounts',
                permissionName: 'mod_wallet_local:manage_support',
                prodVisible: false,
            },
            {
                itemKey: 'badges/statistics',
                label: 'menu.transBadgesStatistics',
                permissionName: 'mod_wallet_local:manage_support',
                prodVisible: false,
            },
        ],
        prodVisible: true,
    },
    {
        icon: <img src={SRC_IMG + 'Promo.svg'} alt='' />,
        itemKey: 'promo',
        label: 'menu.transPromo',
        permissionName: 'mod_promotion:manage_promotion',
        submenus: [
            {
                itemKey: 'promo/dashboard',
                label: 'menu.transPromoDashboard',
                permissionName: 'mod_promotion:manage_promotion',
                prodVisible: false,
            },
            {
                itemKey: 'promo/promotions',
                label: 'menu.transPromotions',
                permissionName: 'mod_promotion:manage_promotion',
                prodVisible: false,
            },
            {
                itemKey: 'promo/voucher',
                label: 'menu.transVoucher',
                permissionName: 'mod_promotion:manage_promotion',
                prodVisible: false,
            },
        ],
        prodVisible: false,
    },
    {
        icon: <img src={SRC_IMG + 'CRM.svg'} alt='' />,
        itemKey: 'crm',
        label: 'menu.transCRM',
        permissionName: 'mod_entity:manage_entity',
        submenus: [
            {
                itemKey: 'crm/dashboard',
                label: 'menu.transCrmDashboard',
                permissionName: 'mod_entity:manage_entity',
                prodVisible: false,
            },
            {
                itemKey: 'crm/clients',
                label: 'menu.transCrmClients',
                permissionName: 'mod_entity:manage_entity',
                prodVisible: false,
            },
            {
                itemKey: 'crm/profiles',
                label: 'menu.transCrmProfiles',
                permissionName: 'mod_profil:manage_profil',
                prodVisible: false,
            },
            {
                itemKey: 'crm/marketing',
                label: 'menu.transCrmMarketing',
                permissionName: 'mod_entity:manage_entity',
                prodVisible: false,
            },
            {
                itemKey: 'crm/facturation',
                label: 'menu.transCrmFacturation',
                permissionName: 'mod_entity:manage_entity',
                prodVisible: false,
            },
            {
                itemKey: 'crm/factures',
                label: 'menu.transCrmFactures',
                permissionName: 'mod_entity:manage_entity',
                prodVisible: false,
            },
        ],
        prodVisible: false,
    },
    {
        icon: <img src={SRC_IMG + 'Boutique.svg'} alt='' />,
        itemKey: 'store',
        label: 'menu.transStore',
        prodVisible: false,
    },
    {
        icon: <img src={SRC_IMG + 'Parametre.svg'} alt='' />,
        itemKey: 'settings',
        label: 'menu.transSettings',
        submenus: [
            {
                itemKey: 'settings/account',
                label: 'menu.transSettingsAccount',
                prodVisible: true,
            },
            {
                itemKey: 'settings/access-list',
                label: 'menu.transSettingsAccessList',
                permissionName: 'mod_manager:manage_company',
                prodVisible: false,
            },
            {
                itemKey: 'settings/access-group',
                label: 'menu.transSettingsAccessGroup',
                permissionName: 'mod_manager:manage_company',
                prodVisible: false,
            },
            {
                itemKey: 'settings/alerts',
                label: 'menu.transSettingsAlerts',
                permissionName: 'mod_manager:manage_company',
                prodVisible: false,
            },
            {
                itemKey: 'settings/widgets',
                label: 'menu.transSettingsWidgets',
                permissionName: 'mod_manager:manage_company',
                prodVisible: false,
            },
        ],
        prodVisible: true,
    },
    {
        icon: <img src={SRC_IMG + 'Deconnexion.svg'} alt='logout' />,
        itemKey: '/',
        label: 'menu.transLogout',
        onClick: () => logout(),
        prodVisible: true,
    },
];
