export function amountFormat(amount, locale, minDigit, maxDigit) {
    const amountLocale = locale || 'fr-FR';
    const amountMinDigit = minDigit || 2;
    const amountMaxDigit = maxDigit || 2;
    const amountFormatted = amount?.toLocaleString(amountLocale, {
        minimumFractionDigits: amountMinDigit,
        maximumFractionDigits: amountMaxDigit,
    });
    return amountFormatted;
}

export function formatPercentage(value) {
    return `${Math.round(value)}%`;
}

export function amountFormatWithCurrencySymbol(
    amount,
    locale = 'fr-FR',
    minDigit = 2,
    maxDigit = 2,
    currency = 'EUR',
) {
    const groupCurrency = localStorage.getItem('currencySymbol');
    if (isNaN(amount)) {
        return '';
    }
    const amountFormatWithCurrencySymbol = `${amountFormat(amount, locale, minDigit, maxDigit)} ${groupCurrency}`;
    // const amountFormatWithCurrencySymbol = new Intl.NumberFormat(locale, {
    //     minimumFractionDigits: minDigit,
    //     maximumFractionDigits: maxDigit,
    //     style: 'currency',
    //     currency: currency
    // }).format(amount);

    return amountFormatWithCurrencySymbol;
}

export function getCurrencySymbol() {
    const groupCurrency = localStorage.getItem('currencySymbol');
    return groupCurrency || '€'; // Par défaut, on met '€' si rien n'est trouvé
}
