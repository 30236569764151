// Fonction utilitaire pour formater le Markdown
export const renderMarkdown = (markdownText) => {
    if (!markdownText) return null;

    // Remplacement des balises Markdown courantes
    const formattedText = markdownText
        .replace(/\*(.*?)\*/g, '<b>$1</b>') // Gras : **text**
        .replace(/- (.*?)(\n|$)/g, '<li>$1</li>') // Listes non ordonnées : - item
        .replace(/\n/g, '<br/>'); // Sauts de ligne

    // Renvoyer le contenu sous forme de JSX sécurisé
    return (
        <span
            className='text-black'
            dangerouslySetInnerHTML={{ __html: formattedText }}
        />
    );
};
