import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import {
    sharing_by_group_paginated_get,
    permissions_by_group_list_get,
} from '../../../modSharedEquipment/slices/sharedEquipmentSlice';
import {
    Spinner,
    Pagination,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import ButtonDisabled from '../../../modUtils/components/buttons/ButtonDisabled';
import Button from '../../../modUtils/components/buttons/Button';
import 'react-calendar/dist/Calendar.css';
import SharedEquipmentCard from '../sharedEquipmentCard/SharedEquipmentCard';
import SharedEquipmentForm from '../sharedEquipmentForm/SharedEquipmentForm';
import { useTranslation } from 'react-i18next';
import { hasPermission } from '../../../modUtils/functions/permissions/handlePermissions';
import PermissionDeniedPage from '../../../modUtils/components/errorPage/PermissionDeniedPage';
import i18next from 'i18next';

export default function SharedEquipmentManageModal({
    siteId,
    closeModal,
    equipment,
    buttonType,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { groupId } = useSelector((state) => state.manager);
    const [modalVisible, setModalVisible] = useState(false);
    const { sharedEquipmentList, sharedEquipmentListCount } = useSelector(
        (state) => state.sharedEquipment,
    );
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const { selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup.permissions;
    const verifyPermissionModEquipentShared = hasPermission(
        'mod_equipment_shared:can_manage',
        permissions,
    );
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermissionModEquipentShared = hasPermission(
        'mod_equipment_shared:can_manage',
        groupPermissions,
    );

    async function fetchData(limit, offset) {
        setLoading(true);
        await dispatch(
            sharing_by_group_paginated_get({
                limit: limit && limit,
                offset: offset && offset,
                groupId: groupId,
                sites: siteId,
                equipments: equipment?.id,
            }),
        );
        await dispatch(
            permissions_by_group_list_get({
                groupId: groupId,
            }),
        );
        setLoading(false);
    }

    // Fonction de gestion de changement de pagination
    const handlePaginationChange = (page, pageSize) => {
        // Mettez à jour les valeurs de limit et offset en fonction de la page et de la taille de page
        const newOffset = (page - 1) * pageSize;
        setOffset(newOffset);
        if (newOffset < 0) {
            setCurrentPage(1);
        } else {
            setCurrentPage(page);
        }
        setLimit(pageSize);

        // Effectuez une requête à l'API avec les nouvelles valeurs de limit et offset pour obtenir les données correspondantes
        fetchData(pageSize, newOffset);
    };

    useEffect(() => {
        if (groupId || equipment?.id) {
            fetchData();
        }
    }, [groupId, equipment?.id, i18next.language]);

    const openModalSharedEquipment = () => {
        setModalVisible(true);
        fetchData();
        closeModal();
    };
    const closeModalSharedEquipment = () => {
        setModalVisible(false);
    };

    return (
        <>
            {buttonType === 'button' ? (
                !verifyPermissionModEquipentShared ||
                !verifyGroupPermissionModEquipentShared ? (
                    <Button
                        onClick={openModalSharedEquipment}
                        color='primary'
                        className={'h-10 w-full whitespace-nowrap'}
                    >
                        {t('sharedEquipmentManageModal.equipmentShares')}
                    </Button>
                ) : (
                    <ButtonDisabled
                        onClick={null}
                        className={'h-10 w-full whitespace-nowrap'}
                    >
                        {t('sharedEquipmentManageModal.equipmentShares')}
                    </ButtonDisabled>
                )
            ) : (
                <p
                    className={`${
                        !verifyPermissionModEquipentShared ||
                        (!verifyGroupPermissionModEquipentShared &&
                            '!text-muted-foreground-color bg-muted-color cursor-not-allowed')
                    }`}
                    onClick={openModalSharedEquipment}
                >
                    {t('sharedEquipmentManageModal.equipmentShares')}
                </p>
            )}

            {modalVisible && (
                <Modal
                    title={t('sharedEquipmentManageModal.equipmentShares')}
                    isOpen={modalVisible}
                    onClose={closeModalSharedEquipment}
                    width={1100}
                    height={800}
                    zIndex={900}
                >
                    {!verifyPermissionModEquipentShared ? (
                        <PermissionDeniedPage
                            permission={verifyGroupPermissionModEquipentShared}
                        />
                    ) : (
                        <>
                            <SharedEquipmentForm
                                equipment={equipment}
                                fetchData={fetchData}
                                buttonText={t(
                                    'sharedEquipmentManageModal.createShare',
                                )}
                            />

                            {loading ? (
                                <Spinner />
                            ) : (
                                <>
                                    <Pagination
                                        defaultCurrent={currentPage}
                                        defaultPageSize={limit}
                                        showQuickJumper={true}
                                        showTotal={false}
                                        total={sharedEquipmentListCount}
                                        onChange={handlePaginationChange}
                                        onShowSizeChange={
                                            handlePaginationChange
                                        }
                                        hideOnSinglePage={true}
                                    />
                                    <div className='flex flex-wrap'>
                                        {sharedEquipmentList?.map(
                                            (sharedEquipment) => (
                                                <SharedEquipmentCard
                                                    sharedEquipment={
                                                        sharedEquipment
                                                    }
                                                    equipment={equipment}
                                                    fetchData={fetchData}
                                                />
                                            ),
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </Modal>
            )}
        </>
    );
}
