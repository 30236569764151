import { useEffect, useState } from 'react';
import HeaderPromoPage from '../../components/headerPromoPage/HeaderPromoPage';
import PromoCard from '../../components/promoCard/PromoCard';
import ModalTickets from '../../components/modalTickets/ModalTickets';
import ModalGenerateQrCode from '../../components/modalGenerateQrCode/ModalGenerateQrCode';
import ModalCloseTicketsGroup from '../../components/modalCloseTicketsGroup/ModalCloseTicketsGroup';
import ExportModalTickets from '../../components/exportModalTickets/ExportModalTickets';
import ModalTestPromo from '../../components/modalTestPromo/ModalTestPromo';
import ModalSendTicketMail from '../../components/modalSendTicketMail/ModalSendTicketMail';
import ModalCloseTicket from '../../components/modalCloseTicket/ModalCloseTicket';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
    get_promotion_detail,
    get_promotion_supports,
    get_promotions_paginated,
    get_supports,
} from '../../slices/promotionSlice';
import NoData from '../../../modUtils/components/noData/NoData';
import { useTranslation } from 'react-i18next';
import ModalCreatePromotion from '../../components/modalCreatePromotion/ModalCreatePromotion';
import { Spinner } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { dateFormat } from '../../../modUtils/functions/values/dateFormat';
import { get_sites_by_group_paginated } from '../../../modManager/slices/managerSlice';
import { get_types_by_group_paginated } from '../../../modEquipment/slices/equipmentSlice';
import i18next from 'i18next';
import ModalDetailTicket from '../../components/modalDetailTicket/ModalDetailTicket';
import { message, Spin } from 'antd';
import AlertMessage from '../../../modUtils/components/AlertMessage';
import { defaultValues } from '../../assets/js/utils';

const PromotionsPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { groupId } = useSelector((state) => state.manager);
    const { promotionDetail } = useSelector((state) => state.promotion);
    const [promotionsData, setPromotionsData] = useState();
    const [loading, setLoading] = useState(true);
    const [hasMoreDataToLoad, setHasMoreDataToLoad] = useState(false); //pour savoir s'il y'a encore de données à charger
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [promotionType, setPromotionType] = useState(null);
    const [modalCreatePromotionOpen, setModalCreatePromotionOpen] =
        useState(false);
    const [modalTickets, setModalTickets] = useState(false);
    const [modalGenerateQrCode, setModalGenerateQrCode] = useState(false);
    const [modalCloseTicketsGroup, setModalCloseTicketsGroup] = useState(false);
    const [modalExportTicket, setModalExportTicket] = useState(false);
    const [modalTestPromo, setModalTestPromo] = useState(false);
    const [modalSendTicketMail, setModalSendTicketMail] = useState(false);
    const [modalCloseTicket, setModalCloseTicket] = useState(false);
    const [modalDetailTicket, setModalDetailTicket] = useState(false);
    const [promotionId, setPromotionId] = useState(null);
    const [supportId, setSupportId] = useState(null);
    const [mode, setMode] = useState('create');
    const formatDate = 'DD/MM/YYYY HH:mm';
    const [startDate, setStartDate] = useState(
        moment().subtract(6, 'days').startOf('day').format(formatDate),
    );
    const [endDate, setEndDate] = useState(
        moment().endOf('day').format(formatDate),
    );
    const defaultOffset = 0;
    const defaultLimit = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [offsetSupport, setOffsetSupport] = useState(defaultOffset);
    const [expired, setExpired] = useState(false);
    const [limit, setLimit] = useState(defaultLimit); // Nombre d'éléments à charger à chaque fois
    const [offset, setOffset] = useState(defaultOffset); // Offset actuel pour la pagination
    const [selectForm, setSelectForm] = useState({
        promotion: null,
        status: null,
        date_validity_start: startDate,
        date_validity_end: endDate,
        qrcode_number: null,
    });
    const [initialValues, setInitialValues] = useState(defaultValues);

    //appel à l'api get_equipments_gb_sites_by_group_paginated pour récupérer la liste des sites et des equipements groupés par site
    const getPromotions = async () => {
        if (offset === defaultOffset) {
            setPromotionsData([]); // Reset data when starting a new request
        }
        try {
            setLoading(true); // Start loading (this should only apply to the first request)
            if (offset !== defaultOffset) {
                setIsLoadingMore(true); // Show spinner for next set of data
            }
            const response = await dispatch(
                get_promotions_paginated({
                    groupId,
                    limit,
                    offset,
                    promotion_type: promotionType,
                    expired,
                }),
            ).unwrap();
            if (response.results) {
                const newPromotions = response.results;
                setPromotionsData((prevPromotions) => [
                    ...prevPromotions,
                    ...newPromotions,
                ]);
                // Incrémenter le décalage pour la demande suivante
                setOffset((prevOffset) => prevOffset + limit);
                // Vérifier s'il y a d'autres promotions à charger
                const hasMore = response.next !== null; // si « next » est nul, il n'y a plus d'éléments.
                setHasMoreDataToLoad(hasMore);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            setIsLoadingMore(false);
        }
    };

    //code pour le scroll
    useEffect(() => {
        const contentElement = document.querySelector('.layout .content');
        const handleScroll = () => {
            const { scrollTop, clientHeight, scrollHeight } = contentElement;
            // Vérifier si nous avons atteint le bas de la page
            if (
                scrollTop + clientHeight >= scrollHeight - 20 &&
                !loading && // Ne pas charger si les données sont encore en cours de récupération
                hasMoreDataToLoad // Ne charger que s'il y a plus de données
            ) {
                // Charger plus de promotions
                getPromotions();
            }
        };
        contentElement.addEventListener('scroll', handleScroll);
        // Nettoyer l'écouteur d'événements lors du démontage d'un composant
        return () => {
            contentElement.removeEventListener('scroll', handleScroll);
        };
    }, [loading]); //loading ajouté dans les dépendances pour s'assurer que le gestionnaire d'événements est correctement mis à jour

    useEffect(() => {
        setPromotionsData([]);
        setOffset(0);
    }, [groupId, promotionType, i18next.language, expired]);

    useEffect(() => {
        if (offset === 0 && groupId) {
            getPromotions();
        }
    }, [offset, groupId]);

    useEffect(() => {
        if (groupId) {
            dispatch(
                get_sites_by_group_paginated({
                    limit: 100,
                    offset: 0,
                    groupId: groupId,
                }),
            );
            dispatch(get_types_by_group_paginated(groupId));
        }
    }, [groupId, i18next.language]);

    useEffect(() => {
        if (promotionDetail)
            setInitialValues({
                promotionName: promotionDetail.name,
                identifier: promotionDetail.id,
                description: promotionDetail.description,
                typePromo: promotionDetail.promotion_type,
                enableObtainPromo:
                    promotionDetail.criteria_obtaining_is_active || null,
                startDateOfferObtainPromo:
                    promotionDetail.criteria_obtaining?.date_start || null,
                endDateOfferObtainPromo:
                    promotionDetail.criteria_obtaining?.date_end || null,
                maximumPrintingObtainPromo:
                    promotionDetail.criteria_obtaining
                        ?.count_distribution_max || null,
                sitesObtainPromo:
                    promotionDetail.criteria_obtaining?.site_ids || null,
                equipementsTypeObtainPromo:
                    promotionDetail.criteria_obtaining?.equipment_types || null,
                constraintObtainPromo:
                    promotionDetail.criteria_obtaining?.criteria_schedules ||
                    [],
                minObtainPromo:
                    promotionDetail.criteria_obtaining?.amount_min || null,
                maxObtainPromo:
                    promotionDetail.criteria_obtaining?.amount_max || null,
                startDateOfferUsePromo:
                    promotionDetail.criteria_usage?.date_start || null,
                endDateOfferUsePromo:
                    promotionDetail.criteria_usage?.date_end || null,
                discountAmount: promotionDetail.amount_promo_type,
                amountUsePromo: promotionDetail.amount,
                maximumPrintingUsePromo: !promotionDetail.criteria_usage
                    ?.count_usage_max
                    ? 'illimited'
                    : promotionDetail.criteria_usage?.count_usage_max === 1
                      ? '1'
                      : promotionDetail.criteria_usage?.count_usage_max,
                startDayValidityUsePromo:
                    promotionDetail.criteria_usage?.lifetime_start_days,
                endDayValidityUsePromo:
                    promotionDetail.criteria_usage?.lifetime,
                sitesUsePromo: promotionDetail.criteria_usage?.site_ids || null,
                equipementsTypeUsePromo:
                    promotionDetail.criteria_usage?.equipment_types || null,
                constraintUsePromo:
                    promotionDetail.criteria_usage?.criteria_schedules || [],
                minUsePromo: promotionDetail.criteria_usage?.amount_max,
                maxUsePromo: promotionDetail.criteria_usage?.amount_max,
                idsUsePromo:
                    promotionDetail.criteria_usage?.selection_ids?.join(',') ||
                    null,
                amount: promotionDetail.criteria_usage?.selection_ids
                    ? 'selection'
                    : 'amount',
                // ticket_template_sms: promotionDetail.ticket_template_sms,
                // ticket_template_email: promotionDetail.ticket_template_email,
                ticket_template_paper: promotionDetail.ticket_template_paper,
            });
    }, [promotionDetail]);

    const toogleCreatePromotionModal = async (value, mode) => {
        setMode(mode);
        if (value) {
            setPromotionId(value);
            await dispatch(
                get_promotion_detail({ groupId, promotionId: value }),
            );
        }
        setModalCreatePromotionOpen((prevModal) => !prevModal);
    };

    const toggleModalTickets = () => {
        setModalTickets((prevModal) => !prevModal);
    };

    const toggleModalGenerateQrCode = async () => {
        toggleModalTickets();
        setModalGenerateQrCode((prevModal) => !prevModal);
    };

    const toggleModalCloseTicketsGroup = () => {
        toggleModalTickets();
        setModalCloseTicketsGroup((prevModal) => !prevModal);
    };

    const toggleModalExportTicket = () => {
        toggleModalTickets();
        setModalExportTicket((prevModal) => !prevModal);
        toggleModalTickets();
    };

    const toggleModalTestPromo = () => {
        toggleModalTickets();
        toggleModalTickets();
        setModalTestPromo((prevModal) => !prevModal);
    };

    const toggleModalSendTicketMail = (record) => {
        toggleModalTickets();
        setPromotionId(record ? record.promotion?.id : null);
        setSupportId(record?.id);
        setModalSendTicketMail(
            (prevModalSendTicketMail) => !prevModalSendTicketMail,
        );
    };

    const toggleModalCloseTicket = (record) => {
        toggleModalTickets();
        setPromotionId(record ? record.promotion?.id : null);
        setSupportId(record?.id);
        setModalCloseTicket(
            (prevModalSendTicketMail) => !prevModalSendTicketMail,
        );
    };
    const toggleModalDetailTicket = (record) => {
        if (typeof record === 'object' && record !== null) {
            setSupportId(record?.id);
        } else if (typeof record === 'number') {
            setSupportId(record);
        }
        //record returns the id
        setModalDetailTicket((prevModalDetailTicket) => !prevModalDetailTicket);
    };
    const getSupports = async () => {
        try {
            await dispatch(
                selectForm.promotion
                    ? get_promotion_supports({
                          groupId,
                          ...selectForm,
                          date_validity_start: moment(
                              selectForm.date_validity_start,
                              formatDate,
                          ).unix(),
                          date_validity_end: moment(
                              selectForm.date_validity_end,
                              formatDate,
                          ).unix(),
                          limit,
                          offset: offsetSupport,
                      })
                    : get_supports({
                          groupId,
                          ...selectForm,
                          date_validity_start: moment(
                              selectForm.date_validity_start,
                              formatDate,
                          ).unix(),
                          date_validity_end: moment(
                              selectForm.date_validity_end,
                              formatDate,
                          ).unix(),
                          limit,
                          offset: offsetSupport,
                      }),
            );
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={'error'}
                        alertMessage={t('errors.transErrorGetListingSupport')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        }
    };
    useEffect(() => {
        getSupports();
    }, [offsetSupport]);
    return (
        <div className='PromotionsPage px-0 2xl:px-14 py-8'>
            <HeaderPromoPage
                toggleModalTickets={toggleModalTickets}
                toogleCreatePromotionModal={toogleCreatePromotionModal}
                setPromotionType={setPromotionType}
                setSelectForm={setSelectForm}
                promotionType={promotionType}
                setExpired={setExpired}
                expired={expired}
                setOffset={setOffset}
            />
            {loading && promotionsData?.length < limit ? (
                <div className='flex items-center justify-center h-full py-24'>
                    <Spinner />
                </div>
            ) : promotionsData && promotionsData.length ? (
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    {promotionsData.map((promotion) => (
                        <div key={promotion.id} className='h-full w-full'>
                            <PromoCard
                                promo={promotion}
                                toggleModalTickets={toggleModalTickets}
                                toogleCreatePromotionModal={
                                    toogleCreatePromotionModal
                                }
                                setSelectForm={setSelectForm}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <NoData text={t('transNoData')} />
            )}
            {isLoadingMore && promotionsData?.length >= limit && (
                <div className='flex justify-center my-8'>
                    <Spin size='large' />
                </div>
            )}
            {modalTickets && (
                <ModalTickets
                    promotionId={promotionId}
                    modal={modalTickets}
                    toggleModalTickets={toggleModalTickets}
                    modalGenerateQrCode={modalGenerateQrCode}
                    toggleModalGenerateQrCode={toggleModalGenerateQrCode}
                    toggleModalCloseTicketsGroup={toggleModalCloseTicketsGroup}
                    toggleModalExportTicket={toggleModalExportTicket}
                    toggleModalTestPromo={toggleModalTestPromo}
                    toggleModalSendTicketMail={toggleModalSendTicketMail}
                    toggleModalCloseTicket={toggleModalCloseTicket}
                    toggleModalDetailTicket={toggleModalDetailTicket}
                    selectForm={selectForm}
                    setSelectForm={setSelectForm}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    groupId={groupId}
                    limit={defaultLimit}
                    offset={offsetSupport}
                    setOffset={setOffsetSupport}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    getSupports={getSupports}
                />
            )}
            {modalGenerateQrCode && (
                <ModalGenerateQrCode
                    modalGenerateQrCode={modalGenerateQrCode}
                    toggleModalGenerateQrCode={toggleModalGenerateQrCode}
                    selectForm={selectForm}
                    promotionId={selectForm.promotion}
                    groupId={groupId}
                />
            )}
            {modalCloseTicketsGroup && (
                <ModalCloseTicketsGroup
                    toggleModalCloseTicketsGroup={toggleModalCloseTicketsGroup}
                    modalCloseTicketsGroup={modalCloseTicketsGroup}
                    promotionId={selectForm.promotion}
                    groupId={groupId}
                />
            )}
            {modalExportTicket && (
                <ExportModalTickets
                    selectForm={selectForm}
                    modalExportTicket={modalExportTicket}
                    toggleModalExportTicket={toggleModalExportTicket}
                />
            )}
            {modalTestPromo && (
                <ModalTestPromo
                    modalTestPromo={modalTestPromo}
                    toggleModalTestPromo={toggleModalTestPromo}
                />
            )}
            {modalSendTicketMail && (
                <ModalSendTicketMail
                    toggleModalSendTicketMail={toggleModalSendTicketMail}
                    modalSendTicketMail={modalSendTicketMail}
                />
            )}
            {modalCloseTicket && (
                <ModalCloseTicket
                    toggleModalCloseTicket={toggleModalCloseTicket}
                    modalCloseTicket={modalCloseTicket}
                    supportId={supportId}
                    groupId={groupId}
                    promotionId={selectForm.promotion}
                    getSupports={getSupports}
                />
            )}
            {modalDetailTicket && (
                <ModalDetailTicket
                    toggleModalDetailTicket={toggleModalDetailTicket}
                    modalDetailTicket={modalDetailTicket}
                    supportId={supportId}
                    groupId={groupId}
                />
            )}
            {modalCreatePromotionOpen && (
                <ModalCreatePromotion
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                    modalCreatePromotionOpen={modalCreatePromotionOpen}
                    setModalCreatePromotion={setModalCreatePromotionOpen}
                    groupId={groupId}
                    promotionId={promotionId}
                    mode={mode}
                    setMode={setMode}
                    getPromotions={getPromotions}
                    setOffset={setOffset}
                    setPromotionsData={setPromotionsData}
                    setPromotionId={setPromotionId}
                    offset={offset}
                    promotionsData={promotionsData}
                />
            )}
        </div>
    );
};

export default PromotionsPage;
