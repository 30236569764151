import React, { useEffect, useState } from 'react';
import { get_discount_recharge_by_group_list } from '../../slices/walletLocalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DiscountCard from './DiscountCard';
import { Spinner } from '../../../modUtils/components/componentsLibrary/componentsLibrary';

export default function DiscountRecharge({
    onDiscountClick,
    selectCardAmount,
}) {
    const { groupId } = useSelector((state) => state.manager);
    const [loading, setLoading] = useState(false);
    const [dataLoaded, setDataloaded] = useState(false);
    const dispatch = useDispatch();
    const { discounts } = useSelector((state) => state.walletLocal);
    const { t } = useTranslation();

    const getDiscounts = async (offset, limit) => {
        try {
            setLoading(true);
            await dispatch(
                get_discount_recharge_by_group_list({
                    groupId,
                    limit,
                    offset,
                }),
            ).unwrap();
            setLoading(false);
            setDataloaded(true);
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error.status}
                        alertMessage={t('discounts.errorMessage')}
                    />
                ),
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        if (groupId) getDiscounts(0, 10);
    }, [groupId]);

    const calculateCredit = (amount, bonus) => {
        return amount + amount * (bonus / 100);
    };

    return (
        <div>
            <div className='text-lg text-left font-semibold pt-4'>
                {t('discounts.palierTitle')}
            </div>
            {loading && <Spinner />}
            <div className='grid gap-6 grid-cols-6 overflow-y-auto max-h-96 min-h-96 pr-4 my-6 '>
                {dataLoaded &&
                    discounts.length > 0 &&
                    discounts.map((data) => {
                        const amount = Math.floor(data.amount_min);
                        const bonus = Math.floor(data.discount_percent);
                        const credit = calculateCredit(amount, bonus);
                        const title = data.name;

                        return (
                            <div className='col-span-2'>
                                <div
                                    className={`relative hover:cursor-pointer `}
                                    onClick={() => {
                                        onDiscountClick(amount, bonus, credit);
                                    }}
                                >
                                    <DiscountCard
                                        title={title}
                                        amount={amount}
                                        bonus={bonus}
                                        credit={credit}
                                        isCardSelect={
                                            selectCardAmount === amount
                                        }
                                    />
                                </div>
                            </div>
                        );
                    })}
                {!loading && dataLoaded && discounts.length === 0 && (
                    <div className='flex col-span-6 justify-center'>
                        {' '}
                        {t('discounts.noDiscountCard')}
                    </div>
                )}
            </div>
        </div>
    );
}
