import { Radio } from 'antd';
import './RadioInput.scss'

const RadioInput = ({ label, description, value }) => {
    return (
            <div className='mt-2.5 radioInput'>
                <Radio value={value}>
                    <span className='font-semibold'>
                        {label}
                        {description && (
                            <p className='description-label font-normal text-xs italic'>
                                {description}
                            </p>
                        )}
                    </span>
                </Radio>
            </div>
    );
};

export default RadioInput;
