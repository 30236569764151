import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';

function DropdownActionsMenu({
    triggerImg,
    menuItems,
    placement,
    handleVisibleChange,
    visible,
}) {
    const content = (
        <ul className='m-0 max-h-[250px] overflow-y-auto'>
            {menuItems?.map((menuItem, index) => (
                <li
                    className={`cursor-pointer text-card-foreground-color my-[0.5em] px-[0.5em] hover:bg-primary-light-color m-0`}
                    key={index}
                >
                    {menuItem}
                </li>
            ))}
        </ul>
    );
    return (
        <Popover
            content={content}
            arrow={false}
            trigger='click'
            open={visible}
            onOpenChange={handleVisibleChange}
            placement={placement}
        >
            <div className='cursor-pointer'>{triggerImg}</div>
        </Popover>
    );
}
DropdownActionsMenu.propTypes = {
    menuItems: PropTypes.array.isRequired,
    triggerImg: PropTypes.node,
    placement: PropTypes.oneOf([
        'topLeft',
        'topRight',
        'bottomLeft',
        'bottomRight',
        'middle',
    ]),
    // onClose: PropTypes.func,
    // closable: PropTypes.bool,
    // duration: PropTypes.number,
    // visible: PropTypes.bool
};

DropdownActionsMenu.defaultProps = {
    placement: 'bottomRight',
    // TODO THEME ICI IL FAUT REMPLACER IMG PAR SVG ET PASSER var(--primary-color)
    triggerImg: <img src={process.env.PUBLIC_URL + '/img/actionsButton.png'} />,
    trigger: 'click',
};
export default DropdownActionsMenu;
