import React, { useEffect, useState } from 'react';
import {
    Input,
    Modal,
    Spinner,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import AlertMessage from '../../../modUtils/components/AlertMessage';
import { useTranslation } from 'react-i18next';
import ButtonAdd from '../../../modUtils/components/buttons/ButtonAdd';
import { Form } from 'antd';
import { message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import {
    get_discount_recharge_by_group_list,
    create_discount_recharge_by_group,
} from '../../slices/walletLocalSlice';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModal from '../../../modUtils/components/confirmationModal/ConfirmationModal';

export default function AddRecharge() {
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [form] = useForm();
    const { groupId } = useSelector((store) => store.manager);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleClick = () => {
        setModalVisible(true);
    };

    const handleModalCancel = () => {
        form.resetFields();
        setModalVisible(false);
    };

    const validatePositiveNumber = (rule, value, callback) => {
        if (value && value < 0) {
            callback(t('badge.badgePositifNombre'));
        } else {
            callback();
        }
    };

    const getDiscounts = async (offset, limit) => {
        try {
            setLoading(true);
            await dispatch(
                get_discount_recharge_by_group_list({
                    groupId,
                    limit,
                    offset,
                }),
            ).unwrap();
            setLoading(false);
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error.status}
                        alertMessage={t('discounts.errorMessage')}
                    />
                ),
            });
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (groupId) getDiscounts(0, 10);
    }, [groupId]);
    const handleOnOk = async () => {
        const formValues = form.getFieldValue();

        try {
            setLoading(true);
            await dispatch(
                create_discount_recharge_by_group({
                    groupId,
                    discount: formValues.discount,
                    amount: formValues.amount,
                    name: formValues.name,
                }),
            ).unwrap();
            setLoading(false);
            form.resetFields();
            setModalVisible(false);
            setConfirmModalVisible(false);
            getDiscounts(0, 10);
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('badge.badgeError')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        } finally {
            setLoading(false);
            setConfirmModalVisible(false);
        }
    };

    const onAddValues = async () => {
        try {
            await form.validateFields();
            setConfirmModalVisible(true);
        } catch (errorInfo) {
            console.error('Validation failed:', errorInfo);
        }
    };

    const handleConfirmCancel = () => {
        setConfirmModalVisible(false);
    };

    return (
        <>
            <div>
                <div className='flex text-xl font-semibold mb-4 justify-center items-center uppercase'>
                    {t('discounts.pageTitle')}
                </div>
                <div className='flex justify-end pr-10'>
                    <ButtonAdd
                        label={t('discounts.buttonLabel')}
                        onClick={handleClick}
                    ></ButtonAdd>
                </div>
                {loading && <Spinner />}
                <Modal
                    title={t('discounts.modalTitle')}
                    isOpen={modalVisible}
                    onCancel={handleModalCancel}
                    onOk={onAddValues}
                    onClose={handleModalCancel}
                    okText='Submit'
                    cancelText='Cancel'
                >
                    <Form
                        form={form}
                        onFinish={onAddValues}
                        layout='vertical'
                        initialValues={{ amount: '', discount: '', name: '' }}
                    >
                        <div className='grid grid-cols-2 gap-4'>
                            <Form.Item
                                label={t('discounts.rechargelabel')}
                                name='amount'
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'badge.badgeRequiredMessage',
                                        ),
                                    },

                                    {
                                        validator: validatePositiveNumber,
                                    },
                                ]}
                            >
                                <Input type='number'></Input>
                            </Form.Item>
                            <Form.Item
                                label={t('discounts.discountPercent')}
                                name='discount'
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'badge.badgeRequiredMessage',
                                        ),
                                    },

                                    {
                                        validator: validatePositiveNumber,
                                    },
                                ]}
                            >
                                <Input type='number'></Input>
                            </Form.Item>
                            <Form.Item
                                label={t('discounts.name')}
                                name='name'
                                className='flex justify-center items-center col-span-2'
                            >
                                <Input type='text'></Input>
                            </Form.Item>
                        </div>
                    </Form>
                </Modal>

                <ConfirmationModal
                    onOk={handleOnOk}
                    visible={confirmModalVisible}
                    onCancel={handleConfirmCancel}
                    confirmLoading={loading}
                    content={<div>{t('discounts.rechargeContent')}</div>}
                />
            </div>
        </>
    );
}
