import React, { useState } from 'react';
import ButtonAdd from '../../../modUtils/components/buttons/ButtonAdd';
import { useTranslation } from 'react-i18next';

export default function AddNewProduct() {
    const { t } = useTranslation();
    return (
        <>
            <div className='flex flex-col text-xl items-center text-center'>
                <div>{t('product.addProductLabel')}</div>
                <ButtonAdd label={t('product.labelBtn')} className=' mt-4' />
            </div>
        </>
    );
}
