import { useDispatch, useSelector } from 'react-redux';
import { hasPermission } from '../../../modUtils/functions/permissions/handlePermissions';
import { useState } from 'react';
import { export_tellecollects_by_group_export_get } from '../../slices/bankingSlice';
import moment from 'moment';
import { simulateDowloadLink } from '../../../modUtils/functions/simulateDowloadLink';
import { message } from 'antd';
import AlertMessage from '../../../modUtils/components/AlertMessage';
import PermissionFadeContainer from '../../../modUtils/components/permissionFadeContainer/PermissionFadeContainer';
import ExportModal from '../../../modUtils/components/exportModal/ExportModal';
import { useTranslation } from 'react-i18next';

export default function ExportModalTelecollectPage({ selectForm }) {
    const dispatch = useDispatch();
    const [fileFormat, setFileFormat] = useState('csv');
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const filterBySearchOptions = [
        { label: t('exportModalTelecollect.optionApp'), value: 'app' },
        {
            label: t('exportModalTelecollect.optionDiscount'),
            value: 'remittance',
        },
        { label: t('exportModalTelecollect.optionAmount'), value: 'amount' },
    ];
    const { selectedGroup } = useSelector((state) => state.manager);

    const formatDate = 'DD/MM/YYYY HH:mm';
    const [selectedOption, setSelectedOption] = useState(
        filterBySearchOptions[0].value,
    );
    // permission

    const permissions = selectedGroup?.permissions;
    const verifyPermission =
        hasPermission('mod_sale:show_sale', permissions) &&
        hasPermission('mod_data:data_export', permissions);

    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission =
        hasPermission('mod_sale:show_sale', groupPermissions) &&
        hasPermission('mod_data:data_export', groupPermissions);
    //export tellecollectes
    const exportTellecollects = async (values) => {
        let searchValue = values?.searchInputValue;
        try {
            setLoading(true);
            const results = await dispatch(
                export_tellecollects_by_group_export_get({
                    groupId: selectedGroup.id,
                    fileFormat: fileFormat,
                    dateStart: moment(
                        selectForm['dateStart'],
                        formatDate,
                    ).unix(),
                    dateEnd: moment(selectForm['dateEnd'], formatDate).unix(),
                    creditCard: selectForm['creditCard'],
                    equipmentType: selectForm['equipmentType']
                        ? selectForm['equipmentType'].join(',')
                        : null,
                    sites: selectForm['siteListName']
                        ? selectForm['siteListName'].join(',')
                        : null,
                    payment: selectForm['payment'],
                    ordering: selectForm['ordering'],
                    remittance:
                        selectedOption == 'remittance' &&
                        searchValue?.trim().length > 0
                            ? searchValue.trim()
                            : null,
                    app:
                        selectedOption == 'app' &&
                        searchValue?.trim().length > 0
                            ? searchValue.trim()
                            : null,
                    amount:
                        selectedOption == 'amount' &&
                        searchValue?.trim().length > 0
                            ? searchValue.trim()
                            : null,
                }),
            ).unwrap();
            //simulateDownloadLink ( results, fileForma exple csv, filename)
            simulateDowloadLink(
                results,
                'télécollectes - ' + selectedGroup.name,
                fileFormat,
            );
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('exportModalTelecollect.errorMessage')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        } finally {
            setLoading(false);
        }
    };
    const onChangeFileType = ({ target: { value } }) => {
        setFileFormat(value);
    };

    return (
        verifyPermission && (
            <PermissionFadeContainer permission={verifyGroupPermission}>
                <ExportModal
                    getExport={exportTellecollects}
                    loading={loading}
                    onChangeFileType={onChangeFileType}
                    selectForm={selectForm}
                />
            </PermissionFadeContainer>
        )
    );
}
