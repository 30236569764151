import React, { useState } from 'react';
import SiteManageModal from '../../../modEquipment/components/siteManageModal/SiteManageModal';

const Site = ({ site }) => {
    const [modalVisible, setModalVisible] = useState(false);

    const handleModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    return (
        <SiteManageModal
            site={site}
            modalVisible={modalVisible}
            handleModal={handleModal}
            closeModal={closeModal}
            mapModal={false}
        />
    );
};
export default Site;
