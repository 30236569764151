import React, { useState } from 'react';
import { Card } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { useTranslation } from 'react-i18next';
import { amountFormatWithCurrencySymbol } from '../../../modUtils/functions/values/numberFormat';

export default function DiscountCard({
    title,
    amount,
    bonus,
    credit,
    cornerIcon,
    onClickCornerIcon,
    isCardSelect,
}) {
    const { t } = useTranslation();

    return (
        <Card
            style={{
                cardBody: { padding: 0 },
            }}
            className={`${isCardSelect && 'border-thirty-color'} `}
        >
            {cornerIcon && (
                <div
                    className={`absolute top-0 right-0 mr-1 mt-1 ${onClickCornerIcon && 'cursor-pointer'}`}
                    onClick={onClickCornerIcon}
                >
                    {cornerIcon}
                </div>
            )}
            <div className='p-2'>
                <span
                    className={`flex justify-center items-center my-2 font-semibold text-lg text-[--primary-color] ${isCardSelect && 'text-thirty-color'}`}
                >
                    {title ? title : t('discounts.bearing') + ' ' + amount}
                </span>
                <span className=' text-md '>{t('badge.badgeRecharge')}</span>
                <span className='text-lg font-semibold flex justify-center items-center '>
                    {amountFormatWithCurrencySymbol(amount)}
                </span>
                <span className='flex justify-center items-center '>
                    {t('badge.badgeBonus')} {bonus + '%'}
                </span>
            </div>
            <div
                className={`bg-primary-color text-primary-foreground-color w-full p-2 rounded-b-md ${isCardSelect && 'bg-thirty-color'} `}
            >
                <span className='flex justify-center  items-center'>
                    {t('badge.badgeCredit')}
                </span>

                <span className='flex justify-center items-center text-lg font-semibold'>
                    {amountFormatWithCurrencySymbol(credit)}
                </span>
            </div>
        </Card>
    );
}
