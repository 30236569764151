import React, { useState, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import PropTypes from 'prop-types';
import 'react-calendar/dist/Calendar.css';
import { FaCalendarAlt } from 'react-icons/fa';
import './DatePicker.scss';

const DatePicker = ({
    format,
    selectedDate,
    onDateChange,
    label,
    placeholder,
    color,
    classNameLabel = '',
    required = false,
    disabled,
    width = ' w-44',
    type,
    minDate,
    error,
}) => {
    // State pour afficher ou masquer le calendrier
    const [showCalendar, setShowCalendar] = useState(false);

    // Ref to keep track of the date picker container
    const datePickerRef = useRef(null);

    // Afficher ou masquer calendar
    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
    };

    const handleDateChange = (date) => {
        onDateChange(formatSelectedDate(date));
        toggleCalendar(); // Masquer calendar aprés selectionner date
    };

    // Format la date sélectionnée
    const formatSelectedDate = (date) => {
        return date ? moment(date, format).format(format) : '';
    };

    const parsedDate = moment(selectedDate, format);
    const formattedDate = parsedDate.isValid()
        ? parsedDate.format('MM/DD/YYYY')
        : null;

    const colorVariants = {
        primary: 'text-primary-color',
        secondary: 'text-secondary-color',
        thirty: 'text-thirty-color',
        neutral: 'text-muted-foreground-color',
    };

    const inputColor = getComputedStyle(document.documentElement)
        .getPropertyValue('--input-color')
        .trim();

    // Fermer calendar si on clique en dehors du sélecteur de date
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                datePickerRef.current &&
                !datePickerRef.current.contains(event.target)
            ) {
                setShowCalendar(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div
            ref={datePickerRef}
            className={`date-picker relative w-full sm:w-auto`}
        >
            <div
                className={`text-left font-black text-sm h-[24px] border-border-color
                    ${color ? colorVariants[color] : 'text-foreground-color'}
                    ${classNameLabel}
                    `}
            >
                {required && <span className='text-destructive-color'>*</span>}{' '}
                {label}
            </div>
            <div
                className={`
                    flex flex-row justify-between items-center p-2.5 h-10
                    border border-solid border-input-color rounded-md
                    w-full sm:${width} sm:max-w-[180px] text-input-color bg-background-color
                    ${error && 'border-destructive-color'}  ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
                `}
                onClick={disabled ? null : toggleCalendar}
            >
                <input
                    type='text'
                    value={formatSelectedDate(selectedDate)}
                    disabled={disabled}
                    placeholder={placeholder}
                    className={`!w-full active:!bg-transparent active:!border-none placeholder-muted-foreground-color ${disabled && 'cursor-not-allowed'}`}
                    style={{ all: 'unset' }}
                />
                <FaCalendarAlt color={inputColor} fontSize={'16px'} />
            </div>
            {error && error}
            {showCalendar && (
                <div
                    className={`absolute top-16 left-0 w-[250px] shadow-md z-[999]  border border-solid border-input-color rounded-md p-2 bg-card-color`}
                >
                    <Calendar
                        value={formattedDate}
                        onChange={(date) => handleDateChange(date)}
                        minDate={type === 'endDate' ? minDate : null}
                    />
                </div>
            )}
        </div>
    );
};

DatePicker.defaultProps = {
    placeholder: 'Select a date',
    format: 'DD/MM/YYYY HH:mm',
};

DatePicker.propTypes = {
    format: PropTypes.string,
};

export default DatePicker;
