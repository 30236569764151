import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    httpGet,
    httpPost,
    httpDelete,
    httpPut,
} from '../../modApi/apis/httpConfig';

// retourner la liste des contraintes temps d'une configuration
export const get_constraint_schedule_paginated = createAsyncThunk(
    'mod_profil/get_constraint_schedule_paginated',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_schedule/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// Créer une contrainte horaire
export const create_constraint_schedule = createAsyncThunk(
    'mod_profil/create_constraint_schedule',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPost(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_schedule/`,
                {
                    name: values.name,
                    equipment_type: values.equipmentType,
                    is_global: values.isGlobal,
                    schedule_monday: values.scheduleMonday,
                    schedule_tuesday: values.scheduleTuesday,
                    schedule_wednesday: values.scheduleWednesday,
                    schedule_thursday: values.scheduleThursday,
                    schedule_friday: values.scheduleFriday,
                    schedule_saturday: values.scheduleSaturday,
                    schedule_sunday: values.scheduleSunday,
                    time_slot: values.timeSlot,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// constraints_schedule_time_slot_create
export const constraints_schedule_time_slot_create = createAsyncThunk(
    'mod_profil/constraints_schedule_time_slot_create',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPost(
                `mod_profil/groups/${values.groupId}/constraints_schedule/${values.constraintScheduleId}/time_slots/`,
                {
                    time_slot_hours_start: values.timeSlotHoursStart,
                    time_slot_hours_end: values.timeSlotHoursEnd,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// retourner le détail d'une contrainte horaires
export const get_constraint_schedule_detail = createAsyncThunk(
    'mod_profil/get_constraint_schedule_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_schedule/${values.constraintId}/`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// modifier une contrainte horaire "constraint_schedule_detail_update"
export const update_constraint_schedule_detail = createAsyncThunk(
    'mod_profil/update_constraint_schedule_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPut(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_schedule/${values.constraintId}/`,
                {
                    name: values.name,
                    equipment_type: values.equipmentType,
                    is_global: values.isGlobal,
                    schedule_monday: values.scheduleMonday,
                    schedule_tuesday: values.scheduleTuesday,
                    schedule_wednesday: values.scheduleWednesday,
                    schedule_thursday: values.scheduleThursday,
                    schedule_friday: values.scheduleFriday,
                    schedule_saturday: values.scheduleSaturday,
                    schedule_sunday: values.scheduleSunday,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// Supprimer une contrainte horaire
export const delete_constraint_schedule_detail = createAsyncThunk(
    'mod_profil/delete_constraint_schedule_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpDelete(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraints_schedule/${values.constraintId}/`,
            );
            return { ...response.data, id: values.constraintId };
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// Modifier une contrainte horaire par son id
export const constraints_schedule_time_slot_detail_update = createAsyncThunk(
    'mod_profil/constraints_schedule_time_slot_detail_update',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPut(
                `mod_profil/groups/${values.groupId}/constraints_schedule/${values.constraintScheduleId}/time_slots/${values.timeSlotId}/`,
                {
                    time_slot_hours_start: values.timeSlotHoursStart,
                    time_slot_hours_end: values.timeSlotHoursEnd,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// Supprimer une contrainte horaire par son id
export const constraints_schedule_time_slot_detail_delete = createAsyncThunk(
    'mod_profil/constraints_schedule_time_slot_detail_delete',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpDelete(
                `mod_profil/groups/${values.groupId}/constraints_schedule/${values.constraintScheduleId}/time_slots/${values.timeSlotId}/`,
            );
            return { ...response.data, id: values.timeSlotId };
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

const constraintScheduleSlice = createSlice({
    name: 'constraintSchedule',
    initialState: {
        loading: true,
        constraintsSchedule: [],
        loadingDetail: true,
        constraintsScheduleDetail: {},
    },
    extraReducers: (builder) => {
        builder
            /* get_constraint_schedule_paginated */
            .addCase(
                get_constraint_schedule_paginated.pending,
                (state, action) => {
                    state.constraintsSchedule = [];
                },
            )
            .addCase(
                get_constraint_schedule_paginated.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.constraintsSchedule = action.payload.results;
                },
            )
            .addCase(
                get_constraint_schedule_paginated.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* create_constraint_schedule */
            .addCase(create_constraint_schedule.fulfilled, (state, action) => {
                state.loading = false;
                action.payload.index = action.payload.id;
                state.constraintsSchedule = [
                    ...state.constraintsSchedule,
                    action.payload,
                ];
            })
            .addCase(create_constraint_schedule.rejected, (state, action) => {
                state.loading = false;
            })

            /* constraints_schedule_time_slot_create */
            .addCase(
                constraints_schedule_time_slot_create.fulfilled,
                (state, action) => {
                    state.loading = false;

                    // Identifiant de la contrainte à mettre à jour
                    const constraintId = state.constraintsScheduleDetail.id;

                    // Nouveau time_slot à ajouter
                    const newTimeSlot = action.payload;

                    // Mise à jour de constraintsSchedule
                    state.constraintsSchedule = state.constraintsSchedule.map(
                        (constraint) => {
                            if (constraint.id === constraintId) {
                                return {
                                    ...constraint,
                                    time_slot: [
                                        ...constraint.time_slot,
                                        newTimeSlot,
                                    ], // Ajout du time_slot
                                };
                            }
                            return constraint;
                        },
                    );

                    // Mise à jour de state.constraintsScheduleDetail
                    if (state.constraintsScheduleDetail.id === constraintId) {
                        state.constraintsScheduleDetail = {
                            ...state.constraintsScheduleDetail,
                            time_slot: [
                                ...state.constraintsScheduleDetail.time_slot,
                                newTimeSlot,
                            ], // Ajout du time_slot
                        };
                    }
                },
            )
            .addCase(
                constraints_schedule_time_slot_create.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* get_constraint_schedule_detail */
            .addCase(
                get_constraint_schedule_detail.pending,
                (state, action) => {
                    state.loadingDetail = true;
                    state.constraintsScheduleDetail = {};
                },
            )
            .addCase(
                get_constraint_schedule_detail.fulfilled,
                (state, action) => {
                    state.constraintsScheduleDetail = action.payload;
                    state.loadingDetail = false;
                },
            )
            .addCase(
                get_constraint_schedule_detail.rejected,
                (state, action) => {
                    state.loadingDetail = false;
                },
            )

            /* update_constraint_schedule_detail */
            .addCase(
                update_constraint_schedule_detail.fulfilled,
                (state, action) => {
                    state.loading = false;

                    // La contrainte à mettre à jour
                    const updatedConstraintSchedule = action.payload;

                    // Mise à jour de constraintsSchedule
                    state.constraintsSchedule = state.constraintsSchedule.map(
                        (constraint) => {
                            if (
                                constraint.id === updatedConstraintSchedule.id
                            ) {
                                return updatedConstraintSchedule;
                            }
                            return constraint;
                        },
                    );
                },
            )
            .addCase(
                update_constraint_schedule_detail.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* constraints_schedule_time_slot_detail_update */
            .addCase(
                constraints_schedule_time_slot_detail_update.fulfilled,
                (state, action) => {
                    state.loading = false;

                    // Identifiant de la contrainte à mettre à jour
                    const constraintId = state.constraintsScheduleDetail.id;

                    // Identifiant du time_slot à mettre à jour
                    const timeSlotIdToUpdate = action.payload.id;

                    // Nouvelle valeur des horaires
                    const { time_slot_hours_start, time_slot_hours_end } =
                        action.payload;

                    // Mise à jour de constraintsSchedule
                    state.constraintsSchedule = state.constraintsSchedule.map(
                        (constraint) => {
                            if (constraint.id === constraintId) {
                                return {
                                    ...constraint,
                                    time_slot: constraint.time_slot.map(
                                        (timeSlot) =>
                                            // Vérifie si c'est le time_slot à mettre à jour
                                            timeSlot.id === timeSlotIdToUpdate
                                                ? {
                                                      ...timeSlot,
                                                      time_slot_hours_start:
                                                          time_slot_hours_start,
                                                      time_slot_hours_end:
                                                          time_slot_hours_end,
                                                  }
                                                : timeSlot,
                                    ),
                                };
                            }
                            return constraint;
                        },
                    );

                    // Mise à jour de state.constraintsScheduleDetail
                    if (state.constraintsScheduleDetail.id === constraintId) {
                        state.constraintsScheduleDetail = {
                            ...state.constraintsScheduleDetail,
                            time_slot:
                                state.constraintsScheduleDetail.time_slot.map(
                                    (timeSlot) =>
                                        // Vérifie si c'est le time_slot à mettre à jour
                                        timeSlot.id === timeSlotIdToUpdate
                                            ? {
                                                  ...timeSlot,
                                                  time_slot_hours_start:
                                                      time_slot_hours_start,
                                                  time_slot_hours_end:
                                                      time_slot_hours_end,
                                              }
                                            : timeSlot,
                                ),
                        };
                    }
                },
            )
            .addCase(
                constraints_schedule_time_slot_detail_update.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* delete_constraint_schedule_detail */
            .addCase(
                delete_constraint_schedule_detail.fulfilled,
                (state, action) => {
                    state.loading = false;

                    // Identifiant de la contraint à supprimer
                    const constraintScheduleToDelete = action.payload.id;

                    // Mise à jour de constraintsSchedule
                    state.constraintsSchedule =
                        state.constraintsSchedule.filter(
                            (constraint) =>
                                constraint.id !== constraintScheduleToDelete,
                        );
                },
            )
            .addCase(
                delete_constraint_schedule_detail.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* constraints_schedule_time_slot_detail_delete */
            .addCase(
                constraints_schedule_time_slot_detail_delete.fulfilled,
                (state, action) => {
                    state.loading = false;

                    // Identifiant de la contrainte à mettre à jour
                    const constraintId = state.constraintsScheduleDetail.id;

                    // Identifiant du time_slot à supprimer
                    const timeSlotIdToRemove = action.payload.id;

                    // Mise à jour de constraintsSchedule
                    state.constraintsSchedule = state.constraintsSchedule.map(
                        (constraint) => {
                            if (constraint.id === constraintId) {
                                return {
                                    ...constraint,
                                    time_slot: constraint.time_slot.filter(
                                        (timeSlot) =>
                                            timeSlot.id !== timeSlotIdToRemove,
                                    ),
                                };
                            }
                            return constraint;
                        },
                    );

                    // Mise à jour de state.constraintsScheduleDetail
                    if (state.constraintsScheduleDetail.id === constraintId) {
                        state.constraintsScheduleDetail = {
                            ...state.constraintsScheduleDetail,
                            time_slot:
                                state.constraintsScheduleDetail.time_slot.filter(
                                    (timeSlot) =>
                                        timeSlot.id !== timeSlotIdToRemove,
                                ),
                        };
                    }
                },
            )
            .addCase(
                constraints_schedule_time_slot_detail_delete.rejected,
                (state, action) => {
                    state.loading = false;
                },
            );
    },
});

export default constraintScheduleSlice.reducer;
