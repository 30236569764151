import React, { useState, useEffect } from 'react';
import { Select } from '../componentsLibrary/componentsLibrary';
import { get_types_by_group_paginated } from '../../../modEquipment/slices/equipmentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { eEquipmentType } from 'ck_commun/src/commun_global/enums';
import i18next from 'i18next';

export default function FilterByEquipmentType({ onValueChange, multiple }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);
    const [equipmentTypeGroup, setEquipmentTypeGroup] = useState();
    const [numberOfSelectedOptions, setNumberOfSelectedOptions] = useState();

    useEffect(() => {
        async function fetchData() {
            await dispatch(get_types_by_group_paginated(groupId)).then(
                (result) => {
                    var options = result?.payload?.map((equipmentType) => ({
                        label: t(eEquipmentType[equipmentType.type]),
                        value: equipmentType.type,
                    }));

                    setEquipmentTypeGroup(options);
                },
            );
        }
        if (groupId) {
            fetchData();
        }
    }, [groupId, i18next.language]);
    function handleChange(value, option, selectedList) {
        setNumberOfSelectedOptions(selectedList.length);
        onValueChange(selectedList);
    }

    const handleDeselect = (deselectedValue, selectedList) => {
        setNumberOfSelectedOptions(selectedList.length);
        onValueChange(selectedList);
    };
    return (
        <Select
            multiple={multiple}
            width={!multiple ? 200 : 105}
            placeholder={
                !multiple
                    ? t('filterEquipment.placeholder')
                    : `Filtres${numberOfSelectedOptions > 0 ? `(${numberOfSelectedOptions})` : ''}`
            }
            options={equipmentTypeGroup}
            dropdownMatchSelectWidth={false}
            onChange={(value) => handleChange()}
            onDeselect={handleDeselect}
            onSelect={handleChange}
            style= {{
                select: { width: "200px"},
                selectOptions: { width: "200px"}
            }}
        />
    );
}
