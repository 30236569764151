import React from 'react';
import { CiFileOn } from 'react-icons/ci';
import { PiMagnifyingGlass } from 'react-icons/pi';
import { ImCross } from 'react-icons/im';

export default function NoData({ text }) {
    return (
        <div className='flex flex-col items-center mt-8 mb-4 text-black'>
            <div>
                <CiFileOn size={60} className='relative left-[20px]' />
                <PiMagnifyingGlass
                    size={30}
                    className='relative top-[-10px] left-[-24px]'
                />
                <ImCross
                    size={10}
                    className='relative top-[-22px] left-[-46px]'
                />
            </div>

            <div className='text-center'>{text}</div>
        </div>
    );
}
