import React, { useEffect, useState } from 'react';
import {
    Modal,
    Spinner,
} from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import './ConfirmationModal.scss';
import { FaExclamationCircle } from 'react-icons/fa';

export default function ConfirmationModal({
    content,
    onOk,
    onCancel,
    visible,
    confirmLoading,
    isAwareVisible = false,
}) {
    const [modalVisible, setModalVisible] = useState(visible);
    const [isAware, setIsAware] = useState(!isAwareVisible);
    const [hideIcon, setHideIcon] = useState(false); // ajouter un state pour cacher  l'icone parce qu'elle s'affiche tjrs

    const { t } = useTranslation();
    const handleOk = () => {
        setHideIcon(true);
        if (!isAware) return;
        onOk();
        // Ne pas définir modalVisible à false ici car le modal se ferme
        // lorsque confirmLoading devient true et déclenche une réinitialisation de modalVisible
    };

    const handleCancel = () => {
        onCancel();
        setModalVisible(false);
        setHideIcon(false);
    };

    useEffect(() => {
        setModalVisible(visible);
    }, [visible]);

    return (
        <>
            <Modal
                isOpen={modalVisible}
                onClose={handleCancel}
                zIndex={9998}
                onOk={handleOk}
                okText={t('common.transButtonSubmit')}
                onCancel={handleCancel}
            >
                <div className='confirmationModalContent'>
                    {content ? (
                        <span>{content}</span>
                    ) : (
                        <span>{t('confirmationModal.content')}</span>
                    )}
                </div>
                {isAwareVisible && (
                    <div className='flex flex-col items-center justify-center'>
                        <div className='flex flex-row'>
                            <input
                                type='checkbox'
                                id='aware'
                                className='mr-2'
                                onChange={() => setIsAware((prev) => !prev)}
                            />
                            <label>{t('confirmationModal.transIsAware')}</label>
                        </div>

                        {!isAware && hideIcon && (
                            <div className='flex flex-row'>
                                <FaExclamationCircle
                                    className='w-4 text-destructive-color'
                                    fill='currentColor'
                                />
                                <span className='text-destructive-color text-xs '>
                                    {t(
                                        'confirmationModal.transIsAwareRequired',
                                    )}
                                </span>
                            </div>
                        )}
                    </div>
                )}
            </Modal>

            {confirmLoading && createPortal(<Spinner />, document.body)}
        </>
    );
}
