import React, { FC } from 'react';
import { ReactComponent as Error404Svg } from '../assets/images/error404.svg';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../components/componentsLibrary/error/ErrorPage';
import { useNavigate } from 'react-router-dom';
import {HOMEPAGE_ROUTE} from '../../../../../../utils/constants';


const Error404Page: FC<{}> = ({}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <ErrorPage
            icon={<Error404Svg className={`max-w-sm`} />}
            title={String(t('Error404Page.title'))}
            description={String(t('Error404Page.description'))}
            onClick={() => navigate(HOMEPAGE_ROUTE)}
        />
    );
};

export default Error404Page;
