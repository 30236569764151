import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { httpGet } from '../../modApi/apis/httpConfig';

// retourner les logs de support pour un groupe
export const support_logs_by_group_paginated_get = createAsyncThunk(
    'mod_profil/support_logs_by_group_paginated_get',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_support/groups/${values.groupId}/support_logs/`,
                {
                    params: {
                        support_id: values.supportId,
                        entity_id: values.entityId,
                        equipment_id: values.equipmentId,
                        error_support: values.error_Support,
                        ordering: values.ordering,
                        limit: values.limit,
                        offset: values.offset,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

const supportSlices = createSlice({
    name: 'support',
    initialState: {
        loading: true,
        support: [],
    },
    extraReducers: (builder) => {
        builder
            /* support_logs_by_group_paginated_get */
            .addCase(
                support_logs_by_group_paginated_get.pending,
                (state, action) => {
                    state.support = [];
                },
            )
            .addCase(
                support_logs_by_group_paginated_get.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.support = action.payload;
                },
            )
            .addCase(
                support_logs_by_group_paginated_get.rejected,
                (state, action) => {
                    state.loading = false;
                },
            );
    },
});

export default supportSlices.reducer;
