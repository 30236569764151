import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { useSelector } from 'react-redux';
import AlertMessage from '../../AlertMessage';
import { useTranslation } from 'react-i18next';
import { Label, Table } from '../componentsLibrary';
import useScrollHandler from '../../../hooks/useScrollHandler';
import { ReactComponent as LaodingSvg } from '../../../assets/images/laoding/laoding.svg';
import i18next from 'i18next';

export default function TablePaginatedScroll({
    label,
    state = false,
    fetch,
    effect,
    columns,
    onRowClick,
    rowClassName,
}) {
    const limit = 30;
    const defaultOffset = 0;
    const { t } = useTranslation();
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const { groupId } = useSelector((state) => state.manager);
    const [hasMoreDataToLoad, setHasMoreDataToLoad] = useState(true); //pour savoir s'il y'a encore de données à charger

    const onScrollEnd = () => {
        if (!loading && hasMoreDataToLoad) {
            fetchData(offset, limit);
        }
    };
    const { tableRef, handleScroll } = useScrollHandler(onScrollEnd);

    const fetchData = async (offset, limit, Load = false) => {
        if (!Load && !hasMoreDataToLoad) return; // Éviter la redondance des appels

        if (offset === defaultOffset) {
            setDataSource([]);
        }

        try {
            setLoading(true);
            let response;
            if (!state) {
                response = await fetch(limit, offset);

                if (response?.next === null) {
                    setHasMoreDataToLoad(false);
                }

                if (
                    Array.isArray(response?.results) &&
                    response.results.length > 0
                ) {
                    setDataSource((prevData) => [
                        ...prevData,
                        ...response.results,
                    ]);
                    setOffset(offset + limit);
                } else if (
                    Array.isArray(response?.entities) &&
                    response.entities.length > 0
                ) {
                    setDataSource((prevData) => [
                        ...prevData,
                        ...response.entities,
                    ]);
                    setOffset(offset + limit);
                }
            } else {
                response = state;

                if (
                    Array.isArray(response?.entities) ||
                    Array.isArray(response?.results)
                ) {
                    const data = Array.isArray(response.entities)
                        ? response.entities
                        : response.results;
                    setDataSource((prevData) => [...prevData, ...data]);
                }
            }
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={'Une erreur est survenue.'}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setDataSource([]);
        setOffset(0);
        setHasMoreDataToLoad(true);

        if (groupId) {
            fetchData(defaultOffset, limit, true);
        }
    }, [effect, i18next.language]);

    const handleTableScroll = (event) => {
        if (handleScroll) {
            handleScroll(event); // Appel de la fonction de rappel onScroll lors du défilement
        }
    };

    return (
        <div className='flex flex-col justify-center items-center w-full mx-auto'>
            {label && (
                <Label
                    label={t(label)}
                    className='my-8 text-lg font-black text-black'
                />
            )}
            <div className='w-full overflow-hidden'>
                <div
                    ref={tableRef}
                    onScroll={handleTableScroll}
                    className={`mt-[-2px] w-full overflow-x-auto${
                        dataSource?.length > 0
                            ? ' table-scrollable'
                            : ' table-overflow'
                    }`}
                >
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        pagination={false}
                        scroll={{ y: 470 }}
                        onRowClick={onRowClick}
                        rowClassName={rowClassName}
                    />
                </div>
            </div>
            {loading && (
                <LaodingSvg
                    className={`inline mx-3 w-8 h-8 text-white animate-spin`}
                />
            )}
        </div>
    );
}
