import { useTranslation } from 'react-i18next';
import {
    Input,
    Modal,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import RangePicker from '../../../modUtils/components/componentsLibrary/datePicker/RangePicker';
import { Formik } from 'formik';
import Button from '../../../modUtils/components/buttons/Button';
import { useDispatch } from 'react-redux';
import {
    close_promotion_supports,
    get_promotion_supports,
} from '../../slices/promotionSlice';
import { message, Spin } from 'antd';
import AlertMessage from '../../../modUtils/components/AlertMessage';
import { useState } from 'react';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import ConfirmationModal from '../../../modUtils/components/confirmationModal/ConfirmationModal';

const ModalCloseTicketsGroup = ({
    modalCloseTicketsGroup,
    toggleModalCloseTicketsGroup,
    promotionId,
    groupId,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [confitmationModalVisible, setConfirmationModalVisible] =
        useState(false);

    const refreshSupports = () => {
        if (promotionId)
            dispatch(
                get_promotion_supports({
                    groupId,
                    promotion: promotionId,
                    limit: 5,
                    offset: 1,
                }),
            );
    };

    const handleSubmit = async (values, resetForm) => {
        setLoading(true);
        try {
            const response = await dispatch(
                close_promotion_supports({ groupId, promotionId, ...values }),
            ).unwrap();
            if (response) {
                message.success({
                    content: (
                        <AlertMessage
                            status={'success'}
                            alertMessage={t(
                                'success.transSuccessCloseSupports',
                            )}
                        />
                    ),
                });
                toggleModalCloseTicketsGroup();
                refreshSupports();
            }
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={'error'}
                        alertMessage={t('errors.transErrorCloseSupports')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        } finally {
            setLoading(false);
        }
    };

    // Fonction pour gérer le changement de plage de dates
    const handleRangeChange = (start, end, setValues, values) => {
        // Fonction pour gérer le changement de plage de dates
        if (start) {
            setStartDate(start);
        }
        if (end) {
            setEndDate(end);
        }
        setValues({
            ...values,
            startDate: moment(start).unix(),
            endDate: moment(end).unix(),
        });
    };
    return (
        <Modal
            isOpen={modalCloseTicketsGroup}
            onClose={toggleModalCloseTicketsGroup}
            title={t('modalViewTickets.modalCloseTicketsGroup.transTitle')}
            style={{
                modalTitle: {
                    color: '#25292D',
                    fontSize: '20px',
                    lineHeight: '23px',
                },
            }}
        >
            <Formik
                initialValues={{
                    startDate: null,
                    endDate: null,
                    close_count: null,
                }}
                onSubmit={(values, { resetForm }) => {
                    handleSubmit(values, resetForm);
                }}
            >
                {({ values, resetForm, setValues, handleChange }) => (
                    <div className='mt-8 w-full sm:w-max mx-auto'>
                        <p className='font-semibold text-sm'>
                            {t(
                                'modalViewTickets.modalCloseTicketsGroup.transDate',
                            )}
                        </p>
                        <RangePicker
                            format={'DD/MM/YYYY'}
                            color={'neutral'}
                            onRangeChange={(start, end) =>
                                handleRangeChange(start, end, setValues, values)
                            }
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                        <Input
                            className={'mt-8 w-48'}
                            placeholder={'100'}
                            value={values.close_count}
                            name={'close_count'}
                            type={'number'}
                            min={0}
                            label={t(
                                'modalViewTickets.modalCloseTicketsGroup.transClosedQuantity',
                            )}
                            onChange={handleChange}
                        />
                        <div className='flex justify-center mt-8'>
                            <Button
                                disabled={loading || !values.close_count}
                                onClick={() =>
                                    /*handleSubmit(values, resetForm)*/ setConfirmationModalVisible(
                                        true,
                                    )
                                }
                                color={'success'}
                                className={'min-w-[120px]'}
                            >
                                {loading ? (
                                    <Spin
                                        indicator={
                                            <LoadingOutlined
                                                twoToneColor='white'
                                                spin
                                            />
                                        }
                                    />
                                ) : (
                                    t('modalViewTickets.transCloseCode')
                                )}
                            </Button>
                            {confitmationModalVisible && (
                                <ConfirmationModal
                                    isAwareVisible={true}
                                    visible={confitmationModalVisible}
                                    content={t(
                                        'confirmationModal.transCloseTicketConfirmMessage',
                                    )}
                                    onOk={() => {
                                        setConfirmationModalVisible(false);
                                        handleSubmit(values, resetForm);
                                    }}
                                    onCancel={() =>
                                        setConfirmationModalVisible(false)
                                    }
                                    confirmLoading={loading}
                                />
                            )}
                        </div>
                    </div>
                )}
            </Formik>
        </Modal>
    );
};

export default ModalCloseTicketsGroup;
