import React from 'react';
import { Card } from '../../modUtils/components/componentsLibrary/componentsLibrary';

export default function ProfilCard({
    cardTitle,
    cardInfo,
    additionalCardInfo,
    cornerIcon,
    onClickCornerIcon,
}) {
    return (
        <Card
            height='100%'
            className='min-h-[110px] flex flex-col items-center justify-center relative'
        >
            {cornerIcon && (
                <div
                    className={`absolute top-0 right-0 mr-1 mt-1 ${onClickCornerIcon && 'cursor-pointer'}`}
                    onClick={onClickCornerIcon}
                >
                    {cornerIcon}
                </div>
            )}

            <div className='w-64'>
                <div className='text-muted-foreground-color text-sm font-semibold text-center'>
                    {cardTitle}
                </div>

                <div className='text-accent-color text-xl font-semibold text-center'>
                    {cardInfo ? cardInfo : '-'}
                </div>

                {additionalCardInfo && (
                    <div className='text-muted-foreground-color text-xs text-center mt-2'>
                        {additionalCardInfo}
                    </div>
                )}
            </div>
        </Card>
    );
}
