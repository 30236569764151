import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { message } from 'antd';
import { Input } from '../../modUtils/components/componentsLibrary/componentsLibrary';
import { useDispatch } from 'react-redux';
import {
    create_tokens_from_email,
    create_tokens_from_refresh,
    get_user_details,
} from '../slices/authenticationSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AlertMessage from '../../modUtils/components/AlertMessage';
import Cookies from 'js-cookie';
import { logout } from '../../modAuthentication/functions/logout';
import ButtonLoginWithDemoAccount from './ButtonLoginWithDemoAccount';
import { decodeToken } from '../functions/handleTokens';
import eLanguage from '../../../commun_global/js/system/e_language';
import Button from '../../modUtils/components/buttons/Button';
import ButtonLoading from '../../modUtils/components/buttons/ButtonLoading';
import { HOMEPAGE_ROUTE } from '../../../../../../utils/constants';
import { CURRENT_DOMAIN } from '../../modApi/apis/constants';

const LoginForm = () => {
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);

    const getUser = async (userId) => {
        const detail_user = await dispatch(get_user_details(userId)).unwrap();

        await i18n.changeLanguage(eLanguage[detail_user.lang].split('.')[1]);
    };

    // connexion en utilisant le cookie
    const loginWithCookie = async () => {
        try {
            await dispatch(
                create_tokens_from_refresh(Cookies.get('token_refresh')),
            ).unwrap();

            // Récupérer la valeur de 'user' depuis le localStorage
            const userId = localStorage.getItem('user');

            getUser(userId);
            navigate(HOMEPAGE_ROUTE);
        } catch (error) {
            logout();
        }
    };

    useEffect(() => {
        if (Cookies.get('token_refresh')) {
            loginWithCookie();
        }
    }, []);

    const schema = Yup.object().shape({
        email: Yup.string()
            .required(t('loginInformation.required'))
            .email(t('loginInformation.emailInvalid')),
        password: Yup.string()
            .required(t('loginInformation.required'))
            .min(8, t('loginInformation.passwordInvalid')),
    });

    const handleSubmit = async (values, resetForm) => {
        setIsSubmitting(true);
        try {
            const response = await dispatch(
                create_tokens_from_email(values),
            ).unwrap();
            // si la checkbox RememberMe est cochée alors nous créons un cookie avec la valeur du refresh pendant 6 mois
            // si non nous créons un cookie avec la valeur du refresh pour la session
            if (rememberMe === true) {
                Cookies.set('token_refresh', response.refresh, {
                    expires: 180,
                    domain: CURRENT_DOMAIN,
                });
            } else {
                Cookies.set('token_refresh', response.refresh, {
                    domain: CURRENT_DOMAIN,
                });
            }
            const userId = decodeToken(response.access).user_id;
            getUser(userId);
            navigate(HOMEPAGE_ROUTE);
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error.status}
                        alertMessage={t(
                            'loginInformation.authentificationError',
                        )}
                        errorDetail={error.data?.detail}
                    />
                ),
            });
        } finally {
            setIsSubmitting(false);
            if (typeof resetForm === 'function') {
                resetForm();
            }
        }
    };
    const handleRememberMeChange = () => {
        setRememberMe(!rememberMe);
        //*TODO ajouter la fonction pour maintenir la connexion
    };
    return (
        <div className='w-full px-4'>
            <Formik
                validationSchema={schema}
                initialValues={{ email: '', password: '' }}
                onSubmit={(values, { resetForm }) => {
                    handleSubmit(values, resetForm);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isValid,
                }) => (
                    <div className='login'>
                        <div className='form'>
                            <form
                                id='form'
                                noValidate
                                onSubmit={handleSubmit}
                                className='w-full'
                            >
                                <Input
                                    type='email'
                                    name='email'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    placeholder='Email'
                                    className='md:w-[300px]'
                                    id='email'
                                />
                                <p className='error text-destructive-color'>
                                    {errors.email &&
                                        touched.email &&
                                        errors.email}
                                </p>
                                <Input
                                    type='password'
                                    name='password'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    placeholder='Mot de passe'
                                    className='form-control'
                                />
                                <p className='error text-destructive-color'>
                                    {errors.password &&
                                        touched.password &&
                                        errors.password}
                                </p>
                                <div className='text-[12px] leading-[10px] text-secondary-color text-left align-middle mb-[20px]'>
                                    <input
                                        type='checkbox'
                                        id='rememberMe'
                                        value={rememberMe}
                                        checked={rememberMe}
                                        onChange={handleRememberMeChange}
                                        className='mr-2.5'
                                    />
                                    <label htmlFor='rememberMe'>
                                        {t('loginCommon.loginRememberMe')}
                                    </label>
                                </div>

                                {isSubmitting ? (
                                    <ButtonLoading className='w-full h-10 mt-5 cursor-pointer !rounded-2xl uppercase text-xs md:text-sm' />
                                ) : (
                                    <Button
                                        color='primary'
                                        className='w-full h-10 mt-5 cursor-pointer !rounded-2xl uppercase text-xs md:text-sm'
                                        type='submit'
                                    >
                                        {t('loginCommon.loginButton')}
                                    </Button>
                                )}
                            </form>
                        </div>
                    </div>
                )}
            </Formik>

            <ButtonLoginWithDemoAccount
                className='w-full h-10 mt-5 cursor-pointer !rounded-2xl uppercase text-xs md:text-sm'
                onClick={handleSubmit}
            >
                {t('loginCommon.loginDemoButton')}
            </ButtonLoginWithDemoAccount>
        </div>
    );
};

export default LoginForm;
