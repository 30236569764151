import React from 'react';
import { Route } from 'react-router-dom';
import CkRoutes from 'ck_commun/src/app/modUtils/routes/CkRoutes';
import SitesMapPage from 'ck_commun/src/app/modEquipment/pages/sitesMapPage/SitesMapPage';
import SitesAndEquipmentPage from 'ck_commun/src/app/modEquipment/pages/sitesAndEquipmentPage/SitesAndEquipmentPage';
import EquipmentsShared from 'ck_commun/src/app/modSharedEquipment/pages/EquipmentsShared';

export default function EquipmentRoutes() {
    return (
        <CkRoutes>
            <Route
                path='sites-equipments'
                element={<SitesAndEquipmentPage />}
            />
            <Route path='sites-map' element={<SitesMapPage />} />
            <Route path='equipments-shared' element={<EquipmentsShared />} />
        </CkRoutes>
    );
}
