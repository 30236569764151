import React, { useState } from 'react';
import moment from 'moment';
import { Input } from 'antd';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import { FaCalendarAlt } from 'react-icons/fa';
import InputBaseForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/InputBaseForm';

export default function InputDateForm({
    form,
    name = 'date',
    messages = {},
    className = '',
    formatDate = 'DD/MM/YYYY',
    futureOnly = false,
    Datedefault = moment(),
    markerRequired = true,
}) {
    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = useState(
        Datedefault && moment(Datedefault),
    );
    const [showCalendar, setShowCalendar] = useState(false);

    const mergedMessages = {
        label: 'inputDateForm.label',
        help: 'inputDateForm.help',
        required: 'inputDateForm.required',
        invalid: 'inputDateForm.invalid',
        ...messages,
    };

    const toggleCalendar = () => {
        setShowCalendar((prevState) => !prevState);
    };

    const handleDateChange = (date) => {
        const momentDate = moment(date);
        setSelectedDate(momentDate);
        // Mettre à jour la valeur dans le formulaire et forcer la validation
        form.setFieldsValue({ [name]: momentDate });
        // Validation du champ après sélection
        form.validateFields([name]);
        setShowCalendar(false);
    };

    return (
        <InputBaseForm
            name={name}
            messages={mergedMessages}
            className={className}
            markerRequired={markerRequired}
            rules={[
                {
                    required: markerRequired,
                    message: t(mergedMessages.required),
                },
                futureOnly && {
                    validator: (_, value) =>
                        value && moment(value).isBefore(moment(), 'day')
                            ? Promise.reject(
                                  new Error(t(mergedMessages.invalid)),
                              )
                            : Promise.resolve(),
                },
            ].filter(Boolean)}
        >
            <Input
                onClick={toggleCalendar}
                type='text'
                value={
                    selectedDate !== null &&
                    moment(selectedDate, formatDate, true).isValid()
                        ? selectedDate.format(formatDate)
                        : null
                }
                placeholder='Sélectionner une date'
                className='!text-input-color !rounded-md !w-[180px] !bg-transparent !focus:outline-none !cursor-pointer'
                readOnly
                suffix={
                    <FaCalendarAlt
                        className='text-input-color cursor-pointer'
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleCalendar();
                        }}
                    />
                }
            />

            {/* Affichage du calendrier */}
            {showCalendar && (
                <div
                    className={`absolute top-12 left-0 w-[250px] shadow-md z-[999] border border-solid border-input-color rounded-md p-2 bg-card-color`}
                >
                    <Calendar
                        value={selectedDate?.toDate()}
                        onChange={handleDateChange}
                        minDate={futureOnly ? new Date() : undefined}
                    />
                </div>
            )}

            <style jsx>{`
                .ant-input-affix-wrapper-readonly .ant-input {
                    cursor: pointer !important;
                }
                .react-calendar {
                    background-color: var(--card-color) !important;
                    border: none !important;
                }

                .react-calendar__tile {
                    background-color: var(--background-color) !important;
                }

                /* Ne modifie que les jours du mois en cours */
                .react-calendar__month-view__days__day:not(
                        .react-calendar__month-view__days__day--neighboringMonth
                    ) {
                    color: var(--card-foreground-color) !important;
                }

                /* Laisse les jours en dehors du mois tels quels */
                .react-calendar__month-view__days__day--neighboringMonth {
                    color: var(--muted-foreground-color);
                }

                /* Date sélectionnée */
                .react-calendar__tile--active {
                    background-color: var(--primary-color) !important;
                }

                .react-calendar__tile--active.react-calendar__month-view__days__day {
                    color: var(--primary-foreground-color) !important;
                }

                /* Hover */
                .react-calendar__tile:hover {
                    background-color: var(--secondary-color) !important;
                }
            `}</style>
        </InputBaseForm>
    );
}
