import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { httpGet, httpPost, httpDelete } from '../../modApi/apis/httpConfig';

//Permet de visualiser le crédit d'un équipement
export const get_credit_by_equipment = createAsyncThunk(
    'mod_fixing/get_credit_by_equipment',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_fixing/groups/${values.groupId}/sites/${values.siteId}/equipments/${values.equipmentId}/credit`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

//Permet de créditer un équipement
export const create_credit_by_equipment = createAsyncThunk(
    'mod_fixing/create_credit_by_equipment',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPost(
                `mod_fixing/groups/${values.groupId}/sites/${values.siteId}/equipments/${values.equipmentId}/credit`,
                { value: values.value },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

//Permet de supprimer le crédit d'un équipement
export const delete_credit_by_equipment = createAsyncThunk(
    'mod_fixing/delete_credit_by_equipment',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpDelete(
                `mod_fixing/groups/${values.groupId}/sites/${values.siteId}/equipments/${values.equipmentId}/credit`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

const fixingSlice = createSlice({
    name: 'fixing',
    initialState: {
        equipmentCredit: 0,
        loading: true,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(get_credit_by_equipment.fulfilled, (state, action) => {
                state.loading = false;
                state.equipmentCredit = action.payload.credit_current;
            })
            .addCase(get_credit_by_equipment.rejected, (state) => {
                state.loading = false;
            })
            .addCase(create_credit_by_equipment.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(create_credit_by_equipment.rejected, (state) => {
                state.loading = false;
            })
            .addCase(delete_credit_by_equipment.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(delete_credit_by_equipment.rejected, (state) => {
                state.loading = false;
            });
    },
});

export default fixingSlice.reducer;
