import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    Input,
    Spinner,
} from '../../modUtils/components/componentsLibrary/componentsLibrary';
import { dateFormat } from '../../modUtils/functions/values/dateFormat';
import { get_action_detail_by_support_by_group } from '../slices/walletLocalSlice';
import { amountFormatWithCurrencySymbol } from '../../modUtils/functions/values/numberFormat';
import { message } from 'antd';
import AlertMessage from '../../modUtils/components/AlertMessage';
import i18next from 'i18next';

var modalStyle = {
    modalContent: {
        minHeight: '50%',
        minWidth: '400px',
        width: '40%',
    },
};

export default function SupportActionDetailsModal({
    supportId,
    actionId,
    modalVisible,
    setModalVisible,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { supportActionDetails } = useSelector((state) => state.walletLocal);
    const [loading, setLoading] = useState(true);
    const { groupId } = useSelector((state) => state.manager);

    const getActionDetail = async () => {
        setLoading(true);
        try {
            await dispatch(
                get_action_detail_by_support_by_group({
                    groupId,
                    supportId: supportId,
                    actionId: actionId,
                }),
            ).unwrap();
            setLoading(false);
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('supportActionDetailsModal.error')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
            setLoading(false);
            setModalVisible(false);
        }
    };

    useEffect(() => {
        if (supportId && actionId) {
            getActionDetail();
        }
    }, [supportId, actionId, groupId, i18next.language]);

    return (
        <>
            <Modal
                // title={t("badge.badgeCreditModalTitle")}
                title={t('supportActionDetailsModal.title')}
                isOpen={modalVisible}
                onClose={() => setModalVisible(false)}
                style={modalStyle}
            >
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <Input
                            label='Type'
                            value={supportActionDetails?.type_label}
                            disabled
                        />
                        <Input
                            label={t('supportActionDetailsModal.status')}
                            value={supportActionDetails?.status_label}
                            disabled
                        />
                        <Input
                            label={t('supportActionDetailsModal.amount')}
                            value={amountFormatWithCurrencySymbol(
                                supportActionDetails?.amount,
                            )}
                            disabled
                        />
                        <Input
                            label={t('supportActionDetailsModal.requestDate')}
                            value={dateFormat(
                                supportActionDetails?.request_date,
                            )}
                            disabled
                        />
                        {supportActionDetails?.status == 2 && (
                            <>
                                <Input
                                    label={t(
                                        'supportActionDetailsModal.equipment',
                                    )}
                                    value={
                                        supportActionDetails?.executed_equipment
                                    }
                                    disabled
                                />
                                <Input
                                    label={t(
                                        'supportActionDetailsModal.executedDate',
                                    )}
                                    value={dateFormat(
                                        supportActionDetails?.executed_date,
                                    )}
                                    disabled
                                />
                            </>
                        )}
                        <Input
                            label={t('supportActionDetailsModal.origin')}
                            value={supportActionDetails?.origin_label}
                            disabled
                        />

                        {/* 
        //*TODO récupérer name user
        <Input
          label="Créée par"
          value={supportActionDetails?.request_user}
          disabled 
        />*/}
                    </>
                )}
            </Modal>
        </>
    );
}
