import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { httpGet, httpPost, httpPut } from '../../modApi/apis/httpConfig';

// récupérer les groupes de l'utilisateur connecté à partir du token
export const get_groups_paginated = createAsyncThunk(
    'mod_manager/get_groups_paginated',

    async (values) => {
        const response = await httpGet(`/mod_manager/groups/`, {
            params: {
                limit: values.limit,
                offset: values.offset,
                name: values.name,
            },
        });

        return response.data;
    },
);
export const get_group_detail = createAsyncThunk(
    'mod_manager/get_group_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `/mod_manager/groups/${values.groupId}/`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

//récupérer les sites d'un groupe
export const get_sites_by_group_paginated = createAsyncThunk(
    'mod_manager/get_sites_by_group_paginated',

    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `/mod_manager/groups/${values.groupId}/sites/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                        name: values.name,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// modifier un site
export const update_site_detail_by_group = createAsyncThunk(
    'mod_manager/update_site_detail_by_group',
    async (values) => {
        const response = await httpPut(
            `mod_manager/groups/${values.groupId}/sites/${values.siteId}`,
            {
                name: values.name,
                description: values.description,
                location_number: values.locationNumber,
                location_street: values.locationStreet,
                location_additional: values.locationAdditional,
                location_city: values.locationCity,
            },
        );
        return response.data;
    },
);

export const saveSelectedGroup = createAsyncThunk(
    'user/saveSelectedGroup',
    async (group) => {
        if (!group || isNaN(group.id)) {
            localStorage.removeItem('group');
            return null;
        }

        return group;
    },
);

//get_permissions_by_group_paginated : Retourne la liste des permissions utilisables par l'exploitant et qu'il peut donner à ses employés
export const get_permissions_by_group_paginated = createAsyncThunk(
    'mod_manager/get_permissions_by_group_paginated',
    async (values) => {
        const response = await httpGet(
            `/mod_manager/groups/${values.groupId}/permissions/`,
            { params: { limit: 100, offset: 0 } },
        );

        return response.data.results;
    },
);

//Retourne la liste des abonnements possible en sélection pour un groupe
export const get_plans_by_group_paginated = createAsyncThunk(
    'mod_manager/get_plans_by_group_paginated',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `/mod_manager/groups/${values.groupId}/plans/`,
                { params: { limit: 100, offset: 0 } },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// modifier un groupe
export const update_group_detail = createAsyncThunk(
    'mod_manager/update_group_detail',
    async (values) => {
        const response = await httpPut(
            `mod_manager/groups/${values.groupId}/`,
            { name: values.name, site_default: values.siteDefault },
        );
        return response.data;
    },
);

// récupérer la liste des employés liés à un group
export const get_employees_by_group_paginated = createAsyncThunk(
    'mod_manager/get_employees_by_group_paginated',
    async (values) => {
        const response = await httpGet(
            `/mod_manager/groups/${values.groupId}/employees/`,
            { params: { limit: values.limit, offset: values.offset } },
        );
        return response.data.results;
    },
);

//create employee
export const create_employees_by_group = createAsyncThunk(
    'mod_manager/create_employees_by_group',
    async (values, { rejectWithValue }) => {
        try {
            const params = {
                email: values.email,
                is_admin: values.isAdmin,
                permissions: values.permissions,
            };
            const response = await httpPost(
                `/mod_manager/groups/${values.groupId}/employees/`,
                params,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);
//update employee details
export const update_employee_detail_by_group = createAsyncThunk(
    'mod_manager/employee_detail_by_group_update',
    async (values, { rejectWithValue }) => {
        try {
            const params = {
                is_admin: values.isAdmin,
                permissions: values.permissions,
            };
            const response = await httpPut(
                `/mod_manager/groups/${values.groupId}/employees/${values.employeeId}/`,
                params,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

const managerSlice = createSlice({
    name: 'manager',
    initialState: {
        loadingGroup: true,
        loading: true,
        groups: [],
        groupId: null,
        groupsCount: 0,
        sites: [],
        selectedGroup: {},
        siteDefault: {},
        groupPermissions: [],
        groupPlans: [],
        groupEmployees: [],
        groupEmployeesCount: 0,
        groupDetail: {},
    },
    extraReducers: (builder) => {
        builder
            .addCase(get_groups_paginated.fulfilled, (state, action) => {
                state.loading = false;
                state.groups = action.payload.results;
                state.groupsCount = action.payload.count;
            })
            .addCase(get_groups_paginated.rejected, (state, action) => {
                state.loadingGroup = false;
            })
            .addCase(saveSelectedGroup.fulfilled, (state, action) => {
                state.selectedGroup = action.payload;
                if (state.selectedGroup) {
                    localStorage.setItem(
                        'group',
                        parseInt(state.selectedGroup.id),
                    );
                    localStorage.setItem(
                        'currencySymbol',
                        state.selectedGroup.currency_used_symbol,
                    );
                    state.groupId = state.selectedGroup.id;
                }

                state.loadingGroup = false;
            })
            //get_group_detail
            .addCase(get_group_detail.fulfilled, (state, action) => {
                state.loading = false;
                state.groupDetail = action.payload;
            })
            .addCase(get_group_detail.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(
                get_sites_by_group_paginated.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.sites = action.payload.results;
                },
            )
            .addCase(get_sites_by_group_paginated.rejected, (state, action) => {
                state.loading = false;
                state.sites = [];
            })
            /* update Site */
            .addCase(update_site_detail_by_group.fulfilled, (state, action) => {
                const index = state.sites.findIndex(
                    (site) => site.id === action.payload.id,
                );
                state.sites[index] = action.payload;
                state.loading = false;
            })
            .addCase(update_site_detail_by_group.rejected, (state, action) => {
                state.loading = false;
            })
            /*get_permissions_by_group_paginated*/
            .addCase(
                get_permissions_by_group_paginated.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.groupPermissions = action.payload;
                },
            )
            .addCase(
                get_permissions_by_group_paginated.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )
            /*get_plans_by_group_paginated*/
            .addCase(
                get_plans_by_group_paginated.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.groupPlans = action.payload.results;
                },
            )
            .addCase(get_plans_by_group_paginated.rejected, (state, action) => {
                state.loading = false;
            })
            /*update_group_detail*/
            .addCase(update_group_detail.fulfilled, (state, action) => {
                const index = state.groups.findIndex(
                    (site) => site.id === action.payload.id,
                );
                state.groups[index] = action.payload;
                state.loading = false;
            })
            .addCase(update_group_detail.rejected, (state, action) => {
                state.loading = false;
            })
            /*get_employees_by_group_paginated*/
            .addCase(
                get_employees_by_group_paginated.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.groupEmployees = action.payload;
                    state.groupEmployeesCount = action.payload.count;
                },
            )
            .addCase(
                get_employees_by_group_paginated.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )
            //create employee
            .addCase(create_employees_by_group.fulfilled, (state, action) => {
                state.loading = false;
                state.groupEmployees = [
                    ...state.groupEmployees,
                    { ...action.payload },
                ];
            })
            .addCase(create_employees_by_group.rejected, (state, action) => {
                state.loading = false;
            })
            //update employee
            .addCase(
                update_employee_detail_by_group.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.groupEmployees = [
                        ...state.groupEmployees,
                        { ...action.payload },
                    ];
                },
            )
            .addCase(
                update_employee_detail_by_group.rejected,
                (state, action) => {
                    state.loading = false;
                },
            );
    },
});

export default managerSlice.reducer;
