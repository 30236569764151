import React from 'react';
import { Route } from 'react-router-dom';
import CkRoutes from './CkRoutes';
import ProductsPage from '../../modBox/pages/ProductsPage';

export default function LockerRoutes() {
    return (
        <CkRoutes>
            <Route path='products' element={<ProductsPage />} />
        </CkRoutes>
    );
}
