const URL_DEV =  'https://dev.cklaundry.app/';
const URL_PROD = 'https://cklaundry.app/';

const SRC_IMG = process.env.PUBLIC_URL + '/img/menuIcons/';
const APP_LOGO = process.env.PUBLIC_URL + '/laundryLogoSquare.png';
const APP_NAME = 'Laundry Manager';
const APP_SHORT_NAME = 'Laundry';

const APP_NAME_CODE = 'LAUNDRY_MANAGER';
const CK_SERVER_DEV_PASSWORD = 'X04mAKksgZkdjg4dJZB6jpJD4s2K9HL9';
const CK_SERVER_PROD_PASSWORD = 'aPtvhYoC5d6g5aO6fWDg5aXU05m3QRKb';

const FOOTER_LOGINPAGE_VISIBLE = false;

const CONTACT_PHONE_NUMBER = '0 473 289 246';
const HOMEPAGE_ROUTE = '/home';

const URL_MOBILE_APPLE =
    'https://apps.apple.com/tn/app/laundry-manager/id6471597507';
const URL_MOBILE_ANDROID =
    'https://play.google.com/store/apps/details?id=fr.cksquare.laundry_manager';

export {
    APP_NAME,
    APP_NAME_CODE,
    APP_LOGO,
    APP_SHORT_NAME,
    CK_SERVER_DEV_PASSWORD,
    CK_SERVER_PROD_PASSWORD,
    CONTACT_PHONE_NUMBER,
    FOOTER_LOGINPAGE_VISIBLE,
    HOMEPAGE_ROUTE,
    URL_DEV,
    URL_PROD,
    SRC_IMG,
    URL_MOBILE_APPLE,
    URL_MOBILE_ANDROID,
};
