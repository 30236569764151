import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { hasPermission } from '../../modUtils/functions/permissions/handlePermissions';
import { Input } from '../../modUtils/components/componentsLibrary/componentsLibrary';
import PermissionDeniedPage from '../../modUtils/components/errorPage/PermissionDeniedPage';
import PermissionFadeContainer from '../../modUtils/components/permissionFadeContainer/PermissionFadeContainer';

export default function SupportTransactionDetails({ payment }) {
    const { t } = useTranslation();
    const { saleDetails } = useSelector((state) => state.sale);
    const { selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission(
        'mod_wallet_local:manage_support',
        permissions,
    );
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission(
        'mod_wallet_local:manage_support',
        groupPermissions,
    );

    const dataPayment = [
        {
            label: 'supportTransactionDetails.badgeName',
            value: saleDetails?.identification?.label,
        },
        {
            label: 'supportTransactionDetails.creditStart',
            value: payment?.account_balance_before,
        },
        {
            label: 'supportTransactionDetails.transactionAmount',
            value: payment?.amount,
        },
        { isDivider: true },
        {
            label: 'supportTransactionDetails.endTransaction',
            value: payment?.account_balance,
        },
    ];

    return (
        <div className='p-5 w-1/2'>
            {!verifyPermission ? (
                <PermissionDeniedPage
                    direction='row'
                    permission={verifyGroupPermission}
                />
            ) : (
                <PermissionFadeContainer permission={verifyGroupPermission}>
                    {dataPayment.map((item, index) =>
                        item.isDivider ? (
                            <hr />
                        ) : (
                            <div
                                key={index}
                                className='flex flex-row justify-between'
                            >
                                <span>{t(item.label)}</span>
                                <Input
                                    size={30}
                                    width='140px'
                                    disabled
                                    value={item.value}
                                />
                            </div>
                        ),
                    )}
                </PermissionFadeContainer>
            )}
        </div>
    );
}
