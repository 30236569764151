import { useTranslation } from 'react-i18next';
import { Modal } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import Button from '../../../modUtils/components/buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
    close_promotion_support,
    get_promotion_support_detail,
} from '../../slices/promotionSlice';
import AlertMessage from '../../../modUtils/components/AlertMessage';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';

const ModalCloseTicket = ({
    toggleModalCloseTicket,
    modalCloseTicket,
    supportId,
    promotionId,
    groupId,
    getSupports,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { supportDetail } = useSelector((state) => state.promotion);
    const [isAware, setIsAware] = useState(false);
    const [hideIcon, setHideIcon] = useState(false); // ajouter un state pour cacher  l'icone parce qu'elle s'affiche tjrs

    useEffect(() => {
        if (!supportDetail)
            dispatch(
                get_promotion_support_detail({
                    groupId,
                    promotionId,
                    supportId,
                }),
            );
    }, [supportDetail]);
    const closeTicket = async () => {
        setHideIcon(true);
        if (!isAware) {
            return;
        }
        try {
            const response = await dispatch(
                close_promotion_support({
                    groupId,
                    promotionId,
                    supportId,
                }),
            ).unwrap();
            if (response) {
                message.success({
                    content: (
                        <AlertMessage
                            status={'success'}
                            alertMessage={t('success.transSuccessCloseSupport')}
                        />
                    ),
                });
                getSupports();
                toggleModalCloseTicket();
            }
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={'error'}
                        alertMessage={t('errors.transErrorCloseSupport')}
                        errorDetail={error?.data.message}
                    />
                ),
            });
        }
    };

    return (
        <Modal
            isOpen={modalCloseTicket}
            onClose={toggleModalCloseTicket}
            title={t('modalViewTickets.modalCloseTicket.transTitle')}
        >
            <div className='text-center'>
                <p className='text-sm'>
                    {t(
                        'modalViewTickets.modalCloseTicket.transCloseTicketConfirmMessage',
                    )}
                </p>
                <p className='text-sm font-semibold'>
                    N°: {supportDetail?.support_number}
                </p>
                <p className='text-sm font-semibold'>
                    {supportDetail?.promotion.name}
                </p>
                <div className='flex flex-col items-center justify-center'>
                    <div className='flex flex-row'>
                        <input
                            type='checkbox'
                            id='aware'
                            className='mr-2'
                            onChange={() => setIsAware(true)}
                        />
                        <label>
                            {t(
                                'modalViewTickets.modalCloseTicket.transIsAware',
                            )}
                        </label>
                    </div>

                    {!isAware && hideIcon && (
                        <div className='flex flex-row'>
                            <FaExclamationCircle className='w-4' fill='red' />
                            <span className='text-red-500 text-xs '>
                                {t(
                                    'modalViewTickets.modalCloseTicket.transIsAwareRequired',
                                )}
                            </span>
                        </div>
                    )}
                </div>
                <Button
                    color={'success'}
                    onClick={closeTicket}
                    className='mt-2'
                >
                    {t('modalViewTickets.transCloseCode')}
                </Button>
            </div>
        </Modal>
    );
};

export default ModalCloseTicket;
