import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Spinner,
    Button,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { imachines_gb_machine_kinds_by_equipment_by_site_by_group_get } from '../../slices/machineSlice';
import MachineCard from '../machineCard/MachineCard';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import NoData from '../../../modUtils/components/noData/NoData';
import CKToolTip from '../../../modUtils/components/CKToolTip/CKToolTip';

export default function MachineList({
    siteId,
    equipment,
    setSelectedMachine,
    openModalMachineAction,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const { groupId, loadingGroup } = useSelector((state) => state.manager);
    const { machines } = useSelector((state) => state.machine);

    async function fetchData() {
        setLoading(true);
        await dispatch(
            imachines_gb_machine_kinds_by_equipment_by_site_by_group_get({
                groupId: groupId,
                equipmentId: equipment.id,
                siteId: siteId,
            }),
        );
        setLoading(false);
    }

    useEffect(() => {
        if (groupId || equipment.id) {
            fetchData();
        }
    }, [groupId, equipment.id, siteId, i18next.language]);

    return (
        <div className='font-bold text-base text-white text-center mt-4'>
            {loading ? (
                <Spinner />
            ) : machines[0] !== undefined && machines[0].length > 0 ? (
                <div className='flex flex-wrap justify-center bg-slate-200'>
                    <Button type='image' className='absolute right-10 top-1.5'>
                        {/* btn refresh */}
                        <img
                            src={
                                process.env.PUBLIC_URL + '/img/refreshIcon.png'
                            }
                            alt='refresh'
                            onClick={() => fetchData()}
                        />
                    </Button>

                    <MachineCard
                        machines={machines}
                        equipment={equipment}
                        setSelectedMachine={setSelectedMachine}
                        openModalMachineAction={openModalMachineAction}
                    />
                </div>
            ) : (
                <NoData
                    text={
                        <>
                            {t('machineAction.noEngine')}
                            <CKToolTip
                                placement='topLeft'
                                content={t('machineAction.checkConnectivity')}
                                trigger='hover'
                            >
                                <span className='cursor-help'> ⓘ</span>
                            </CKToolTip>
                        </>
                    }
                />
            )}
        </div>
    );
}
