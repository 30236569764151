import { Checkbox } from 'antd';
import './Checkbox.scss';

const CheckboxInput = ({
    error,
    onChange,
    name,
    id,
    value,
    label,
    description,
    checked,
}) => {
    return (
        <div className='flex-display align-items-baseline'>
            <Checkbox
                id={id}
                checked={checked}
                value={value}
                name={name}
                onChange={onChange}
                style={{ marginRight: '10px' }}
                className={error ? 'error-input' : ''}
            />
            <label htmlFor={id} className='text-sm font-medium'>
                {label}
                <br />
                {description && (
                    <p className='description-label font-normal text-xs italic'>
                        {description}
                    </p>
                )}
            </label>
        </div>
    );
};

export default CheckboxInput;
