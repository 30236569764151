export const ePromotionTypes = {
    0: "createPromotionModal.stepperCreatePromotion.transGenerateCodePromo",
    1: "createPromotionModal.stepperCreatePromotion.transAppliMobCodePromo"
}

export const ePromotionStatus = {
    0: "modalViewTickets.filterSelectOptions.transInStock",
    1: "modalViewTickets.filterSelectOptions.transDistributed",
    2: "modalViewTickets.filterSelectOptions.transInUse",
    3: "modalViewTickets.filterSelectOptions.transClosed"
}