import React from 'react';
import { useTranslation } from 'react-i18next';
import MarkerRequired from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/MarkerRequired';

function FormLegend({ legend, requiredFormItemLegend }) {
    const { t } = useTranslation();
    return (
        <div className='flex flex-col items-start align-self-start text-xs text-black'>
            {requiredFormItemLegend && (
                <div>
                    <MarkerRequired />
                    {t('FormLegend.requiredField')}
                </div>
            )}
            <div>{legend}</div>
        </div>
    );
}

export default FormLegend;
