import React from 'react';
import { Route } from 'react-router-dom';
import CkRoutes from 'ck_commun/src/app/modUtils/routes/CkRoutes';
import VoucherPage from 'ck_commun/src/app/modSupportPrepayment/pages/VoucherPage';
import BuildingPage from 'ck_commun/src/app/modUtils/components/errorPage/BuildingPage';
import PromotionsPage from 'ck_commun/src/app/modPromotion/pages/promotionsPage/PromotionsPage';

export default function PromoRoutes() {
    return (
        <CkRoutes>
            <Route path='dashboard' element={<BuildingPage />} />
            <Route path='promotions' element={<PromotionsPage />} />
            <Route path='voucher' element={<VoucherPage />} />
        </CkRoutes>
    );
}
