import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AlertMessage from '../../modUtils/components/AlertMessage';
import ButtonAdd from '../../modUtils/components/buttons/ButtonAdd';
import AddPeriodRangeConstraintModal from './AddPeriodRangeConstraintModal';
import { ReactComponent as DeleteIcon } from '../../modEntity/assets/images/trash-can.svg';
import {
    Spinner,
    Table,
} from '../../modUtils/components/componentsLibrary/componentsLibrary';
import {
    get_constraint_period_paginated,
    delete_constraint_period_detail,
} from '../slices/constraintPeriodSlice';

export default function PeriodConstraints({
    configuration,
    selectedConfigId,
    isConstraintActive,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const { groupId } = useSelector((state) => state.manager);
    const [periodRangesId, setPeriodRangesId] = useState(null);
    const [isAddPeriodModalVisible, setIsAddPeriodModalVisible] =
        useState(false);
    const { constraintsPeriod } = useSelector(
        (state) => state.profil.constraintPeriod,
    );

    // Récupérer les contraintes de temps
    const getConstraintPeriod = async () => {
        setLoading(true);
        await dispatch(
            get_constraint_period_paginated({
                groupId,
                configurationId: selectedConfigId,
                limit: 100,
                offset: 0,
            }),
        ).unwrap();
        setLoading(false);
    };

    // Supprimer une contrainte de période
    const deleteConstraintPeriod = async (constraintId) => {
        await dispatch(
            delete_constraint_period_detail({
                groupId,
                configurationId: selectedConfigId,
                constraintId,
            }),
        ).unwrap();
    };

    useEffect(() => {
        groupId && selectedConfigId && getConstraintPeriod();
    }, [groupId, selectedConfigId]);

    const handleClickUpdate = (id) => {
        setIsEdit(true);
        setPeriodRangesId(id);
        setIsAddPeriodModalVisible(true);
    };

    const handleClickCreate = () => {
        setIsEdit(false);
        setIsAddPeriodModalVisible(true);
    };

    const columnsPeriod = [
        {
            title: 'scheduleAndPeriodConstraints.startPeriod',
            dataIndex: 'day_start',
            key: 'day_start',
            width: '45%',
            render: (text, record) => (
                <div
                    className='w-full h-full flex flex-col items-center justify-center'
                    onClick={() => handleClickUpdate(record?.id)}
                >
                    {record?.period_ranges?.map((periodRange, index) => (
                        <>
                            {index > 0 && (
                                <hr className='w-[calc(100%+10px)] border-gray-50 -ml-[5px]' />
                            )}

                            {`${periodRange?.start_day} ${periodRange?.start_month}`}
                        </>
                    ))}
                </div>
            ),
        },
        {
            title: 'scheduleAndPeriodConstraints.endPeriod',
            dataIndex: 'day_end',
            key: 'day_end',
            width: '45%',
            render: (text, record) => (
                <div
                    className='w-full h-full flex flex-col items-center justify-center'
                    onClick={() => handleClickUpdate(record?.id)}
                >
                    {record?.period_ranges?.map((periodRange, index) => (
                        <>
                            {index > 0 && (
                                <hr className='w-[calc(100%+10px)] border-gray-50 -ml-[5px]' />
                            )}

                            {`${periodRange?.end_day} ${periodRange?.end_month}`}
                        </>
                    ))}
                </div>
            ),
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (text, record) => (
                <div
                    className='w-full h-full flex items-center justify-center'
                    onClick={() => handleClickUpdate(text)}
                >
                    <div
                        className='m-auto w-[15px]'
                        onClick={(e) => {
                            e.stopPropagation();
                            deleteConstraintPeriod(text);
                        }}
                    >
                        <DeleteIcon />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            {isConstraintActive && (
                <div className='flex flex-col justify-center items-center'>
                    <span className='font-black text-sm my-6'>
                        {t('scheduleAndPeriodConstraints.periodConstraints')}
                    </span>

                    {configuration?.is_future && (
                        <>
                            {constraintsPeriod.length === 0 && (
                                <ButtonAdd
                                    label={t(
                                        'scheduleAndPeriodConstraints.addPeriodConstraint',
                                    )}
                                    className='mb-4'
                                    onClick={() => handleClickCreate()}
                                />
                            )}

                            {isAddPeriodModalVisible && (
                                <AddPeriodRangeConstraintModal
                                    isModalVisible={isAddPeriodModalVisible}
                                    setIsModalVisible={
                                        setIsAddPeriodModalVisible
                                    }
                                    selectedConfigId={selectedConfigId}
                                    periodRangesId={periodRangesId}
                                    isEdit={isEdit}
                                />
                            )}
                        </>
                    )}

                    {loading ? (
                        <Spinner />
                    ) : (
                        <Table
                            columns={columnsPeriod}
                            dataSource={constraintsPeriod}
                        />
                    )}
                </div>
            )}
        </>
    );
}
