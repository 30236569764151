import { useEffect, useRef } from 'react';

export default function useScrollHandler(onScrollEnd) {
    const tableRef = useRef();
    const lastScrollTop = useRef(0); // Stocker la dernière position de défilement

    const handleScroll = () => {
        const tableElement = tableRef.current;
        const scrollTop = tableElement.scrollTop; // Position actuelle du défilement
        const scrollHeight = tableElement.scrollHeight; // Hauteur totale du contenu
        const clientHeight = tableElement.clientHeight; // Hauteur visible

        // Vérifier si on a atteint le bas du conteneur
        if (scrollTop + clientHeight >= scrollHeight - 10) {
            onScrollEnd();
        }

        lastScrollTop.current = scrollTop; // Mettre à jour la dernière position de défilement
    };

    useEffect(() => {
        const tableElement = tableRef.current;
        if (tableElement) {
            tableElement.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (tableElement) {
                tableElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return { tableRef, handleScroll };
}
