import React from 'react';
import { Route } from 'react-router-dom';
import CkRoutes from 'ck_commun/src/app/modUtils/routes/CkRoutes';
import SalesPage from 'ck_commun/src/app/modSale/pages/salesPage/SalesPage';
import PickupsPage from 'ck_commun/src/app/modStatement/pages/PickupsPage';
import TelecollectsPage from 'ck_commun/src/app/modBanking/pages/TelecollectsPage';
import BuildingPage from 'ck_commun/src/app/modUtils/components/errorPage/BuildingPage';

export default function HistoryRoutes() {
    return (
        <CkRoutes>
            <Route path='logs' element={<BuildingPage />} />
            <Route path='telecollects' element={<TelecollectsPage />} />
            <Route path='pickups' element={<PickupsPage />} />
            <Route path='sales' element={<SalesPage />} />
        </CkRoutes>
    );
}
