import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowRight } from 'react-icons/fa';

export default function ButtonSubmit({
    onClick,
    label,
    className = '',
    arrow = true,
}) {
    const { t } = useTranslation();
    return (
        <button
            htmltype='submit'
            onClick={onClick}
            className={`${className} flex justify-center uppercase items-center px-4 py-2 rounded shadow cursor-pointer
             font-bold ease-in-out border-0 text-white text-nowrap w-full sm:w-auto bg-accent-color hover:brightness-110`}
        >
            {label ? (
                label
            ) : (
                <>
                    <span className='mr-1'>
                        {t('common.transButtonExecute')}
                    </span>
                    {arrow ? <FaArrowRight /> : null}
                </>
            )}
        </button>
    );
}
