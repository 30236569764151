import { useTranslation } from 'react-i18next';
import {
    Input,
    Label,
    Select,
    Table,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { Col, Row } from 'antd';
import { useState } from 'react';
import DayCard from '../dayCard/DayCard';
import Button from '../../../modUtils/components/buttons/Button';
import { CgAddR } from 'react-icons/cg';
import { useSelector } from 'react-redux';
import { eEquipmentType } from '../../../../commun_global/enums';
import RangePicker from '../../../modUtils/components/componentsLibrary/datePicker/RangePicker';
import { columns } from '../../assets/js/utils';
import CheckboxInput from '../../../modUtils/components/componentsLibrary/checkbox/Checkbox';
import moment from 'moment';
import RadioGroup from '../../../modUtils/components/radioGroup/RadioGroup';
import RadioInput from '../../../modUtils/components/radio/RadioInput';
import TooltipLabel from '../../../modUtils/components/tooltipLabel/TooltipLabel';
import { renderError } from '../../../modUtils/functions/values/renderError';
import ButtonDisabled from '../../../modUtils/components/buttons/ButtonDisabled';
import { IoClose } from 'react-icons/io5';
import RepeaterTime from '../repeaterTime/RepeaterTime';

const ThirdStepperScreen = ({
    values,
    setFieldValue,
    errors,
    handleChange,
    days,
    initialValues,
    mode,
}) => {
    const { t } = useTranslation();
    const { sites } = useSelector((state) => state.manager);
    const { equipmentTypeGroup } = useSelector((state) => state.equipment);
    const [addNewContraint, setAddNewContraint] = useState(false);
    const [startDate, setStartDate] = useState(
        values.startDateOfferUsePromo
            ? moment.unix(initialValues.startDateOfferUsePromo).toDate()
            : null,
    );
    const [endDate, setEndDate] = useState(
        initialValues.endDateOfferUsePromo
            ? moment.unix(initialValues.endDateOfferUsePromo).toDate()
            : null,
    );
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [schedulesList, setSchedulesList] = useState([]);
    const [selectedDays, setSelectedDays] = useState({
        schedule_monday: false,
        schedule_tuesday: false,
        schedule_wednesday: false,
        schedule_thursday: false,
        schedule_friday: false,
        schedule_saturday: false,
        schedule_sunday: false,
    });
    const [timeRanges, setTimeRanges] = useState([
        {
            // Mettre un identifiant pour chaque time range pour assurer que chaque élement a un clé unique `key={range.id}` et pour eviter les erreurs et les conflits aussi pour mettre le même retour dans le mode update
            id: 0,
            start: moment('00:00', 'HH:mm'),
            end: moment('23:59', 'HH:mm'),
        },
    ]);

    const isUpdateMode = mode === 'update';

    const saveSchedule = () => {
        let updatedSchedulesList = [...schedulesList]; // Declare the updated list outside

        if (addNewContraint) {
            if (selectedSchedule) {
                // Editing an existing schedule
                const updatedSchedule = {
                    ...selectedSchedule,
                    ...selectedDays, // Update selected days
                    time_slots: timeRanges.map(({ start, end }) => ({
                        slot_hours_start: start ? start.format('HH:mm') : null,
                        slot_hours_end: end ? end.format('HH:mm') : null,
                    })), // Update time slots
                };

                updatedSchedulesList = updatedSchedulesList.map((schedule) =>
                    schedule === selectedSchedule ? updatedSchedule : schedule,
                );
            } else {
                // Creating a new schedule
                const newSchedule = {
                    ...selectedDays,
                    time_slots: timeRanges.map(({ start, end }) => ({
                        slot_hours_start: start ? start.format('HH:mm') : null,
                        slot_hours_end: end ? end.format('HH:mm') : null,
                    })),
                };

                updatedSchedulesList = [...updatedSchedulesList, newSchedule];
            }

            // Directly update `setSchedulesList` and `setFieldValue` with the updated list
            setSchedulesList(updatedSchedulesList);
            setFieldValue('constraintUsePromo', updatedSchedulesList);
        }

        // Reset states
        setSelectedDays({
            schedule_monday: false,
            schedule_tuesday: false,
            schedule_wednesday: false,
            schedule_thursday: false,
            schedule_friday: false,
            schedule_saturday: false,
            schedule_sunday: false,
        });
        setTimeRanges([
            {
                id: 0,
                start: moment('00:00', 'HH:mm'),
                end: moment('23:59', 'HH:mm'),
            },
        ]);
        setSelectedSchedule(null); // Reset selection
        setAddNewContraint((prevState) => !prevState);
    };

    const deleteSchedule = (scheduleToDelete) => {
        setSchedulesList((prev) =>
            prev.filter((schedule) => schedule !== scheduleToDelete),
        );
    };

    const toggleAllDays = (e) => {
        const isChecked = e.target.checked;

        setSelectedDays({
            schedule_monday: isChecked,
            schedule_tuesday: isChecked,
            schedule_wednesday: isChecked,
            schedule_thursday: isChecked,
            schedule_friday: isChecked,
            schedule_saturday: isChecked,
            schedule_sunday: isChecked,
        });
    };

    const toggleDaySelection = (day) => {
        setSelectedDays((prev) => ({
            ...prev,
            [day]: !prev[day], // Bascule entre true/false
        }));
    };

    const handleEditSchedule = (schedule) => {
        setSelectedSchedule(schedule);
        // Set selected days
        setSelectedDays({
            schedule_monday: schedule.schedule_monday,
            schedule_tuesday: schedule.schedule_tuesday,
            schedule_wednesday: schedule.schedule_wednesday,
            schedule_thursday: schedule.schedule_thursday,
            schedule_friday: schedule.schedule_friday,
            schedule_saturday: schedule.schedule_saturday,
            schedule_sunday: schedule.schedule_sunday,
        });

        // Set time slots
        setTimeRanges(
            schedule.time_slots.map((slot, index) => ({
                id: index,
                start: moment(slot.slot_hours_start, 'HH:mm'),
                end: moment(slot.slot_hours_end, 'HH:mm'),
            })),
        );
        setAddNewContraint(true);
    };

    const handleRangeChange = (start, end) => {
        // Fonction pour gérer le changement de plage de dates
        if (start) {
            setStartDate(start);
            setFieldValue('startDateOfferUsePromo', moment(start).unix());
        }
        if (end) {
            setEndDate(end);
            setFieldValue('endDateOfferUsePromo', moment(end).unix());
        }
    };

    const style = {
        td: {
            padding: 0,
        },
    };

    const onSelect = (e, name) => {
        const currentValues = values[name] || [];
        setFieldValue(name, [...currentValues, e]);
    };
    const onDeselect = (e, name) => {
        const newData = values[name].filter((site) => site !== e);
        setFieldValue(name, newData);
    };

    return (
        <div
            className={`ThirdStepperScreen ${isUpdateMode && 'bg-muted-color brightness-[0.8] cursor-not-allowed p-6 rounded'}`}
        >
            <Row
                className={`border border-[#4D4D4D42] border-solid py-2.5 px-6 sm:px-12 rounded-[5px] sm:w-4/5 w-full mb-4 mx-auto justify-between ${isUpdateMode ? 'is-update-mode' : ''}`}
            >
                <Col
                    lg={11}
                    md={24}
                    sm={24}
                    sx={24}
                    className={isUpdateMode ? 'is-update-mode' : ''}
                >
                    <TooltipLabel
                        label={t(
                            'createPromotionModal.stepperCreatePromotion.transOfferValidityUse',
                        )}
                        tooltipMessage={t(
                            'createPromotionModal.stepperCreatePromotion.transOfferValidityUseTooltipMessage',
                        )}
                    />
                    <RangePicker
                        format={'DD/MM/YYYY'}
                        className={`flex items-center gap-2 ${isUpdateMode ? 'is-update-mode' : ''}`}
                        color={'neutral'}
                        errorStartDate={renderError(
                            t,
                            errors,
                            'startDateOfferUsePromo',
                        )}
                        errorEndDate={renderError(
                            t,
                            errors,
                            'endDateOfferUsePromo',
                        )}
                        startLabel={t(
                            'createPromotionModal.stepperCreatePromotion.transFrom',
                        )}
                        endLabel={t(
                            'createPromotionModal.stepperCreatePromotion.transTo',
                        )}
                        onRangeChange={handleRangeChange}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        disabled={isUpdateMode}
                    />
                </Col>
                <div
                    className={`border border-[#4D4D4D42] border-solid ${isUpdateMode ? 'is-update-mode' : ''}`}
                ></div>
                <Col
                    lg={11}
                    md={24}
                    sm={24}
                    sx={24}
                    className={isUpdateMode ? 'is-update-mode' : ''}
                >
                    <div
                        className={`flex ${isUpdateMode ? 'is-update-mode' : ''}`}
                    >
                        <Label
                            label={t(
                                'createPromotionModal.stepperCreatePromotion.transDiscount',
                            )}
                        />
                        <TooltipLabel
                            tooltipMessage={t(
                                'createPromotionModal.stepperCreatePromotion.transDiscountTooltipMessage',
                            )}
                        />
                    </div>
                    <div
                        className={`flex gap-4 items-center ${isUpdateMode ? 'is-update-mode' : ''}`}
                    >
                        <div
                            className={`flex flex-col ${isUpdateMode ? 'is-update-mode' : ''}`}
                        >
                            <RadioGroup
                                name={'discountAmount'}
                                defaultValue={values.discountAmount}
                                onChange={handleChange}
                                disabled={isUpdateMode}
                            >
                                <RadioInput
                                    value={0}
                                    label={t(
                                        'createPromotionModal.stepperCreatePromotion.transAmount',
                                    )}
                                />
                                <RadioInput
                                    value={1}
                                    label={t(
                                        'createPromotionModal.stepperCreatePromotion.transDiscountPercent',
                                    )}
                                />
                            </RadioGroup>
                        </div>
                        <Input
                            label={
                                values.discountAmount
                                    ? t(
                                          'createPromotionModal.stepperCreatePromotion.transPercentIn',
                                      )
                                    : t(
                                          'createPromotionModal.stepperCreatePromotion.transAmountIn',
                                      ) + localStorage.getItem('currencySymbol')
                            }
                            name={'amountUsePromo'}
                            type={'number'}
                            min={0}
                            onChange={handleChange}
                            value={values.amountUsePromo}
                            error={renderError(t, errors, 'amountUsePromo')}
                            disabled={isUpdateMode}
                        />
                    </div>
                </Col>
            </Row>
            <Row
                className={`gap-2 w-full justify-between ${isUpdateMode ? 'is-update-mode' : ''}`}
            >
                <Col
                    lg={5}
                    md={24}
                    sm={24}
                    sx={24}
                    className={`border border-[#4D4D4D42] border-solid p-2 rounded-[5px] ${isUpdateMode ? 'is-update-mode' : ''}`}
                >
                    <div
                        className={`w-full checkbox-block ${isUpdateMode ? 'is-update-mode' : ''}`}
                    >
                        <Label
                            className={'label'}
                            label={t(
                                'createPromotionModal.stepperCreatePromotion.transPropriety',
                            )}
                        />
                        <div
                            className={`flex-display align-items-baseline gap-1 mb-1 ${isUpdateMode ? 'is-update-mode' : ''}`}
                        >
                            <CheckboxInput
                                errors={errors}
                                id='uniqueUseCode'
                                name='codeType'
                                checked={values.maximumPrintingUsePromo === '1'}
                                value={'uniqueUseCode'}
                                onChange={(e) =>
                                    setFieldValue(
                                        'maximumPrintingUsePromo',
                                        e.target.checked ? '1' : '',
                                    )
                                }
                                label={t(
                                    'createPromotionModal.stepperCreatePromotion.transUniqueUseCode',
                                )}
                                error={renderError(
                                    t,
                                    errors,
                                    'maximumPrintingUsePromo',
                                )}
                                disabled={isUpdateMode}
                            />
                            <TooltipLabel
                                tooltipMessage={t(
                                    'createPromotionModal.stepperCreatePromotion.transUniqueUseCodeDescription',
                                )}
                            />
                        </div>
                        <div
                            className={`flex-display align-items-baseline gap-1 ${isUpdateMode ? 'is-update-mode' : ''}`}
                        >
                            <CheckboxInput
                                id='usedManyTimeCode'
                                name='codeType'
                                checked={
                                    values.maximumPrintingUsePromo ===
                                    'illimited'
                                }
                                value={'illimited'}
                                onChange={(e) =>
                                    setFieldValue(
                                        'maximumPrintingUsePromo',
                                        e.target.checked ? 'illimited' : '',
                                    )
                                }
                                label={t(
                                    'createPromotionModal.stepperCreatePromotion.transUsedManyTimeCode',
                                )}
                                error={renderError(
                                    t,
                                    errors,
                                    'maximumPrintingUsePromo',
                                )}
                                disabled={isUpdateMode}
                            />
                            <TooltipLabel
                                tooltipMessage={t(
                                    'createPromotionModal.stepperCreatePromotion.transUsedManyTimeCodeDescription',
                                )}
                            />
                        </div>
                        <Input
                            label={t(
                                'createPromotionModal.stepperCreatePromotion.transMaxUse',
                            )}
                            placeholder={
                                values.maximumPrintingUsePromo === '1'
                                    ? `1 ${t('createPromotionModal.stepperCreatePromotion.transTimes')}`
                                    : values.maximumPrintingUsePromo ===
                                        'illimited'
                                      ? t(
                                            'createPromotionModal.stepperCreatePromotion.transUnlimited',
                                        )
                                      : `0 ${t(
                                            'createPromotionModal.stepperCreatePromotion.transTimes',
                                        )}`
                            }
                            disabled={
                                values.maximumPrintingUsePromo ===
                                    'illimited' ||
                                values.maximumPrintingUsePromo === '1' ||
                                isUpdateMode
                            }
                            value={values.maximumPrintingUsePromo}
                            id='maximumPrinting'
                            name={'maximumPrintingUsePromo'}
                            onChange={handleChange}
                            width={'100%'}
                            min={0}
                            type={'number'}
                            classnames={`h-12 w-full ${isUpdateMode ? 'is-update-mode' : ''}`}
                            error={renderError(
                                t,
                                errors,
                                'maximumPrintingUsePromo',
                            )}
                        />
                        <Label
                            label={`${t(
                                'createPromotionModal.stepperCreatePromotion.transDailyValidity',
                            )}*`}
                        />
                        <div
                            className={`flex gap-2 ${isUpdateMode ? 'is-update-mode' : ''}`}
                        >
                            <Input
                                label={t(
                                    'createPromotionModal.stepperCreatePromotion.transStarting',
                                )}
                                min={0}
                                type={'number'}
                                value={values.startDayValidityUsePromo}
                                onChange={handleChange}
                                name={'startDayValidityUsePromo'}
                                id={'startDayValidityUsePromo'}
                                disabled={isUpdateMode}
                                error={renderError(
                                    t,
                                    errors,
                                    'startDayValidityUsePromo',
                                )}
                            />
                            <Input
                                label={t(
                                    'createPromotionModal.stepperCreatePromotion.transUpTo',
                                )}
                                min={0}
                                type={'number'}
                                placeholder={'90'}
                                value={values.endDayValidityUsePromo}
                                onChange={handleChange}
                                name={'endDayValidityUsePromo'}
                                id={'endDayValidityUsePromo'}
                                disabled={isUpdateMode}
                                error={renderError(
                                    t,
                                    errors,
                                    'endDayValidityUsePromo',
                                )}
                            />
                        </div>
                        <p
                            className={`mb-0 ${isUpdateMode ? 'is-update-mode' : ''}`}
                        >
                            0 ={' '}
                            {t(
                                'createPromotionModal.stepperCreatePromotion.transDDay',
                            )}
                        </p>
                    </div>
                </Col>
                <Col
                    lg={5}
                    md={24}
                    sm={24}
                    sx={24}
                    className='border border-[#4D4D4D42] border-solid p-2 rounded-[5px]'
                >
                    <TooltipLabel
                        label={t(
                            'createPromotionModal.stepperCreatePromotion.transWhere',
                        )}
                        tooltipMessage={t(
                            'createPromotionModal.stepperCreatePromotion.transWhereUseToolitipMessage',
                        )}
                    />
                    <div>
                        <Select
                            onSelect={(value) =>
                                onSelect(value, 'sitesUsePromo')
                            }
                            onDeselect={(value) =>
                                onDeselect(value, 'sitesUsePromo')
                            }
                            multiple
                            disabled={isUpdateMode}
                            error={renderError(t, errors, 'sitesUsePromo')}
                            style={{ selectLabel: { color: '#25292D' } }}
                            selectLabel={t(
                                'createPromotionModal.stepperCreatePromotion.transSite',
                            )}
                            containerWidth={'full'}
                            dropdownMatchSelectWidth
                            msgDisplayedWhenNoOptions={t(
                                'createPromotionModal.stepperCreatePromotion.transNoData',
                            )}
                            defaultValue={values.sitesUsePromo}
                            block
                            name='sitesUsePromo'
                            placeholder={t(
                                'createPromotionModal.stepperCreatePromotion.transSelect',
                            )}
                            options={sites?.map((site) => ({
                                value: site.id,
                                label: site.name,
                            }))}
                            selectedMessage={
                                values.sitesUsePromo?.length
                                    ? `${values.sitesUsePromo?.length} ${t(
                                          'createPromotionModal.stepperCreatePromotion.transSelectedSites',
                                      )}`
                                    : t(
                                          'createPromotionModal.stepperCreatePromotion.transNoSitesSelected',
                                      )
                            }
                        />
                    </div>
                    <div className='mt-2'>
                        <Select
                            onSelect={(value) =>
                                onSelect(value, 'equipementsTypeUsePromo')
                            }
                            onDeselect={(value) =>
                                onDeselect(value, 'equipementsTypeUsePromo')
                            }
                            multiple
                            disabled={isUpdateMode}
                            style={{ selectLabel: { color: '#25292D' } }}
                            selectLabel={t(
                                'createPromotionModal.stepperCreatePromotion.transEquipmentType',
                            )}
                            containerWidth={'full'}
                            dropdownMatchSelectWidth
                            msgDisplayedWhenNoOptions={t(
                                'createPromotionModal.stepperCreatePromotion.transNoData',
                            )}
                            defaultValue={values.equipementsTypeUsePromo}
                            error={renderError(
                                t,
                                errors,
                                'equipementsTypeUsePromo',
                            )}
                            block
                            name='equipementType'
                            placeholder={t(
                                'createPromotionModal.stepperCreatePromotion.transSelect',
                            )}
                            options={equipmentTypeGroup?.map(
                                (equipmentType) => ({
                                    label: t(
                                        eEquipmentType[equipmentType.type],
                                    ),
                                    value: equipmentType.type,
                                }),
                            )}
                            selectedMessage={
                                values.equipementsTypeUsePromo?.length
                                    ? `${values.equipementsTypeUsePromo?.length} ${t(
                                          'createPromotionModal.stepperCreatePromotion.transSelectedEquipmentType',
                                      )}`
                                    : t(
                                          'createPromotionModal.stepperCreatePromotion.transNoEquipmentTypeSelected',
                                      )
                            }
                        />
                    </div>
                </Col>

                {/* TODO SCHEDULE  A VALIDER PLUS TARD */}
                <Col
                    lg={8}
                    md={24}
                    sm={24}
                    sx={24}
                    className='border border-[#4D4D4D42] border-solid rounded-[5px]'
                >
                    {!addNewContraint ? (
                        <div className='p-2'>
                            <TooltipLabel
                                label={t(
                                    'createPromotionModal.stepperCreatePromotion.transWhen',
                                )}
                                tooltipMessage={t(
                                    'createPromotionModal.stepperCreatePromotion.transWhenUseToolitipMessage',
                                )}
                            />
                            <Table
                                style={style}
                                columns={columns(
                                    t,
                                    handleEditSchedule,
                                    deleteSchedule,
                                    isUpdateMode,
                                )}
                                dataSource={values.constraintUsePromo || []}
                                noDataMessage={t(
                                    'createPromotionModal.stepperCreatePromotion.table.transNoSchedulesData',
                                )}
                            />
                            <div className='flex justify-center mt-4'>
                                {isUpdateMode ? (
                                    <ButtonDisabled
                                        className={
                                            'flex-display align-items-center gap-2 brightness-[0.9]'
                                        }
                                    >
                                        <CgAddR />{' '}
                                        {t(
                                            'createPromotionModal.stepperCreatePromotion.transAddContraint',
                                        )}
                                    </ButtonDisabled>
                                ) : (
                                    <Button
                                        color={'thirty'}
                                        onClick={() =>
                                            setAddNewContraint(
                                                (prevState) => !prevState,
                                            )
                                        }
                                        className={
                                            'font-bold text-xs w-auto shadow-none flex-display align-items-center gap-2'
                                        }
                                    >
                                        <CgAddR />{' '}
                                        {t(
                                            'createPromotionModal.stepperCreatePromotion.transAddContraint',
                                        )}
                                    </Button>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className='bg-white rounded'>
                            <div className='flex justify-end p-2'>
                                <IoClose
                                    onClick={() =>
                                        setAddNewContraint(
                                            (prevState) => !prevState,
                                        )
                                    }
                                    color='#1B8ADC'
                                    fontSize={20}
                                    className='cursor-pointer'
                                />
                            </div>
                            <div className='p-5'>
                                <div className='flex-display align-items-baseline'>
                                    <CheckboxInput
                                        errors={errors}
                                        id='everyDay'
                                        name='days'
                                        value={'everyDay'}
                                        checked={Object.values(
                                            selectedDays,
                                        ).every(Boolean)}
                                        onChange={toggleAllDays}
                                        label={t(
                                            'createPromotionModal.stepperCreatePromotion.transEveryDay',
                                        )}
                                    />
                                </div>
                                <div className='flex-display align-items-center justify-content-center my-4 gap-2'>
                                    {days?.map((day) => (
                                        <DayCard
                                            day={day}
                                            selectDay={() =>
                                                toggleDaySelection(day.value)
                                            }
                                            selected={selectedDays[day.value]}
                                        />
                                    ))}
                                </div>
                                <RepeaterTime
                                    startTimeLabel={t(
                                        'createPromotionModal.stepperCreatePromotion.transFromTime',
                                    )}
                                    endTimeLabel={t(
                                        'createPromotionModal.stepperCreatePromotion.transToTime',
                                    )}
                                    setTimeRanges={setTimeRanges}
                                    timeRanges={timeRanges}
                                />
                                <div className='flex items-center justify-center mt-4 gap-2'>
                                    {Object.values(selectedDays).some(
                                        (value) => value,
                                    ) ? (
                                        <Button
                                            color={'success'}
                                            onClick={saveSchedule}
                                            className={'font-bold text-xs'}
                                        >
                                            {t(
                                                'createPromotionModal.transValidateButton',
                                            )}
                                        </Button>
                                    ) : (
                                        <ButtonDisabled
                                            color={'success'}
                                            className={'font-bold text-xs'}
                                        >
                                            {t(
                                                'createPromotionModal.transValidateButton',
                                            )}
                                        </ButtonDisabled>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </Col>

                <Col
                    lg={5}
                    md={24}
                    sm={24}
                    sx={24}
                    className='border border-[#4D4D4D42] border-solid p-2 rounded-[5px]'
                >
                    <TooltipLabel
                        label={t(
                            'createPromotionModal.stepperCreatePromotion.transWhy',
                        )}
                        tooltipMessage={t(
                            'createPromotionModal.stepperCreatePromotion.transWhyUseToolitipMessage',
                        )}
                    />
                    <div className='flex gap-2'>
                        <RadioGroup
                            defaultValue={'amount'}
                            name={'amount'}
                            onChange={handleChange}
                            disabled={isUpdateMode}
                        >
                            <div className='flex'>
                                <RadioInput
                                    value={'amount'}
                                    label={t(
                                        'createPromotionModal.stepperCreatePromotion.transAmount',
                                    )}
                                />
                                <RadioInput
                                    value={'selection'}
                                    label={t(
                                        'createPromotionModal.stepperCreatePromotion.transSelection',
                                    )}
                                />
                            </div>
                        </RadioGroup>
                    </div>
                    <Label
                        label={t(
                            'createPromotionModal.stepperCreatePromotion.transForPurchase',
                        )}
                    />
                    <p className='text-sm'>
                        {values.amount === 'amount'
                            ? t(
                                  'createPromotionModal.stepperCreatePromotion.transAmountDescription',
                              ) + localStorage.getItem('currencySymbol')
                            : t(
                                  'createPromotionModal.stepperCreatePromotion.transSelectionDescription',
                              )}
                    </p>

                    {values.amount === 'amount' ? (
                        <div className='flex gap-2 '>
                            <Input
                                onChange={handleChange}
                                name='minUsePromo'
                                label={
                                    t(
                                        'createPromotionModal.stepperCreatePromotion.transMin',
                                    ) + localStorage.getItem('currencySymbol')
                                }
                                value={values.minUsePromo}
                                id='minUsePromo'
                                width={'100%'}
                                type='number'
                                min={0}
                                step='0.01'
                                placeholder='0,00'
                                error={renderError(t, errors, 'minUsePromo')}
                                disabled={isUpdateMode}
                            />
                            <Input
                                onChange={handleChange}
                                name='maxUsePromo'
                                label={
                                    t(
                                        'createPromotionModal.stepperCreatePromotion.transMax',
                                    ) + localStorage.getItem('currencySymbol')
                                }
                                value={values.maxUsePromo}
                                id='maxUsePromo'
                                width={'100%'}
                                type='number'
                                min={0}
                                step='0.01'
                                placeholder='0,00 €'
                                error={renderError(t, errors, 'maxUsePromo')}
                                disabled={isUpdateMode}
                            />
                        </div>
                    ) : (
                        <Input
                            label={
                                <TooltipLabel
                                    label={`${t('createPromotionModal.stepperCreatePromotion.transSelectionLabel')}`}
                                    tooltipMessage={t(
                                        'createPromotionModal.stepperCreatePromotion.transSelectionTooltipMessage',
                                    )}
                                />
                            }
                            placeholder={'1, 2, 3, 4, 5, 6,'}
                            value={values.idsUsePromo}
                            name={'idsUsePromo'}
                            id={'idsUsePromo'}
                            onChange={handleChange}
                            error={renderError(t, errors, 'idsUsePromo')}
                            disabled={isUpdateMode}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default ThirdStepperScreen;
