import React from 'react';
import { Checkbox, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import LabelForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/LabelForm';

export default function CheckboxForm({
    options = [],
    messages = {},
    className = '',
    markerRequired = false,
}) {
    const { t } = useTranslation();

    const mergedMessages = {
        label: 'checkboxForm.label', // todo a faire
        ...messages,
    };

    return (
        <div className={className}>
            <LabelForm
                messages={mergedMessages}
                markerRequired={markerRequired}
            />

            {options.map((option) => (
                <Form.Item
                    key={option.name}
                    name={option.name}
                    valuePropName='checked'
                    rules={option?.rules}
                    className='!m-0'
                >
                    <Checkbox>
                        <span className='text-primary-color font-bold'>
                            {t(option.label)}
                        </span>
                    </Checkbox>
                </Form.Item>
            ))}
        </div>
    );
}
