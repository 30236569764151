import { FaCheckCircle } from 'react-icons/fa';
import { ReactComponent as ProgressIcon } from '../../../modUtils/assets/images/progressIcon.svg';

export const steps = (t) => [
    {
        title: `${t('createPromotionModal.stepperCreatePromotion.transStep')} 1`,
        fields: ['promotionName', 'identifier', 'desccription', 'typePromo'],
        icon: <ProgressIcon />,
        successIcon: (
            <FaCheckCircle
                color='#0BD834'
                fontSize={'26px'}
                width={'26px'}
                height={'26px'}
                className='bg-[#fff] rounded-full'
            />
        ),
        waitingIcon: (
            <div className='w-[26px] h-[26px] bg-[#C6C6C6] rounded-full'></div>
        ),
    },
    {
        title: `${t('createPromotionModal.stepperCreatePromotion.transStep')} 2`,
        fields: ['promotionName', 'identifier', 'desccription', 'typePromo'],
        icon: <ProgressIcon />,
        successIcon: (
            <FaCheckCircle
                color='#0BD834'
                fontSize={'26px'}
                width={'26px'}
                height={'26px'}
                className='bg-[#fff] rounded-full'
            />
        ),
        waitingIcon: (
            <div className='w-[26px] h-[26px] bg-[#C6C6C6] rounded-full'></div>
        ),
    },
    {
        title: `${t('createPromotionModal.stepperCreatePromotion.transStep')} 3`,
        fields: ['promotionName', 'identifier', 'desccription', 'typePromo'],
        icon: <ProgressIcon />,
        successIcon: (
            <FaCheckCircle
                color='#0BD834'
                fontSize={'26px'}
                width={'26px'}
                height={'26px'}
                className='bg-[#fff] rounded-full'
            />
        ),
        waitingIcon: (
            <div className='w-[26px] h-[26px] bg-[#C6C6C6] rounded-full'></div>
        ),
    },
    {
        title: `${t('createPromotionModal.stepperCreatePromotion.transStep')} 4`,
        fields: ['promotionName', 'identifier', 'desccription', 'typePromo'],
        icon: <ProgressIcon />,
        successIcon: (
            <FaCheckCircle
                color='#0BD834'
                fontSize={'26px'}
                width={'26px'}
                height={'26px'}
                className='bg-[#fff] rounded-full'
            />
        ),
        waitingIcon: (
            <div className='w-[26px] h-[26px] bg-[#C6C6C6] rounded-full'></div>
        ),
    },
];
