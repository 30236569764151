import { useTranslation } from 'react-i18next';
import {
    Modal,
    Spinner,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { useEffect } from 'react';
import moment from 'moment';
import { optionsStatus } from '../../assets/js/utils';
import { useDispatch, useSelector } from 'react-redux';
import { get_promotion_support_detail } from '../../slices/promotionSlice';

const ModalDetailTicket = ({
    modalDetailTicket,
    toggleModalDetailTicket,
    groupId,
    supportId,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { supportDetail, loadingSupportDetail } = useSelector(
        (state) => state.promotion,
    );
    useEffect(() => {
        dispatch(
            get_promotion_support_detail({
                groupId,
                supportId,
            }),
        );
    }, [supportId]);

    return (
        <Modal
            isOpen={modalDetailTicket}
            onClose={toggleModalDetailTicket}
            title={t('modalViewTickets.modalDetailTicket.transTitle')}
            style={{
                modalTitle: {
                    color: '#25292D',
                    fontSize: '20px',
                    lineHeight: '23px',
                },
            }}
        >
            {loadingSupportDetail ? (
                <Spinner />
            ) : (
                supportDetail && (
                    <div className='flex items-center justify-between'>
                        <div>
                            <div className='flex flex-row'>
                                <span className='font-bold'>
                                    {t('modalViewTickets.table.transCode')}
                                </span>
                                <p className='ml-4'>
                                    {supportDetail?.support_number}
                                </p>
                            </div>
                            <div className='flex flex-row'>
                                <span className='font-bold'>
                                    {t('modalViewTickets.table.transCreate')}
                                </span>
                                <p className='ml-4'>
                                    {moment
                                        .unix(supportDetail?.date_creation)
                                        .format('dddd DD MMMM YYYY')}
                                </p>
                            </div>
                            <div className='flex flex-row'>
                                <span className='font-bold'>
                                    {t('modalViewTickets.table.transStartDate')}
                                </span>
                                <p className='ml-4'>
                                    {moment
                                        .unix(
                                            supportDetail?.date_validity_start,
                                        )
                                        .format('dddd DD MMMM YYYY')}
                                </p>
                            </div>
                            <div className='flex flex-row'>
                                <span className='font-bold'>
                                    {t('modalViewTickets.table.transEndDate')}
                                </span>
                                <p className='ml-4'>
                                    {moment
                                        .unix(supportDetail?.date_validity_end)
                                        .format('dddd DD MMMM YYYY')}
                                </p>
                            </div>
                            <div className='flex flex-row'>
                                <span className='font-bold'>
                                    {t('modalViewTickets.table.TransUse')}
                                </span>
                                <p className='ml-4'>
                                    {supportDetail?.use_count}
                                </p>
                            </div>
                            <div className='flex flex-row'>
                                <span className='font-bold'>
                                    {t('modalViewTickets.table.transStatus')}
                                </span>
                                <p className='ml-4'>
                                    {
                                        optionsStatus(t).find(
                                            (option) =>
                                                option.value ===
                                                supportDetail?.status,
                                        )?.label
                                    }
                                </p>
                            </div>
                        </div>
                        <div>
                            <img
                                src={supportDetail.support_dematerialized}
                                alt='support_qrcode'
                                width={200}
                            />
                        </div>
                    </div>
                )
            )}
        </Modal>
    );
};

export default ModalDetailTicket;
