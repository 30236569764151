import React from 'react';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

export default function CheckboxCRM({ checked, onChange, label }) {
    const { t } = useTranslation();

    return (
        <div className='flex items-center justify-center'>
            <div className='pb-2'>
                <Checkbox checked={checked} onChange={onChange} />
            </div>

            <span className='text-primary-color font-bold ml-2'>
                {label && t(label)}
            </span>
        </div>
    );
}
