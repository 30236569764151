import { FaRegTrashAlt } from 'react-icons/fa';
import { HiOutlinePencil } from 'react-icons/hi2';

export const days = (t) => [
    {
        value: 'schedule_monday',
        label: t('createPromotionModal.stepperCreatePromotion.transMonday'),
    },
    {
        value: 'schedule_tuesday',
        label: t('createPromotionModal.stepperCreatePromotion.transTuesday'),
    },
    {
        value: 'schedule_wednesday',
        label: t('createPromotionModal.stepperCreatePromotion.transWednesday'),
    },
    {
        value: 'schedule_thursday',
        label: t('createPromotionModal.stepperCreatePromotion.transThursday'),
    },
    {
        value: 'schedule_friday',
        label: t('createPromotionModal.stepperCreatePromotion.transFriday'),
    },
    {
        value: 'schedule_saturday',
        label: t('createPromotionModal.stepperCreatePromotion.transSaturday'),
    },
    {
        value: 'schedule_sunday',
        label: t('createPromotionModal.stepperCreatePromotion.transSunday'),
    },
];

export const columns = (t, handleEditSchedule, deleteSchedule, disabled) => [
    {
        title: t('createPromotionModal.stepperCreatePromotion.table.transDays'),
        dataIndex: 'days',
        key: 'days',
        width: '50%',
        render: (text, record) => {
            const selectedDays = days(t)
                .filter((day) => record[day.value]) // Keep only the days where the schedule is true
                .map((day) => day.label.substring(0, 3));
            return (
                <div className='px-[5px]'>
                    <span className='text-xs text-wrap'>
                        {selectedDays.join(', ')}
                    </span>
                </div>
            );
        },
    },
    {
        title: t(
            'createPromotionModal.stepperCreatePromotion.table.transScheduleFrom',
        ),
        dataIndex: 'schedules',
        key: 'schedules',
        td: { padding: 0 },
        render: (text, record) => (
            <div>
                {record.time_slots.map((schedule, index) => (
                    <div
                        className={`py-1${index !== record.time_slots.length - 1 && ' border-b border-t-0 border-x-0 border-solid border-b-[#D1D8DD]'}`}
                        key={index}
                    >
                        {schedule.slot_hours_start}
                    </div>
                ))}
            </div>
        ),
    },
    {
        title: t(
            'createPromotionModal.stepperCreatePromotion.table.transScheduleTo',
        ),
        dataIndex: 'schedules',
        key: 'schedules',
        render: (text, record) => (
            <div>
                {record.time_slots.map((schedule, index) => (
                    <div
                        className={`py-1${index !== record.time_slots.length - 1 && ' border-b border-t-0 border-x-0 border-solid border-b-[#D1D8DD]'}`}
                        key={index}
                    >
                        {schedule.slot_hours_end}
                    </div>
                ))}
            </div>
        ),
    },
    {
        title: t(
            'createPromotionModal.stepperCreatePromotion.table.transActions',
        ),
        dataIndex: '',
        key: '',
        render: (text, record) => (
            <div className='actions-table flex items-center justify-around'>
                <HiOutlinePencil
                    className='cursor-pointer'
                    color={disabled ? '#c1c1c1' : '#000'}
                    fontSize={15}
                    onClick={() =>
                        disabled ? null : handleEditSchedule(record)
                    }
                />
                <FaRegTrashAlt
                    className='cursor-pointer'
                    color={disabled ? '#c1c1c1' : '#ff0000'}
                    fontSize={15}
                    onClick={() => (disabled ? null : deleteSchedule(record))}
                />
            </div>
        ),
    },
];

export const variable_array = (t) => [
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.variableList.transQrCode',
        ),
        variable: 'QR_CODE.CMD',
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.variableList.transBarCode',
        ),
        variable: 'QR_CODE.VALUE',
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.variableList.transDiscount',
        ),
        variable: 'DISCOUNT',
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.variableList.transSites',
        ),
        variable: 'SITES',
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.variableList.transEquipmentsType',
        ),
        variable: 'EQUIPMENTS.TYPE',
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.variableList.transWhen',
        ),
        variable: 'WHEN',
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.variableList.transFor',
        ),
        variable: 'FOR',
    },

    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.variableList.transUsageMax',
        ),
        variable: 'USAGE_MAX',
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.variableList.transSupportValidityStart',
        ),
        variable: 'SUPPORT.VALIDITY_START',
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.variableList.transSupportValidityStart',
        ),
        variable: 'SUPPORT.VALIDITY_END',
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.variableList.transSupportPrintDate',
        ),
        variable: 'SUPPORT.PRINT_DATE',
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.variableList.transCompanyName',
        ),
        variable: 'COMPANY_NAME',
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.variableList.transCompanyAddress',
        ),
        variable: 'COMPANY_ADDRESS',
    },
];

export const style_array = (t) => [
    {
        key: t('createPromotionModal.stepperCreatePromotion.transLeftVariable'),
        variable: 'LEFT',
        ennum: 0,
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.trabnsRightVariable',
        ),
        variable: 'RIGHT',
        ennum: 1,
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.transCenterVariable',
        ),
        variable: 'CENTER',
        ennum: 2,
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transNormalSize'),
        variable: 'SIZE_NORMAL',
        ennum: 3,
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transDoubleSize'),
        variable: 'SIZE_DOUBLE',
        ennum: 4,
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transBoldVariable'),
        variable: 'BOLD',
        ennum: 10,
    },
    {
        key: t('createPromotionModal.stepperCreatePromotion.transItalic'),
        variable: 'ITALIC',
        ennum: 11,
    },
    {
        key: t(
            'createPromotionModal.stepperCreatePromotion.transSkipLineVariable',
        ),
        variable: 'LF',
        ennum: 20,
    },
];

export const optionsStatus = (t) => [
    {
        value: null,
        label: t('modalViewTickets.filterSelectOptions.transAll'),
    },
    {
        value: 0,
        label: t('modalViewTickets.filterSelectOptions.transInStock'),
    },
    {
        value: 1,
        label: t('modalViewTickets.filterSelectOptions.transDistributed'),
    },
    {
        value: 2,
        label: t('modalViewTickets.filterSelectOptions.transInUse'),
    },
    {
        value: 3,
        label: t('modalViewTickets.filterSelectOptions.transClosed'),
    },
];

export const defaultValues = {
        promotionName: null,
        description: null,
        typePromo: 1,
        enableObtainPromo: true,
        startDateOfferObtainPromo: null,
        endDateOfferObtainPromo: null,
        maximumPrintingObtainPromo: null,
        sitesObtainPromo: null,
        equipementsTypeObtainPromo: null,
        constraintObtainPromo: [],
        minObtainPromo: null,
        maxObtainPromo: null,
        startDateOfferUsePromo: null,
        endDateOfferUsePromo: null,
        discountAmount: 0,
        amountUsePromo: null,
        maximumPrintingUsePromo: '',
        codeTypeUsepromo: null,
        startDayValidityUsePromo: 0,
        endDayValidityUsePromo: null,
        sitesUsePromo: null,
        equipementsTypeUsePromo: null,
        constraintUsePromo: [],
        minUsePromo: null,
        maxUsePromo: null,
        amountUsePromo: null,
        idsUsePromo: null,
        // ticket_template_sms: null,
        // ticket_template_email: null,
        ticket_template_paper: {
            data: `<CENTER>$(QR_CODE.CMD)</CENTER><LF>
    <CENTER>$(QR_CODE.VALUE)</CENTER><LF>
    <SIZE_DOUBLE><CENTER>$(NAME)</CENTER></SIZE_DOUBLE><LF>
    <CENTER>-----------------------------------</CENTER><LF>
    <SIZE_DOUBLE><CENTER>$(DISCOUNT)</CENTER></SIZE_DOUBLE><LF>
    <CENTER>CONDITIONS DE VALIDITÉ</CENTER><LF>
    Début : $(SUPPORT.VALIDITY_START)<LF>
    Fin :$(SUPPORT.VALIDITY_END)<LF>
    Type d'équipements : $(EQUIPMENTS.TYPE)<LF>
    Sélections :  de $(FOR)<LF>
    <CENTER>-----------------------------------</CENTER><LF>
    <CENTER>$(COMPANY_NAME)</CENTER><LF>
    <CENTER>$(COMPANY_ADDRESS)</CENTER><LF>
    <CENTER>imprimé le $(SUPPORT.PRINT_DATE)</CENTER>
    <CUT_FULL>`,
            props: {
                module_name: null,
                template_name: 'ticket_template_paper',
                variables_available: null,
                interpretor: {
                    commands_available: null,
                    line_length_max: 0,
                    total_length_max: 0,
                },
            },
        },
        amount: 'amount',
    }
