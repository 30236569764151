import React, { useState, useEffect } from 'react';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import InputBaseForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/InputBaseForm';

export default function RadioForm({
    name = 'radio',
    options = [],
    messages = {},
    className = '',
    defaultValue,
    markerRequired = true,
}) {
    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState(defaultValue ?? null);

    useEffect(() => {
        setSelectedOption(defaultValue);
    }, [defaultValue]);

    const mergedMessages = {
        label: 'radioForm.label',
        help: 'radioForm.help',
        required: 'radioForm.required',
        ...messages,
    };

    return (
        <InputBaseForm
            name={name}
            messages={mergedMessages}
            className={className}
            markerRequired={markerRequired}
            rules={[
                {
                    required: true,
                    message: t(mergedMessages.required),
                },
            ]}
        >
            <Radio.Group
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
            >
                {options?.map((option) => (
                    <Radio.Button
                        key={option.value}
                        value={option.value}
                        className='!border-none ::before!bg-white !p-0 !mr-2'
                    >
                        <span
                            className={`w-24 h-[32px] relative left-0 flex justify-center items-center
                                rounded border border-solid border-primary-color border-[1px]
                            ${
                                option.value === selectedOption
                                    ? 'bg-primary-color text-white'
                                    : 'text-primary-color hover:bg-primary-color hover:text-white'
                            } 
                            transition-all duration-200 ease-in-out active:scale-95`}
                        >
                            {t(option.label)}
                        </span>
                    </Radio.Button>
                ))}

                <style jsx>{`
                    .ant-radio-button-wrapper:not(:first-child)::before {
                        display: none; /* Supprime la bordure à gauche */
                    }
                `}</style>
            </Radio.Group>
        </InputBaseForm>
    );
}
