import { useState } from 'react';

export default function useFormResponse() {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);

    const formResponse = async (fetchFunction, onSuccess) => {
        try {
            setLoading(true);
            setStatus(null);
            await fetchFunction(); // Exécute la fonction passée en paramètre
            setStatus('success');
            if (onSuccess) onSuccess(); // Permet de faire une fonction onSuccess
        } catch (error) {
            setStatus('error');
        } finally {
            setLoading(false);
        }
    };

    return { formResponse, loading, status };
}
