import React from 'react';
import { useTranslation } from 'react-i18next';
import { dateFormat } from '../../../modUtils/functions/values/dateFormat';
import { eMachinesOperatingStatus } from '../../../../commun_global/js/machines/enums';
import machineSvg from '../../assets/images/machine.svg';
import dryerSvg from '../../assets/images/dryer.svg';
import washingPowderSvg from '../../assets/images/washingPowder.svg';
import softenerSvg from '../../assets/images/softener.svg';
import CKToolTip from '../../../modUtils/components/CKToolTip/CKToolTip';

export default function MachineCard({
    machines,
    equipment,
    setSelectedMachine,
    openModalMachineAction,
}) {
    const { t } = useTranslation();
    const connexionToServerIsConfigured = equipment.connection_is_configured;

    const onClickMachineCard = (machine) => {
        setSelectedMachine(machine);
        openModalMachineAction();
    };

    const setStyleMachineCard = (machines) => {
        let style =
            'flex-auto mx-5 my-2.5 rounded-md cursor-pointer w-[200px] max-w-[200px] min-w-[200px] ';

        if (machines?.service_type === 1) {
            style = style + 'bg-sky-600 border-sky-600 ';
        } else if (machines?.service_type === 2) {
            style = style + 'bg-amber-600 border-amber-600';
        } else if (machines?.service_type === 3) {
            style = style + 'bg-thirty-color border-thirty-color';
        } else if (machines?.service_type === 4) {
            style = style + 'bg-primary-color border-primary-color';
        } else if (machines?.service_type === 0) {
            style =
                style +
                'bg-muted-foreground-color border-muted-foreground-color';
        }

        return style;
    };

    const setStyleTextColorSatus = (machine) => {
        let style = '';

        if (machine?.operating_status >= 1 && machine?.operating_status <= 4) {
            style = 'text-destructive-color';
        } else if (
            machine?.operating_status === 5 ||
            machine?.operating_status === 9
        ) {
            style = 'text-accent-color';
        } else {
            style = 'text-secondary-color';
        }

        return style;
    };

    return machines[0].map((machines) =>
        machines.machines.map((machine) => (
            <CKToolTip
                placement='topLeft'
                content={t('machineCard.connectionNotConfigured')}
                trigger={!connexionToServerIsConfigured && 'click'}
            >
                <div // machine card
                    className={`${setStyleMachineCard(machines)}`}
                    key={machine.id}
                    onClick={() =>
                        connexionToServerIsConfigured &&
                        onClickMachineCard(machine)
                    }
                >
                    <div className='flex border-b-4 h-[60px]'>
                        <div className='flex justify-center items-center w-[50%] border-r-2 p-1 text-3xl'>
                            <span>{machine.machine_visual_number}</span>
                        </div>

                        <div className='flex justify-center items-center w-[50%] border-l-2 p-1'>
                            {machines?.service_type === 1 ? ( // 1 = Machine machine
                                <img
                                    width={30}
                                    src={machineSvg}
                                    alt='Machine icon'
                                />
                            ) : machines?.service_type === 2 ? ( // 2 = Séchoir dryer
                                <img
                                    width={30}
                                    src={dryerSvg}
                                    alt='Séchoir icon'
                                />
                            ) : machines?.service_type === 3 ? ( // 3 = Lessive washingPowder
                                <img
                                    width={30}
                                    src={washingPowderSvg}
                                    alt='Lessive icon'
                                />
                            ) : machines?.service_type === 4 ? ( // 4 = Adoucissant softener
                                <img
                                    width={30}
                                    src={softenerSvg}
                                    alt='Adoucissant icon'
                                />
                            ) : (
                                // 0 = Indéfini undefined
                                <img
                                    width={40}
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/design/noData.png'
                                    }
                                    alt='no data to display'
                                />
                            )}
                        </div>
                    </div>

                    <div className='flex justify-center items-center h-[70px]'>
                        <span className='m-0'>{machine.label}</span>
                    </div>

                    <div className='flex justify-center items-center h-[70px] bg-white'>
                        <span className={`${setStyleTextColorSatus(machine)}`}>
                            {t(
                                eMachinesOperatingStatus[
                                    machine?.operating_status
                                ],
                            )}
                        </span>
                    </div>

                    <div className='flex justify-center items-center h-[50px]'>
                        {(machine?.operating_status === 8 ||
                            machine?.operating_status === 6) && (
                            <div>
                                {dateFormat(machine?.starting_date, 'HH:mm')}
                            </div>
                        )}
                    </div>
                </div>
            </CKToolTip>
        )),
    );
}
