import commonTranslation from 'ck_commun/src/app/modUtils/locale/commonTranslation';
import translationModBankingEN from 'ck_commun/src/app/modBanking/locale/en.json';
import translationModBankingFR from 'ck_commun/src/app/modBanking/locale/fr.json';
import translationModCumulativeEN from 'ck_commun/src/app/modCumulative/locale/en.json';
import translationModCumulativeFR from 'ck_commun/src/app/modCumulative/locale/fr.json';
import translationModEntityEN from 'ck_commun/src/app/modEntity/locale/en.json';
import translationModEntityFR from 'ck_commun/src/app/modEntity/locale/fr.json';
import translationModFixingEN from 'ck_commun/src/app/modFixing/locale/en.json';
import translationModFixingFR from 'ck_commun/src/app/modFixing/locale/fr.json';
import translationModImachineEN from 'ck_commun/src/app/modImachine/locale/en.json';
import translationModImachineFR from 'ck_commun/src/app/modImachine/locale/fr.json';
import translationModSaleEN from 'ck_commun/src/app/modSale/locale/en.json';
import translationModSaleFR from 'ck_commun/src/app/modSale/locale/fr.json';
import translationModSharedEquipmentEN from 'ck_commun/src/app/modSharedEquipment/locale/en.json';
import translationModSharedEquipmentFR from 'ck_commun/src/app/modSharedEquipment/locale/fr.json';
import translationModStatementEN from 'ck_commun/src/app/modStatement/locale/en.json';
import translationModStatementFR from 'ck_commun/src/app/modStatement/locale/fr.json';
import translationModSubscriptionEN from 'ck_commun/src/app/modSubscription/locale/en.json';
import translationModSubscriptionFR from 'ck_commun/src/app/modSubscription/locale/fr.json';
import translationModProfilEN from 'ck_commun/src/app/modProfil/locale/en.json';
import translationModProfilFR from 'ck_commun/src/app/modProfil/locale/fr.json';
import translationModWalletLocalEN from 'ck_commun/src/app/modWalletLocal/locale/en.json';
import translationModWalletLocalFR from 'ck_commun/src/app/modWalletLocal/locale/fr.json';
import translationModWalletCentralizedEN from 'ck_commun/src/app/modWalletCentralized/locale/en.json';
import translationModWalletCentralizedFR from 'ck_commun/src/app/modWalletCentralized/locale/fr.json';
import translationModSupportPrepaymentEN from 'ck_commun/src/app/modSupportPrepayment/locale/en.json';
import translationModSupportPrepaymentFR from 'ck_commun/src/app/modSupportPrepayment/locale/fr.json';
import translationModWeatherEN from 'ck_commun/src/app/modWeather/locale/en.json';
import translationModWeatherFR from 'ck_commun/src/app/modWeather/locale/fr.json';
import translationModPromoEN from 'ck_commun/src/app/modPromotion/locale/en.json';
import translationModPromoFR from 'ck_commun/src/app/modPromotion/locale/fr.json';

// ajout des traductions des modules spécifiques a l'application,
// les traductions communes sont dans le fichier commonTranslation.js et comprennent les modules
// toujours présents dans les projets CK
const translation = {
    en: {
        ...commonTranslation.en,
        ...translationModBankingEN,
        ...translationModCumulativeEN,
        ...translationModEntityEN,
        ...translationModFixingEN,
        ...translationModImachineEN,
        ...translationModSaleEN,
        ...translationModSharedEquipmentEN,
        ...translationModStatementEN,
        ...translationModSubscriptionEN,
        ...translationModProfilEN,
        ...translationModWalletLocalEN,
        ...translationModWalletCentralizedEN,
        ...translationModSupportPrepaymentEN,
        ...translationModWeatherEN,
        ...translationModPromoEN,
    },
    fr: {
        ...commonTranslation.fr,
        ...translationModBankingFR,
        ...translationModCumulativeFR,
        ...translationModEntityFR,
        ...translationModFixingFR,
        ...translationModImachineFR,
        ...translationModSaleFR,
        ...translationModSharedEquipmentFR,
        ...translationModStatementFR,
        ...translationModSubscriptionFR,
        ...translationModProfilFR,
        ...translationModWalletLocalFR,
        ...translationModWalletCentralizedFR,
        ...translationModSupportPrepaymentFR,
        ...translationModWeatherFR,
        ...translationModPromoFR,
    },
};

export default translation;
