import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { httpGet } from '../../modApi/apis/httpConfig';
//  Exporte les ventes sous le fichier de format souhaité
export const export_sales_by_group_export_get = createAsyncThunk(
    'mod_sale/export_sales_by_group_export_get',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `/mod_sale/exports/${values.fileFormat}/groups/${values.groupId}/sales/`,
                {
                    params: {
                        fileFormat: values.fileFormat,
                        groupId: values.groupId,
                        date_start: values.dateStart,
                        date_end: values.dateEnd,
                        credit_card: values.creditCard,
                        equipment_type: values.equipmentType
                            ? values.equipmentType
                            : null,
                        sites: values.sites ? values.sites : null,
                        payment: values.payment ? values.payment : null,
                        ordering: values.ordering,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

//récupérer toutes les ventes par groupId
export const get_sales_by_group_paginated = createAsyncThunk(
    'mod_sale/get_sales_by_group_paginated',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `/mod_sale/groups/${values.groupId}/sales`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                        date_start: values.dateStart,
                        date_end: values.dateEnd,
                        equipment_type: values.equipmentType
                            ? values.equipmentType
                            : null,
                        sites: values.sites ? values.sites : null,
                        payment: values.payment ? values.payment : null,
                        credit_card: values.creditCard,
                        ordering: values.ordering,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

//récupérer détails d'une vente par saleId
export const get_sale_detail_by_group = createAsyncThunk(
    'mod_sale/get_sale_detail_by_group',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `/mod_sale/groups/${values.groupId}/sales/${values.saleId}`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

//récupérer  les ventes d'un équipement
export const get_sales_equipment_by_group_paginated = createAsyncThunk(
    'mod_sale/get_sales_equipment_by_group_paginated',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `/mod_sale/groups/${values.groupId}/equipments/${values.equipmentId}/sales`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                        ordering: values.ordering,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

const saleSlice = createSlice({
    name: 'sale',
    initialState: {
        sales: [],
        countSales: 0,
        loading: true,
        saleDetails: [],
        equipmentSales: [],
    },
    extraReducers: (builder) => {
        builder
            //récupérer toutes les ventes par groupId
            .addCase(get_sales_by_group_paginated.pending, (state, action) => {
                state.sales = [];
                state.countSales = 0;
            })
            .addCase(
                get_sales_by_group_paginated.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.sales = action.payload.results;
                    state.countSales = action.payload.count;
                },
            )
            .addCase(get_sales_by_group_paginated.rejected, (state, action) => {
                state.loading = false;
            })

            //récupérer détails d'une vente par saleId
            .addCase(get_sale_detail_by_group.fulfilled, (state, action) => {
                state.saleDetails = action.payload;
            })
            .addCase(get_sale_detail_by_group.rejected, (state, action) => {
                state.loading = false;
            })

            //getget_sales_equipment_by_group_paginated :récupérer les ventes d'un équipement par equipmentId lié à un groupeId
            .addCase(
                get_sales_equipment_by_group_paginated.pending,
                (state, action) => {
                    // Réinitialisation uniquement si l'offset est à 0
                    if (action.meta.arg.offset === 0) {
                        state.equipmentSales = [];
                    }
                },
            )
            .addCase(
                get_sales_equipment_by_group_paginated.fulfilled,
                (state, action) => {
                    state.equipmentSales = action.payload.results;
                    state.loading = false;
                },
            )
            .addCase(
                get_sales_equipment_by_group_paginated.rejected,
                (state, action) => {
                    state.loading = false;
                },
            );
    },
});

export default saleSlice.reducer;
