import React, { useEffect, useState, useCallback } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import CKToolTip from 'ck_commun/src/app/modUtils/components/CKToolTip/CKToolTip';
import FormHeader from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/FormHeader';
import FormFooter from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/FormFooter';
import LoadingSpinner from 'ck_commun/src/app/modUtils/components/componentsLibrary/spinner/LoadingSpinner';
import MessageSuccessForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/MessageSuccessForm';

/**
 * Formulaire générique
 * @param {Object} props
 * @param {Object} props.form - Instance du formulaire Ant Design
 * @param {string} [props.layout="vertical"] - Layout du formulaire
 * @param {boolean} props.loading - Indicateur de chargement
 * @param {string|null} props.status - Statut du formulaire (ex: 'success', 'error')
 * @param {Function} props.onFinish - Fonction exécutée lors de la soumission
 * @param {Object} props.initialValues - Valeurs initiales du formulaire
 */
export default function CkForm({
    form,
    filter = false,
    edit = false,
    label,
    layout = 'vertical',
    status = null,
    loading = false,
    onClose,
    messages,
    onFinish,
    onDelete,
    isInModal = false,
    className = '',
    initialValues,
    onFieldsChange,
    messageSuccess,
    ...props
}) {
    const { t } = useTranslation();
    const [hasSuccess, setHasSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isResetting, setIsResetting] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(status);

    // Fonction pour réinitialiser le formulaire
    const resetForm = useCallback(() => {
        if (!form) return;

        setIsResetting(true);
        setTimeout(() => {
            setCurrentStatus(null);
            form.resetFields(); // Reset les champs + erreurs
            setErrorMessage(''); // Réinitialise le message d'erreur global
            form.setFieldsValue(initialValues); // Appliquer les valeurs initiales
            setIsResetting(false);
        }, 1000);
    }, [form, initialValues]);

    // Gestion des erreurs des champs
    const handleFieldErrors = (changedFields, allFields) => {
        if (onFieldsChange) {
            onFieldsChange(changedFields, allFields);
        }

        // Récupérer les erreurs des champs
        const fieldErrors = form?.getFieldsError() || [];
        // Filtrer les champs avec erreurs
        const erroredFields = fieldErrors.filter(
            ({ errors }) => errors.length > 0,
        );

        setErrorMessage(
            erroredFields.length > 0
                ? `⚠️ ${erroredFields.length} ${t('ckForm.fieldInError')}\n` +
                      erroredFields
                          .map(
                              ({ name, errors }) =>
                                  `❌ ${name} : ${errors.join(', ')}`,
                          )
                          .join('\n')
                : '',
        );
    };

    // Reset automatique du formulaire en cas de succès
    useEffect(() => {
        setCurrentStatus(status);
    }, [status]);

    useEffect(() => {
        if (currentStatus === 'success') {
            setHasSuccess(true);
        }
    }, [currentStatus]);

    // click pour fermer le message success
    const handleOnClick = () => {
        setHasSuccess(false);
        resetForm();
    };

    return (
        <div className={className}>
            {hasSuccess && messageSuccess ? (
                <MessageSuccessForm
                    messageSuccess={messageSuccess}
                    onClick={handleOnClick}
                />
            ) : isResetting ? (
                <div className='h-full flex justify-center items-center'>
                    <span>⏳ {t('ckForm.resetInProgress')}</span>
                    <CKToolTip
                        placement='top'
                        content={t('ckForm.tooltipContent')}
                        trigger='hover'
                    >
                        <LoadingSpinner />
                    </CKToolTip>
                </div>
            ) : (
                <Form
                    form={form}
                    layout={layout}
                    onFinish={onFinish}
                    initialValues={initialValues}
                    onFieldsChange={handleFieldErrors}
                    className={`${filter && 'flex items-center flex-wrap'}`}
                >
                    <FormHeader
                        label={label}
                        filter={filter}
                        loading={loading}
                        onClose={onClose}
                        messages={messages}
                        onDelete={onDelete}
                        isInModal={isInModal}
                        onRefresh={resetForm}
                    />

                    {props.children}

                    <FormFooter
                        filter={filter}
                        status={currentStatus}
                        loading={isResetting || loading}
                        errorMessage={errorMessage}
                    />
                </Form>
            )}
        </div>
    );
}
