import React, { useState, useEffect } from 'react';
import { Select } from '../../modUtils/components/componentsLibrary/componentsLibrary';
import { useTranslation } from 'react-i18next';

export default function FilterByTotalType({
    onValueChange,
    paramList,
    paymentTypeList,
}) {
    const { t } = useTranslation();
    const optionsGroup = [
        {
            groupValue: 1,
            options: [
                {
                    label: t('filterByTotalType.optionsLabelBySites'),
                    value: 'by_sites',
                },
                {
                    label: t('filterByTotalType.optionsLabelByEquipmentTypes'),
                    value: 'by_equipment_types',
                },
                {
                    label: t('filterByTotalType.optionsLabelByEquipments'),
                    value: 'by_equipments',
                },
            ],
        },
        {
            label: (
                <div
                    style={{
                        borderTop: '1.5px solid #338EF5',
                        width: '100%',
                        margin: '10px 0',
                    }}
                />
            ),
            groupValue: 2,
            options: [
                {
                    label: t('filterByTotalType.optionsGroupReal'),
                    value: 'group_real',
                },
                {
                    label: t('filterByTotalType.optionsGroupOther'),
                    value: 'group_other',
                },
            ],
        },
    ];
    const [selectedOptions, setSelectedOptions] = useState([
        {
            groupValue: 1,
            options: paramList,
        },
        {
            groupValue: 2,
            options: paymentTypeList,
        },
    ]);
    let numberOfSelectedOptions =
        selectedOptions[0].options.length + selectedOptions[1].options.length;

    useEffect(() => {
        onValueChange(selectedOptions);
    }, [selectedOptions, onValueChange]);

    const handleChange = (value) => {
        // Appeler le callback
        if (value === 'group_real' || value === 'group_other') {
            setSelectedOptions((prevSelectedOptions) => {
                const updatedOptions = [...prevSelectedOptions];
                const groupIndex = updatedOptions.findIndex(
                    (group) => group.groupValue === 2,
                );
                updatedOptions[groupIndex].options.push(value);
                return updatedOptions;
            });
        } else {
            setSelectedOptions((prevSelectedOptions) => {
                const updatedOptions = [...prevSelectedOptions];
                const groupIndex = updatedOptions.findIndex(
                    (group) => group.groupValue === 1,
                );
                updatedOptions[groupIndex].options.push(value);
                return updatedOptions;
            });
        }
    };

    const handleDeselect = (deselectedValue, selectedList) => {
        setSelectedOptions((prevSelectedOptions) => {
            const updatedOptions = [...prevSelectedOptions];

            // Find the group that contains the deselected value
            const groupIndex = updatedOptions.findIndex((group) =>
                group.options.includes(deselectedValue),
            );

            if (groupIndex !== -1) {
                // Remove the deselected value from the options array
                updatedOptions[groupIndex].options = updatedOptions[
                    groupIndex
                ].options.filter((option) => option !== deselectedValue);
            }
            return updatedOptions;
        });
    };

    return (
        <Select
            options={optionsGroup}
            defaultValue={[...paramList, ...paymentTypeList]}
            multiple
            placeholder={
                t('filterByTotalType.placeholder') +
                (numberOfSelectedOptions > 0
                    ? ` (${numberOfSelectedOptions})`
                    : '')
            }
            width={240}
            onChange={(value) => handleChange(value)}
            onDeselect={(value, selectedList) =>
                handleDeselect(value, selectedList)
            }
            onSelect={(value, option) => handleChange(value, option)}
            style= {{
                select: { width: "240px"},
                selectOptions: { width: "240px"}
            }}
        ></Select>
    );
}
