/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/device/e_sensor_id.py
Aucune modification manuelle acceptée
*/

// Enumération des id composant des capteurs disponibles
const eSensorId = Object.freeze({
  // Composant STH40
  1: 'eSensorId.sth40',

  // Composant PAC195X
  2: 'eSensorId.pac195X',

  // Capteur de Luminosité
  3: 'eSensorId.veml7700',

  // Capteur de Humidité et température
  4: 'eSensorId.temperatureSensorCctalk',
});

export default eSensorId;
