import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { get_events_by_equipment_by_group_paginated } from '../../slices/equipmentSlice';
import { dateFormat } from '../../../modUtils/functions/values/dateFormat';
import { hasPermission } from 'ck_commun/src/app/modUtils/functions/permissions/handlePermissions';
import PermissionDeniedPage from 'ck_commun/src/app/modUtils/components/errorPage/PermissionDeniedPage';
import PermissionFadeContainer from '../../../modUtils/components/permissionFadeContainer/PermissionFadeContainer';
import i18next from 'i18next';

export default function EquipmentEventsHistory({ equipment }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const { groupId, selectedGroup, loadingGroup } = useSelector(
        (state) => state.manager,
    );
    const { equipmentEvents } = useSelector((state) => state.equipment);
    const [limit, setLimit] = useState(50);
    const [offset, setOffset] = useState(0);
    const [nextUrl, setNextUrl] = useState();
    const tableRef = useRef(null);
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission(
        'mod_equipment:show_event',
        permissions,
    );
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission(
        'mod_equipment:show_event',
        groupPermissions,
    );
    const [equipmentEventsLoader, setEquipmentEventsLoader] =
        useState(equipmentEvents);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            await dispatch(
                get_events_by_equipment_by_group_paginated({
                    groupId: groupId,
                    equipmentId: equipment.id,
                    limit: limit,
                    offset: offset,
                    ordering: '-date',
                }),
            ).then((result) => {
                setNextUrl(result.payload.next);
                setLoading(false);
                setLoadingMore(false);
                setEquipmentEventsLoader((prevEvents) => [
                    ...prevEvents,
                    ...result.payload.results,
                ]);
            });
        }
        if (verifyPermission && groupId && equipment.id) {
            fetchData();
        }
    }, [groupId, equipment.id, limit, offset, i18next.language]);

    const loadMoreData = () => {
        if (nextUrl && !loadingMore) {
            setLoadingMore(true);

            dispatch(
                get_events_by_equipment_by_group_paginated({
                    groupId: groupId,
                    equipmentId: equipment.id,
                    limit: limit,
                    offset: offset + limit,
                    ordering: '-date',
                }),
            ).then((result) => {
                setNextUrl(result.payload.next);
                setOffset(offset + limit);
                setLoadingMore(false);

                // Ajoutez les nouvelles données aux données existantes
                setEquipmentEventsLoader((prevEvents) => [
                    ...prevEvents,
                    ...result.payload.results,
                ]);
            });
        }
    };

    const handleScroll = () => {
        const tableElement = tableRef.current;

        if (
            tableElement &&
            tableElement.scrollTop + tableElement.clientHeight >=
                tableElement.scrollHeight - 100 // seuil à ajuster
        ) {
            loadMoreData();
        }
    };

    useEffect(() => {
        const tableElement = tableRef.current;
        tableElement.addEventListener('scroll', handleScroll);

        return () => {
            if (tableElement) {
                tableElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const columns = [
        {
            title: t('table.tabColDate'),
            dataIndex: 'date',
            key: 'date',
            width: '20%',
            render: (text) => dateFormat(text),
        },
        {
            title: t('table.tabColEvents'),
            dataIndex: 'description',
            key: 'description',
            align: 'left',
            bold: true,
            width: '80%',
        },
    ];

    return (
        <div ref={tableRef} style={{ overflowY: 'auto', height: '690px' }}>
            {!verifyPermission ? (
                <PermissionDeniedPage permission={verifyGroupPermission} />
            ) : (
                <PermissionFadeContainer
                    permission={verifyGroupPermission}
                    opacity={50}
                >
                    <Table
                        columns={columns}
                        dataSource={equipmentEventsLoader}
                        onScroll={handleScroll}
                        scroll={{ y: 690 }}
                    />
                </PermissionFadeContainer>
            )}
        </div>
    );
}
