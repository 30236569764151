import React from 'react';
import { FiRefreshCcw } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import CKToolTip from 'ck_commun/src/app/modUtils/components/CKToolTip/CKToolTip';

export default function RefreshButton({ onRefresh }) {
    const { t } = useTranslation();

    return (
        <CKToolTip
            placement='top'
            content={t('resetForm.tooltipContent')}
            trigger='hover'
        >
            <FiRefreshCcw
                role='button'
                tabIndex={0}
                aria-label={t('resetForm.tooltipContent')}
                className='text-primary-color cursor-pointer'
                size={18}
                onClick={onRefresh}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        onRefresh();
                    }
                }}
            />
        </CKToolTip>
    );
}
