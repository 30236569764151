/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/device/e_sensor_type.py
Aucune modification manuelle acceptée
*/

// Enumération des types de capteurs disponibles
const eSensorType = Object.freeze({
  // Capteur de Température
  0: 'eSensorType.temperature',

  // Capteur d'Humidité
  1: 'eSensorType.humidity',

  // Capteur de Luminosité
  2: 'eSensorType.luminosity',

  // Accéléromètre
  3: 'eSensorType.accelerometer',

  // Gyroscope
  4: 'eSensorType.gyroscope',

  // Récepteur GPS
  5: 'eSensorType.gps',

  // Capteur de voltage
  6: 'eSensorType.voltage',

  // Capteur de courant
  7: 'eSensorType.current',

  // Capteur de puissance
  8: 'eSensorType.power',
});

export default eSensorType;
