import React from 'react';

export default function ButtonRefresh({ onClick }) {
    return (
        <img
            className='absolute right-10 top-1.5 cursor-pointer'
            src={process.env.PUBLIC_URL + '/img/refreshIcon.png'}
            alt='refresh'
            onClick={onClick}
        />
    );
}
