import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { Form, TimePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FormLegend from '../../modUtils/components/FormLegend';
import { Modal } from '../../modUtils/components/componentsLibrary/componentsLibrary';
import {
    constraints_schedule_time_slot_create,
    constraints_schedule_time_slot_detail_update,
} from '../slices/constraintScheduleSlice';

export default function AddTimeSlotModal({
    isEdit,
    timeSlotId,
    isModalVisible,
    setIsModalVisible,
}) {
    const formatDate = 'HH:mm';
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);
    const { constraintsScheduleDetail } = useSelector(
        (state) => state.profil.constraintSchedule,
    );

    // Créer un nouvel horaire contrainte de temps
    const createConstraintsScheduleTimeSlot = async (
        timeSlotHoursStart,
        timeSlotHoursEnd,
    ) => {
        await dispatch(
            constraints_schedule_time_slot_create({
                groupId,
                constraintScheduleId: constraintsScheduleDetail.id,
                timeSlotHoursStart: timeSlotHoursStart,
                timeSlotHoursEnd: timeSlotHoursEnd,
            }),
        ).unwrap();
    };

    // Modifier un horaire contrainte de temps
    const updateConstraintsScheduleTimeSlotDetail = async (
        timeSlotHoursStart,
        timeSlotHoursEnd,
    ) => {
        await dispatch(
            constraints_schedule_time_slot_detail_update({
                groupId: groupId,
                constraintScheduleId: constraintsScheduleDetail.id,
                timeSlotId: timeSlotId,
                timeSlotHoursStart: timeSlotHoursStart,
                timeSlotHoursEnd: timeSlotHoursEnd,
            }),
        ).unwrap();
    };

    const onFinish = (values) => {
        const timeSlotHoursStart = values.startHours.format(formatDate);
        const timeSlotHoursEnd = values.endHours.format(formatDate);

        if (isEdit) {
            updateConstraintsScheduleTimeSlotDetail(
                timeSlotHoursStart,
                timeSlotHoursEnd,
            );
        } else {
            createConstraintsScheduleTimeSlot(
                timeSlotHoursStart,
                timeSlotHoursEnd,
            );
        }
        setIsModalVisible(false);
    };

    // Initialisation des valeurs du formulaire pour le mode édition
    useEffect(() => {
        form.resetFields();
        if (isEdit && constraintsScheduleDetail && timeSlotId) {
            const timeSlotToEdit = constraintsScheduleDetail.time_slot.find(
                (slot) => slot.id === timeSlotId,
            );
            if (timeSlotToEdit) {
                form.setFieldsValue({
                    startHours: dayjs(
                        timeSlotToEdit.time_slot_hours_start,
                        formatDate,
                    ),
                    endHours: dayjs(
                        timeSlotToEdit.time_slot_hours_end,
                        formatDate,
                    ),
                });
            }
        }
    }, [isEdit, timeSlotId, constraintsScheduleDetail, form]);

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {
        form.submit();
    };

    // Modification de l'heure de début
    const handleChangeStartDate = (date) => {
        form.setFieldsValue({ startHours: date });
    };

    // Modification de l'heure de fin
    const handleChangeEndDate = (date) => {
        form.setFieldsValue({ endHours: date });
    };

    return (
        <Modal
            title={t('addScheduleConstraintModal.addScheduleConstraint')}
            isOpen={isModalVisible}
            onClose={handleCancel}
            onCancel={handleCancel}
            onOk={handleOk}
            zIndex={1001}
        >
            <Form form={form} onFinish={onFinish} layout='vertical'>
                <div className='flex justify-evenly'>
                    <Form.Item
                        label={t('scheduleAndPeriodConstraints.scheduleOf')}
                        name='startHours'
                        rules={[
                            {
                                required: true,
                                message: t(
                                    'addScheduleConstraintModal.pleaseSelectStartTime',
                                ),
                            },
                        ]}
                    >
                        <TimePicker
                            placeholder={t(
                                'addScheduleConstraintModal.startTime',
                            )}
                            format={formatDate}
                            use12Hours={false}
                            onChange={handleChangeStartDate}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('scheduleAndPeriodConstraints.scheduleAt')}
                        name='endHours'
                        rules={[
                            {
                                required: true,
                                message: t(
                                    'addScheduleConstraintModal.pleaseSelectEndTime',
                                ),
                            },
                        ]}
                    >
                        <TimePicker
                            placeholder={t(
                                'addScheduleConstraintModal.endTime',
                            )}
                            format={formatDate}
                            use12Hours={false}
                            onChange={handleChangeEndDate}
                        />
                    </Form.Item>
                </div>

                <FormLegend requiredFormItemLegend={true} />
            </Form>
        </Modal>
    );
}
