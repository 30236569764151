import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, TimePicker, Space, message } from 'antd';
import { daysOfWeek } from '../functions/handleDaysOfWeek';
import { ReactComponent as CheckIcon } from '../../modEntity/assets/images/check-solid.svg';
import FormLegend from '../../modUtils/components/FormLegend';
import {
    Label,
    Modal,
    Spinner,
    Table,
} from '../../modUtils/components/componentsLibrary/componentsLibrary';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { ReactComponent as DeleteIcon } from '../../modEntity/assets/images/trash-can.svg';
import moment from 'moment';
import AddTimeSlotModal from './AddTimeSlotModal';
import {
    constraints_schedule_time_slot_detail_delete,
    create_constraint_schedule,
    update_constraint_schedule_detail,
} from '../slices/constraintScheduleSlice';
import CKToolTip from '../../modUtils/components/CKToolTip/CKToolTip';

export default function AddScheduleConstraintModal({
    isModalVisible,
    setIsModalVisible,
    selectedConfigId,
    setLoading,
    isEdit,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);
    const { constraintsScheduleDetail, loadingDetail } = useSelector(
        (state) => state.profil.constraintSchedule,
    );
    const [form] = Form.useForm();
    const formatDate = 'HH:mm';
    const [timePickers, setTimePickers] = useState([
        { id: Date.now(), startHours: null, endHours: null },
    ]);
    const [isModalVisibleAddTimeSlot, setIsModalVisibleAddTimeSlot] =
        useState(false);
    const [timeSlotId, setTimeSlotId] = useState(null);
    const [isEditFormTimeSlot, setIsEditFormTimeSlot] = useState(false);
    const [selectedDays, setSelectedDays] = useState({
        schedule_monday: false,
        schedule_tuesday: false,
        schedule_wednesday: false,
        schedule_thursday: false,
        schedule_friday: false,
        schedule_saturday: false,
        schedule_sunday: false,
    });

    const handleCancel = () => {
        form.resetFields();
        setIsModalVisible(false);
    };

    const handleOk = () => {
        form.submit();
    };

    // Ajouter une nouvelle contrainte de temps
    const addNewScheduleConstraint = async (values) => {
        setLoading(true);
        await dispatch(
            create_constraint_schedule({
                groupId,
                configurationId: selectedConfigId,
                scheduleMonday: values.scheduleMonday,
                scheduleTuesday: values.scheduleTuesday,
                scheduleWednesday: values.scheduleWednesday,
                scheduleThursday: values.scheduleThursday,
                scheduleFriday: values.scheduleFriday,
                scheduleSaturday: values.scheduleSaturday,
                scheduleSunday: values.scheduleSunday,
                timeSlot: values.timeSlot,
            }),
        ).unwrap();
        setLoading(false);
    };

    // Modifier une contrainte de temps
    const updateScheduleConstraint = async (values) => {
        setLoading(true);
        await dispatch(
            update_constraint_schedule_detail({
                groupId: groupId,
                configurationId: selectedConfigId,
                constraintId: constraintsScheduleDetail.id,
                scheduleMonday: values.scheduleMonday,
                scheduleTuesday: values.scheduleTuesday,
                scheduleWednesday: values.scheduleWednesday,
                scheduleThursday: values.scheduleThursday,
                scheduleFriday: values.scheduleFriday,
                scheduleSaturday: values.scheduleSaturday,
                scheduleSunday: values.scheduleSunday,
            }),
        ).unwrap();
        setLoading(false);
    };

    // supprimmer un horaire
    const deleteConstraintsScheduleTimeSlotDetail = async (timeSlotId) => {
        await dispatch(
            constraints_schedule_time_slot_detail_delete({
                groupId: groupId,
                constraintScheduleId: constraintsScheduleDetail.id,
                timeSlotId: timeSlotId,
            }),
        ).unwrap();
    };

    useEffect(() => {
        if (isEdit && !loadingDetail) {
            // Mettre à jour les jours sélectionnés
            setSelectedDays({
                schedule_monday: constraintsScheduleDetail.schedule_monday,
                schedule_tuesday: constraintsScheduleDetail.schedule_tuesday,
                schedule_wednesday:
                    constraintsScheduleDetail.schedule_wednesday,
                schedule_thursday: constraintsScheduleDetail.schedule_thursday,
                schedule_friday: constraintsScheduleDetail.schedule_friday,
                schedule_saturday: constraintsScheduleDetail.schedule_saturday,
                schedule_sunday: constraintsScheduleDetail.schedule_sunday,
            });

            // Initialiser les champs timePickers
            const initialPickers = constraintsScheduleDetail.time_slot.map(
                (slot) => ({
                    id: slot.id,
                    startHours: slot.time_slot_hours_start
                        ? moment(slot.time_slot_hours_start, formatDate)
                        : null,
                    endHours: slot.time_slot_hours_end
                        ? moment(slot.time_slot_hours_end, formatDate)
                        : null,
                }),
            );
            setTimePickers(initialPickers);

            // Initialiser les valeurs du formulaire
            const initialFormValues =
                constraintsScheduleDetail.time_slot.reduce((values, slot) => {
                    values[`startHours_${slot.id}`] = slot.time_slot_hours_start
                        ? moment(slot.time_slot_hours_start, formatDate)
                        : null;
                    values[`endHours_${slot.id}`] = slot.time_slot_hours_end
                        ? moment(slot.time_slot_hours_end, formatDate)
                        : null;
                    return values;
                }, {});

            form.setFieldsValue(initialFormValues);
        }
    }, [constraintsScheduleDetail, form]);

    // handle change datepicker start date
    const handleChangeStartDate = (date, id) => {
        setTimePickers((prev) =>
            prev.map((picker) =>
                picker.id === id ? { ...picker, startHours: date } : picker,
            ),
        );

        // Mettre à jour le champ correspondant dans le formulaire
        form.setFieldsValue({ [`startHours_${id}`]: date });
    };

    // handle change datepicker end date for a specific group
    const handleChangeEndDate = (date, id) => {
        setTimePickers((prev) =>
            prev.map((picker) =>
                picker.id === id ? { ...picker, endHours: date } : picker,
            ),
        );

        // Mettre à jour le champ correspondant dans le formulaire
        form.setFieldsValue({ [`endHours_${id}`]: date });
    };

    const handleClickChangeDays = (day) => {
        setSelectedDays((prevDays) => ({
            ...prevDays,
            [day]: !prevDays[day],
        }));
    };

    // fonction appelée quand on envoie le formulaire
    const onFinish = (values) => {
        // Récupérer toutes les valeurs du formulaire
        const allValues = form.getFieldsValue();

        // Extraire les valeurs dynamiques
        const timeSlot = timePickers.map(({ id }) => ({
            time_slot_hours_start: allValues[`startHours_${id}`]
                ? allValues[`startHours_${id}`].format(formatDate)
                : null,
            time_slot_hours_end: allValues[`endHours_${id}`]
                ? allValues[`endHours_${id}`].format(formatDate)
                : null,
        }));

        const formValues = {
            timeSlot,
            scheduleMonday: selectedDays.schedule_monday,
            scheduleTuesday: selectedDays.schedule_tuesday,
            scheduleWednesday: selectedDays.schedule_wednesday,
            scheduleThursday: selectedDays.schedule_thursday,
            scheduleFriday: selectedDays.schedule_friday,
            scheduleSaturday: selectedDays.schedule_saturday,
            scheduleSunday: selectedDays.schedule_sunday,
        };

        if (isEdit) {
            updateScheduleConstraint(formValues);
        } else {
            addNewScheduleConstraint(formValues);
        }

        setIsModalVisible(false);
    };

    const handleClickAddTimeSlot = () => {
        if (isEdit) {
            setIsEditFormTimeSlot(false);
            setIsModalVisibleAddTimeSlot(true);
        } else {
            const newPicker = {
                id: Date.now(),
                startHours: null,
                endHours: null,
            };
            setTimePickers((prev) => [...prev, newPicker]);

            // Initialiser les champs dans le formulaire
            form.setFieldsValue({
                [`startHours_${newPicker.id}`]: null,
                [`endHours_${newPicker.id}`]: null,
            });
        }
    };

    const handleClickDelete = (id) => {
        if (isEdit) {
            deleteConstraintsScheduleTimeSlotDetail(id);
        }

        // Supprimer le champ de l'état `timePickers`
        setTimePickers((prev) => prev.filter((picker) => picker.id !== id));

        // Supprimer les champs correspondants dans le formulaire tout en conservant les autres
        const currentValues = form.getFieldsValue(); // Récupérer toutes les valeurs actuelles du formulaire
        const {
            [`startHours_${id}`]: _,
            [`endHours_${id}`]: __,
            ...remainingValues
        } = currentValues; // Exclure les champs supprimés

        // Mettre à jour le formulaire avec les valeurs restantes
        form.setFieldsValue(remainingValues);
    };

    const handleClickUpdate = (id) => {
        setTimeSlotId(id);
        setIsEditFormTimeSlot(true);
        setIsModalVisibleAddTimeSlot(true);
    };

    const columns = [
        {
            title: 'scheduleAndPeriodConstraints.scheduleOf',
            dataIndex: 'id', //startHours
            key: 'id',
            width: '15%',
            render: (text, record) => (
                <div
                    className='w-full h-full flex items-center justify-center'
                    onClick={() => handleClickUpdate(text)}
                >
                    {record?.startHours?.format(formatDate)}
                </div>
            ),
        },
        {
            title: 'scheduleAndPeriodConstraints.scheduleAt',
            dataIndex: 'id', //endHours
            key: 'id',
            width: '15%',
            render: (text, record) => (
                <div
                    className='w-full h-full flex items-center justify-center'
                    onClick={() => handleClickUpdate(text)}
                >
                    {record?.endHours?.format(formatDate)}
                </div>
            ),
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (text, record) => (
                <div
                    className='w-full h-full flex items-center justify-center'
                    onClick={() => handleClickUpdate(text)}
                >
                    <div
                        className='m-auto w-[15px]'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClickDelete(text);
                        }}
                    >
                        <DeleteIcon />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <Modal
            title={t('addScheduleConstraintModal.updateTimeConstraint')}
            isOpen={isModalVisible}
            onClose={handleCancel}
            onCancel={handleCancel}
            onOk={handleOk}
        >
            {loadingDetail && isEdit ? (
                <Spinner />
            ) : (
                <Form form={form} onFinish={onFinish} layout='vertical'>
                    <div className='flex flex-col items-center'>
                        <Form.Item
                            name='days'
                            rules={[
                                {
                                    validator: (_, value) => {
                                        const isAtLeastOneDaySelected =
                                            Object.values(selectedDays).some(
                                                (day) => day,
                                            );
                                        return isAtLeastOneDaySelected
                                            ? Promise.resolve()
                                            : Promise.reject(
                                                  new Error(
                                                      'Veuillez sélectionner au moins un jour.',
                                                  ),
                                              );
                                    },
                                },
                            ]}
                        >
                            <Space size={16} align='center' wrap>
                                {daysOfWeek.map((day) => (
                                    <div
                                        key={day.key}
                                        onClick={() =>
                                            handleClickChangeDays(day.key)
                                        }
                                        className={`h-14 w-14 cursor-pointer flex flex-col items-center p-2
                                            border border-solid border-[0.5px] border-primary-color rounded
                                                ${
                                                    selectedDays[day.key]
                                                        ? 'bg-primary-color text-white'
                                                        : 'text-primary-color'
                                                }
                                            `}
                                    >
                                        <CheckIcon className='w-4' />
                                        <div>{day.abbrev}</div>
                                    </div>
                                ))}
                            </Space>
                        </Form.Item>

                        <CKToolTip
                            placement='top'
                            content={t(
                                'addScheduleConstraintModal.youConfigureDetermineUsable',
                            )}
                            trigger='click'
                        >
                            <p className='text-gray-400'>
                                {t(
                                    'addScheduleConstraintModal.youConfigureDetermineUsable',
                                )}
                            </p>
                        </CKToolTip>

                        {isEdit ? (
                            <div className='min-h-[300px] w-full mt-8'>
                                <Table
                                    columns={columns}
                                    dataSource={timePickers}
                                />
                            </div>
                        ) : (
                            <>
                                <div className='min-w-[400px] flex flex-row'>
                                    <Label
                                        label={t(
                                            'scheduleAndPeriodConstraints.scheduleOf',
                                        )}
                                        className='w-[142px]'
                                    />
                                    <Label
                                        label={t(
                                            'scheduleAndPeriodConstraints.scheduleAt',
                                        )}
                                        className='w-[142px] ml-[10px]'
                                    />
                                </div>

                                <div className='min-w-[400px] min-h-[300px] max-h-[300px] overflow-y-auto'>
                                    {timePickers.map(
                                        (
                                            { id, startHours, endHours },
                                            index,
                                        ) => (
                                            <React.Fragment key={id}>
                                                <div className='flex'>
                                                    <Form.Item
                                                        name={`startHours_${id}`}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t(
                                                                    'addScheduleConstraintModal.pleaseSelectStartTime',
                                                                ),
                                                            },
                                                        ]}
                                                    >
                                                        <TimePicker
                                                            value={startHours}
                                                            onChange={(value) =>
                                                                handleChangeStartDate(
                                                                    value,
                                                                    id,
                                                                )
                                                            }
                                                            placeholder={t(
                                                                'addScheduleConstraintModal.startTime',
                                                            )}
                                                            format={formatDate}
                                                            use12Hours={false}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={`endHours_${id}`}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t(
                                                                    'addScheduleConstraintModal.pleaseSelectEndTime',
                                                                ),
                                                            },
                                                        ]}
                                                    >
                                                        <TimePicker
                                                            value={endHours}
                                                            onChange={(value) =>
                                                                handleChangeEndDate(
                                                                    value,
                                                                    id,
                                                                )
                                                            }
                                                            placeholder={t(
                                                                'addScheduleConstraintModal.endTime',
                                                            )}
                                                            format={formatDate}
                                                            use12Hours={false}
                                                            style={{
                                                                marginLeft:
                                                                    '10px',
                                                            }}
                                                        />
                                                    </Form.Item>

                                                    {/* Icône de suppression */}
                                                    {index > 0 && (
                                                        <DeleteIcon
                                                            onClick={() =>
                                                                handleClickDelete(
                                                                    id,
                                                                )
                                                            }
                                                            className='w-[20px] cursor-pointer ml-4'
                                                        />
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        ),
                                    )}
                                </div>
                            </>
                        )}

                        <div className='w-[50px] h-[50px] cursor-pointer my-4 text-primary-color'>
                            {timePickers.length < 15 && (
                                <IoIosAddCircleOutline
                                    onClick={handleClickAddTimeSlot}
                                    className='w-full h-full'
                                />
                            )}
                        </div>

                        <AddTimeSlotModal
                            isModalVisible={isModalVisibleAddTimeSlot}
                            setIsModalVisible={setIsModalVisibleAddTimeSlot}
                            isEdit={isEditFormTimeSlot}
                            timeSlotId={timeSlotId}
                        />
                    </div>

                    <FormLegend requiredFormItemLegend={true} />
                </Form>
            )}
        </Modal>
    );
}
