import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { httpGet } from '../../modApi/apis/httpConfig';

export const get_products_by_group_paginated = createAsyncThunk(
    'mod_box/get_products_by_group_paginated',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_box/groups/${values.groupId}/products/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                    },
                },
            );

            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

const boxSlice = createSlice({
    name: 'box',
    initialState: {
        products: [],
        productsCount: 0,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            /* get_products_by_group_paginated */

            .addCase(
                get_products_by_group_paginated.pending,
                (state, action) => {
                    state.products = [];
                    state.productsCount = 0;
                },
            )

            .addCase(
                get_products_by_group_paginated.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.products = action.payload.results;
                    state.productsCount = action.payload.count;
                },
            )
            .addCase(
                get_products_by_group_paginated.rejected,
                (state, action) => {
                    state.loading = false;
                },
            );
    },
});

export const {} = boxSlice.actions;

export default boxSlice.reducer;
