import React from 'react';
import { Route } from 'react-router-dom';
import Plans from 'ck_commun/src/app/modManager/pages/Plans';
import HomePage from 'ck_commun/src/app/modUtils/pages/HomePage';
import CkRoutes from 'ck_commun/src/app/modUtils/routes/CkRoutes';
import LoginPage from 'ck_commun/src/app/modAuthentication/pages/LoginPage';
import CrmRoutes from 'ck_commun/src/app/modUtils/routes/CrmRoutes';
import PromoRoutes from 'ck_commun/src/app/modUtils/routes/PromoRoutes';
import BadgesRoutes from 'ck_commun/src/app/modUtils/routes/BadgesRoutes';
import HistoryRoutes from 'ck_commun/src/app/modUtils/routes/HistoryRoutes';
import PrivateRoutes from 'ck_commun/src/app/modUtils/routes/PrivateRoutes';
import ActivityRoutes from 'ck_commun/src/app/modUtils/routes/ActivityRoutes';
import SettingsRoutes from 'ck_commun/src/app/modUtils/routes/SettingsRoutes';
import EquipmentRoutes from 'ck_commun/src/app/modUtils/routes/EquipmentRoutes';
import MainLayout from 'ck_commun/src/app/modUtils/components/layout/MainLayout';
import BuildingPage from 'ck_commun/src/app/modUtils/components/errorPage/BuildingPage';
import { IS_PROD } from '../utils/constants';
import LockerRoutes from './LockerRoutes';

export default function MainRoutes() {
    return (
        <CkRoutes>
            <Route exact path='/' element={<LoginPage />} />
            <Route element={<PrivateRoutes />}>
                <Route element={<MainLayout />}>
                    <Route path='/home' element={<HomePage />} />
                    <Route path='plans' element={<Plans />} />
                    <Route path='equipment/*' element={<EquipmentRoutes />} />
                    <Route path='locker/*' element={<LockerRoutes />} />
                    <Route path='activity/*' element={<ActivityRoutes />} />
                    <Route path='history/*' element={<HistoryRoutes />} />
                    <Route path='badges/*' element={<BadgesRoutes />} />
                    <Route path='settings/*' element={<SettingsRoutes />} />

                    {IS_PROD === false && (
                        <>
                            <Route path='promo/*' element={<PromoRoutes />} />
                            <Route path='crm/*' element={<CrmRoutes />} />
                            <Route path='store' element={<BuildingPage />} />
                        </>
                    )}
                </Route>
            </Route>
        </CkRoutes>
    );
}
