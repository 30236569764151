import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import './Menu.scss';
import { hasPermission } from '../../../functions/permissions/handlePermissions';
import { useTranslation } from 'react-i18next';
import { FaAngleDown } from 'react-icons/fa';

const MenuItem = ({
    label,
    itemKey,
    icon,
    submenus,
    openKeys,
    onOpenChange,
    collapsed,
    currentLink,
    setCurrentLink,
    onClick,
    permissionName,
    openModal,
    toggleMobileMenu,
    hiddenWithoutPermissions = false,
    ...props
}) => {
    const { t } = useTranslation();
    const isOpen = openKeys.includes(itemKey);
    const navigate = useNavigate();
    const location = useLocation();

    const { groupId, selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup?.permissions;
    const groupPermissions = selectedGroup?.plan?.permissions;

    const userHasPermission = permissionName
        ? hasPermission(permissionName, permissions)
        : true;

    const groupHasPermission = permissionName
        ? hasPermission(permissionName, groupPermissions)
        : true;

    const isHidden = !groupHasPermission && hiddenWithoutPermissions;

    useEffect(() => {
        // Check if the current item is active and set the activeMenuKey of the  local storage accordingly
        itemKey == currentLink &&
            localStorage.setItem('activeMenuKey', itemKey);
    }, [itemKey, currentLink]);

    const handleItemClick = () => {
        if (!userHasPermission) {
            setCurrentLink(itemKey);
            openModal();
        } else {
            if (toggleMobileMenu) {
                if (!submenus) {
                    toggleMobileMenu();
                }
            }
            if (submenus) {
                onOpenChange(itemKey);
            } else if (itemKey) {
                setCurrentLink(itemKey); // Update the active menu item in the parent component
                localStorage.setItem('activeMenuKey', itemKey);
                if (onClick) {
                    onClick(); // Call the provided onClick function
                }
                navigate(`/${itemKey}`);
            }
        }
    };
    const subMenuList = () => {
        return (
            <ul className='submenu'>
                {submenus?.map((submenu) => (
                    <MenuItem
                        key={submenu.itemKey}
                        itemKey={submenu.key}
                        {...submenu}
                        openKeys={openKeys}
                        onOpenChange={onOpenChange}
                        currentLink={currentLink} // Pass the current active menu item as prop
                        setCurrentLink={setCurrentLink} // Pass the function to update the active menu item
                        openModal={openModal}
                        hiddenWithoutPermissions={hiddenWithoutPermissions}
                    />
                ))}
            </ul>
        );
    };

    const classNameItemDisabled =
        '!bg-gray-500 !cursor-not-allowed !opacity-40';

    const LabelTranslated = t(label);

    return (
        <>
            {isHidden ? null : ( // Si l'élément est caché, ne rien afficher
                <li
                    key={itemKey} // Clé unique pour l'élément de menu
                    onClick={handleItemClick} // Gestionnaire de clic
                    className={`
                        ${!userHasPermission && classNameItemDisabled}
                        hover:backdrop-brightness-[0.85]
                        ${itemKey === location.pathname.substring(1) ? 'menu-active backdrop-brightness-[0.85]' : ''} 
                        ${groupHasPermission === false && classNameItemDisabled}
                    `}
                    // .substring(1) est utilisé pour récupérer la clé du menu à partir de l'URL sans "/"
                >
                    {/* Afficher l'icône si elle existe */}
                    {icon && (
                        <div className='menu-item tooltip'>
                            <div className='active-menu-border'></div>
                            <span className='menu-item-icon'>{icon}</span>
                            {/* Afficher le tooltip si le menu est réduit */}
                            {collapsed && (
                                <span className='tooltiptext'>
                                    {submenus ? (
                                        subMenuList() // Afficher les sous-menus dans le tooltip
                                    ) : (
                                        <span className='menu-item-label'>
                                            {LabelTranslated}
                                        </span>
                                    )}
                                </span>
                            )}
                        </div>
                    )}
                    {/* Afficher le label si le menu n'est pas réduit */}
                    {!collapsed && (
                        <span className='menu-item-label'>
                            {LabelTranslated}
                        </span>
                    )}
                    {/* Afficher l'icône de déploiement si des sous-menus existent et que le menu n'est pas réduit */}
                    {submenus && !collapsed && (
                        <FaAngleDown
                            color='white'
                            className={
                                isOpen
                                    ? 'menu-expand-icon expanded' // Classe si le menu est ouvert
                                    : 'menu-expand-icon' // Classe par défaut
                            }
                        />
                    )}
                </li>
            )}
            {/* Afficher les sous-menus si le menu est ouvert et non réduit */}
            {isOpen && submenus && !collapsed && (
                <li
                    onClick={() => toggleMobileMenu && toggleMobileMenu()} // Fermer le menu mobile si nécessaire
                    className={`${!userHasPermission && classNameItemDisabled}`} // Désactiver si l'utilisateur n'a pas la permission
                >
                    {subMenuList()} {/* Afficher la liste des sous-menus */}
                </li>
            )}
        </>
    );
};

MenuItem.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired, //le label d'un élément du menu
    itemKey: PropTypes.string.isRequired, //la clé d'un élément  du menu, elle présente le chemin complet à utiliser dans routes pour la navgation
    collapsed: PropTypes.bool, //définit l'état courant du menu (s'il est collapsed ou non)
    openKeys: PropTypes.array.isRequired, //les clés des sous-menus actuellement ouverts
    submenus: PropTypes.array, //la liste des sous-menus d'un élément du menu
    onClick: PropTypes.func, //fonction à appeler quand l'élement du menu est cliqué
    userHasPermission: PropTypes.bool,
};

export default MenuItem;
