import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import InputBaseForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/InputBaseForm';
const { Option } = Select;

export default function SelectForm({
    name = 'select',
    options = [{ label: 'selectForm.noData', value: null }],
    onChange,
    messages = {},
    className = '',
    defaultValue,
    markerRequired = true,
}) {
    const { t } = useTranslation();

    const mergedMessages = {
        label: 'selectForm.label', // todo a faire
        help: 'selectForm.help', // todo a faire
        required: 'selectForm.required', // todo a faire
        placeholder: 'selectForm.placeholder', // todo a faire
        ...messages,
    };

    return (
        <InputBaseForm
            name={name}
            messages={mergedMessages}
            className={className}
            markerRequired={markerRequired}
            rules={[
                {
                    required: true,
                    message: t(mergedMessages.required),
                },
            ]}
        >
            <Select
                placeholder={t(mergedMessages.placeholder)}
                defaultValue={defaultValue}
                onChange={onChange}
            >
                {options?.map((option) => (
                    <Option key={`option-${option.value}`} value={option.value}>
                        {t(option.label)}
                    </Option>
                ))}
            </Select>
        </InputBaseForm>
    );
}
