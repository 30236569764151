/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/device/e_device_code_printer.py
Aucune modification manuelle acceptée
*/

// Codes erreurs spécifiques à la catégorie de périphériques imprimantes
// 
// Anciennement EFEATURECID_PRINTER_ERRORCODE
const eDeviceCodePrinter = Object.freeze({
  // Imprimante erreur température
  101: 'eDeviceCodePrinter.temperature',

  // Imprimante Tête levée
  102: 'eDeviceCodePrinter.headerUp',

  // Imprimante sans papier
  103: 'eDeviceCodePrinter.noPaper',

  // Erreur d'alimentation de l'imprimante
  104: 'eDeviceCodePrinter.power',

  // Imprimante impression
  105: 'eDeviceCodePrinter.inProgress',

  // Imprimante hors service
  106: 'eDeviceCodePrinter.offline',

  // Erreur de coupe de l'imprimante
  107: 'eDeviceCodePrinter.cutterError',

  // Imprimante erreur capteur 1
  108: 'eDeviceCodePrinter.sensor1Error',

  // Imprimante erreur capteur 2
  109: 'eDeviceCodePrinter.sensor2Error',

  // Erreur NEOP imprimante
  110: 'eDeviceCodePrinter.neopError',

  // Imprimante erreur 11
  111: 'eDeviceCodePrinter.error',

  // Imprimante erreur inconnue
  112: 'eDeviceCodePrinter.errorUnknown',

  // Imprimante erreur Xoff
  113: 'eDeviceCodePrinter.xoff',
});

export default eDeviceCodePrinter;
