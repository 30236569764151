import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Language from '../../locale/language';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import { useDispatch, useSelector } from 'react-redux';
import {
    get_user_details,
    user_details_update,
} from '../../../modAuthentication/slices/authenticationSlice';
import eLanguage from '../../../../commun_global/js/system/e_language';

const colorVariants = {
    dark: 'text-gray-500',
    light: 'text-white',
};

export function CountryFlag({
    flagIcon,
    languageLabel,
    countryCode,
    onClick,
    color,
}) {
    return (
        <div
            onClick={onClick}
            key={countryCode}
            className='w-fit mx-[5px] flex flex-col items-center'
        >
            <img
                className='w-[25px]'
                src={process.env.PUBLIC_URL + `/img/flags/${flagIcon}`}
            />
            <div className={`text-[9px] font-medium ${colorVariants[color]}`}>
                {languageLabel}
            </div>
        </div>
    );
}

export default function LanguageSwitcher({ color }) {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const userId = localStorage.getItem('user');
    const { user } = useSelector((state) => state.authentication);
    const [defaultLang, setDefaultlang] = useState(
        localStorage.getItem('i18nextLng'),
    );

    useEffect(() => {
        if (userId && user) {
            var lang = eLanguage[user.lang]?.split('.')[1]
            setDefaultlang(lang);
            document.documentElement.lang = lang;
        }
    }, [userId, user]);

    const [showFlags, setShowFlags] = useState(false);

    const toggleFlags = () => {
        setShowFlags(!showFlags);
    };

    const updateLangUser = async (lang) => {
        try {
            await dispatch(
                user_details_update({ userId: userId, lang: lang }),
            ).then((res) => {
                dispatch(get_user_details(userId));
            });
        } catch (error) {
            console.error('error', error);
        }
    };

    const changeLanguage = (language, lang) => {
        setDefaultlang(language);
        i18n.changeLanguage(language);
        setShowFlags(false); // Pour cacher la liste après avoir sélectionné une langue
        // Modifier la langue de l'utilisateur
        if (userId) updateLangUser(lang);
    };

    return (
        <div className='flex flex-row justify-end'>
            <div
                className={`w-[25px] h-[25px] text-center ${colorVariants[color]}`}
                onClick={toggleFlags}
            >
                {showFlags ? (
                    <FaArrowRight className='h-[25px]' />
                ) : (
                    <FaArrowLeft className='h-[25px]' />
                )}
            </div>

            <div className='flag-list flex flex-row justify-end'>
                {showFlags ? (
                    <>
                        <CountryFlag
                            flagIcon='en.png'
                            languageLabel={t('common.transEnglish')}
                            countryCode={Language.EN}
                            onClick={() => changeLanguage(Language.EN, 1)}
                            color={color}
                        />
                        <CountryFlag
                            flagIcon='fr.png'
                            languageLabel={t('common.transFrench')}
                            countryCode={Language.FR}
                            onClick={() => changeLanguage(Language.FR, 0)}
                            color={color}
                        />
                        {/* Ajoutez d'autres drapeaux de pays ici */}
                    </>
                ) : (
                    <CountryFlag
                        flagIcon={`${defaultLang}.png`}
                        countryCode={i18n.language}
                    />
                )}
            </div>
        </div>
    );
}
