import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { eCdbsPaymentType } from '../../../../commun_global/enums';
import { amountFormatWithCurrencySymbol } from '../../../modUtils/functions/values/numberFormat';
import './EquipmentSaleDetails.scss';

export default function EquipmentSaleDetails({ sale }) {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const openPopover = () => {
        setVisible(!visible);
    };

    const columns = [
        {
            title: t('equipmentSaleDetails.tableTypePayment'),
            dataIndex: 'type_payment',
            key: 'type_payment',
            width: '35%',
            render: (text) => t(eCdbsPaymentType[text]),
        },
        {
            title: t('equipmentSaleDetails.tableCount'),
            dataIndex: 'count',
            key: 'count',
            width: '35%',
            render: (text, record) => (
                <span>
                    {record?.count > 1 && record?.count + ' * '}
                    {amountFormatWithCurrencySymbol(record?.unit)}
                </span>
            ),
        },
        {
            title: t('equipmentSaleDetails.tableAmount'),
            dataIndex: 'amount',
            key: 'amount',
            width: '30%',
            render: (text, record) => (
                <span>{amountFormatWithCurrencySymbol(text)}</span>
            ),
        },
    ];

    const style = {
        th: {
            backgroundColor: '#F1F3F9',
            color: '#8898AA',
            fontSize: '11px',
            fontWeight: '500',
            height: '28px',
            padding: 0,
        },
        td: {
            height: '25px',
            padding: 0,
            border: '1px solid #F7FAFC',
        },
    };

    return (
        <div>
            <div className={`chevron-down ${visible ? 'expanded' : ''}`}>
                <img
                    src={process.env.PUBLIC_URL + '/img/chevron-right.svg'}
                    onClick={openPopover}
                />
            </div>
            {visible && (
                <div className='saleDetailDiv flex-display flex-row justify-content-between'>
                    <div
                        className='flex-display flex-column justify-content-between align-items-start'
                        style={{ width: '30%' }}
                    >
                        <ul
                            style={{
                                color: '#000000',
                                fontSize: '11px',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                textAlign: 'left',
                                padding: '0 15px',
                            }}
                        >
                            {t('equipmentSaleDetails.productsPurchased')}
                            {sale?.retails?.map((product) => (
                                <li
                                    key={sale.id + product.label_local}
                                    style={{
                                        fontWeight: '500',
                                        listStyle: 'none',
                                    }}
                                >
                                    -{' '}
                                    {product?.label_local +
                                        ' (' +
                                        product?.local_id +
                                        ')' +
                                        ' X ' +
                                        product.quantity}
                                </li>
                            ))}
                        </ul>
                        <button className='ButtonSubmit block'>
                            <div> {t('equipmentSaleDetails.priceTotal')}</div>{' '}
                            <div>{sale?.price_total_selected} €</div>
                        </button>
                    </div>
                    <div style={{ width: '70%' }}>
                        <Table
                            columns={columns}
                            dataSource={sale.payments}
                            style={style}
                        ></Table>
                    </div>
                </div>
            )}
        </div>
    );
}
