import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { shared_sites_paginated_get } from 'ck_commun/src/app/modSharedEquipment/slices/sharedEquipmentSlice';
import SelectPaginatedForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/SelectPaginatedForm';

export default function SelectSharedSitesForm({ group }) {
    const dispatch = useDispatch();
    const [options, setOptions] = useState();
    const { sharedSites, hasMoreSites } = useSelector(
        (state) => state.sharedEquipment,
    );

    //
    const getDataPaginated = async (limit, offset, search) => {
        return await dispatch(
            shared_sites_paginated_get({
                limit: limit,
                offset: offset,
                name: search,
                group: group,
            }),
        ).unwrap();
    };

    // Formatage des options pour le Select
    useEffect(() => {
        if (sharedSites?.length) {
            const formattedOptions = sharedSites.map((site) => ({
                value: site?.id,
                label: `${site?.name}`,
            }));
            setOptions(formattedOptions);
        }
    }, [sharedSites]);

    return (
        <SelectPaginatedForm
            hasMore={hasMoreSites}
            className='text-left'
            name='sites'
            markerRequired={false}
            messages={{
                label: 'selectSharedSitesForm.label',
                help: '',
                required: '',
                placeholder: 'selectSharedSitesForm.placeholder',
            }}
            fetchOptions={getDataPaginated}
            options={options}
        />
    );
}
