import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { httpGet } from '../apis/httpConfig';

//retourner la liste de tous les paement

export const get_enums = createAsyncThunk(
    'mod_api/get_enums',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_api/enums/${values.name}/${values.methodName}/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                    },
                },
            );

            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

const apiSlice = createSlice({
    name: 'api',
    initialState: { paymentTypes: [] },
    reducers: {},
    extraReducers: (builder) => {
        builder

            /* get_enums */
            .addCase(get_enums.pending, (state, action) => {
                state.paymentTypes = [];
            })

            .addCase(get_enums.fulfilled, (state, action) => {
                state.loading = false;
                state.paymentTypes = action.payload.result;
            })

            .addCase(get_enums.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export const {} = apiSlice.actions;

export default apiSlice.reducer;
