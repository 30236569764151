import React, { useState, useEffect } from 'react';
import {
    Modal,
    Input,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { Form } from 'antd';
import DatePicker from 'ck_commun/src/app/modUtils/components/componentsLibrary/datePicker/DatePicker';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FormLegend from '../../../modUtils/components/FormLegend';
import moment from 'moment';
import {
    create_actions_by_support_by_group,
    get_actions_by_support_by_group_paginated,
} from '../../slices/walletLocalSlice';
import { get_sites_by_group_paginated } from '../../../modManager/slices/managerSlice';
import ConfirmationModal from '../../../modUtils/components/confirmationModal/ConfirmationModal';
import { amountFormatWithCurrencySymbol } from '../../../modUtils/functions/values/numberFormat';
import { message } from 'antd';
import AlertMessage from '../../../modUtils/components/AlertMessage';
import DiscountRecharge from '../discountRecharge/DiscountRecharge';
import CKToolTip from '../../../modUtils/components/CKToolTip/CKToolTip';
import { get_enums } from '../../../modApi/slices/apiSlice';
import eCdbsPaymentType from '../../../../commun_global/js/payment/e_cdbs_payment_type';

import { Select } from 'antd';

export default function SupportCreditModal({
    modalVisible,
    setModalVisible,
    support,
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [confirmModalVisible, setConfirmModalVisible] = useState(false); // État pour le modal de confirmation
    const [confirmLoading, setConfirmLoading] = useState(false);
    const { groupId, sites } = useSelector((state) => state.manager);
    const formatDate = 'DD/MM/YYYY';
    const [selectedDate, setSelectedDate] = useState(null);
    const defaultValidatedDate = moment().add(6, 'months'); //par défaut après six mois de la date actuelle
    const [rechargeValue, setRechargeValue] = useState();
    const [creditValue, setCreditValue] = useState();
    const { discounts } = useSelector((store) => store.walletLocal);
    const { paymentTypes } = useSelector((store) => store.api);
    const [paymentType, setPaymentType] = useState();
    const [siteId, setSiteId] = useState();
    const [selectCardAmount, setSelectCardAmount] = useState(null);

    const openModal = () => {
        form.resetFields();
        form.setFieldValue('validatedDate', defaultValidatedDate);
        setModalVisible(true);
        closeModal();
    };

    const getSupportActions = async (offset, limit) => {
        await dispatch(
            get_actions_by_support_by_group_paginated({
                groupId,
                supportId: support.id,
                ordering: '-date',
                limit,
                offset,
            }),
        ).unwrap();
    };

    const getPaymentTypes = async (offset, limit) => {
        await dispatch(
            get_enums({
                offset,
                limit,
                name: 'cklibs.ck_commun.mod_commun_global.python.payment.e_cdbs_payment_type',
                methodName: 'TypeCanBeUsedInShop',
            }),
        ).unwrap();
    };

    const getSiteDetails = async (offset, limit) => {
        await dispatch(
            get_sites_by_group_paginated({
                groupId,
                limit,
                offset,
            }),
        ).unwrap();
    };

    const handleInputChange = (e) => {
        const value = e.target.value;

        if (value === '') {
            setRechargeValue('');
            setCreditValue('');
        } else {
            const amount = Math.floor(value);

            const nearest = discounts
                .filter((d) => parseFloat(d.amount_min) <= amount)
                .reduce(
                    (prev, curr) =>
                        parseFloat(curr.amount_min) >
                        parseFloat(prev.amount_min)
                            ? curr
                            : prev,
                    { amount_min: '0', discount_percent: '0' },
                );

            const bonus = nearest.discount_percent;
            const calculatedCredit = amount + amount * (bonus / 100);

            setRechargeValue(amount);
            setCreditValue(calculatedCredit);
        }
    };

    const handleCardClick = (amount, bonus, credit) => {
        if (selectCardAmount === amount) {
            setSelectCardAmount(null);
            form.resetFields();
            setRechargeValue(null);
            setCreditValue(null);
        } else {
            setSelectCardAmount(amount);
            form.setFieldsValue({ amount });
            setRechargeValue(amount);
            setCreditValue(credit);
        }
    };

    const onExecuteUpdateBadgeSolde = async () => {
        try {
            // Valider les champs du formulaire
            await form.validateFields();
            // Si les champs sont validés, afficher le modal de confirmation
            setConfirmModalVisible(true);
        } catch (errorInfo) {
            // Si la validation échoue
            //console.log('Validation failed:', errorInfo);
        }
    };

    useEffect(() => {
        if (groupId) {
            getSiteDetails(0, 10);
            getPaymentTypes(0, 10);
        }
    }, [groupId]);

    const updatedPaymentTypes = paymentTypes
        .map((index) => {
            const value = eCdbsPaymentType[index];
            return value
                ? {
                      label: value.split('.').pop(),
                      value: value.split('.').pop(),
                      originalValue: index,
                  }
                : null;
        })
        .filter((item) => item !== null);

    const siteNames = sites?.map((site) => {
        return { label: site.name, value: site.id };
    });

    const handleConfirmOk = async () => {
        const formValues = form.getFieldsValue();
        const date = formValues.validatedDate
            ? formValues.validatedDate
            : defaultValidatedDate;
        const rechargeAmount = formValues.amount;
        try {
            setConfirmLoading(true);
            await dispatch(
                create_actions_by_support_by_group({
                    groupId,
                    supportId: support.id,
                    amount: rechargeAmount,
                    type: 1, //créditer
                    validatedDate: date
                        ? Math.floor(date.valueOf() / 1000)
                        : null,

                    paymentData: [
                        { amount: rechargeAmount, payment_type: paymentType },
                    ],
                    siteId: siteId,
                }),
            ).unwrap();

            setConfirmLoading(false);
            setRechargeValue(null);
            setCreditValue(null);
            setModalVisible(false);
            setConfirmModalVisible(false);
            getSupportActions(0, 10);
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('badge.badgeError')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
            setConfirmLoading(false);
            setConfirmModalVisible(false);
        }
    };

    const handleConfirmCancel = () => {
        setConfirmModalVisible(false);
    };

    const handleModalCancel = () => {
        form.resetFields();
        setRechargeValue(null);
        setCreditValue(null);
        setModalVisible(false);
    };

    // Fonction de validation personnalisée pour vérifier si la valeur est positive
    const validatePositiveNumber = (rule, value, callback) => {
        if (value && value < 0) {
            callback(t('badge.badgePositifNombre'));
        } else {
            callback();
        }
    };

    const handleDateChange = (date) => {
        const momentDate = moment(date, formatDate, true);
        setSelectedDate(momentDate);
        form.setFieldsValue({ validatedDate: momentDate });
    };

    const handleOnSelectPayment = (value) => {
        const selectedOption = updatedPaymentTypes.find(
            (item) => item.value === value,
        );
        setPaymentType(selectedOption.originalValue);
    };

    const handleSelectSite = (value) => {
        localStorage.setItem('SelectedSiteId', value);
        setSiteId(value);
    };

    const getStoredSite = siteNames.find(
        (item) =>
            item?.value === Number(localStorage.getItem('SelectedSiteId')),
    );

    useEffect(() => {
        if (getStoredSite) {
            form.setFieldsValue({ sites: getStoredSite.value });
            setSiteId(getStoredSite.value);
        }
    }, [getStoredSite, form]);

    return (
        <>
            <Modal
                title={`${t('badge.badgeCreditModalTitle')} N°${support.badge_number}`}
                isOpen={modalVisible}
                onClose={handleModalCancel}
                onOk={onExecuteUpdateBadgeSolde}
                onCancel={handleModalCancel}
                style={{
                    modalContent: {
                        minWidth: '300px',
                        width: '50%',
                        minHeight: '700px',
                    },
                }}
            >
                <div className='credit-support-form'>
                    <Form
                        form={form}
                        onFinish={onExecuteUpdateBadgeSolde}
                        layout='vertical'
                    >
                        <div className='grid grid-cols-3 gap-4 '>
                            <Form.Item
                                label={
                                    <CKToolTip
                                        placement='topLeft'
                                        trigger='hover'
                                        content={t(
                                            'discounts.inputTooltipRecharge',
                                        )}
                                    >
                                        {t('badge.badgeRechargeAmount')}
                                    </CKToolTip>
                                }
                                name='amount'
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'badge.badgeRequiredMessage',
                                        ),
                                    },
                                    {
                                        validator: validatePositiveNumber, // Utiliser la fonction de validation personnalisée
                                    },
                                ]}
                            >
                                <Input
                                    type='number'
                                    value={rechargeValue ? rechargeValue : ''}
                                    onChange={handleInputChange}
                                    required={true}
                                />
                            </Form.Item>

                            <Form.Item
                                label={
                                    <CKToolTip
                                        placement='topLeft'
                                        trigger='hover'
                                        content={t(
                                            'discounts.inputTooltipCredit',
                                        )}
                                    >
                                        {t('badge.badgeCreditAmount')}
                                    </CKToolTip>
                                }
                                readOnly
                            >
                                <Input
                                    type='number'
                                    value={creditValue ? creditValue : ''}
                                    disabled
                                    onChange={handleInputChange}
                                />
                            </Form.Item>

                            <Form.Item name='validatedDate'>
                                <DatePicker
                                    format={formatDate}
                                    label={`${t('badge.badgeValidationDate')}`}
                                    classNameLabel='mb-[5px]'
                                    selectedDate={
                                        selectedDate
                                            ? selectedDate
                                            : defaultValidatedDate
                                    }
                                    onDateChange={(date) =>
                                        handleDateChange(date)
                                    }
                                    required={true}
                                />
                            </Form.Item>
                        </div>
                        <DiscountRecharge
                            onDiscountClick={handleCardClick}
                            selectCardAmount={selectCardAmount}
                        />
                        <div className='grid grid-cols-2 gap-6 items-center justify-between mb-12'>
                            {' '}
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: t('discounts.requiredMessage'),
                                    },
                                ]}
                                name='payments'
                                label={t('discounts.paymentLabel')}
                                className='flex justify-center'
                            >
                                <Select
                                    placeholder={t(
                                        'discounts.paymentsPlaceholder',
                                    )}
                                    style={{ width: '160px', height: '40px' }}
                                    value={paymentType ? paymentType : null}
                                    options={updatedPaymentTypes}
                                    onSelect={(value) =>
                                        handleOnSelectPayment(value)
                                    }
                                ></Select>
                            </Form.Item>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: t('discounts.requiredMessage'),
                                    },
                                ]}
                                name='sites'
                                label={t('discounts.paymentSite')}
                                className='flex justify-center'
                            >
                                <Select
                                    placeholder='Site'
                                    className='h-8'
                                    style={{ width: '160px', height: '40px' }}
                                    options={siteNames}
                                    defaultValue={
                                        getStoredSite?.label
                                            ? getStoredSite?.label
                                            : null
                                    }
                                    onChange={(value) =>
                                        handleSelectSite(value)
                                    }
                                ></Select>
                            </Form.Item>
                        </div>
                    </Form>{' '}
                </div>

                <FormLegend requiredFormItemLegend={true} />
            </Modal>

            {/* Modal de confirmation */}
            <ConfirmationModal
                onOk={handleConfirmOk}
                onCancel={handleConfirmCancel}
                visible={confirmModalVisible}
                confirmLoading={confirmLoading}
                content={
                    <div>
                        {t('badge.badgeContent')}{' '}
                        <b>N°{support.badge_number}</b> {t('badge.badgeAmount')}{' '}
                        <b>{amountFormatWithCurrencySymbol(creditValue)} </b>
                        {t('badge.badgeSecondContent')}
                    </div>
                }
            />
        </>
    );
}
