import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { httpGet } from '../../modApi/apis/httpConfig';

//get_activity_cumulative permet de retourner un dictionnaire qui contients le total montant des cumules regrouper par un type de filtrage passé
export const get_activity_cumulative = createAsyncThunk(
    'mod_cumulative/get_activity_cumulative',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_cumulative/groups/${values.groupId}/activity`,
                {
                    params: {
                        param: values.param,
                        payment_group: values.paymentGroup,
                        year_last_active: values.yearLastActive,
                        start_date: values.startDate,
                        end_date: values.endDate,
                        sites: values.sites,
                        equipment_types: values.equipmentTypes,
                        equipments: values.equipments,
                    },
                },
            );

            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);
//Retourne les cumulatifs de la journée, de la semaine, du mois et de l'année. Pour la journée, on retourne également le type de paiement. Pour la semaine, mois et année on retourne les cumulatifs de avant et aussi l'année dernier.
export const get_dashboard_cumulative_by_group = createAsyncThunk(
    'mod_cumulative/get_dashboard_cumulative_by_group',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_cumulative/groups/${values.groupId}/dashboard`,
                {
                    params: {
                        sites: values.sites,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

//get_dashboard_cumulatives_latest_by_group : Récupérer les cumules par jour,mois, semaine et années dans un intervalle de temps "range"
export const get_dashboard_cumulatives_latest_by_group = createAsyncThunk(
    'mod_cumulative/get_dashboard_cumulatives_latest_by_group',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_cumulative/groups/${values.groupId}/cumulatives/latest`,
                {
                    params: {
                        range: values.range,
                        sites: values.sites,
                        equipments: values.equipments,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// get_dashboard_top_sites_by_group : Récupère les sites avec la plus grosse croissance d'activité en euro
export const get_dashboard_top_sites_by_group = createAsyncThunk(
    'mod_cumulative/get_dashboard_top_sites_by_group',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_cumulative/groups/${values.groupId}/dashboard/top_sites`,
                {
                    params: {
                        start_date: values.startDate,
                        end_date: values.endDate,
                        number_of_sites: values.numberOfSites,
                        sites: values.sites,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// get_dashboard_top_sites_growth_by_group : Récupère les sites avec la plus grosse croissance d'activité en pourcentage
export const get_dashboard_top_sites_growth_by_group = createAsyncThunk(
    'mod_cumulative/get_dashboard_top_sites_growth_by_group',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_cumulative/groups/${values.groupId}/dashboard/top_sites_growth`,
                {
                    params: {
                        start_date: values.startDate,
                        end_date: values.endDate,
                        number_of_sites: values.numberOfSites,
                        sites: values.sites,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

// get_statistic_by_payment_types_distribution_list : Retourne une liste contenant le moyen de paiement, son total ainsi que le pourcentage qu'il représente sur l'intervalle de date fournie.
export const get_statistic_by_payment_types_distribution_list =
    createAsyncThunk(
        'mod_cumulative/get_statistic_by_payment_types_distribution_list',
        async (values) => {
            const response = await httpGet(
                `mod_cumulative/groups/${values.groupId}/statistics/payment_type/distribution`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                        sites: values.sites,
                        type_equipment: values.typeEquipment,
                        start_date: values.startDate,
                        end_date: values.endDate,
                    },
                },
            );
            return response.data.results;
        },
    );

// get_statistic_by_payment_types_distribution_by_period_list : Retourne pour chaque période une liste contenant le moyen de paiement et son total sur l'intervalle de date fournie.
export const get_statistic_by_payment_types_distribution_by_period_list =
    createAsyncThunk(
        'mod_cumulative/get_statistic_by_payment_types_distribution_by_period_list',
        async (values) => {
            const response = await httpGet(
                `mod_cumulative/groups/${values.groupId}/statistics/payment_type/distribution/period`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                        sites: values.sites,
                        type_equipment: values.typeEquipment,
                        start_date: values.startDate,
                        end_date: values.endDate,
                    },
                },
            );
            return response.data.results;
        },
    );

// get_statistic_by_payment_types_global_by_period_list : Retourne pour chaque période deux listes : une des paiements réels, l'autre le reste.
// Contenant le moyen de paiement et son total sur l'intervalle de date fournie. Ainsi que le total que représente ces deux listes.
export const get_statistic_by_payment_types_global_by_period_list =
    createAsyncThunk(
        'mod_cumulative/get_statistic_by_payment_types_global_by_period_list',
        async (values) => {
            const response = await httpGet(
                `mod_cumulative/groups/${values.groupId}/statistics/payment_type/global/period`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                        sites: values.sites,
                        type_equipment: values.typeEquipment,
                        start_date: values.startDate,
                        end_date: values.endDate,
                    },
                },
            );
            return response.data.results;
        },
    );

// get_statistic_global_by_day_list : Retourne la repartition journalière des cumuls sur l'intervalle de temps fournie.
export const get_statistic_global_by_day_list = createAsyncThunk(
    'mod_cumulative/get_statistic_global_by_day_list',
    async (values) => {
        const response = await httpGet(
            `mod_cumulative/groups/${values.groupId}/statistics/global/by_day`,
            {
                params: {
                    sites: values.sites,
                    type_equipment: values.typeEquipment,
                    start_date: values.startDate,
                    end_date: values.endDate,
                },
            },
        );
        return response.data.results;
    },
);

const cumulativeSlice = createSlice({
    name: 'cumulative',
    initialState: {
        activityCumuleByGroupId: [],
        dashboardCumulativeByGroup: [],
        dashboardCumulativeLatestByGroup: [],
        dashboardTopSitesByGroup: [],
        dashboardTopSitesGrowthByGroup: [],
        statisticByPaymentTypesDistribution: [],
        statisticByPaymentTypesDistributionByPeriod: [],
        statisticByPaymentTypesGlobalByPeriod: [],
        statisticGlobalByDay: [],
        loading: true,
    },
    extraReducers: (builder) => {
        builder
            /* get_activity_cumulative */
            .addCase(get_activity_cumulative.fulfilled, (state, action) => {
                state.loading = false;
                state.activityCumuleByGroupId = action.payload;
            })

            .addCase(get_activity_cumulative.rejected, (state, action) => {
                state.loading = false;
            })

            /* get_dashboard_cumulative_by_group */
            .addCase(
                get_dashboard_cumulative_by_group.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.dashboardCumulativeByGroup = action.payload;
                },
            )
            .addCase(
                get_dashboard_cumulative_by_group.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )
            /* get_dashboard_cumulatives_latest_by_group */
            .addCase(
                get_dashboard_cumulatives_latest_by_group.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.dashboardCumulativeLatestByGroup = action.payload;
                },
            )
            .addCase(
                get_dashboard_cumulatives_latest_by_group.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )
            /* get_dashboard_top_sites_by_group */
            .addCase(
                get_dashboard_top_sites_by_group.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.dashboardTopSitesByGroup = action.payload.result;
                },
            )
            .addCase(
                get_dashboard_top_sites_by_group.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )
            /* get_dashboard_top_sites_growth_by_group */
            .addCase(
                get_dashboard_top_sites_growth_by_group.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.dashboardTopSitesGrowthByGroup =
                        action.payload.result;
                },
            )
            .addCase(
                get_dashboard_top_sites_growth_by_group.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )
            /* get_statistic_by_payment_types_distribution_list */
            .addCase(
                get_statistic_by_payment_types_distribution_list.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.statisticByPaymentTypesDistribution = action.payload;
                },
            )
            .addCase(
                get_statistic_by_payment_types_distribution_list.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )
            /* get_statistic_by_payment_types_distribution_by_period_list */
            .addCase(
                get_statistic_by_payment_types_distribution_by_period_list.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.statisticByPaymentTypesDistributionByPeriod =
                        action.payload;
                },
            )
            .addCase(
                get_statistic_by_payment_types_distribution_by_period_list.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )
            /* get_statistic_by_payment_types_global_by_period_list */
            .addCase(
                get_statistic_by_payment_types_global_by_period_list.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.statisticByPaymentTypesGlobalByPeriod =
                        action.payload;
                },
            )
            .addCase(
                get_statistic_by_payment_types_global_by_period_list.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )
            /* get_statistic_global_by_day_list */
            .addCase(
                get_statistic_global_by_day_list.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.statisticGlobalByDay = action.payload;
                },
            )
            .addCase(
                get_statistic_global_by_day_list.rejected,
                (state, action) => {
                    state.loading = false;
                },
            );
    },
});

export default cumulativeSlice.reducer;
