/**
 * Inverse un objet enum (clé -> valeur devient valeur -> clé)
 * @param {Object} enumObj - L'énumération à inverser
 * @returns {Object} - L'énumération inversée
 */
export function invertEnum(enumObj) {
    return Object.fromEntries(
        Object.entries(enumObj).map(([key, value]) => [value, Number(key)]),
    );
}
