import React from 'react';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import { Label } from '../componentsLibrary/componentsLibrary';
import CKToolTip from '../CKToolTip/CKToolTip';

const TooltipLabel = ({ label, tooltipMessage }) => {
    return (
        <div className='flex items-center gap-1'>
            {label && <Label label={label} />}
            <CKToolTip
                placement='bottom'
                content={<p className='text-xs' dangerouslySetInnerHTML={{ __html: tooltipMessage.replace('\n', '<br />') }} />}
                trigger='hover'
            >
                <HiQuestionMarkCircle color='#1B8ADC' fontSize={16} />
            </CKToolTip>
        </div>
    );
};

export default TooltipLabel;
