import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    wallet_by_group_paginated_get,
    wallet_detail_by_group_get,
} from 'ck_commun/src/app/modWalletCentralized/slices/walletCentralizedSlices';
import { amountFormatWithCurrencySymbol } from '../../modUtils/functions/values/numberFormat';

export default function WalletCentralizedDetail({ entity }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId } = useSelector((state) => state.manager);
    const { entityDetail } = useSelector((state) => state.entity);
    const { walletCentralizedList, walletCentralizedDetail } = useSelector(
        (state) => state.walletCentralized,
    );

    // retourne le wallet, par groupId et entityId
    const getWalletByGroupPaginated = async () => {
        await dispatch(
            wallet_by_group_paginated_get({
                groupId: groupId,
                entityId: entity.id,
                limit: 50,
            }),
        ).unwrap();
    };

    // retourne le détail d'un portefeuille
    const getWalletDetailByGroup = async () => {
        await dispatch(
            wallet_detail_by_group_get({
                groupId: groupId,
                walletId: walletCentralizedList[0]?.id,
            }),
        ).unwrap();
    };

    useEffect(() => {
        groupId && entityDetail && getWalletByGroupPaginated();
    }, [groupId, entityDetail]);

    useEffect(() => {
        groupId && walletCentralizedList[0]?.id && getWalletDetailByGroup();
    }, [groupId, walletCentralizedList]);

    return (
        <p className='text-center'>
            <span>{t('walletCentralizedDetail.sold')}</span>{' '}
            <span className='font-bold text-success-color'>
                {amountFormatWithCurrencySymbol(
                    walletCentralizedDetail?.balance,
                )}
            </span>
        </p>
    );
}
