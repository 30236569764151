import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, DatePicker } from 'antd';
import {
    constraints_period_range_create,
    constraints_period_range_detail_update,
} from '../slices/constraintPeriodSlice';
import FormLegend from '../../modUtils/components/FormLegend';
import {
    Modal,
    Spinner,
} from '../../modUtils/components/componentsLibrary/componentsLibrary';
import moment from 'moment';

export default function AddPeriodConstraintModal({
    isModalVisible,
    setIsModalVisible,
    periodRangesId,
    periodId,
    isEdit,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { groupId } = useSelector((state) => state.manager);
    const [form] = Form.useForm();
    const formatDate = 'DD/MM';
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const { constraintsPeriodDetail } = useSelector(
        (state) => state.profil.constraintPeriod,
    );

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {
        form.submit();
    };

    // ajouter nouvelle période
    const createPeriod = async (values) => {
        setLoading(true);
        await dispatch(
            constraints_period_range_create({
                groupId,
                constraintPeriodId: periodRangesId,
                startDay: values.startDay,
                startMonth: values.startMonth,
                endDay: values.endDay,
                endMonth: values.endMonth,
            }),
        ).unwrap();
        setLoading(false);
    };

    // modifier une période
    const updatePeriod = async (values) => {
        setLoading(true);
        await dispatch(
            constraints_period_range_detail_update({
                groupId,
                constraintPeriodId: periodRangesId,
                periodRangeId: periodId,
                startDay: values.startDay,
                startMonth: values.startMonth,
                endDay: values.endDay,
                endMonth: values.endMonth,
            }),
        ).unwrap();
        setLoading(false);
    };

    useEffect(() => {
        form.resetFields();

        if (isEdit && periodId) {
            // Recherche de la période à éditer dans constraintsPeriodDetail
            const periodToEdit = constraintsPeriodDetail?.period_ranges.find(
                (period) => period.id === periodId,
            );

            if (periodToEdit) {
                // Mise à jour des valeurs du formulaire avec les données de la période sélectionnée
                const { start_day, start_month, end_day, end_month } =
                    periodToEdit;

                // Convertir start_day/start_month et end_day/end_month en objets moment pour les DatePicker
                const startMoment = moment(
                    `${start_day} ${start_month}`,
                    'D MMMM',
                );
                const endMoment = moment(`${end_day} ${end_month}`, 'D MMMM');

                // Remplir les champs du formulaire
                form.setFieldsValue({
                    startDate: startMoment,
                    endDate: endMoment,
                });

                // Définir les dates locales pour startDate et endDate
                setStartDate(startMoment);
                setEndDate(endMoment);
            }
        }
    }, [isEdit, periodId, constraintsPeriodDetail, form]);

    // handle change datepicker start date
    const handleStartChange = (date) => {
        setStartDate(date);
        form.setFieldsValue({ startDate: date });
    };

    // handle change datepicker end date
    const handleEndChange = (date) => {
        setEndDate(date);
        form.setFieldsValue({ endDate: date });
    };

    // fonction appelée quand on envoie le formulaire
    const onFinish = (values) => {
        const startDay = values.startDate ? values.startDate.date() : null;
        const startMonth = values.startDate
            ? values.startDate.month() + 1
            : null;
        const endDay = values.endDate ? values.endDate.date() : null;
        const endMonth = values.endDate ? values.endDate.month() + 1 : null;

        const formValues = {
            startDay,
            startMonth,
            endDay,
            endMonth,
        };

        if (isEdit) {
            updatePeriod(formValues);
        } else {
            createPeriod(formValues);
        }
        form.resetFields();
        setIsModalVisible(false);
    };

    return (
        <Modal
            title={
                isEdit
                    ? t('addPeriodConstraintModal.editPeriod')
                    : t('addPeriodConstraintModal.addPeriod')
            }
            isOpen={isModalVisible}
            onClose={handleCancel}
            onCancel={handleCancel}
            onOk={handleOk}
        >
            <Form form={form} onFinish={onFinish} layout='vertical'>
                <div className='flex flex-row justify-center'>
                    <div className='grid grid-cols-2 gap-0 sm:gap-4 '>
                        <div className='col-span-2 sm:col-span-1'>
                            <Form.Item
                                label={t(
                                    'addPeriodConstraintModal.periodStartDate',
                                )}
                                name='startDate'
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'addPeriodConstraintModal.pleaseSelectStartDate',
                                        ),
                                    },
                                ]}
                            >
                                <DatePicker
                                    value={startDate}
                                    onChange={handleStartChange}
                                    placeholder={t(
                                        'addPeriodConstraintModal.startOfPeriod',
                                    )}
                                    format={formatDate}
                                />
                            </Form.Item>
                        </div>

                        <div className='col-span-2 sm:col-span-1'>
                            <Form.Item
                                label={t(
                                    'addPeriodConstraintModal.periodEndDate',
                                )}
                                name='endDate'
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'addPeriodConstraintModal.pleaseSelectEndDate',
                                        ),
                                    },
                                ]}
                            >
                                <DatePicker
                                    value={endDate}
                                    onChange={handleEndChange}
                                    placeholder={t(
                                        'addPeriodConstraintModal.endOfPeriod',
                                    )}
                                    format={formatDate}
                                    disabledDate={(current) => {
                                        return (
                                            startDate &&
                                            current &&
                                            current < startDate.endOf('day')
                                        );
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <FormLegend requiredFormItemLegend={true} />
                {loading && <Spinner />}
            </Form>
        </Modal>
    );
}
