import React from 'react';
import { useTranslation } from 'react-i18next';
import CKToolTip from 'ck_commun/src/app/modUtils/components/CKToolTip/CKToolTip';

export default function MarkerRequiredForm({
    content,
    className = '',
    markerRequired = false,
}) {
    const { t } = useTranslation();

    if (!markerRequired) return null;

    const tooltipContent = content || t('markerRequiredForm.markerRequired');

    return (
        <CKToolTip placement='top' trigger='hover' content={tooltipContent}>
            <span
                className={`cursor-help text-destructive-color text-sm inline-flex items-center ${className}`}
                aria-label={tooltipContent}
            >
                *
            </span>
        </CKToolTip>
    );
}
