import React from 'react';
import { useTranslation } from 'react-i18next';
import CkForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/CkForm';
import {
    Modal,
    Spinner,
} from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';

export default function CkFormModal({
    edit = false,
    form,
    status,
    loading,
    onDelete,
    onFinish,
    messages = {
        title: 'ckFormModal.form',
    },
    loadingDetail,
    initialValues,
    onFieldsChange,
    isModalVisible,
    setIsModalVisible,
    ...props
}) {
    const { t } = useTranslation();

    const onClose = () => {
        setIsModalVisible(false);
    };

    return (
        <Modal
            title={t(messages.title)}
            isOpen={isModalVisible}
            onClose={onClose}
        >
            {edit && loadingDetail ? (
                <Spinner />
            ) : (
                <CkForm
                    edit={edit}
                    form={form}
                    status={status}
                    loading={loading}
                    onClose={onClose}
                    messages={messages}
                    onFinish={onFinish}
                    onDelete={onDelete}
                    isInModal={true}
                    className='w-max !mx-auto'
                    initialValues={initialValues}
                    onFieldsChange={onFieldsChange}
                >
                    {props.children}
                </CkForm>
            )}
        </Modal>
    );
}
