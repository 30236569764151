import React from 'react';
// import { MdDelete } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
// import { useDispatch, useSelector } from "react-redux";
import { dateFormat } from '../../../modUtils/functions/values/dateFormat';
import SharedEquipmentForm from '../sharedEquipmentForm/SharedEquipmentForm';
// import { sharing_permissions_by_group_delete } from "../../../modSharedEquipment/slices/sharedEquipmentSlice";

export default function SharedEquipmentCard({
    sharedEquipment,
    equipment,
    fetchData,
}) {
    // const dispatch = useDispatch();
    const { t } = useTranslation();
    // const { groupId } = useSelector((state) => state.manager);

    // utile pour supprimé individuelement on l'utilisera plus tard
    // function deleteSharing(sharingPermissionId) {
    //     dispatch(
    //         sharing_permissions_by_group_delete({
    //         groupId: groupId,
    //         sharingPermissionId: sharingPermissionId
    //       })
    //     );

    //     fetchData();
    // };

    return (
        <div
            className={`border border-solid border-primary-color-100 rounded-lg m-5 p-3 w-max max-w-[300px] min-w-[300px]
            shadow-lg flex flex-col`}
        >
            <div className='absolute'>
                <div className='flex flex-row justify-end w-[274px]'>
                    <SharedEquipmentForm
                        equipment={equipment}
                        fetchData={fetchData}
                        edited={true}
                        sharedEquipment={sharedEquipment}
                    />
                </div>
            </div>

            <h5 className='m-0 font-bold'>
                {t('sharedEquipmentCard.company')}
            </h5>
            <p className='text-lg font-bold text-secondary-color'>
                {sharedEquipment?.group?.name}
            </p>

            <div className='flex flex-row justify-between text-xs'>
                <p>{t('sharedEquipmentCard.permissionName')}</p>
                <p>{t('sharedEquipmentCard.expirationDate')}</p>
            </div>

            <div className='min-h-[100px] max-h-[100px] overflow-y-auto'>
                {sharedEquipment?.shared_permissions?.map(
                    (shared_permissions) => (
                        <div className='flex flex-row justify-between truncate text-xs border-0 border-b-[0.5px] border-solid border-black mb-2 p-1'>
                            <p className='text-xs font-bold m-0'>
                                {shared_permissions?.permission?.title}
                            </p>

                            <p className='text-xs font-bold m-0'>
                                {shared_permissions?.expiration_date !== null
                                    ? dateFormat(
                                          shared_permissions?.expiration_date,
                                          'DD/MM/YYYY',
                                      )
                                    : t('sharedEquipmentCard.noExpiration')}
                            </p>
                        </div>
                    ),
                )}
            </div>
        </div>
    );
}
