/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/device/e_device_categories.py
Aucune modification manuelle acceptée
*/

// Categorie des péripheriques
const eDeviceCategories = Object.freeze({
  // Personnalisé
  0: 'eDeviceCategories.custom',

  // Lecteur de billets
  1: 'eDeviceCategories.billValidator',

  // Accepteur de pièces
  2: 'eDeviceCategories.coinValidator',

  // Système bancaire
  3: 'eDeviceCategories.bankingSystem',

  // Lecteur de badges.Anciennement FEATURECID_BARCODE_READER. Anciennement FEATURECID_BADGESYSTEM.
  4: 'eDeviceCategories.accountReader',

  // Périphérique
  5: 'eDeviceCategories.periph',

  // Imprimante
  6: 'eDeviceCategories.printer',

  // Vocal
  7: 'eDeviceCategories.vocal',

  // Mobile
  9: 'eDeviceCategories.mobile',

  // Historique
  10: 'eDeviceCategories.historic',

  // Écran externe
  11: 'eDeviceCategories.externScreen',

  // HTTP
  12: 'eDeviceCategories.http',

  // Configuration
  13: 'eDeviceCategories.config',

  // Marketing
  14: 'eDeviceCategories.marketing',

  // Accessoire
  15: 'eDeviceCategories.accessory',

  // Affichage
  16: 'eDeviceCategories.display',

  // Anciennement FEATURECID_GENERAL
  17: 'eDeviceCategories.application',

  // Code
  18: 'eDeviceCategories.code',

  // Capteur
  19: 'eDeviceCategories.sensor',

  // Objet propre au systeme. Exemple ComBridge est propre au system.
  20: 'eDeviceCategories.system',

  // Bus de communication
  21: 'eDeviceCategories.communicationBus',

  // Système d'entrées/sorties
  22: 'eDeviceCategories.ios',

  // Machines
  23: 'eDeviceCategories.machines',

  // Distributeur
  24: 'eDeviceCategories.distributor',

  // Point de service
  25: 'eDeviceCategories.servicespoint',

  // Dispositif de communication. Communication comme le module Lora, Modem ...
  26: 'eDeviceCategories.communicationDevices',

  // Domotique
  27: 'eDeviceCategories.domotics',

  // Pac195x
  28: 'eDeviceCategories.pac195X',

  // valeur max sur 6 bits
  63: 'eDeviceCategories.unused',
});

export default eDeviceCategories;
