import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonSubmit from 'ck_commun/src/app/modUtils/components/buttons/ButtonSubmit';
import { ReactComponent as CheckIcon } from 'ck_commun/src/app/modEntity/assets/images/check.svg';

export default function MessageSuccessForm({ messageSuccess, onClick }) {
    const { t } = useTranslation();

    return (
        <div className='flex flex-col justify-center h-[90%]'>
            <CheckIcon
                width='100'
                height='100'
                className='text-center mx-auto'
            />

            <p className='mt-8 text-center'>{messageSuccess}</p>

            <ButtonSubmit
                onClick={onClick}
                label={t('ckForm.back')}
                className='mx-auto'
            />
        </div>
    );
}
