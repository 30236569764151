import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Pagination.scss';
import Select from '../select/Select';
import Button from '../button/Button';
import { useTranslation } from 'react-i18next';
const Pagination = ({
    current,
    defaultCurrent,
    showSizeChange,
    defaultPageSize,
    hideOnSinglePage,
    pageSize,
    pageSizeOptions,
    showQuickJumper,
    showTotal,
    total,
    onChange,
    onShowSizeChange,
    disabled,
}) => {
    const [currentPage, setCurrentPage] = useState(defaultCurrent);
    const { t } = useTranslation();
    const [pageSizeValue, setPageSizeValue] = useState(
        defaultPageSize || pageSizeOptions[0],
    );
    const [quickJumperInputValue, setQuickJumperInputValue] =
        useState(defaultCurrent);
    useEffect(() => {
        setCurrentPage(current || defaultCurrent);
    }, [current, defaultCurrent, defaultPageSize, pageSize]);

    const handlePageSizeChange = (e) => {
        // setCurrentPage(1)
        const newPageSizeValue = Number(e);
        setPageSizeValue(newPageSizeValue);
        onShowSizeChange && onShowSizeChange(currentPage, newPageSizeValue);
    };

    const handlePageChange = (newPage) => {
        const totalPages = Math.ceil(total / pageSizeValue);
        if (newPage > totalPages) {
            setCurrentPage(2);
        } else if (newPage < 1) {
            setCurrentPage(1);
        } else {
            setCurrentPage(newPage);
        }

        onChange && onChange(newPage, pageSizeValue);
        onChange && setQuickJumperInputValue(newPage);
    };

    const totalPages = Math.ceil(total / pageSizeValue);
    //renderPageNumbers pour afficher la liste des numéros de page
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageDisplay = 3; // Nombre maximum de pages à afficher autour de la page actuelle incluse
        const ellipsis = <span className='pagination-ellipsis'>...</span>;
        for (let numPage = 1; numPage <= totalPages; numPage++) {
            if (
                numPage === 1 ||
                numPage === totalPages ||
                (currentPage <= 3 && numPage < 5) ||
                (currentPage >= totalPages - 2 && numPage >= totalPages - 3) ||
                (numPage >= currentPage - Math.floor(maxPageDisplay / 2) &&
                    numPage <= currentPage + Math.floor(maxPageDisplay / 2))
            ) {
                // Affiche la première, la dernière et les pages autour de la page actuelle
                pageNumbers.push(
                    <li
                        key={numPage}
                        className={`page-item${currentPage === numPage ? ' active' : ''}`}
                    >
                        <span
                            className='page-link cursor-pointer'
                            onClick={() => handlePageChange(numPage)}
                        >
                            {numPage}
                        </span>
                    </li>,
                );
            } else if (
                (numPage === currentPage - Math.floor(maxPageDisplay / 2) - 1 &&
                    currentPage > 3) ||
                (numPage === currentPage + Math.floor(maxPageDisplay / 2) + 1 &&
                    currentPage < totalPages - 2) ||
                //test added
                (currentPage <= 2 && numPage <= 5) ||
                (currentPage >= totalPages - 1 &&
                    numPage === 2 &&
                    //test added
                    maxPageDisplay < totalPages)
            ) {
                // Ajoute les points de suspension
                pageNumbers.push(<div key={numPage}>{ellipsis}</div>);
            }
        }
        return pageNumbers;
    };

    const renderPageSizeOptions = () => {
        return pageSizeOptions.map((option) => (
            <option key={option} value={option}>
                {option}
            </option>
        ));
    };

    const renderTotal = () => {
        const start = Math.max((currentPage - 1) * pageSizeValue + 1, 1);
        const end = Math.min(start + pageSizeValue - 1, total);
        const displayedEnd = Math.min(end, total); // Limit the displayed end value to the total
        return `${start}-${displayedEnd} of ${total}`;
    };
    //handleKeyPress: change value of input of the quick jumper when pressing enter
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            const quickJumperInputValue = Number(e.target.value);
            if (quickJumperInputValue >= totalPages) {
                handlePageChange(totalPages);
            } else if (quickJumperInputValue <= 1) {
                handlePageChange(0);
            } else handlePageChange(quickJumperInputValue);
        }
    };
    return totalPages > 1 || !hideOnSinglePage ? (
        <nav
            aria-label='Pagination'
            className='pagination-container flex-display mb-2.5'
        >
            <div className='pagination justify-content-center flex-display align-item-center flex-col-reverse sm:flex-row gap-4'>
                <div className='flex-display'>
                    <div
                        className={`prec-btn page-item${
                            currentPage === 1 ? ' disabled' : ''
                        }`}
                    >
                        <Button
                            className='page-link'
                            type='primary'
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/img/design/IconRegGauche.png'
                                }
                            />
                        </Button>
                    </div>
                    {renderPageNumbers()}
                    <div
                        className={`next-btn page-item${
                            currentPage === totalPages ? ' disabled' : ''
                        }`}
                    >
                        <Button
                            className='page-link'
                            type='primary'
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    '/img/design/IconRegDroite.png'
                                }
                            />
                        </Button>
                    </div>
                    {showTotal && (
                        <div className='page-item'>
                            <span className='page-link'>{renderTotal()}</span>
                        </div>
                    )}
                </div>
                <div className='flex-display align-items-center justify-content-between gap-4'>
                    {showQuickJumper && (
                        <div className='page-item'>
                            <div className='input-group'>
                                <span>PAGE </span>
                                <input
                                    width={10}
                                    type='number'
                                    className='form-control'
                                    min='0'
                                    max={totalPages}
                                    value={quickJumperInputValue}
                                    onChange={(e) =>
                                        setQuickJumperInputValue(e.target.value)
                                    }
                                    onKeyPress={handleKeyPress}
                                />
                                <span> / {totalPages}</span>
                            </div>
                        </div>
                    )}
                    {showSizeChange && (
                        <div className='page-item'>
                            <div className='pagination-select'>
                                <label
                                    htmlFor='paginationSelect'
                                    className='pagination-select-label'
                                >
                                    {t('pagination.label')}
                                </label>

                                <select
                                    id='paginationSelect'
                                    className='form-select'
                                    value={pageSizeValue}
                                    onChange={(e) =>
                                        handlePageSizeChange(e.target.value)
                                    }
                                >
                                    {renderPageSizeOptions()}
                                </select>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    ) : null;
};

Pagination.defaultProps = {
    defaultCurrent: 1,
    //disabled: false,
    hideOnSinglePage: false,
    //pageSizeOptions: [20, 50, 100, 500, 1000, 5000],
    pageSizeOptions: [20, 50], //les autres valeurs sont éliminées vu que nos serveurs ne retournent que 50 au maximum
    defaultPageSize: 20,
    total: 0,
    showSizeChange: true,
};

Pagination.propTypes = {
    current: PropTypes.number, //Current page number
    defaultCurrent: PropTypes.number, //Default initial page number
    defaultPageSize: PropTypes.number, //Default number of data items per page
    hideOnSinglePage: PropTypes.bool, //Whether to hide pager on single page
    pageSize: PropTypes.number, //Number of data items per page
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number, PropTypes.string), //Specify the sizeChanger options
    showQuickJumper: PropTypes.bool, //Determine whether you can jump to pages directly
    showTotal: PropTypes.bool,
    total: PropTypes.number,
    onChange: PropTypes.func,
    onShowSizeChange: PropTypes.func,
    disabled: PropTypes.bool, //Disable pagination
    showSizeChange: PropTypes.bool,
};

export default Pagination;
