import React from 'react';
import { Route } from 'react-router-dom';
import CkRoutes from 'ck_commun/src/app/modUtils/routes/CkRoutes';
import StatisticsPage from 'ck_commun/src/app/modCumulative/pages/StatisticsPage';
import BuildingPage from 'ck_commun/src/app/modUtils/components/errorPage/BuildingPage';
import ActivityCAPage from 'ck_commun/src/app/modCumulative/pages/activityCAPage/ActivityCAPage';
import ActivityDashboardPage from 'ck_commun/src/app/modCumulative/pages/activityDashboardPage/ActivityDashboardPage';

export default function ActivityRoutes() {
    return (
        <CkRoutes>
            <Route path='dashboard' element={<ActivityDashboardPage />} />
            <Route path='ca' element={<ActivityCAPage />} />
            <Route path='marketing' element={<BuildingPage />} />
            <Route path='reporting' element={<BuildingPage />} />
            <Route path='statistics' element={<StatisticsPage />} />
        </CkRoutes>
    );
}
