import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { shared_groups_paginated_get } from 'ck_commun/src/app/modSharedEquipment/slices/sharedEquipmentSlice';
import SelectPaginatedForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/SelectPaginatedForm';

export default function SelectSharedGroupsForm({ onChange }) {
    const dispatch = useDispatch();
    const [options, setOptions] = useState();
    const { sharedGroups, hasMoreGroups } = useSelector(
        (state) => state.sharedEquipment,
    );

    //
    const getDataPaginated = async (limit, offset, search) => {
        return await dispatch(
            shared_groups_paginated_get({
                limit: limit,
                offset: offset,
                name: search,
            }),
        ).unwrap();
    };

    // Formatage des options pour le Select
    useEffect(() => {
        if (sharedGroups?.length) {
            const formattedOptions = sharedGroups.map((group) => ({
                value: group?.id,
                label: `${group?.name}`,
            }));
            setOptions(formattedOptions);
        }
    }, [sharedGroups]);

    return (
        <SelectPaginatedForm
            onChange={onChange}
            hasMore={hasMoreGroups}
            className='text-left'
            name='groups'
            markerRequired={false}
            messages={{
                label: 'selectSharedGroupsForm.label',
                help: '',
                required: '',
                placeholder: 'selectSharedGroupsForm.placeholder',
            }}
            fetchOptions={getDataPaginated}
            options={options}
        />
    );
}
