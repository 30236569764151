import React from 'react';

export default function InfoCard({ icon, title, subtitle }) {
    return (
        <div>
            <div
                className={`
                block rounded-xl border border-border-color p-4
                shadow-sm hover:border-primary-light-color hover:ring-1
                hover:ring-primary-light-color focus:outline-none focus:ring
                `}
            >
                <span className='inline-block rounded-lg bg-gray-50 p-3'>
                    {icon}
                </span>

                <h2 className='mt-4 text-base font-bold truncate'>{title}</h2>

                <p className='text-muted-foreground-color break font-semibold truncate'>
                    {subtitle}{' '}
                </p>
            </div>
        </div>
    );
}
