import configurationSlice from './configurationSlice';
import constraintFinancialSlice from './constraintFinancialSlice';
import constraintFincancialEntityCumulsSlice from './constraintFincancialEntityCumulsSlice';
import constraintIdentificationSlice from './constraintIdentificationSlice';
import constraintPeriodSlice from './constraintPeriodSlice';
import constraintServiceSlice from './constraintServiceSlice';
import constraintScheduleSlice from './constraintScheduleSlice';
import profilSlice from './profilSlice';
import { combineReducers } from '@reduxjs/toolkit';

const profilReducer = combineReducers({
    profil: profilSlice,
    configuration: configurationSlice,
    constraintFinancial: constraintFinancialSlice,
    constraintFincancialEntityCumuls: constraintFincancialEntityCumulsSlice,
    constraintIdentification: constraintIdentificationSlice,
    constraintPeriod: constraintPeriodSlice,
    constraintService: constraintServiceSlice,
    constraintSchedule: constraintScheduleSlice,
});
export default profilReducer;
