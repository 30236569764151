import React from 'react';
import Widget from 'ck_commun/src/app/modUtils/components/widget/Widget';
import { useSelector } from 'react-redux';
import { hasPermission } from 'ck_commun/src/app/modUtils/functions/permissions/handlePermissions';
import PermissionFadeContainer from '../../../modUtils/components/permissionFadeContainer/PermissionFadeContainer';
import { useTranslation } from 'react-i18next';
import { IoUnlinkSharp } from 'react-icons/io5';
import { FaCheckCircle, FaMinusCircle } from 'react-icons/fa';
import { IoIosWarning } from 'react-icons/io';

export default function WidgetIncident({ loading }) {
    const { countEquipmentStatus } = useSelector((state) => state.equipment);
    const { selectedGroup } = useSelector((state) => state.manager);
    const equimentsCount = selectedGroup?.equipment_count;
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission(
        'mod_equipment:show_device',
        permissions,
    );
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission(
        'mod_equipment:show_device',
        groupPermissions,
    );
    const { t } = useTranslation();

    let content = (
        <div className='widget-equipment-statistic'>
            <table className='w-full'>
                <tbody>
                    <tr className='h-[50px] text-primary text-[0.88em] w-full'>
                        <td>
                            <div
                                className={`rounded-full w-5 h-5 flex justify-center items-center${
                                    countEquipmentStatus.disconnectedCount
                                        ? ' bg-[#E30613]'
                                        : ' bg-[#2EA719]'
                                }`}
                            >
                                <IoUnlinkSharp className='text-white text-base rotate-45' />
                            </div>
                        </td>
                        <td className='pl-4'>
                            {t('widgetIncident.offlineEquipment')}
                        </td>
                        <td className='whitespace-nowrap font-bold text-[1.17em] text-right'>{`${countEquipmentStatus.disconnectedCount} / ${equimentsCount}`}</td>
                    </tr>
                    <tr className='h-[50px] text-primary text-[0.88em] w-full'>
                        <td>
                            {countEquipmentStatus.errorCount ? (
                                <IoIosWarning color='#FFB60D' fontSize={20} />
                            ) : (
                                <FaCheckCircle fontSize={20} color='#2EA719' />
                            )}
                        </td>
                        <td className='pl-4'>
                            {t('widgetIncident.errorEquipment')}
                        </td>
                        <td className='whitespace-nowrap font-bold text-[1.17em] text-right'>
                            {countEquipmentStatus.errorCount}
                        </td>
                    </tr>
                    <tr className='h-[50px] text-primary text-[0.88em] w-full'>
                        <td>
                            {countEquipmentStatus.outCount ? (
                                <FaMinusCircle color='#FF0000' fontSize={20} />
                            ) : (
                                <FaCheckCircle fontSize={20} color='#2EA719' />
                            )}
                        </td>
                        <td className='pl-4'>
                            {t('widgetIncident.OutServiceEquipment')}
                        </td>
                        <td className='whitespace-nowrap font-bold text-[1.17em] text-right'>
                            {countEquipmentStatus.outCount}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );

    return (
        !loading &&
        verifyPermission && (
            <PermissionFadeContainer permission={verifyGroupPermission}>
                <Widget title={t('widgetIncident.title')} content={content} />
            </PermissionFadeContainer>
        )
    );
}
