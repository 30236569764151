import React from 'react';
import { Route } from 'react-router-dom';
import CkRoutes from 'ck_commun/src/app/modUtils/routes/CkRoutes';
import SupportsPage from 'ck_commun/src/app/modWalletLocal/pages/SupportsPage';
import DiscountsPage from 'ck_commun/src/app/modWalletLocal/pages/DiscountsPage';
import BuildingPage from 'ck_commun/src/app/modUtils/components/errorPage/BuildingPage';

export default function BadgesRoutes() {
    return (
        <CkRoutes>
            <Route path='dashboard' element={<BuildingPage />} />
            <Route path='statistics' element={<BuildingPage />} />
            <Route path='subscription' element={<SupportsPage />} />
            <Route path='discounts' element={<DiscountsPage />} />
        </CkRoutes>
    );
}
