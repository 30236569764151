import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Input = ({
    bordered,
    className,
    labelClassName = 'text-[#394761]',
    defaultValue,
    disabled,
    id,
    maxLength,
    classnames,
    status,
    size,
    width,
    type,
    value,
    placeholder,
    name,
    onBlur,
    onFocus,
    onChange,
    onPressEnter,
    label,
    style,
    icon,
    svg,
    min,
    error,
    lang,
}) => {
    const [showPassword, setShowPassword] = useState(false); // État pour suivre si le mot de passe est visible ou non

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword); // Inverse l'état actuel de visibilité du mot de passe
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && onPressEnter) {
            onPressEnter();
        }
    };

    return (
        <div className={`input flex flex-col ${className}`}>
            <label
                htmlFor={id}
                className={`text-left text-primary-color text-sm font-bold ${labelClassName}`}
            >
                {label}
            </label>
            <div
                className={`input-wrapper flex flex-row justify-between items-center
                        ${disabled && 'bg-muted-color border border-muted-foreground-color text-muted-foreground-color cursor-not-allowed'}
                        ${error && 'border-destructive-color'}
                    `}
                style={{
                    width: width,
                    height: size,
                    ...style,
                }}
            >
                <input
                    name={name}
                    type={type === 'password' && showPassword ? 'text' : type} // Affiche le texte brut si showPassword est true
                    id={id}
                    defaultValue={defaultValue}
                    value={value}
                    disabled={disabled}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onKeyDown={handleKeyPress}
                    className={`bg-background-color ${classnames}`}
                    style={{ width: '100%' }}
                    min={min}
                />
                {type === 'password' && ( // Affiche l'icône d'œil uniquement pour les champs de type mot de passe
                    <span
                        className='password-toggle-icon'
                        onClick={handleTogglePasswordVisibility}
                    >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                )}
                {icon && <img src={icon} alt='input-icon' />}
                {svg && svg}
            </div>
            {error && error}
        </div>
    );
};

Input.defaultProps = {
    bordered: true,
    disabled: false,
    type: 'text',
    size: 40,
    className: 'flex-column',
};

Input.propTypes = {
    bordered: PropTypes.bool,
    // defaultValue,
    disabled: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // maxLength,
    // classnames,
    // status,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // type,
    // value,
    // placeholder,
    // onChange,
    //onPressEnter,
};
export default Input;
