import * as Yup from 'yup';
// Schémas de validation pour chaque étape
export const stepValidationSchemas = [
    Yup.object({
        promotionName: Yup.string().required('Required'),
        typePromo: Yup.string().required('Required'),
    }),
    Yup.object({
        startDateOfferObtainPromo: Yup.number().required('Required'),
        endDateOfferObtainPromo: Yup.string().required('Required'),
    }),
    Yup.object({
        startDateOfferUsePromo: Yup.number().required('Required'),
        endDateOfferUsePromo: Yup.string().required('Required'),
        discountAmount: Yup.string().required('Required'),
        amountUsePromo: Yup.string().required('Required'),
        maximumPrintingUsePromo: Yup.string().required('Required'),
        startDayValidityUsePromo: Yup.string().required('Required'),
        endDayValidityUsePromo: Yup.string().required('Required'),
    }),
];
