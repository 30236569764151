import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    httpDelete,
    httpGet,
    httpPost,
    httpPut,
} from 'ck_commun/src/app/modApi/apis/httpConfig';

// Retourne la liste des permissions partageable d'un groupe
export const permissions_by_group_list_get = createAsyncThunk(
    'mod_equipment_shared/permissions_by_group_list_get',
    async (values) => {
        const response = await httpGet(
            `/mod_equipment_shared/groups/${values.groupId}/permissions`,
            {
                params: {
                    limit: values.limit ? values.limit : 50,
                    offset: values.offset ? values.offset : 0,
                    groupId: values.groupId,
                },
            },
        );
        return response.data;
    },
);

// Récupère la liste des équipements partagés où l'on a accès.
export const shared_equipments_paginated_get = createAsyncThunk(
    'mod_equipment_shared/shared_equipments',
    async (values) => {
        const response = await httpGet(
            `/mod_equipment_shared/shared_equipments/`,
            {
                params: {
                    limit: values.limit,
                    offset: values.offset,
                    serial_number: values.serialNumber,
                    groups: values.groups,
                    sites: values.sites,
                },
            },
        );
        return response.data;
    },
);

// Récupère la liste des groupes partagés où l'on a accès.
export const shared_groups_paginated_get = createAsyncThunk(
    'mod_equipment_shared/shared_groups_paginated_get',
    async (values) => {
        const response = await httpGet(`/mod_equipment_shared/shared_groups/`, {
            params: {
                limit: values.limit,
                offset: values.offset,
                name: values.name,
            },
        });
        return response.data.results;
    },
);

// Récupère la liste des sites partagés où l'on a accès
export const shared_sites_paginated_get = createAsyncThunk(
    'mod_equipment_shared/shared_sites_paginated_get',
    async (values) => {
        const response = await httpGet(`/mod_equipment_shared/shared_sites/`, {
            params: {
                limit: values.limit,
                offset: values.offset,
                name: values.name,
                groups: values.groups,
            },
        });

        return response.data.results;
    },
);

// Création/Mise à jour d'un partage d'un équipements
export const sharing_by_equipment_by_group_update = createAsyncThunk(
    'mod_equipment_shared/sharing_by_equipment_by_group_update',
    async (values) => {
        try {
            await httpPut(
                `mod_equipment_shared/groups/${values.groupId}/equipments/${values.equipmentId}/sharings/`,
                {
                    equipmentId: values.equipmentId,
                    groupId: values.groupId,
                    group: values.group && values.group,
                    shared_permissions: values.shared_permissions,
                },
            );

            return {
                status: 'success',
                detail: 'Operation réalisée avec succès',
            };
        } catch (error) {
            if (error.response.status === 400) {
                if (error?.response?.data?.detail?.group[0]) {
                    return {
                        status: 'error',
                        detail: error?.response?.data?.detail?.group[0],
                    };
                } else {
                    return {
                        status: 'error',
                        detail: error?.response?.data?.detail
                            ?.shared_permissions[0],
                    };
                }
            } else {
                return { status: 'error', detail: 'Un problème est survenu.' };
            }
        }
    },
);

// Récupère la liste des partages d'un équipement
export const sharing_by_group_paginated_get = createAsyncThunk(
    'mod_equipment_shared/sharing_by_group_paginated_get',
    async (values) => {
        const response = await httpGet(
            `/mod_equipment_shared/groups/${values.groupId}/sharings`,
            {
                params: {
                    limit: values.limit ? values.limit : 20,
                    offset: values.offset ? values.offset : 0,
                    groupId: values.groupId,
                    sites: values.sites && values.sites,
                    equipments: values.equipments && values.equipments,
                },
            },
        );
        return response.data;
    },
);

// Supprime une permission d'un partage
export const sharing_permissions_by_group_delete = createAsyncThunk(
    'mod_equipment_shared/sharing_permissions_by_group_delete',
    async (values) => {
        const response = await httpDelete(
            `/mod_equipment_shared/groups/${values.groupId}/sharing_permissions/${values.sharingPermissionId}/`,
            {
                groupId: values.groupId,
                sharingPermissionId: values.sharingPermissionId,
            },
        );
        return response.data;
    },
);

// Récupère la liste des demandes de partage d'un équipement
export const sharing_request_list_get = createAsyncThunk(
    'mod_equipment_shared/sharing_request_list_get',
    async (values) => {
        const response = await httpGet(
            `/mod_equipment_shared/groups/${values.groupId}/sharing_requests`,
            {
                params: {
                    limit: values.limit ? values.limit : 50,
                    offset: values.offset ? values.offset : 0,
                    groupId: values.groupId,
                },
            },
        );
        // console.log(response)
        return response.data;
    },
);

// Demande d'obtention d'un partage.
export const sharing_request_create = createAsyncThunk(
    'mod_equipment_shared/sharing_request_create',
    async (values) => {
        try {
            const response = await httpPost(
                `mod_equipment_shared/groups/${values.groupId}/sharing_requests/`,
                {
                    message: values.message,
                    equipment_to_shared: values.equipment_to_shared,
                },
            );
            if (response.status === 200) {
                const response = {
                    status: 'success',
                    message: 'Operation réalisée avec succès',
                };
                return response;
            }
        } catch (error) {
            switch (error?.response?.status) {
                case 400:
                    if (typeof error?.response?.data?.detail === 'object') {
                        if (error?.response?.data?.detail[0]?.length > 0) {
                            const response = {
                                status: 'error',
                                message: error?.response?.data?.detail[0],
                            };
                            return response;
                        } else if (
                            error?.response?.data?.detail
                                ?.equipment_to_shared[0]
                        ) {
                            const response = {
                                status: 'error',
                                message:
                                    error?.response?.data?.detail
                                        ?.equipment_to_shared[0],
                            };
                            return response;
                        }
                    } else if (
                        typeof error?.response?.data?.detail === 'string'
                    ) {
                        const response = {
                            status: 'error',
                            message: error?.response?.data?.detail,
                        };
                        return response;
                    }
                default:
                    const response = {
                        status: 'error',
                        message: 'Un problème est survenu',
                    };
                    return response;
            }
        }
    },
);

const sharedEquipmentSlice = createSlice({
    name: 'sharedEquipment',
    initialState: {
        permissionsList: [],
        sharedEquipment: [],
        sharedEquipmentCount: 0,
        sharedGroups: [],
        hasMoreGroups: true,
        sharedSites: [],
        hasMoreSites: true,
        sharedEquipmentList: [],
        sharedEquipmentListCount: 0,
        sharingRequestList: [],
        messageAlert: [],
        loading: true,
    },
    extraReducers: (builder) => {
        builder
            // Retourne la liste des permissions partageable d'un groupe
            .addCase(permissions_by_group_list_get.pending, (state, action) => {
                state.permissionsList = [];
            })
            .addCase(
                permissions_by_group_list_get.fulfilled,
                (state, action) => {
                    state.permissionsList = action.payload.results;
                    state.loading = false;
                },
            )
            .addCase(
                permissions_by_group_list_get.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            // Récupère la liste des équipements partagés où l'on a accès.
            .addCase(
                shared_equipments_paginated_get.pending,
                (state, action) => {
                    state.sharedEquipment = [];
                    state.sharedEquipmentCount = 0;
                },
            )
            .addCase(
                shared_equipments_paginated_get.fulfilled,
                (state, action) => {
                    state.sharedEquipment = action.payload.results;
                    state.sharedEquipmentCount = action.payload.count;
                    state.loading = false;
                },
            )
            .addCase(
                shared_equipments_paginated_get.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            // Récupère la liste des groupes partagés où l'on a accès.
            .addCase(shared_groups_paginated_get.pending, (state, action) => {
                if (action.meta.arg.offset === 0) {
                    state.sharedGroups = [];
                    state.hasMoreGroups = true;
                }
            })
            .addCase(shared_groups_paginated_get.fulfilled, (state, action) => {
                state.sharedGroups = action.payload;
                state.hasMoreGroups = action.payload.next;
                state.loading = false;
            })
            .addCase(shared_groups_paginated_get.rejected, (state, action) => {
                state.loading = false;
            })

            // Récupère la liste des sites partagés où l'on a accès
            .addCase(shared_sites_paginated_get.pending, (state, action) => {
                if (action.meta.arg.offset === 0) {
                    state.sharedSites = [];
                    state.hasMoreSites = true;
                }
            })
            .addCase(shared_sites_paginated_get.fulfilled, (state, action) => {
                state.sharedSites = action.payload;
                state.hasMoreSites = action.payload.next;
                state.loading = false;
            })
            .addCase(shared_sites_paginated_get.rejected, (state, action) => {
                state.loading = false;
            })

            // Récupère la liste des partages d'un équipement
            .addCase(
                sharing_by_group_paginated_get.pending,
                (state, action) => {
                    state.sharedEquipmentList = [];
                    state.sharedEquipmentListCount = 0;
                },
            )
            .addCase(
                sharing_by_group_paginated_get.fulfilled,
                (state, action) => {
                    state.sharedEquipmentList = action.payload.results;
                    state.sharedEquipmentListCount = action.payload.count;
                    state.loading = false;
                },
            )
            .addCase(
                sharing_by_group_paginated_get.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            // Supprime une permission d'un partage
            .addCase(
                sharing_permissions_by_group_delete.fulfilled,
                (state, action) => {
                    state.loading = false;
                },
            )
            .addCase(
                sharing_permissions_by_group_delete.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            // Récupère la liste des demandes de partage d'un équipement
            .addCase(sharing_request_list_get.pending, (state, action) => {
                state.sharingRequestList = [];
            })
            .addCase(sharing_request_list_get.fulfilled, (state, action) => {
                state.sharedEquipmentList = action.payload.results;
                state.loading = false;
            })
            .addCase(sharing_request_list_get.rejected, (state, action) => {
                state.loading = false;
            })

            // Demande d'obtention d'un partage.
            .addCase(sharing_request_create.pending, (state, action) => {
                state.messageAlert = [];
            })
            .addCase(sharing_request_create.fulfilled, (state, action) => {
                state.messageAlert = action.payload;
                state.loading = false;
            })
            .addCase(sharing_request_create.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export default sharedEquipmentSlice.reducer;
