import React, { useState } from 'react';
import AddRecharge from '../components/discountRecharge/AddRecharge';
import AlertMessage from '../../modUtils/components/AlertMessage';
import { useDispatch, useSelector } from 'react-redux';
import DiscountCard from '../components/discountRecharge/DiscountCard';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from '../../modEntity/assets/images/trash-can.svg';
import {
    delete_discount_recharge_detail_by_group,
    get_discount_recharge_by_group_list,
} from '../slices/walletLocalSlice';
import { message } from 'antd';
import ConfirmationModal from '../../modUtils/components/confirmationModal/ConfirmationModal';

export default function DiscountsPage() {
    const [loading, setLoading] = useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [discountId, setDiscountId] = useState();
    const { discounts } = useSelector((store) => store.walletLocal);
    const { t } = useTranslation();
    const { groupId } = useSelector((store) => store.manager);
    const dispatch = useDispatch();

    const calculateCredit = (amount, bonus) => {
        return amount + amount * (bonus / 100);
    };

    const deleteDiscount = async (discountId) => {
        setLoading(true);
        try {
            setConfirmLoading(true);
            await dispatch(
                delete_discount_recharge_detail_by_group({
                    groupId,
                    discountId: discountId,
                }),
            ).unwrap();
            setConfirmLoading(false);
            setConfirmModalVisible(false);
            getDiscounts(0, 10);
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error.status}
                        alertMessage={t('discounts.errorMessage')}
                    />
                ),
            });
            setConfirmModalVisible(false);
        }
    };

    const handleClick = (discountId) => {
        setDiscountId(discountId);
        setConfirmModalVisible(true);
    };

    const getDiscounts = async () => {
        await dispatch(
            get_discount_recharge_by_group_list({
                groupId,
            }),
        ).unwrap();
    };

    const handleConfirmCancel = () => {
        setConfirmModalVisible(false);
    };
    return (
        <div className='mt-12'>
            <AddRecharge />
            <div className='grid gap-6 grid-cols-6 overflow-y-auto max-h-screen min-h-96 pr-4 my-6 '>
                {discounts.length > 0 ? (
                    discounts.map((data) => {
                        const amount = Math.floor(data.amount_min);
                        const bonus = Math.floor(data.discount_percent);
                        const credit = calculateCredit(amount, bonus);
                        const discountId = data.id;
                        const title = data.name;
                        return (
                            <div className='col-span-2'>
                                <div className='relative '>
                                    <DiscountCard
                                        amount={amount}
                                        title={title}
                                        bonus={bonus}
                                        credit={credit}
                                        cornerIcon={
                                            <DeleteIcon className='w-[12px] cursor-pointer m-2' />
                                        }
                                        onClickCornerIcon={() =>
                                            handleClick(discountId)
                                        }
                                    />
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className='flex col-span-6 justify-center'>
                        {' '}
                        {t('discounts.noDiscountCard')}
                    </div>
                )}
            </div>
            <ConfirmationModal
                onOk={() => deleteDiscount(discountId)}
                onCancel={handleConfirmCancel}
                visible={confirmModalVisible}
                confirmLoading={confirmLoading}
                content={<div>{t('discounts.confirmDelete')}</div>}
            ></ConfirmationModal>
        </div>
    );
}
