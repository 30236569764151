import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NoData from 'ck_commun/src/app/modUtils/components/noData/NoData';
import SharedEquipmentList from '../../components/sharedEquipmentList/SharedEquipmentList';
import { Spinner } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import { Pagination } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import FormFilterSharedEquipment from 'ck_commun/src/app/modSharedEquipment/components/FormFilterSharedEquipment';
import { shared_equipments_paginated_get } from 'ck_commun/src/app/modSharedEquipment/slices/sharedEquipmentSlice';
import EquipmentAccessResquestForm from 'ck_commun/src/app/modSharedEquipment/components/equipmentAccessResquestForm/EquipmentAccessResquestForm';

export default function SharedEquipmentPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { sharedEquipment, sharedEquipmentCount } = useSelector(
        (state) => state.sharedEquipment,
    );
    const [loading, setLoading] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const { groupId } = useSelector((state) => state.manager);

    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const [filterValues, setFilterValues] = useState({});

    async function fetchDataSharedEquipments(limit, offset) {
        await dispatch(
            shared_equipments_paginated_get({
                limit: limit,
                offset: offset,
                ...filterValues,
            }),
        );
    }

    // Fonction de gestion de changement de pagination
    function handlePaginationChange(page, pageSize) {
        // Mettez à jour les valeurs de limit et offset en fonction de la page et de la taille de page
        const newOffset = (page - 1) * pageSize;
        setOffset(newOffset);
        if (newOffset < 0) {
            setCurrentPage(1);
        } else {
            setCurrentPage(page);
        }
        setLimit(pageSize);

        // Effectuez une requête à l'API avec les nouvelles valeurs de limit et offset pour obtenir les données correspondantes
        setLoadingSearch(true);
        fetchDataSharedEquipments(limit, newOffset);
        setLoadingSearch(false);
    }

    useEffect(() => {
        if (groupId) {
            setLoading(true);
            fetchDataSharedEquipments(limit, 0);
            setLoading(false);
        }
    }, [groupId, i18next.language]);

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <FormFilterSharedEquipment
                        setFilterValues={setFilterValues}
                    />

                    <div className='text-center'>
                        <h1>{t('SharedEquipmentPage.listOfEquipment')}</h1>
                    </div>

                    <div className='flex flex-raw justify-end items-baseline mb-5'>
                        <span className='font-semibold mr-2.5 text-black'>
                            {t('SharedEquipmentPage.missingEquipment')}
                        </span>
                        <EquipmentAccessResquestForm />
                    </div>

                    {loadingSearch ? (
                        <Spinner />
                    ) : sharedEquipment?.length > 0 ? (
                        <>
                            <Pagination
                                defaultCurrent={currentPage}
                                defaultPageSize={limit}
                                showQuickJumper={true}
                                showTotal={false}
                                total={sharedEquipmentCount}
                                onChange={handlePaginationChange}
                                onShowSizeChange={handlePaginationChange}
                                hideOnSinglePage={true}
                            />
                            {sharedEquipment?.map((equipement) => (
                                <SharedEquipmentList
                                    equipment={equipement}
                                    loading={loading}
                                />
                            ))}
                        </>
                    ) : (
                        <NoData text={t('SharedEquipmentPage.noData')} />
                    )}
                </>
            )}
        </>
    );
}
