import React, { useState } from 'react';

export default function Widget({ title, content, className = '' }) {
    const [isVisible, setIsVisible] = useState(true);
    function hideWidget() {
        setIsVisible(false);
    }

    return (
        isVisible && (
            <div
                className={`${className} border border-solid border-border-color rounded-md shadow-[0px_17px_30px_#0000001A]
                max-w-full w-[300px] min-w-[250px] h-[200px]`}
            >
                <div className='flex flex-row items-center justify-between border-0 border-b border-solid border-border-color px-2.5 py-1.5'>
                    <div className='uppercase font-semibold text-xs text-card-foreground-color'>
                        {title}
                    </div>
                    <div onClick={hideWidget}> X </div>
                </div>

                <div className='p-2.5'>{content}</div>
            </div>
        )
    );
}
