import React from 'react';
import { Label } from '../componentsLibrary';

export default function Textarea({
    label,
    name,
    value,
    defaultValue,
    onBlur,
    onChange,
    className,
    rows,
    ...props
}) {
    return (
        <>
            {label && <Label label={label} className='text-black' />}

            <textarea
                name={name}
                rows={rows}
                value={value}
                defaultValue={defaultValue}
                onBlur={onBlur}
                onChange={onChange}
                className={`bg-background-color w-full border border-input-color
                    focus:outline-none focus:ring-1 focus:ring-input-color
                    focus:border-input-color rounded-md ${className}`}
            >
                {props.children}
            </textarea>
        </>
    );
}
