import React from 'react';
import LoadingSpinner from '../componentsLibrary/spinner/LoadingSpinner';

export default function LoadingMessageSelect({ className }) {
    return (
        <>
            <LoadingSpinner className={className} />
            <span>Loading...</span>
        </>
    );
}
