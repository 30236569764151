import React from 'react';
import { Tooltip } from 'antd';

export default function CKToolTip({
    placement,
    content,
    trigger,
    arrow = true,
    ...props
}) {
    return (
        <Tooltip
            placement={placement}
            trigger={trigger}
            title={content}
            arrow={arrow}
        >
            {props.children}
        </Tooltip>
    );
}
