import React, { useEffect, useState } from 'react';
import { Marker } from 'react-leaflet';
import { Icon } from 'leaflet';
import { renderToString } from 'react-dom/server';
import { FaMapMarkerAlt } from 'react-icons/fa';
import SiteManageModal from '../../../modEquipment/components/siteManageModal/SiteManageModal';

export default function SiteMarker({ site }) {
    const [modalVisible, setModalVisible] = useState(false);
    const handleModal = () => {
        setModalVisible(true);
    };
    const closeModal = () => {
        setModalVisible(false);
    };

    const getCSSVariable = (variable) => {
        return getComputedStyle(document.documentElement).getPropertyValue(
            variable,
        );
    };
    const primaryColor = getCSSVariable('--primary-color');

    // Crée une icône personnalisée en SVG depuis React-Icons
    const customIcon = new Icon({
        iconUrl: `data:image/svg+xml,${encodeURIComponent(
            renderToString(<FaMapMarkerAlt size={40} color={primaryColor} />),
        )}`,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
    });

    return site.location_latitude && site.location_longitude ? (
        <Marker
            position={{
                lat: site.location_latitude,
                lng: site.location_longitude,
            }}
            icon={customIcon}
            eventHandlers={{
                click: (e) => {
                    handleModal();
                },
            }}
        >
            <SiteManageModal
                site={site}
                modalVisible={modalVisible}
                handleModal={handleModal}
                closeModal={closeModal}
            />
        </Marker>
    ) : null;
}
