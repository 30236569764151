import React, { useState, useRef, useCallback, useMemo } from 'react';
import { Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ZoomSvg } from 'ck_commun/src/app/modUtils/assets/images/zoom.svg';
import InputBaseForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/InputBaseForm';

const { Option } = Select;

export default function SelectPaginatedForm({
    hasMore = true,
    name = 'entity',
    messages = {},
    className = '',
    markerRequired = true,
    renderOption,
    fetchOptions, // Fonction de récupération des données (renommée pour éviter le conflit avec fetch)
    options = [], // Liste des options déjà chargées
    defaultValue,
    onChange,
}) {
    const { t } = useTranslation();
    const dropdownRef = useRef(null);
    const defaultLimit = 9;
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // Fusionne les messages par défaut avec ceux passés en props
    const mergedMessages = useMemo(
        () => ({
            label: 'selectForm.label',
            help: 'selectForm.help',
            required: 'selectForm.required',
            placeholder: 'selectForm.placeholder',
            ...messages,
        }),
        [messages],
    );

    /**
     * Charge les options paginées depuis l'API
     * @param {string} search - Texte recherché (facultatif)
     * @param {boolean} reset - Indique si la recherche doit être réinitialisée
     */
    const loadOptions = useCallback(
        async (search = '', reset = false, nextPage = 1) => {
            setLoading(true);
            const offset = (nextPage - 1) * defaultLimit;

            try {
                await fetchOptions(defaultLimit, offset, search);
                if (reset) setPage(1);
            } finally {
                setLoading(false);
            }
        },
        [fetchOptions],
    );

    /**
     * Gère la recherche dans le champ
     * @param {string} value - Texte saisi par l'utilisateur
     */
    const handleSearch = useCallback(
        (value) => {
            if (value.length >= 3 && !loading) {
                setPage(1);
                loadOptions(value, true, 1);
                setSearch(value);
            }
        },
        [loadOptions],
    );

    /**
     * Détecte le scroll en bas du menu et charge plus d'options si possible
     * @param {Event} event - Événement de défilement
     */
    const handleDropdownScroll = useCallback(
        (event) => {
            if (!loading && hasMore) {
                const { scrollTop, scrollHeight, clientHeight } = event.target;
                if (scrollTop + clientHeight >= scrollHeight - 10) {
                    setPage((prevPage) => prevPage + 1);
                    loadOptions(search, false, page + 1);
                }
            }
        },
        [loading, hasMore, loadOptions],
    );

    /**
     * Gère l'affichage du menu déroulant
     * @param {boolean} open - Indique si le menu est ouvert ou fermé
     */
    const handleDropdownVisibleChange = (open) => {
        setIsDropdownOpen(open);
        if (open && options.length === 0 && !loading) {
            loadOptions(); // Charge les options uniquement si elles ne sont pas déjà en cours de chargement
        }
    };

    return (
        <InputBaseForm
            name={name}
            messages={mergedMessages}
            className={className}
            markerRequired={markerRequired}
            rules={[
                {
                    required: markerRequired,
                    message: t(mergedMessages.required),
                },
            ]}
        >
            <Select
                showSearch
                onChange={onChange}
                filterOption={false}
                onSearch={handleSearch}
                onPopupScroll={handleDropdownScroll}
                onDropdownVisibleChange={handleDropdownVisibleChange}
                suffixIcon={<ZoomSvg className='text-input-color' />}
                open={isDropdownOpen || loading}
                defaultValue={defaultValue}
                placeholder={t(mergedMessages?.placeholder)}
                dropdownRender={(menu) => (
                    <div ref={dropdownRef}>
                        {menu}
                        {loading && (
                            <div className='text-center p-2'>
                                <Spin />
                            </div>
                        )}
                    </div>
                )}
            >
                {options.map((option) => (
                    <Option key={option.value} value={option.value}>
                        {renderOption ? renderOption(option) : option.label}
                    </Option>
                ))}
            </Select>
        </InputBaseForm>
    );
}
