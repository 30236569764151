import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import LabelForm from 'ck_commun/src/app/modUtils/components/componentsLibrary/form/components/LabelForm';

export default function InputBaseForm({
    name,
    messages = {},
    className = '',
    markerRequired = false,
    rules = [],
    ...props
}) {
    return (
        <div className={className}>
            <LabelForm messages={messages} markerRequired={markerRequired} />

            <Form.Item name={name} rules={rules} {...props}>
                {props.children}
            </Form.Item>
        </div>
    );
}

InputBaseForm.propTypes = {
    name: PropTypes.string,
    messages: PropTypes.shape({
        label: PropTypes.string,
        help: PropTypes.string,
        addHelp: PropTypes.string,
        updateHelp: PropTypes.string,
        invalid: PropTypes.string,
    }),
    className: PropTypes.string,
    markerRequired: PropTypes.bool,
    rules: PropTypes.arrayOf(PropTypes.object),
};

InputBaseForm.defaultProps = {
    messages: {},
    className: '',
    markerRequired: false,
    rules: [],
};
