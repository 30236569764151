import {
    APP_NAME_CODE,
    CK_SERVER_DEV_PASSWORD,
    CK_SERVER_PROD_PASSWORD,
} from '../../../../../../utils/constants';
import { IS_PROD } from '../../modUtils/utils/constants';
import createHttpInstance from './httpCommon';
import i18n from 'i18next';

const url = '/api/v1';
const headers = {
    'Content-type': 'application/json',
};
const httpConfig = createHttpInstance(url, headers);

// Add a request interceptor to dynamically set the Accept-Language header
httpConfig.interceptors.request.use(
    (config) => {
        // Update the Accept-Language header with the current language from i18n
        config.headers['Accept-Language'] = i18n.language || 'fr';
        config.headers['Ck-App-Key'] = APP_NAME_CODE;
        config.headers['Ck-App-Password'] = IS_PROD
            ? CK_SERVER_PROD_PASSWORD
            : CK_SERVER_DEV_PASSWORD;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

// Listen for language changes and update the Accept-Language header
i18n.on('languageChanged', (lng) => {
    // Update the Accept-Language header dynamically when language changes
    httpConfig.defaults.headers['Accept-Language'] = lng;
});

const createHttpRequest = async (method, url, options) => {
    try {
        const response = await httpConfig[method](url, options);
        return response;
    } catch (error) {
        throw error;
    }
};

export const httpGet = (url, params = {}) =>
    createHttpRequest('get', url, params);
export const httpPost = (url, data) => createHttpRequest('post', url, data);
export const httpPut = (url, data) => createHttpRequest('put', url, data);
export const httpDelete = (url, data) => createHttpRequest('delete', url, data);

export default httpConfig;
