import React from 'react';
import { useTranslation } from 'react-i18next';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import CKToolTip from 'ck_commun/src/app/modUtils/components/CKToolTip/CKToolTip';

export default function HelpForm({ messages, markerRequired }) {
    const { t } = useTranslation();
    const requiredMessage = t('helpForm.markerRequired');
    const helpBase = messages?.help ? t(messages?.help) : null;

    // Déterminer le message d'aide
    const helpMessage =
        messages?.updateHelp || messages?.addHelp
            ? `${helpBase}\n${t(messages?.addHelp)}`
            : helpBase || null;

    // Construire le message final pour le tooltip
    let tooltipMessage = '';
    if (markerRequired && helpMessage) {
        tooltipMessage = `${requiredMessage}\n${helpMessage}`;
    } else if (helpMessage) {
        tooltipMessage = helpMessage;
    } else if (markerRequired) {
        tooltipMessage = requiredMessage;
    }

    return tooltipMessage ? (
        <CKToolTip placement='top' trigger='hover' content={tooltipMessage}>
            <HiQuestionMarkCircle
                className='cursor-help text-primary-color'
                fontSize={16}
            />
        </CKToolTip>
    ) : null;
}
