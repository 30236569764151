import { useTranslation } from 'react-i18next';
import {
    Input,
    Modal,
    Textarea,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { Formik } from 'formik';
import Button from '../../../modUtils/components/buttons/Button';

const ModalSendTicketMail = ({
    toggleModalSendTicketMail,
    modalSendTicketMail,
}) => {
    const { t } = useTranslation();
    const handleSubmit = (values, resetForm) => {
        toggleModalSendTicketMail();
    };

    return (
        <Modal
            isOpen={modalSendTicketMail}
            onClose={toggleModalSendTicketMail}
            title={t('modalViewTickets.transSendByMail')}
        >
            <Formik
                initialValues={{
                    email: null,
                    promotion: null,
                    message: null,
                }}
                onSubmit={(values, { resetForm }) => {
                    handleSubmit(values, resetForm);
                }}
            >
                {({ values, resetForm }) => (
                    <div className='mt-8 w-max mx-auto'>
                        <p className='font-semibold text-sm'>
                            {t(
                                'modalViewTickets.modalCloseTicketsGroup.transDate',
                            )}
                        </p>
                        <Input
                            type={'email'}
                            className={'mt-4'}
                            value={values.email}
                            label={t(
                                'createPromotionModal.stepperCreatePromotion.transEmail',
                            )}
                        />
                        <Input
                            className={'mt-4'}
                            placeholder={'100'}
                            value={values.promotion}
                            label={t('modalViewTickets.transFilterPromotion')}
                        />
                        <Textarea
                            label={t('transMessage')}
                            rows={10}
                            className={
                                'p-2 border-[#4D4D4D42] border-solid border bg-[#EEEFF1] text-[#25292D]'
                            }
                        />
                        <div className='flex justify-center mt-8'>
                            <Button
                                onClick={() => handleSubmit(values, resetForm)}
                                color={'success'}
                            >
                                {t('transButtonSend')}
                            </Button>
                        </div>
                    </div>
                )}
            </Formik>
        </Modal>
    );
};

export default ModalSendTicketMail;
