import React from 'react';
import ButtonDisabled from 'ck_commun/src/app/modUtils/components/buttons/ButtonDisabled';
import LoadingSpinner from 'ck_commun/src/app/modUtils/components/componentsLibrary/spinner/LoadingSpinner';

export default function ButtonLoading({ onClick, ...props }) {
    return (
        <ButtonDisabled
            size={props.size}
            onClick={onClick}
            className={props.className}
        >
            {props.children}
            <LoadingSpinner />
        </ButtonDisabled>
    );
}
