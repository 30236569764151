import React from 'react';
import DevicesManageModal from '../devicesManageModal/DevicesManageModal';
import EquipmentConnectionToServerButton from '../equipmentConnectionToServerButton/EquipmentConnectionToServerButton';
import ModalFixingEquipment from '../modalFixingEquipment/ModalFixingEquipment';
import MachineManageModal from '../../../modImachine/components/machineManageModal/MachineManageModal';
import SharedEquipmentManageModal from '../../../modSharedEquipment/components/sharedEquipmentManageModal/SharedEquipmentManageModal';
import DropdownActionsMenu from '../../../modUtils/components/dropdownActionsMenu/DropdownActionsMenu';
import AdminLink from '../../../modUtils/components/adminLink/AdminLink';
import { useTranslation } from 'react-i18next';
import EquipmentUpdateModal from '../equipmentUpdateModal/EquipmentUpdateModal';

export default function EquipmentActionsMenu({
    siteId,
    siteName,
    equipment,
    visible,
    hide,
    handleVisibleChange,
    isEquipmentFixingModalVisible,
    setIsEquipmentFixingModalVisible,
    verifyPermission,
    verifyGroupPermission,
}) {
    const { t } = useTranslation();

    const openModalFixing = () => {
        setIsEquipmentFixingModalVisible(true);
        hide();
    };

    //ELEMENTS DU MENU AFFICHE DANS LE POPOVER (LISTE DES ACTIONS POSSIBLES)
    let menuItems = [
        // TODO CH rappelle qu'il faudra gerer le soucis de dépendances des modules dans ce composant

        <p
            className={`${
                !verifyPermission || !verifyGroupPermission ? 'disabled' : ''
            }`}
            onClick={() => openModalFixing()}
        >
            {t('equipmentActionsMenu.troubleshooting')}
        </p>,

        <DevicesManageModal
            closeModal={hide}
            siteName={siteName}
            equipment={equipment}
            buttonType='popover'
        />,
        <EquipmentUpdateModal
            closeModal={hide}
            buttonType='popover'
            equipment={equipment}
        />,
        ,
        <EquipmentConnectionToServerButton equipment={equipment} />,
        <SharedEquipmentManageModal
            siteId={siteId}
            closeModal={hide}
            equipment={equipment}
        />,
        <AdminLink
            adminPath={`mod_equipment/equipment/${equipment.id}/change/`}
            onClick={hide}
        />,
    ];

    //traitement lié aux equipements de type machines
    const EquipmentTypeWithMachinesList = [10, 11, 12];
    if (EquipmentTypeWithMachinesList.includes(equipment?.type)) {
        menuItems = [
            ...menuItems.slice(0, 1),
            <MachineManageModal
                siteId={siteId}
                closeModal={hide}
                equipment={equipment}
            />,
            ...menuItems.slice(1),
        ];
    }

    return (
        <>
            <DropdownActionsMenu
                menuItems={menuItems}
                handleVisibleChange={handleVisibleChange}
                visible={visible}
            />
            {isEquipmentFixingModalVisible && (
                <ModalFixingEquipment
                    siteId={siteId}
                    equipment={equipment}
                    isModalVisible={isEquipmentFixingModalVisible}
                    setIsModalVisible={setIsEquipmentFixingModalVisible}
                />
            )}
        </>
    );
}
