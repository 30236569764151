import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'ck_commun/src/app/modUtils/components/select/Select';
import { ePromotionStatus } from 'ck_commun/src/app/modPromotion/assets/js/ennum';

export default function SelectSupportStatus({ onChange, defaultValue }) {
    const { t } = useTranslation();

    // Génération dynamique des options du Select
    const filterOptions = Object.entries(ePromotionStatus).map(
        ([key, value]) => ({
            id: key, // Utilisation de la clé numérique comme valeur
            name: t(value),
        }),
    );

    return (
        <Select
            className='!mb-0 !h-[40px]'
            value={defaultValue}
            onChange={onChange}
            options={filterOptions}
        />
    );
}
