import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { httpGet, httpPost } from '../../modApi/apis/httpConfig';

// retourner la liste des contraintes identification d'une configuration
export const get_constraint_identification = createAsyncThunk(
    'mod_profil/get_constraint_identification',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraint_identification/`,
            );
            return response.data;
        } catch (error) {
            if (
                error?.response?.status === 404 &&
                error?.response?.data?.detail === 'Object Not found'
            ) {
                return error?.response?.data?.detail;
            } else {
                return rejectWithValue({
                    status: error.response.status,
                    data: error.response.data,
                });
            }
        }
    },
);

// Créer une contrainte d'identification
export const create_constraint_identification = createAsyncThunk(
    'mod_profil/create_constraint_identification',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPost(
                `mod_profil/groups/${values.groupId}/configurations/${values.configurationId}/constraint_identification/`,
                {
                    name: values.name,
                    equipment_type: values.equipmentType,
                    is_global: values.isGlobal,
                    formats_authorized: values.formatsAuthorized,
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

const constraintIdentificationSlice = createSlice({
    name: 'constraintIdentification',
    initialState: {
        loading: true,
        constraintsIdentification: [],
        constraintsIdentificationDetail: {},
    },
    extraReducers: (builder) => {
        builder
            /* get_constraint_identification */
            .addCase(get_constraint_identification.pending, (state, action) => {
                state.constraintsIdentification = [];
            })
            .addCase(
                get_constraint_identification.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.constraintsIdentification = action.payload;
                },
            )
            .addCase(
                get_constraint_identification.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* create_constraint_identification */
            .addCase(
                create_constraint_identification.pending,
                (state, action) => {
                    state.constraintsIdentification = [];
                },
            )
            .addCase(
                create_constraint_identification.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.constraintsIdentification = action.payload;
                },
            )
            .addCase(
                create_constraint_identification.rejected,
                (state, action) => {
                    state.loading = false;
                },
            );
    },
});

export default constraintIdentificationSlice.reducer;
