import { IS_PROD } from '../../utils/constants';

function menuGenerate(menuData) {
    // Data pour generer le menu de la leftSideBar

    // fonction pour generer le menu de prod

    const menuItems = ProdMenuGet(menuData);

    return menuItems;
}

export default menuGenerate;

function ProdMenuGet(items) {
    if (!IS_PROD) {
        return items;
    }
    return items
        .filter((item) => item.prodVisible)
        .map((item) => ({
            ...item,
            submenus: item.submenus ? ProdMenuGet(item.submenus) : undefined,
        }));
}
