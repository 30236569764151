/* AUTO_FILE_GENERATED
Fichier généré automatiquement à partir de: mod_commun_global/python/system/e_message_send_type.py
Aucune modification manuelle acceptée
*/

// Type d'envoie d'un message/d'une information
// 
// Utilisée pour l'envoie de ticket. Mais on peut également imaginer l'utiliser
// pour envoyer des informations/notifications à un utilisateur (par sms, où même notification
// téléphone)
const eMessageSendType = Object.freeze({
  // SMS
  1: 'eMessageSendType.sms',

  // EMail
  2: 'eMessageSendType.email',
});

export default eMessageSendType;
