import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get_employees_by_group_paginated } from '../slices/managerSlice';
import ButtonSubmit from '../../modUtils/components/buttons/ButtonSubmit';
import EmployeeCard from '../components/EmployeeCard/EmployeeCard';
import {
    // AlertMessage,
    Button,
    Pagination,
    Select,
    Spinner,
} from '../../modUtils/components/componentsLibrary/componentsLibrary';
import EmployeeModal from '../components/EmployeeCard/EmployeeModal/EmployeeModal';
import ButtonAdd from '../../modUtils/components/buttons/ButtonAdd';
import i18next from 'i18next';
import { message } from 'antd';
import AlertMessage from '../../modUtils/components/AlertMessage';

export default function AccessListPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { groupId, groupEmployees } = useSelector((state) => state.manager);
    const [searchValue, setSearchValue] = useState('');
    const [employees, setEmployees] = useState([]);
    const [totalEmployees, setTotalEmployees] = useState(0);
    const [offset, setOffset] = useState(0);
    const options = [
        {
            value: 'firstName',
            label: t('manageEmployees.optionsFilterFirstName'),
        },
        {
            value: 'lastName',
            label: t('manageEmployees.optionsFilterLastName'),
        },
        { value: 'role', label: t('manageEmployees.optionsFilterRole') },
    ];
    const [sortOption, setSortOption] = useState(options[1].value);
    const ref = useRef();
    const [mode, setMode] = useState('create');
    const [modalVisible, setModalVisible] = useState(false);
    const [limit, setLimit] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const handleEditPermissionClick = (employee) => {
        setSelectedEmployee(employee);
        setMode('updatePermission');
        setModalVisible(true);
    };
    const handleAddClick = () => {
        setSelectedEmployee(null);
        setMode('create');
        setModalVisible(true);
    };

    const getEmployees = async () => {
        setLoading(true);
        try {
            const results = await dispatch(
                get_employees_by_group_paginated({
                    groupId: groupId,
                    limit: limit,
                    offset: offset,
                }),
            ).unwrap();
            const employeeSorted = sortEmployees(results, sortOption);
            setTotalEmployees(results.length);
            setEmployees(employeeSorted);
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('manageEmployees.errorMessage')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        } finally {
            setLoading(false);
        }
    };
    const sortEmployees = (employeeList, sortBy) => {
        return [...employeeList].sort((a, b) => {
            if (sortBy === 'firstName') {
                return a.user.first_name
                    .toLowerCase()
                    .localeCompare(b.user.first_name.toLowerCase());
            } else if (sortBy === 'lastName') {
                return a.user.last_name
                    .toLowerCase()
                    .localeCompare(b.user.last_name.toLowerCase());
            } else if (sortBy === 'role') {
                return a.user.is_admin === b.user.is_admin
                    ? 0
                    : a.user.is_admin
                      ? -1
                      : 1;
            }

            return 0;
        });
        return employeeList;
    };
    const handleSortChange = (value) => {
        setSortOption(value);
        const sortedEmployees = sortEmployees(employees, value);
        setEmployees(sortedEmployees);
    };
    useEffect(() => {
        if (groupId) {
            getEmployees({ offset: 0, limit });
        }
    }, [groupId, limit]);

    const handlePaginationChange = (page, pageSize) => {
        // Mettez à jour les valeurs de limit et offset en fonction de la page et de la taille de page
        const newOffset = (page - 1) * pageSize;
        setOffset(newOffset);
        if (newOffset < 0) {
            setCurrentPage(1);
        } else {
            setCurrentPage(page);
        }
        setLimit(pageSize);
        // Effectuez une requête à l'API avec les nouvelles valeurs de limit et offset pour obtenir les données correspondantes
        getEmployees({ offset: newOffset, limit: pageSize });
    };
    const handleSearchInputChange = (event) => {
        setSearchValue(event.target.value);
        if (event.target.value.trim() === '') {
            setEmployees(groupEmployees);
        }
    };
    const handleSearchButton = () => {
        if (groupEmployees) {
            const filtred = groupEmployees.filter((employee) => {
                const firsName = employee.user.first_name
                    .toLowerCase()
                    .includes(searchValue.toLocaleLowerCase());
                const lastName = employee.user.last_name
                    .toLowerCase()
                    .includes(searchValue.toLocaleLowerCase());
                return firsName || lastName;
            });
            setEmployees(filtred);
        }
    };

    return (
        <div
            style={{
                layout: {
                    content: { overflow: 'hidden ' },
                },
            }}
        >
            <div className='filters flex flex-row items-end flex-wrap sm:max-w-[calc(100vw-500px)]'>
                <div className='search-site flex-display flex-row'>
                    <input
                        id='searchInput'
                        type='text'
                        className='search-site'
                        value={searchValue}
                        onChange={handleSearchInputChange}
                        onBlur={handleSearchInputChange}
                        placeholder={t('manageEmployees.searchPlaceholder')}
                    />
                    <img
                        src={process.env.PUBLIC_URL + '/img/design/zoom.svg'}
                        alt='zoom'
                    />
                </div>
                <ButtonSubmit onClick={handleSearchButton} className='ml-3' />
            </div>
            <div className='relative'>
                <div className='flex justify-start'>
                    <Select
                        selectLabel={t('manageEmployees.labelSelect')}
                        placeholder={
                            options.find(
                                (option) => option.value === sortOption,
                            )?.label
                        }
                        value={sortOption}
                        onSelect={handleSortChange}
                        options={options}
                        style={{
                            select: { width: '160px' },
                            selectOptions: { width: '160px' },
                        }}
                    />
                </div>
                <div className='flex justify-end'>
                    {/* TODO JE COMMENTE CELA POUR L'INSTANT CAR NON FONCTIONNEL */}
                    {/* <ButtonAdd
                        className='uppercase'
                        onClick={handleAddClick}
                        label={t('employeeInfoModal.addTitle')}
                    /> */}
                </div>

                <div className='table-pre-text m-2'>
                    {totalEmployees} {t('employee(s)')}
                </div>

                <div className='flex justify-center mt-8'>
                    <h1 className='font-bold'>
                        {t('manageEmployees.manageEmployeeTitle')}
                    </h1>
                </div>
                {loading ? (
                    <Spinner />
                ) : (
                    <div className='grid justify-items-center grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4 mt-8 lg:px-48 gap-x-[20rem] gap-y-3'>
                        {employees.length > 0 ? (
                            employees?.map((employee) => (
                                <EmployeeCard
                                    key={employee.id}
                                    firstName={employee.user.first_name}
                                    lastName={employee.user.last_name}
                                    email={employee.user.email}
                                    image={employee.user.image}
                                    isAdmin={employee.user.is_admin}
                                    onEditPermissionClick={() =>
                                        handleEditPermissionClick(employee)
                                    }
                                />
                            ))
                        ) : (
                            <div>
                                <p>{t('manageEmployees.employeeNotFound')}</p>
                            </div>
                        )}
                        {loading}
                    </div>
                )}
            </div>
            {modalVisible && (
                <EmployeeModal
                    mode={mode}
                    employeeData={selectedEmployee}
                    refresh={getEmployees}
                    isOpen={modalVisible} // Pass the modal visibility
                    onClose={() => setModalVisible(false)} // Function to close the modal
                />
            )}
        </div>
    );
}
